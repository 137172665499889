import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import Head from "next/head";
import Script from "next/script";
import {
  AppState,
  AppStateProvider,
  DialogContextProvider,
  Footer,
  Header,
  LinkContext,
  useAppState,
  WindowDimensionsProvider,
  LocaleModalProps,
  Dialog,
  Link,
} from "@travellocal/ui";
import { getStaticPageContent, NextLink } from "..";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { getCountriesWithPublishedTrips } from "../../utils/api/site2";

import css from "./Page.module.scss";
import {
  TYPEFORM_SUBSCRIBE_DE_LIVE_CODE,
  TYPEFORM_SUBSCRIBE_EN_LIVE_CODE,
} from "utils/typeformCodes";
import { getSubscribe } from "utils/api/cms";
import { GetStaticPropsContext } from "next";
import { useLocaleModal } from "utils/hooks/use-locale-modal";
import { dataTrackingIdsFooter, dataTrackingIdsHeader } from "utils/types";

const LocaleModal = dynamic<LocaleModalProps>(() =>
  import("@travellocal/ui").then((mod) => mod.LocaleModal)
);

export interface AlternatePage {
  /** UTS string locale of the alternate page, all lowercase. */
  locale: string;
  /** Fully qualified URL of the alternate page. */
  url: string;
  /**
   * If true, then the URL is only used for the language switcher
   * - it's a fallback for when this locale is selected, but it's not an
   * alternate version of the same page.
   * To support scenario:
   * 1. user is on an article page
   * 2. selects DE locale
   * 3. user taken to DE articles archive
   */
  isFallback?: boolean;
}

export interface PageProps {
  title: string;
  className?: string;
  containerClassName?: string;
  countries: Awaited<ReturnType<typeof getCountriesWithPublishedTrips>>;
  initialAppState: AppState;
  children: React.ReactNode;
  onSessionLoad?: (user: AppState["user"]) => void;
  /**
   * Full list of alternate locales for this page.
   * N.B. According to Google this must include the current page as well.
   * https://developers.google.com/search/docs/specialty/international/localized-versions#sitemap
   **/
  alternatePages?: AlternatePage[];
  meta: {
    author?: string;
    canonicalUrl: string;
    description?: string;
    imageSrc?: string;
    locale: string;
    publishedDate?: string;
    title: string;
    type?: null | `article`;
  };
  subscribeResponse?: {
    subscribeBodyText: string;
    subscribeText: string;
  };
  socialMedia: Array<{
    label: string;
    platform: string;
    url: string;
  }>;
  shouldShowYourTripButton?: boolean;
  hasMainNavigation?: boolean;
  hasUtilityButtons?: boolean;
  showMinimalFooter?: boolean;
  hideTrust?: boolean;
  dataTrackingIdsHeader?: dataTrackingIdsHeader;
  dataTrackingIdsFooter?: dataTrackingIdsFooter;
}

interface ProvidersProps {
  initialAppState: AppState;
  children: React.ReactNode;
}

/**
 * Bundle of all the provider components we need to run the app.
 */
const Providers: React.FC<ProvidersProps> = ({ initialAppState, children }) => {
  // Force a remount of AppStateProvider when rendering a static page in fallback mode;
  // Otherwise it will initialise AppState empty and won't rerender when data is loaded in.
  const router = useRouter();

  return (
    <AppStateProvider initialState={initialAppState} key={router.isFallback ? 0 : 1}>
      <LinkContext.Provider value={{ Component: NextLink, localePrefix: true }}>
        <WindowDimensionsProvider>
          <DialogContextProvider appRootId="__next">{children}</DialogContextProvider>
        </WindowDimensionsProvider>
      </LinkContext.Provider>
    </AppStateProvider>
  );
};

/**
 * Wrapper component for pages, built with Container. Should exist once per route.
 *
 * @status development
 */
const InnerPage: React.FC<PageProps> = ({
  alternatePages,
  countries,
  title,
  children,
  className,
  containerClassName,
  meta,
  onSessionLoad,
  subscribeResponse,
  socialMedia,
  shouldShowYourTripButton,
  hasMainNavigation = true,
  hasUtilityButtons = true,
  showMinimalFooter = false,
  hideTrust = false,
  dataTrackingIdsHeader,
  dataTrackingIdsFooter,
}) => {
  const { loadUserSession, appState, setCookie } = useAppState();
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);
  const { showLocaleModal, closeLocaleModal, setLocaleCallback } = useLocaleModal({
    alternatePages,
    appState,
    setCookie,
  });
  const mappedCountries = useMemo(
    () =>
      countries?.map(({ id, href, name }) => ({
        id,
        href,
        label: name,
      })),
    [countries]
  );
  const { t } = useTranslation();
  const router = useRouter();

  const subscribeTextFallback = t(
    "web:Subscribe_body",
    "Join our newsletter for more inspiration, local expertise, and updates on how we’re making travel a force for good."
  );
  const subscribeTitleFallback = t("web:Subscribe_title", "Reimagine travel with us");

  // User could be logged into either Site 1 or Site 2
  useEffect(() => {
    if (!router.isFallback) {
      loadUserSession().then((user) => {
        onSessionLoad?.(user);
      });
    }
  }, [router.isFallback]);

  const isPreviewMode = appState.featureFlags?.length > 0;

  const structuredDataSnippet = null; // For testing buildStructuredDataForContent(meta, meta.canonicalUrl);

  return (
    <>
      <div
        className={classNames("page", className, {
          [css["preview"]]: isPreviewMode,
        })}>
        <Script src="//embed.typeform.com/next/embed.js" strategy="lazyOnload" />
        <Head>
          <title>{title}</title>
          <meta name="title" property="og:title" content={meta.title || title} />
          <link rel="canonical" href={meta.canonicalUrl} />
          <meta name="url" property="og:url" content={meta.canonicalUrl} />
          <meta
            name="image"
            property="og:image"
            content={
              meta?.imageSrc ? meta.imageSrc : `${process.env.NEXT_PUBLIC_WEB_ROOT}/api/og-image`
            }
          />
          {meta.type && <meta name="type" property="og:type" content={meta.type} />}
          {meta.description && (
            <>
              <meta name="description" property="og:description" content={meta.description} />
              <meta name="twitter:description" content={meta.description} />
            </>
          )}
          {meta.locale && <meta name="locale" property="og:locale" content={meta.locale} />}
          {meta.author && (
            <meta name="author" property="article:author" content={meta.author || "TravelLocal"} />
          )}
          {meta.publishedDate && (
            <>
              <meta
                name="og:article:published_time"
                property="og:article:published_time"
                content={meta.publishedDate}
              />
              <meta
                name="article:published_time"
                property="article:published_time"
                content={meta.publishedDate}
              />
            </>
          )}
          <meta property="twitter:site" content="@travellocal" />
          {structuredDataSnippet && (
            <script type={structuredDataSnippet.type}>{structuredDataSnippet.innerHTML}</script>
          )}

          {(alternatePages ?? [])
            .filter((x) => !x.isFallback)
            .map((alt) => (
              <link key={alt.locale} rel="alternate" hrefLang={alt.locale} href={alt.url} />
            ))}
        </Head>

        <Header
          countries={mappedCountries}
          onLocaleSelect={setLocaleCallback}
          currentPath={router.asPath}
          faqUrl={t("routes:faq")}
          shouldShowYourTrip={shouldShowYourTripButton}
          hasMainNavigation={hasMainNavigation}
          hasUtilityButtons={hasUtilityButtons}
          dataTrackingIds={dataTrackingIdsHeader}
        />

        <main className={classNames(containerClassName)}>{children}</main>

        <Footer
          subscribeTfLive={
            appState.locale === "de"
              ? TYPEFORM_SUBSCRIBE_DE_LIVE_CODE
              : TYPEFORM_SUBSCRIBE_EN_LIVE_CODE
          }
          subscribeClassName={classNames(css.subscribeFooter)}
          subscribeColumnClassName={classNames(css.subscribeColumnFooter)}
          subscribeTitle={subscribeResponse?.subscribeText ?? subscribeTitleFallback}
          subscribeBodyText={subscribeResponse?.subscribeBodyText ?? subscribeTextFallback}
          socialMedia={socialMedia}
          bCorpLink="https://reimagine.travellocal.com/en/certified-b-corporation"
          hideTrust={hideTrust}
          isMinimal={showMinimalFooter}
          dataTrackingIds={dataTrackingIdsFooter}
        />
      </div>
      {showLocaleModal && <LocaleModal onClick={setLocaleCallback} onClose={closeLocaleModal} />}

      {isPreviewMode && (
        <>
          <Dialog
            className={css["preview__dialog"]}
            isOpen={isPreviewDialogOpen}
            hasCloseButton
            ariaLabel="Preview mode settings dialog"
            onClose={() => setIsPreviewDialogOpen(false)}>
            <h1 className="title mb-3">Preview mode is active!</h1>
            <p className="mb-4">The following feature flags are turned on:</p>
            <ul className="mb-4 ml-4 is-family-code has-text-weight-bold">
              {appState.featureFlags.map((flag) => (
                <li key={flag}>{flag}</li>
              ))}
            </ul>
            <p>
              For information about the currently available feature flags, take a look at{" "}
              <a
                className="has-text-weight-bold"
                href="https://app.clickup.com/18339656/v/dc/hfnu8-8821/hfnu8-43508"
                target="_blank"
                rel="noreferrer">
                the Engineering Wiki
              </a>
              .
            </p>
            <hr />
            <div className="level">
              <div className="level-left">
                <Link
                  className="link"
                  href={`/api/exit-preview?redirect=${router.pathname}`}
                  localePrefix={false}>
                  Exit preview mode
                </Link>
              </div>
              <div className="level-right">
                <button className="button" onClick={() => setIsPreviewDialogOpen(false)}>
                  Keep browsing
                </button>
              </div>
            </div>
          </Dialog>
          <div className={css["preview__ui"]}>
            <button
              className={classNames("is-size-7 subtitle py-1 pl-2", css["preview__button"])}
              onClick={() => setIsPreviewDialogOpen(true)}>
              Preview
            </button>
          </div>
        </>
      )}
    </>
  );
};

export const getStaticProps = async (context: GetStaticPropsContext) => {
  if (context.locale === "default") {
    return {
      notFound: true,
    };
  }

  const pageProps = await getStaticPageContent(context);
  const currentLocale =
    pageProps.initialAppState.locale ?? pageProps.initialAppState.detectedLocale;
  const subscribeResponse = await getSubscribe([currentLocale]);

  return {
    revalidate: 600,
    props: {
      ...pageProps,
      subscribeResponse: subscribeResponse,
    },
  };
};

export const Page: React.FC<PageProps> = (props) => (
  <Providers initialAppState={props.initialAppState}>
    <InnerPage
      subscribeResponse={
        typeof props.children === "object" && "props" in props.children
          ? props.children.props.subscribeResponse?.[0]?.globalContent?.subscribeComponent
          : undefined
      }
      {...props}
    />
  </Providers>
);
