import { AppState, CookieKeys } from "@travellocal/ui";
import { Locale } from "@travellocal/utils";
import { i18n } from "next-i18next";
import { GetServerSidePropsContext, GetStaticPropsContext, PreviewData } from "next";
import nookies from "nookies";
import { getGlobalContent } from "utils/api/cms";
import { PageProps } from "..";
import { getCountriesWithPublishedTrips } from "../../utils/api/site2";

interface SocialMediaProfile {
  label: string;
  platform: string;
  url: string;
}

function isSocialMediaProfile(sm: unknown): sm is SocialMediaProfile {
  return typeof sm === `object` && `label` in sm && `platform` in sm && `url` in sm;
}

export type InitialPageProps = Pick<
  PageProps,
  "countries" | "initialAppState" | "alternatePages" | "socialMedia"
>;

export const getServerPageContent = async (context: GetServerSidePropsContext | null) => {
  const cookies = nookies.get(context) as Record<CookieKeys, string>;
  return getInitialPageContent(context.locale, cookies, context.previewData);
};

export const getStaticPageContent = async (context: GetStaticPropsContext | null) => {
  return getInitialPageContent(context.locale, undefined, context.previewData);
};

const getInitialPageContent = async (
  pageLocale: string,
  cookies: Partial<Record<CookieKeys, string>> = {},
  previewData: PreviewData = undefined
): Promise<InitialPageProps> => {
  if (process.env.NODE_ENV === "development") {
    // Keep resources up to date server-side
    await i18n?.reloadResources();
  }

  // Required for the dropdown menus in the header
  const countries = await getCountriesWithPublishedTrips(pageLocale as Locale);

  const globalContent = await getGlobalContent([pageLocale]);

  const socialMedia = (globalContent.nodes[0]?.globalContent.socialmedia || []).filter(
    isSocialMediaProfile
  );

  return {
    countries: countries ?? [],
    initialAppState: {
      locale: pageLocale,
      cookies,
      featureFlags: typeof previewData === "object" ? previewData["flags"] : [],
    } as AppState,
    socialMedia,
  };
};

export default getInitialPageContent;
