import { useCallback, useEffect, useState } from "react";
import { AppState, useAppState } from "@travellocal/ui/dist";
import { LocalStorageHelper } from "@travellocal/utils";
import { useRouter } from "next/router";
import { GetLanguageSwitcherPagesQuery } from "utils/api/site2/graphql.generated";
import { getLanguageSwitcherPages } from "utils/api/site2";

const LOCALE_MODAL_STATUS_KEY = "localeModalStatusKey";

interface UseLocaleModal {
  appState: AppState;
  setCookie: Pick<ReturnType<typeof useAppState>, `setCookie`>[`setCookie`];
  alternatePages: GetLanguageSwitcherPagesQuery["result"];
}

export const useLocaleModal = ({
  appState,
  setCookie,
  alternatePages,
}: UseLocaleModal) => {
  const [showLocaleModal, setShowLocaleModal] = useState(false);
  const [localePages, setLocalePages] =
    useState<GetLanguageSwitcherPagesQuery["result"]>(alternatePages);
  const router = useRouter();

  useEffect(() => {
    // Get alternate locale paths for the current page. NB result from API takes precedence over
    // what the page passes in via props,
    getLanguageSwitcherPages(appState.locale, router.pathname).then((result) =>
      setLocalePages((result ?? []).concat(alternatePages ?? []))
    );
  }, [appState.locale, router.pathname, alternatePages]);

  const closeLocaleModal = () => {
    setShowLocaleModal(false);
    LocalStorageHelper.setItem(LOCALE_MODAL_STATUS_KEY, `1`);
  };

  useEffect(() => {
    if (!LocalStorageHelper.getItem(LOCALE_MODAL_STATUS_KEY)) {
      setShowLocaleModal(
        appState.locale !==
          (appState.preferredLocale ?? appState.detectedLocale)
      );
    }
  }, [appState.detectedLocale, appState.locale, appState.preferredLocale]);

  const setLocaleCallback = useCallback(
    (newLocale: string) => {
      setCookie("preferredLanguage", newLocale);
      if (newLocale != appState.locale) {
        // Navigate to the alternative locale of this page if it exists, otherwise to the homepage.
        const newPath =
          localePages?.find((page) => page?.locale === newLocale)?.url ??
          `/${newLocale}`;

        // If the url is absolute and is for the current host, then make it relative.
        // This is a workaround for dev and Vercel preview deployments, where
        // NEXT_PUBLIC_WEB_ROOT doesn't match the URL that is used to view the site.
        if (newPath.includes(process.env.NEXT_PUBLIC_WEB_ROOT)) {
          const newPathRelative = new URL(newPath).pathname;
          window.location.assign(newPathRelative);
        } else {
          window.location.assign(newPath);
        }
      }
    },
    [appState.locale, localePages, setCookie]
  );

  return {
    showLocaleModal,
    closeLocaleModal,
    setLocaleCallback,
  };
};
