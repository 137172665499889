import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

/** A Field Group registered by ACF */
export type AcfFieldGroup = {
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
};

/** ACF Link field */
export type AcfLink = {
  __typename?: "AcfLink";
  /** The target of the link (_blank, etc) */
  target?: Maybe<Scalars["String"]>;
  /** The title of the link */
  title?: Maybe<Scalars["String"]>;
  /** The url of the link */
  url?: Maybe<Scalars["String"]>;
};

/** The Article type */
export type Article = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithContentEditor &
  NodeWithFeaturedImage &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: "Article";
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    articleId: Scalars["Int"];
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Template | Articles&quot; was set to Show in GraphQL. */
    articles?: Maybe<Article_Articles>;
    /** Connection between the Article type and the category type */
    categories?: Maybe<ArticleToCategoryConnection>;
    /** The content of the post. */
    content?: Maybe<Scalars["String"]>;
    /** Connection between the Article type and the ContentLanguages type */
    contentLanguages?: Maybe<ArticleToContentLanguagesConnection>;
    /** Connection between the Article type and the ContentStatus type */
    contentStatuses?: Maybe<ArticleToContentStatusConnection>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars["String"];
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** Post publishing date. */
    date?: Maybe<Scalars["String"]>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars["String"]>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars["String"]>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars["String"]>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars["Int"]>;
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars["ID"]>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars["String"]>;
    /** The globally unique identifier of the article object. */
    id: Scalars["ID"];
    /** Whether the node is a Content Node */
    isContentNode: Scalars["Boolean"];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars["Boolean"]>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** Whether the node is a Term */
    isTermNode: Scalars["Boolean"];
    /** Polylang language */
    language?: Maybe<Language>;
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars["String"]>;
    /** Connection between the Article type and the Locale type */
    locales?: Maybe<ArticleToLocaleConnection>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Meta&quot; was set to Show in GraphQL. */
    meta?: Maybe<Article_Meta>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars["String"]>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars["String"]>;
    /** Connection between the Article type and the Article type */
    preview?: Maybe<ArticleToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars["Int"]>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars["ID"]>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars["String"]>;
    /** The current status of the object */
    status?: Maybe<Scalars["String"]>;
    /** Connection between the Article type and the tag type */
    tags?: Maybe<ArticleToTagConnection>;
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>;
    /** Connection between the Article type and the TermNode type */
    terms?: Maybe<ArticleToTermNodeConnection>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars["String"]>;
    /** Get specific translation version of this object */
    translation?: Maybe<Article>;
    /** List all translated versions of this post */
    translations?: Maybe<Array<Maybe<Article>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars["String"]>;
  };

/** The Article type */
export type ArticleCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArticleToCategoryConnectionWhereArgs>;
};

/** The Article type */
export type ArticleContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The Article type */
export type ArticleContentLanguagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArticleToContentLanguagesConnectionWhereArgs>;
};

/** The Article type */
export type ArticleContentStatusesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArticleToContentStatusConnectionWhereArgs>;
};

/** The Article type */
export type ArticleEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The Article type */
export type ArticleEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The Article type */
export type ArticleLocalesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArticleToLocaleConnectionWhereArgs>;
};

/** The Article type */
export type ArticleTagsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArticleToTagConnectionWhereArgs>;
};

/** The Article type */
export type ArticleTermsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ArticleToTermNodeConnectionWhereArgs>;
};

/** The Article type */
export type ArticleTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The Article type */
export type ArticleTranslationArgs = {
  language: LanguageCodeEnum;
};

/** Set relationships between the Article to categories */
export type ArticleCategoriesInput = {
  /** If true, this will append the category to existing related categories. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<ArticleCategoriesNodeInput>>>;
};

/** List of categories to connect the Article to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type ArticleCategoriesNodeInput = {
  /** The description of the category. This field is used to set a description of the category if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the category. If present, this will be used to connect to the Article. If no existing category exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the category. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the category. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Connection to Article Nodes */
export type ArticleConnection = {
  /** A list of edges (relational context) between RootQuery and connected Article Nodes */
  edges: Array<ArticleConnectionEdge>;
  /** A list of connected Article Nodes */
  nodes: Array<Article>;
  /** Information about pagination in a connection. */
  pageInfo: ArticleConnectionPageInfo;
};

/** Edge between a Node and a connected Article */
export type ArticleConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected Article Node */
  node: Article;
};

/** Page Info on the connected ArticleConnectionEdge */
export type ArticleConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** Set relationships between the Article to ContentLanguages */
export type ArticleContentLanguagesInput = {
  /** If true, this will append the ContentLanguages to existing related ContentLanguages. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<ArticleContentLanguagesNodeInput>>>;
};

/** List of ContentLanguages to connect the Article to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type ArticleContentLanguagesNodeInput = {
  /** The description of the ContentLanguages. This field is used to set a description of the ContentLanguages if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the ContentLanguages. If present, this will be used to connect to the Article. If no existing ContentLanguages exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the ContentLanguages. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the ContentLanguages. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Set relationships between the Article to ContentStatuses */
export type ArticleContentStatusesInput = {
  /** If true, this will append the ContentStatus to existing related ContentStatuses. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<ArticleContentStatusesNodeInput>>>;
};

/** List of ContentStatuses to connect the Article to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type ArticleContentStatusesNodeInput = {
  /** The description of the ContentStatus. This field is used to set a description of the ContentStatus if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the ContentStatus. If present, this will be used to connect to the Article. If no existing ContentStatus exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the ContentStatus. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the ContentStatus. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ArticleIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  Id = "ID",
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = "SLUG",
  /** Identify a resource by the URI. */
  Uri = "URI",
}

/** Set relationships between the Article to Locales */
export type ArticleLocalesInput = {
  /** If true, this will append the Locale to existing related Locales. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<ArticleLocalesNodeInput>>>;
};

/** List of Locales to connect the Article to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type ArticleLocalesNodeInput = {
  /** The description of the Locale. This field is used to set a description of the Locale if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the Locale. If present, this will be used to connect to the Article. If no existing Locale exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the Locale. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the Locale. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Set relationships between the Article to tags */
export type ArticleTagsInput = {
  /** If true, this will append the tag to existing related tags. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<ArticleTagsNodeInput>>>;
};

/** List of tags to connect the Article to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type ArticleTagsNodeInput = {
  /** The description of the tag. This field is used to set a description of the tag if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the tag. If present, this will be used to connect to the Article. If no existing tag exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the tag. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the tag. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Article type and the category type */
export type ArticleToCategoryConnection = CategoryConnection &
  Connection & {
    __typename?: "ArticleToCategoryConnection";
    /** Edges for the ArticleToCategoryConnection connection */
    edges: Array<ArticleToCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Category>;
    /** Information about pagination in a connection. */
    pageInfo: ArticleToCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type ArticleToCategoryConnectionEdge = CategoryConnectionEdge &
  Edge & {
    __typename?: "ArticleToCategoryConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Category;
  };

/** Page Info on the &quot;ArticleToCategoryConnection&quot; */
export type ArticleToCategoryConnectionPageInfo = CategoryConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "ArticleToCategoryConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ArticleToCategoryConnection connection */
export type ArticleToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the Article type and the ContentLanguages type */
export type ArticleToContentLanguagesConnection = Connection &
  ContentLanguagesConnection & {
    __typename?: "ArticleToContentLanguagesConnection";
    /** Edges for the ArticleToContentLanguagesConnection connection */
    edges: Array<ArticleToContentLanguagesConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentLanguages>;
    /** Information about pagination in a connection. */
    pageInfo: ArticleToContentLanguagesConnectionPageInfo;
  };

/** An edge in a connection */
export type ArticleToContentLanguagesConnectionEdge = ContentLanguagesConnectionEdge &
  Edge & {
    __typename?: "ArticleToContentLanguagesConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentLanguages;
  };

/** Page Info on the &quot;ArticleToContentLanguagesConnection&quot; */
export type ArticleToContentLanguagesConnectionPageInfo = ContentLanguagesConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "ArticleToContentLanguagesConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ArticleToContentLanguagesConnection connection */
export type ArticleToContentLanguagesConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the Article type and the ContentStatus type */
export type ArticleToContentStatusConnection = Connection &
  ContentStatusConnection & {
    __typename?: "ArticleToContentStatusConnection";
    /** Edges for the ArticleToContentStatusConnection connection */
    edges: Array<ArticleToContentStatusConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentStatus>;
    /** Information about pagination in a connection. */
    pageInfo: ArticleToContentStatusConnectionPageInfo;
  };

/** An edge in a connection */
export type ArticleToContentStatusConnectionEdge = ContentStatusConnectionEdge &
  Edge & {
    __typename?: "ArticleToContentStatusConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentStatus;
  };

/** Page Info on the &quot;ArticleToContentStatusConnection&quot; */
export type ArticleToContentStatusConnectionPageInfo = ContentStatusConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "ArticleToContentStatusConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ArticleToContentStatusConnection connection */
export type ArticleToContentStatusConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the Article type and the Locale type */
export type ArticleToLocaleConnection = Connection &
  LocaleConnection & {
    __typename?: "ArticleToLocaleConnection";
    /** Edges for the ArticleToLocaleConnection connection */
    edges: Array<ArticleToLocaleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Locale>;
    /** Information about pagination in a connection. */
    pageInfo: ArticleToLocaleConnectionPageInfo;
  };

/** An edge in a connection */
export type ArticleToLocaleConnectionEdge = Edge &
  LocaleConnectionEdge & {
    __typename?: "ArticleToLocaleConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Locale;
  };

/** Page Info on the &quot;ArticleToLocaleConnection&quot; */
export type ArticleToLocaleConnectionPageInfo = LocaleConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "ArticleToLocaleConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ArticleToLocaleConnection connection */
export type ArticleToLocaleConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the Article type and the Article type */
export type ArticleToPreviewConnectionEdge = ArticleConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: "ArticleToPreviewConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: Article;
  };

/** Connection between the Article type and the tag type */
export type ArticleToTagConnection = Connection &
  TagConnection & {
    __typename?: "ArticleToTagConnection";
    /** Edges for the ArticleToTagConnection connection */
    edges: Array<ArticleToTagConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Tag>;
    /** Information about pagination in a connection. */
    pageInfo: ArticleToTagConnectionPageInfo;
  };

/** An edge in a connection */
export type ArticleToTagConnectionEdge = Edge &
  TagConnectionEdge & {
    __typename?: "ArticleToTagConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Tag;
  };

/** Page Info on the &quot;ArticleToTagConnection&quot; */
export type ArticleToTagConnectionPageInfo = PageInfo &
  TagConnectionPageInfo &
  WpPageInfo & {
    __typename?: "ArticleToTagConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ArticleToTagConnection connection */
export type ArticleToTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the Article type and the TermNode type */
export type ArticleToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: "ArticleToTermNodeConnection";
    /** Edges for the ArticleToTermNodeConnection connection */
    edges: Array<ArticleToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: ArticleToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type ArticleToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: "ArticleToTermNodeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;ArticleToTermNodeConnection&quot; */
export type ArticleToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: "ArticleToTermNodeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ArticleToTermNodeConnection connection */
export type ArticleToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Field Group */
export type Article_Articles = AcfFieldGroup & {
  __typename?: "Article_Articles";
  /** The name of the person who wrote this article. */
  author?: Maybe<Scalars["String"]>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  relatedCountries?: Maybe<Array<Maybe<Article_Articles_RelatedCountries>>>;
};

export type Article_Articles_RelatedCountries = CountryProfile;

/** Field Group */
export type Article_Meta = AcfFieldGroup & {
  __typename?: "Article_Meta";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** Override for the page title, if empty the actual title will be used. */
  pageTitle?: Maybe<Scalars["String"]>;
  /** Description of content shown to search engines, if empty the search engine will extract its own description from the page. */
  searchDescription?: Maybe<Scalars["String"]>;
  /** Image shown to search engines and linkbots, if empty then a hero image or a generic image will be used. */
  searchImage?: Maybe<MediaItem>;
  /** Override for the title shown to search engines, if empty the page title or the actual title will be used. */
  searchTitle?: Maybe<Scalars["String"]>;
  /**
   * A representation of this page in the URL Use only lowercase letters, numbers, and hyphens.
   *
   * Don&#039;t add slash ( / ) to the beginning of the url fragment.
   */
  urlFragment?: Maybe<Scalars["String"]>;
};

/** Avatars are profile images for users. WordPress by default uses the Gravatar service to host and fetch avatars from. */
export type Avatar = {
  __typename?: "Avatar";
  /** URL for the default image or a default type. Accepts &#039;404&#039; (return a 404 instead of a default image), &#039;retro&#039; (8bit), &#039;monsterid&#039; (monster), &#039;wavatar&#039; (cartoon face), &#039;indenticon&#039; (the &#039;quilt&#039;), &#039;mystery&#039;, &#039;mm&#039;, or &#039;mysteryman&#039; (The Oyster Man), &#039;blank&#039; (transparent GIF), or &#039;gravatar_default&#039; (the Gravatar logo). */
  default?: Maybe<Scalars["String"]>;
  /** HTML attributes to insert in the IMG element. Is not sanitized. */
  extraAttr?: Maybe<Scalars["String"]>;
  /** Whether to always show the default image, never the Gravatar. */
  forceDefault?: Maybe<Scalars["Boolean"]>;
  /** Whether the avatar was successfully found. */
  foundAvatar?: Maybe<Scalars["Boolean"]>;
  /** Height of the avatar image. */
  height?: Maybe<Scalars["Int"]>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars["Boolean"]>;
  /** What rating to display avatars up to. Accepts &#039;G&#039;, &#039;PG&#039;, &#039;R&#039;, &#039;X&#039;, and are judged in that order. */
  rating?: Maybe<Scalars["String"]>;
  /** Type of url scheme to use. Typically HTTP vs. HTTPS. */
  scheme?: Maybe<Scalars["String"]>;
  /** The size of the avatar in pixels. A value of 96 will match a 96px x 96px gravatar image. */
  size?: Maybe<Scalars["Int"]>;
  /** URL for the gravatar image source. */
  url?: Maybe<Scalars["String"]>;
  /** Width of the avatar image. */
  width?: Maybe<Scalars["Int"]>;
};

/** What rating to display avatars up to. Accepts 'G', 'PG', 'R', 'X', and are judged in that order. Default is the value of the 'avatar_rating' option */
export enum AvatarRatingEnum {
  /** Indicates a G level avatar rating level. */
  G = "G",
  /** Indicates a PG level avatar rating level. */
  Pg = "PG",
  /** Indicates an R level avatar rating level. */
  R = "R",
  /** Indicates an X level avatar rating level. */
  X = "X",
}

/** The category type */
export type Category = DatabaseIdentifier &
  HierarchicalNode &
  HierarchicalTermNode &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: "Category";
    /** The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<CategoryToAncestorsCategoryConnection>;
    /** Connection between the Category type and the Article type */
    articles?: Maybe<CategoryToArticleConnection>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    categoryId?: Maybe<Scalars["Int"]>;
    /** Connection between the category type and its children categories. */
    children?: Maybe<CategoryToCategoryConnection>;
    /** Connection between the Category type and the ContentNode type */
    contentNodes?: Maybe<CategoryToContentNodeConnection>;
    /** The number of objects connected to the object */
    count?: Maybe<Scalars["Int"]>;
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** The description of the object */
    description?: Maybe<Scalars["String"]>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The unique resource identifier path */
    id: Scalars["ID"];
    /** Whether the node is a Content Node */
    isContentNode: Scalars["Boolean"];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** Whether the node is a Term */
    isTermNode: Scalars["Boolean"];
    /** List available translations for this post */
    language?: Maybe<Language>;
    /** The link to the term */
    link?: Maybe<Scalars["String"]>;
    /** The human friendly name of the object. */
    name?: Maybe<Scalars["String"]>;
    /** Connection between the category type and its parent category. */
    parent?: Maybe<CategoryToParentCategoryConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars["Int"]>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars["ID"]>;
    /** Connection between the Category type and the post type */
    posts?: Maybe<CategoryToPostConnection>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars["String"]>;
    /** Connection between the Category type and the Taxonomy type */
    taxonomy?: Maybe<CategoryToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars["String"]>;
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars["Int"]>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars["Int"]>;
    /** Get specific translation version of this object */
    translation?: Maybe<Category>;
    /** List all translated versions of this term */
    translations?: Maybe<Array<Maybe<Category>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars["String"]>;
  };

/** The category type */
export type CategoryAncestorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The category type */
export type CategoryArticlesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CategoryToArticleConnectionWhereArgs>;
};

/** The category type */
export type CategoryChildrenArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CategoryToCategoryConnectionWhereArgs>;
};

/** The category type */
export type CategoryContentNodesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CategoryToContentNodeConnectionWhereArgs>;
};

/** The category type */
export type CategoryEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The category type */
export type CategoryEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The category type */
export type CategoryPostsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CategoryToPostConnectionWhereArgs>;
};

/** The category type */
export type CategoryTranslationArgs = {
  language: LanguageCodeEnum;
};

/** Connection to category Nodes */
export type CategoryConnection = {
  /** A list of edges (relational context) between RootQuery and connected category Nodes */
  edges: Array<CategoryConnectionEdge>;
  /** A list of connected category Nodes */
  nodes: Array<Category>;
  /** Information about pagination in a connection. */
  pageInfo: CategoryConnectionPageInfo;
};

/** Edge between a Node and a connected category */
export type CategoryConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected category Node */
  node: Category;
};

/** Page Info on the connected CategoryConnectionEdge */
export type CategoryConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CategoryIdType {
  /** The Database ID for the node */
  DatabaseId = "DATABASE_ID",
  /** The hashed Global ID */
  Id = "ID",
  /** The name of the node */
  Name = "NAME",
  /** Url friendly name of the node */
  Slug = "SLUG",
  /** The URI for the node */
  Uri = "URI",
}

/** Connection between the Category type and the category type */
export type CategoryToAncestorsCategoryConnection = CategoryConnection &
  Connection & {
    __typename?: "CategoryToAncestorsCategoryConnection";
    /** Edges for the CategoryToAncestorsCategoryConnection connection */
    edges: Array<CategoryToAncestorsCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Category>;
    /** Information about pagination in a connection. */
    pageInfo: CategoryToAncestorsCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type CategoryToAncestorsCategoryConnectionEdge = CategoryConnectionEdge &
  Edge & {
    __typename?: "CategoryToAncestorsCategoryConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Category;
  };

/** Page Info on the &quot;CategoryToAncestorsCategoryConnection&quot; */
export type CategoryToAncestorsCategoryConnectionPageInfo = CategoryConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "CategoryToAncestorsCategoryConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Connection between the Category type and the Article type */
export type CategoryToArticleConnection = ArticleConnection &
  Connection & {
    __typename?: "CategoryToArticleConnection";
    /** Edges for the CategoryToArticleConnection connection */
    edges: Array<CategoryToArticleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Article>;
    /** Information about pagination in a connection. */
    pageInfo: CategoryToArticleConnectionPageInfo;
  };

/** An edge in a connection */
export type CategoryToArticleConnectionEdge = ArticleConnectionEdge &
  Edge & {
    __typename?: "CategoryToArticleConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Article;
  };

/** Page Info on the &quot;CategoryToArticleConnection&quot; */
export type CategoryToArticleConnectionPageInfo = ArticleConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "CategoryToArticleConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the CategoryToArticleConnection connection */
export type CategoryToArticleConnectionWhereArgs = {
  /** Category ID */
  categoryId?: InputMaybe<Scalars["Int"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars["String"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Category type and the category type */
export type CategoryToCategoryConnection = CategoryConnection &
  Connection & {
    __typename?: "CategoryToCategoryConnection";
    /** Edges for the CategoryToCategoryConnection connection */
    edges: Array<CategoryToCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Category>;
    /** Information about pagination in a connection. */
    pageInfo: CategoryToCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type CategoryToCategoryConnectionEdge = CategoryConnectionEdge &
  Edge & {
    __typename?: "CategoryToCategoryConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Category;
  };

/** Page Info on the &quot;CategoryToCategoryConnection&quot; */
export type CategoryToCategoryConnectionPageInfo = CategoryConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "CategoryToCategoryConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the CategoryToCategoryConnection connection */
export type CategoryToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the Category type and the ContentNode type */
export type CategoryToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: "CategoryToContentNodeConnection";
    /** Edges for the CategoryToContentNodeConnection connection */
    edges: Array<CategoryToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: CategoryToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type CategoryToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: "CategoryToContentNodeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;CategoryToContentNodeConnection&quot; */
export type CategoryToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "CategoryToContentNodeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the CategoryToContentNodeConnection connection */
export type CategoryToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfCategoryEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Category type and the category type */
export type CategoryToParentCategoryConnectionEdge = CategoryConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: "CategoryToParentCategoryConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: Category;
  };

/** Connection between the Category type and the post type */
export type CategoryToPostConnection = Connection &
  PostConnection & {
    __typename?: "CategoryToPostConnection";
    /** Edges for the CategoryToPostConnection connection */
    edges: Array<CategoryToPostConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Post>;
    /** Information about pagination in a connection. */
    pageInfo: CategoryToPostConnectionPageInfo;
  };

/** An edge in a connection */
export type CategoryToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: "CategoryToPostConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Post;
  };

/** Page Info on the &quot;CategoryToPostConnection&quot; */
export type CategoryToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: "CategoryToPostConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the CategoryToPostConnection connection */
export type CategoryToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars["Int"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars["String"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Category type and the Taxonomy type */
export type CategoryToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: "CategoryToTaxonomyConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** The Collection type */
export type Collection = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithFeaturedImage &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: "Collection";
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    collectionId: Scalars["Int"];
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Template | Collections&quot; was set to Show in GraphQL. */
    collections?: Maybe<Collection_Collections>;
    /** Connection between the Collection type and the ContentLanguages type */
    contentLanguages?: Maybe<CollectionToContentLanguagesConnection>;
    /** Connection between the Collection type and the ContentStatus type */
    contentStatuses?: Maybe<CollectionToContentStatusConnection>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars["String"];
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** Post publishing date. */
    date?: Maybe<Scalars["String"]>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars["String"]>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars["String"]>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars["String"]>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars["Int"]>;
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars["ID"]>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars["String"]>;
    /** The globally unique identifier of the collection object. */
    id: Scalars["ID"];
    /** Whether the node is a Content Node */
    isContentNode: Scalars["Boolean"];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars["Boolean"]>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** Whether the node is a Term */
    isTermNode: Scalars["Boolean"];
    /** Polylang language */
    language?: Maybe<Language>;
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars["String"]>;
    /** Connection between the Collection type and the Locale type */
    locales?: Maybe<CollectionToLocaleConnection>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Meta&quot; was set to Show in GraphQL. */
    meta?: Maybe<Collection_Meta>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars["String"]>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars["String"]>;
    /** Connection between the Collection type and the Collection type */
    preview?: Maybe<CollectionToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars["Int"]>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars["ID"]>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars["String"]>;
    /** The current status of the object */
    status?: Maybe<Scalars["String"]>;
    /** Connection between the Collection type and the tag type */
    tags?: Maybe<CollectionToTagConnection>;
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>;
    /** Connection between the Collection type and the TermNode type */
    terms?: Maybe<CollectionToTermNodeConnection>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars["String"]>;
    /** Get specific translation version of this object */
    translation?: Maybe<Collection>;
    /** List all translated versions of this post */
    translations?: Maybe<Array<Maybe<Collection>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars["String"]>;
  };

/** The Collection type */
export type CollectionContentLanguagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CollectionToContentLanguagesConnectionWhereArgs>;
};

/** The Collection type */
export type CollectionContentStatusesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CollectionToContentStatusConnectionWhereArgs>;
};

/** The Collection type */
export type CollectionEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The Collection type */
export type CollectionEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The Collection type */
export type CollectionLocalesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CollectionToLocaleConnectionWhereArgs>;
};

/** The Collection type */
export type CollectionTagsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CollectionToTagConnectionWhereArgs>;
};

/** The Collection type */
export type CollectionTermsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CollectionToTermNodeConnectionWhereArgs>;
};

/** The Collection type */
export type CollectionTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The Collection type */
export type CollectionTranslationArgs = {
  language: LanguageCodeEnum;
};

/** Connection to Collection Nodes */
export type CollectionConnection = {
  /** A list of edges (relational context) between RootQuery and connected Collection Nodes */
  edges: Array<CollectionConnectionEdge>;
  /** A list of connected Collection Nodes */
  nodes: Array<Collection>;
  /** Information about pagination in a connection. */
  pageInfo: CollectionConnectionPageInfo;
};

/** Edge between a Node and a connected Collection */
export type CollectionConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected Collection Node */
  node: Collection;
};

/** Page Info on the connected CollectionConnectionEdge */
export type CollectionConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** Set relationships between the Collection to ContentLanguages */
export type CollectionContentLanguagesInput = {
  /** If true, this will append the ContentLanguages to existing related ContentLanguages. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<CollectionContentLanguagesNodeInput>>>;
};

/** List of ContentLanguages to connect the Collection to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CollectionContentLanguagesNodeInput = {
  /** The description of the ContentLanguages. This field is used to set a description of the ContentLanguages if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the ContentLanguages. If present, this will be used to connect to the Collection. If no existing ContentLanguages exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the ContentLanguages. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the ContentLanguages. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Set relationships between the Collection to ContentStatuses */
export type CollectionContentStatusesInput = {
  /** If true, this will append the ContentStatus to existing related ContentStatuses. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<CollectionContentStatusesNodeInput>>>;
};

/** List of ContentStatuses to connect the Collection to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CollectionContentStatusesNodeInput = {
  /** The description of the ContentStatus. This field is used to set a description of the ContentStatus if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the ContentStatus. If present, this will be used to connect to the Collection. If no existing ContentStatus exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the ContentStatus. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the ContentStatus. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CollectionIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  Id = "ID",
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = "SLUG",
  /** Identify a resource by the URI. */
  Uri = "URI",
}

/** Set relationships between the Collection to Locales */
export type CollectionLocalesInput = {
  /** If true, this will append the Locale to existing related Locales. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<CollectionLocalesNodeInput>>>;
};

/** List of Locales to connect the Collection to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CollectionLocalesNodeInput = {
  /** The description of the Locale. This field is used to set a description of the Locale if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the Locale. If present, this will be used to connect to the Collection. If no existing Locale exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the Locale. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the Locale. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Set relationships between the Collection to tags */
export type CollectionTagsInput = {
  /** If true, this will append the tag to existing related tags. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<CollectionTagsNodeInput>>>;
};

/** List of tags to connect the Collection to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CollectionTagsNodeInput = {
  /** The description of the tag. This field is used to set a description of the tag if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the tag. If present, this will be used to connect to the Collection. If no existing tag exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the tag. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the tag. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Collection type and the ContentLanguages type */
export type CollectionToContentLanguagesConnection = Connection &
  ContentLanguagesConnection & {
    __typename?: "CollectionToContentLanguagesConnection";
    /** Edges for the CollectionToContentLanguagesConnection connection */
    edges: Array<CollectionToContentLanguagesConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentLanguages>;
    /** Information about pagination in a connection. */
    pageInfo: CollectionToContentLanguagesConnectionPageInfo;
  };

/** An edge in a connection */
export type CollectionToContentLanguagesConnectionEdge = ContentLanguagesConnectionEdge &
  Edge & {
    __typename?: "CollectionToContentLanguagesConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentLanguages;
  };

/** Page Info on the &quot;CollectionToContentLanguagesConnection&quot; */
export type CollectionToContentLanguagesConnectionPageInfo = ContentLanguagesConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "CollectionToContentLanguagesConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the CollectionToContentLanguagesConnection connection */
export type CollectionToContentLanguagesConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the Collection type and the ContentStatus type */
export type CollectionToContentStatusConnection = Connection &
  ContentStatusConnection & {
    __typename?: "CollectionToContentStatusConnection";
    /** Edges for the CollectionToContentStatusConnection connection */
    edges: Array<CollectionToContentStatusConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentStatus>;
    /** Information about pagination in a connection. */
    pageInfo: CollectionToContentStatusConnectionPageInfo;
  };

/** An edge in a connection */
export type CollectionToContentStatusConnectionEdge = ContentStatusConnectionEdge &
  Edge & {
    __typename?: "CollectionToContentStatusConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentStatus;
  };

/** Page Info on the &quot;CollectionToContentStatusConnection&quot; */
export type CollectionToContentStatusConnectionPageInfo = ContentStatusConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "CollectionToContentStatusConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the CollectionToContentStatusConnection connection */
export type CollectionToContentStatusConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the Collection type and the Locale type */
export type CollectionToLocaleConnection = Connection &
  LocaleConnection & {
    __typename?: "CollectionToLocaleConnection";
    /** Edges for the CollectionToLocaleConnection connection */
    edges: Array<CollectionToLocaleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Locale>;
    /** Information about pagination in a connection. */
    pageInfo: CollectionToLocaleConnectionPageInfo;
  };

/** An edge in a connection */
export type CollectionToLocaleConnectionEdge = Edge &
  LocaleConnectionEdge & {
    __typename?: "CollectionToLocaleConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Locale;
  };

/** Page Info on the &quot;CollectionToLocaleConnection&quot; */
export type CollectionToLocaleConnectionPageInfo = LocaleConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "CollectionToLocaleConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the CollectionToLocaleConnection connection */
export type CollectionToLocaleConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the Collection type and the Collection type */
export type CollectionToPreviewConnectionEdge = CollectionConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: "CollectionToPreviewConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: Collection;
  };

/** Connection between the Collection type and the tag type */
export type CollectionToTagConnection = Connection &
  TagConnection & {
    __typename?: "CollectionToTagConnection";
    /** Edges for the CollectionToTagConnection connection */
    edges: Array<CollectionToTagConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Tag>;
    /** Information about pagination in a connection. */
    pageInfo: CollectionToTagConnectionPageInfo;
  };

/** An edge in a connection */
export type CollectionToTagConnectionEdge = Edge &
  TagConnectionEdge & {
    __typename?: "CollectionToTagConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Tag;
  };

/** Page Info on the &quot;CollectionToTagConnection&quot; */
export type CollectionToTagConnectionPageInfo = PageInfo &
  TagConnectionPageInfo &
  WpPageInfo & {
    __typename?: "CollectionToTagConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the CollectionToTagConnection connection */
export type CollectionToTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the Collection type and the TermNode type */
export type CollectionToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: "CollectionToTermNodeConnection";
    /** Edges for the CollectionToTermNodeConnection connection */
    edges: Array<CollectionToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: CollectionToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type CollectionToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: "CollectionToTermNodeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;CollectionToTermNodeConnection&quot; */
export type CollectionToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: "CollectionToTermNodeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the CollectionToTermNodeConnection connection */
export type CollectionToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Field Group */
export type Collection_Collections = AcfFieldGroup & {
  __typename?: "Collection_Collections";
  components?: Maybe<Collection_Collections_Components>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type Collection_Collections_Components = AcfFieldGroup & {
  __typename?: "Collection_Collections_Components";
  articlesArticles?: Maybe<Array<Maybe<Collection_Collections_Components_ArticlesArticles>>>;
  articlesCta?: Maybe<AcfLink>;
  articlesSectionDescription?: Maybe<Scalars["String"]>;
  articlesSectionSubtitle?: Maybe<Scalars["String"]>;
  articlesSectionTitle?: Maybe<Scalars["String"]>;
  chapters?: Maybe<Array<Maybe<Collection_Collections_Components_Chapters>>>;
  chaptersHeaderimage?: Maybe<MediaItem>;
  /** Short section title, used in left hand menu and on mobile. */
  chaptersNavlabel?: Maybe<Scalars["String"]>;
  /** If a video exists, it will be used instead of the featured image. If it doesn&#039;t exist then the featured image will be used. */
  collectionsVideo?: Maybe<Scalars["String"]>;
  experttipCta?: Maybe<AcfLink>;
  /** Pick two images. The first image should be of the local expert, the second should relate to the quote. */
  experttipImages?: Maybe<Array<Maybe<MediaItem>>>;
  /** The expert&#039;s tip. */
  experttipQuote?: Maybe<Scalars["String"]>;
  /** Displayed above the quote */
  experttipTitle?: Maybe<Scalars["String"]>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  footerCta?: Maybe<Collection_Collections_Components_FooterCta>;
  header?: Maybe<Collection_Collections_Components_Header>;
  reviewslistReviews?: Maybe<Array<Maybe<Collection_Collections_Components_ReviewslistReviews>>>;
  reviewslistTitle?: Maybe<Scalars["String"]>;
  tripideas?: Maybe<Array<Maybe<Collection_Collections_Components_Tripideas>>>;
  tripideasSectionDescription?: Maybe<Scalars["String"]>;
  tripideasSectionSubtitle?: Maybe<Scalars["String"]>;
  tripideasSectionTitle?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type Collection_Collections_Components_FooterCta = AcfFieldGroup & {
  __typename?: "Collection_Collections_Components_FooterCta";
  /** CTA link and label. If not provided will link to Destinations overview page. */
  cta?: Maybe<AcfLink>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** Title for the CTA. If not provided will have some default text. */
  title?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type Collection_Collections_Components_Header = AcfFieldGroup & {
  __typename?: "Collection_Collections_Components_Header";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** A couple of sentences introducing the collection */
  intro?: Maybe<Scalars["String"]>;
  /** Displayed above the h1 in the title */
  subtitle?: Maybe<Scalars["String"]>;
  /** Displayed at the top of the page and in the breadcrumb. If empty the page title field will be used */
  title?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type Collection_Collections_Components_ArticlesArticles = AcfFieldGroup & {
  __typename?: "Collection_Collections_Components_articlesArticles";
  article?: Maybe<Collection_Collections_Components_ArticlesArticles_Article>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Collection_Collections_Components_ArticlesArticles_Article = Article;

/** Field Group */
export type Collection_Collections_Components_Chapters = AcfFieldGroup & {
  __typename?: "Collection_Collections_Components_chapters";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  html?: Maybe<Scalars["String"]>;
  /** Shorter title for the menu. If empty the title will be used. */
  menutitle?: Maybe<Scalars["String"]>;
  /** A slug for this chapter, unique within this page, formatted lowercase with no spaces. Used for the sharing links. e.g. greek-inspiration */
  slug?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type Collection_Collections_Components_ReviewslistReviews = AcfFieldGroup & {
  __typename?: "Collection_Collections_Components_reviewslistReviews";
  /** The customer name and where they travelled to. Usually written as: Sim, travelled to Bristol */
  author?: Maybe<Scalars["String"]>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** Customer quote. Keep it short. Don&#039;t include the speech marks. There is a character limit of 325. */
  quote?: Maybe<Scalars["String"]>;
  /** How many stars is the review? Can handle 0.5 increments */
  rating?: Maybe<Scalars["Float"]>;
  title?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type Collection_Collections_Components_Tripideas = AcfFieldGroup & {
  __typename?: "Collection_Collections_Components_tripideas";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** URL of a Site 2 trip idea */
  url?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type Collection_Meta = AcfFieldGroup & {
  __typename?: "Collection_Meta";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** Override for the page title, if empty the actual title will be used. */
  pageTitle?: Maybe<Scalars["String"]>;
  /** Description of content shown to search engines, if empty the search engine will extract its own description from the page. */
  searchDescription?: Maybe<Scalars["String"]>;
  /** Image shown to search engines and linkbots, if empty then a hero image or a generic image will be used. */
  searchImage?: Maybe<MediaItem>;
  /** Override for the title shown to search engines, if empty the page title or the actual title will be used. */
  searchTitle?: Maybe<Scalars["String"]>;
  /**
   * A representation of this page in the URL Use only lowercase letters, numbers, and hyphens.
   *
   * Don&#039;t add slash ( / ) to the beginning of the url fragment.
   */
  urlFragment?: Maybe<Scalars["String"]>;
};

/** A Comment object */
export type Comment = DatabaseIdentifier &
  Node & {
    __typename?: "Comment";
    /** User agent used to post the comment. This field is equivalent to WP_Comment-&gt;comment_agent and the value matching the &quot;comment_agent&quot; column in SQL. */
    agent?: Maybe<Scalars["String"]>;
    /**
     * The approval status of the comment. This field is equivalent to WP_Comment-&gt;comment_approved and the value matching the &quot;comment_approved&quot; column in SQL.
     * @deprecated Deprecated in favor of the `status` field
     */
    approved?: Maybe<Scalars["Boolean"]>;
    /** The author of the comment */
    author?: Maybe<CommentToCommenterConnectionEdge>;
    /** IP address for the author. This field is equivalent to WP_Comment-&gt;comment_author_IP and the value matching the &quot;comment_author_IP&quot; column in SQL. */
    authorIp?: Maybe<Scalars["String"]>;
    /**
     * ID for the comment, unique among comments.
     * @deprecated Deprecated in favor of databaseId
     */
    commentId?: Maybe<Scalars["Int"]>;
    /** Connection between the Comment type and the ContentNode type */
    commentedOn?: Maybe<CommentToContentNodeConnectionEdge>;
    /** Content of the comment. This field is equivalent to WP_Comment-&gt;comment_content and the value matching the &quot;comment_content&quot; column in SQL. */
    content?: Maybe<Scalars["String"]>;
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** Date the comment was posted in local time. This field is equivalent to WP_Comment-&gt;date and the value matching the &quot;date&quot; column in SQL. */
    date?: Maybe<Scalars["String"]>;
    /** Date the comment was posted in GMT. This field is equivalent to WP_Comment-&gt;date_gmt and the value matching the &quot;date_gmt&quot; column in SQL. */
    dateGmt?: Maybe<Scalars["String"]>;
    /** The globally unique identifier for the comment object */
    id: Scalars["ID"];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** Karma value for the comment. This field is equivalent to WP_Comment-&gt;comment_karma and the value matching the &quot;comment_karma&quot; column in SQL. */
    karma?: Maybe<Scalars["Int"]>;
    /** Connection between the Comment type and the Comment type */
    parent?: Maybe<CommentToParentCommentConnectionEdge>;
    /** The database id of the parent comment node or null if it is the root comment */
    parentDatabaseId?: Maybe<Scalars["Int"]>;
    /** The globally unique identifier of the parent comment node. */
    parentId?: Maybe<Scalars["ID"]>;
    /** Connection between the Comment type and the Comment type */
    replies?: Maybe<CommentToCommentConnection>;
    /** The approval status of the comment. This field is equivalent to WP_Comment-&gt;comment_approved and the value matching the &quot;comment_approved&quot; column in SQL. */
    status?: Maybe<CommentStatusEnum>;
    /** Type of comment. This field is equivalent to WP_Comment-&gt;comment_type and the value matching the &quot;comment_type&quot; column in SQL. */
    type?: Maybe<Scalars["String"]>;
  };

/** A Comment object */
export type CommentContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A Comment object */
export type CommentParentArgs = {
  where?: InputMaybe<CommentToParentCommentConnectionWhereArgs>;
};

/** A Comment object */
export type CommentRepliesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CommentToCommentConnectionWhereArgs>;
};

/** A Comment Author object */
export type CommentAuthor = Commenter &
  DatabaseIdentifier &
  Node & {
    __typename?: "CommentAuthor";
    /** Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument. */
    avatar?: Maybe<Avatar>;
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** The email for the comment author */
    email?: Maybe<Scalars["String"]>;
    /** The globally unique identifier for the comment author object */
    id: Scalars["ID"];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** The name for the comment author. */
    name?: Maybe<Scalars["String"]>;
    /** The url the comment author. */
    url?: Maybe<Scalars["String"]>;
  };

/** A Comment Author object */
export type CommentAuthorAvatarArgs = {
  forceDefault?: InputMaybe<Scalars["Boolean"]>;
  rating?: InputMaybe<AvatarRatingEnum>;
  size?: InputMaybe<Scalars["Int"]>;
};

/** Connection to Comment Nodes */
export type CommentConnection = {
  /** A list of edges (relational context) between RootQuery and connected Comment Nodes */
  edges: Array<CommentConnectionEdge>;
  /** A list of connected Comment Nodes */
  nodes: Array<Comment>;
  /** Information about pagination in a connection. */
  pageInfo: CommentConnectionPageInfo;
};

/** Edge between a Node and a connected Comment */
export type CommentConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected Comment Node */
  node: Comment;
};

/** Page Info on the connected CommentConnectionEdge */
export type CommentConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single comment node. Default is "ID". To be used along with the "id" field. */
export enum CommentNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  Id = "ID",
}

/** The status of the comment object. */
export enum CommentStatusEnum {
  /** Comments with the Approved status */
  Approve = "APPROVE",
  /** Comments with the Unapproved status */
  Hold = "HOLD",
  /** Comments with the Spam status */
  Spam = "SPAM",
  /** Comments with the Trash status */
  Trash = "TRASH",
}

/** Connection between the Comment type and the Comment type */
export type CommentToCommentConnection = CommentConnection &
  Connection & {
    __typename?: "CommentToCommentConnection";
    /** Edges for the CommentToCommentConnection connection */
    edges: Array<CommentToCommentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Comment>;
    /** Information about pagination in a connection. */
    pageInfo: CommentToCommentConnectionPageInfo;
  };

/** An edge in a connection */
export type CommentToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: "CommentToCommentConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Comment;
  };

/** Page Info on the &quot;CommentToCommentConnection&quot; */
export type CommentToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "CommentToCommentConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the CommentToCommentConnection connection */
export type CommentToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars["String"]>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars["String"]>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars["String"]>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars["String"]>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars["ID"]>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars["String"]>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars["Int"]>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars["Int"]>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars["String"]>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars["String"]>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars["ID"]>;
};

/** Connection between the Comment type and the Commenter type */
export type CommentToCommenterConnectionEdge = CommenterConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: "CommentToCommenterConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: Commenter;
  };

/** Connection between the Comment type and the ContentNode type */
export type CommentToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: "CommentToContentNodeConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: ContentNode;
  };

/** Connection between the Comment type and the Comment type */
export type CommentToParentCommentConnectionEdge = CommentConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: "CommentToParentCommentConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: Comment;
  };

/** Arguments for filtering the CommentToParentCommentConnection connection */
export type CommentToParentCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars["String"]>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars["String"]>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars["String"]>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars["String"]>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars["ID"]>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars["String"]>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars["Int"]>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars["Int"]>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars["String"]>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars["String"]>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars["ID"]>;
};

/** The author of a comment */
export type Commenter = {
  /** Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument. */
  avatar?: Maybe<Avatar>;
  /** Identifies the primary key from the database. */
  databaseId: Scalars["Int"];
  /** The email address of the author of a comment. */
  email?: Maybe<Scalars["String"]>;
  /** The globally unique identifier for the comment author. */
  id: Scalars["ID"];
  /** Whether the author information is considered restricted. (not fully public) */
  isRestricted?: Maybe<Scalars["Boolean"]>;
  /** The name of the author of a comment. */
  name?: Maybe<Scalars["String"]>;
  /** The url of the author of a comment. */
  url?: Maybe<Scalars["String"]>;
};

/** Edge between a Node and a connected Commenter */
export type CommenterConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected Commenter Node */
  node: Commenter;
};

/** Options for ordering the connection */
export enum CommentsConnectionOrderbyEnum {
  /** Order by browser user agent of the commenter. */
  CommentAgent = "COMMENT_AGENT",
  /** Order by approval status of the comment. */
  CommentApproved = "COMMENT_APPROVED",
  /** Order by name of the comment author. */
  CommentAuthor = "COMMENT_AUTHOR",
  /** Order by e-mail of the comment author. */
  CommentAuthorEmail = "COMMENT_AUTHOR_EMAIL",
  /** Order by IP address of the comment author. */
  CommentAuthorIp = "COMMENT_AUTHOR_IP",
  /** Order by URL address of the comment author. */
  CommentAuthorUrl = "COMMENT_AUTHOR_URL",
  /** Order by the comment contents. */
  CommentContent = "COMMENT_CONTENT",
  /** Order by date/time timestamp of the comment. */
  CommentDate = "COMMENT_DATE",
  /** Order by GMT timezone date/time timestamp of the comment. */
  CommentDateGmt = "COMMENT_DATE_GMT",
  /** Order by the globally unique identifier for the comment object */
  CommentId = "COMMENT_ID",
  /** Order by the array list of comment IDs listed in the where clause. */
  CommentIn = "COMMENT_IN",
  /** Order by the comment karma score. */
  CommentKarma = "COMMENT_KARMA",
  /** Order by the comment parent ID. */
  CommentParent = "COMMENT_PARENT",
  /** Order by the post object ID. */
  CommentPostId = "COMMENT_POST_ID",
  /** Order by the the type of comment, such as 'comment', 'pingback', or 'trackback'. */
  CommentType = "COMMENT_TYPE",
  /** Order by the user ID. */
  UserId = "USER_ID",
}

/** A plural connection from one Node Type in the Graph to another Node Type, with support for relational data via &quot;edges&quot;. */
export type Connection = {
  /** A list of edges (relational context) between connected nodes */
  edges: Array<Edge>;
  /** A list of connected nodes */
  nodes: Array<Node>;
  /** Information about pagination in a connection. */
  pageInfo: PageInfo;
};

/** The ContentLanguages type */
export type ContentLanguages = DatabaseIdentifier &
  HierarchicalNode &
  HierarchicalTermNode &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: "ContentLanguages";
    /** The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<ContentLanguagesToAncestorsContentLanguagesConnection>;
    /** Connection between the ContentLanguages type and the Article type */
    articles?: Maybe<ContentLanguagesToArticleConnection>;
    /** Connection between the ContentLanguages type and its children ContentLanguages. */
    children?: Maybe<ContentLanguagesToContentLanguagesConnection>;
    /** Connection between the ContentLanguages type and the Collection type */
    collections?: Maybe<ContentLanguagesToCollectionConnection>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    contentLanguagesId?: Maybe<Scalars["Int"]>;
    /** Connection between the ContentLanguages type and the ContentNode type */
    contentNodes?: Maybe<ContentLanguagesToContentNodeConnection>;
    /** The number of objects connected to the object */
    count?: Maybe<Scalars["Int"]>;
    /** Connection between the ContentLanguages type and the CountryProfile type */
    countryProfiles?: Maybe<ContentLanguagesToCountryProfileConnection>;
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** The description of the object */
    description?: Maybe<Scalars["String"]>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** Connection between the ContentLanguages type and the HomePage type */
    homePages?: Maybe<ContentLanguagesToHomePageConnection>;
    /** Connection between the ContentLanguages type and the HowItWorksPage type */
    howItWorksPages?: Maybe<ContentLanguagesToHowItWorksPageConnection>;
    /** The unique resource identifier path */
    id: Scalars["ID"];
    /** Whether the node is a Content Node */
    isContentNode: Scalars["Boolean"];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** Whether the node is a Term */
    isTermNode: Scalars["Boolean"];
    /** The link to the term */
    link?: Maybe<Scalars["String"]>;
    /** The human friendly name of the object. */
    name?: Maybe<Scalars["String"]>;
    /** Connection between the ContentLanguages type and its parent ContentLanguages. */
    parent?: Maybe<ContentLanguagesToParentContentLanguagesConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars["Int"]>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars["ID"]>;
    /** Connection between the ContentLanguages type and the RegionProfile type */
    regionProfiles?: Maybe<ContentLanguagesToRegionProfileConnection>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars["String"]>;
    /** Connection between the ContentLanguages type and the Taxonomy type */
    taxonomy?: Maybe<ContentLanguagesToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars["String"]>;
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars["Int"]>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars["Int"]>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars["String"]>;
  };

/** The ContentLanguages type */
export type ContentLanguagesAncestorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The ContentLanguages type */
export type ContentLanguagesArticlesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentLanguagesToArticleConnectionWhereArgs>;
};

/** The ContentLanguages type */
export type ContentLanguagesChildrenArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentLanguagesToContentLanguagesConnectionWhereArgs>;
};

/** The ContentLanguages type */
export type ContentLanguagesCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentLanguagesToCollectionConnectionWhereArgs>;
};

/** The ContentLanguages type */
export type ContentLanguagesContentNodesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentLanguagesToContentNodeConnectionWhereArgs>;
};

/** The ContentLanguages type */
export type ContentLanguagesCountryProfilesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentLanguagesToCountryProfileConnectionWhereArgs>;
};

/** The ContentLanguages type */
export type ContentLanguagesEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The ContentLanguages type */
export type ContentLanguagesEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The ContentLanguages type */
export type ContentLanguagesHomePagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentLanguagesToHomePageConnectionWhereArgs>;
};

/** The ContentLanguages type */
export type ContentLanguagesHowItWorksPagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentLanguagesToHowItWorksPageConnectionWhereArgs>;
};

/** The ContentLanguages type */
export type ContentLanguagesRegionProfilesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentLanguagesToRegionProfileConnectionWhereArgs>;
};

/** Connection to ContentLanguages Nodes */
export type ContentLanguagesConnection = {
  /** A list of edges (relational context) between RootQuery and connected ContentLanguages Nodes */
  edges: Array<ContentLanguagesConnectionEdge>;
  /** A list of connected ContentLanguages Nodes */
  nodes: Array<ContentLanguages>;
  /** Information about pagination in a connection. */
  pageInfo: ContentLanguagesConnectionPageInfo;
};

/** Edge between a Node and a connected ContentLanguages */
export type ContentLanguagesConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected ContentLanguages Node */
  node: ContentLanguages;
};

/** Page Info on the connected ContentLanguagesConnectionEdge */
export type ContentLanguagesConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ContentLanguagesIdType {
  /** The Database ID for the node */
  DatabaseId = "DATABASE_ID",
  /** The hashed Global ID */
  Id = "ID",
  /** The name of the node */
  Name = "NAME",
  /** Url friendly name of the node */
  Slug = "SLUG",
  /** The URI for the node */
  Uri = "URI",
}

/** Connection between the ContentLanguages type and the ContentLanguages type */
export type ContentLanguagesToAncestorsContentLanguagesConnection = Connection &
  ContentLanguagesConnection & {
    __typename?: "ContentLanguagesToAncestorsContentLanguagesConnection";
    /** Edges for the ContentLanguagesToAncestorsContentLanguagesConnection connection */
    edges: Array<ContentLanguagesToAncestorsContentLanguagesConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentLanguages>;
    /** Information about pagination in a connection. */
    pageInfo: ContentLanguagesToAncestorsContentLanguagesConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentLanguagesToAncestorsContentLanguagesConnectionEdge =
  ContentLanguagesConnectionEdge &
    Edge & {
      __typename?: "ContentLanguagesToAncestorsContentLanguagesConnectionEdge";
      /** A cursor for use in pagination */
      cursor?: Maybe<Scalars["String"]>;
      /** The item at the end of the edge */
      node: ContentLanguages;
    };

/** Page Info on the &quot;ContentLanguagesToAncestorsContentLanguagesConnection&quot; */
export type ContentLanguagesToAncestorsContentLanguagesConnectionPageInfo =
  ContentLanguagesConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: "ContentLanguagesToAncestorsContentLanguagesConnectionPageInfo";
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars["String"]>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars["Boolean"];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars["Boolean"];
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars["String"]>;
    };

/** Connection between the ContentLanguages type and the Article type */
export type ContentLanguagesToArticleConnection = ArticleConnection &
  Connection & {
    __typename?: "ContentLanguagesToArticleConnection";
    /** Edges for the ContentLanguagesToArticleConnection connection */
    edges: Array<ContentLanguagesToArticleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Article>;
    /** Information about pagination in a connection. */
    pageInfo: ContentLanguagesToArticleConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentLanguagesToArticleConnectionEdge = ArticleConnectionEdge &
  Edge & {
    __typename?: "ContentLanguagesToArticleConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Article;
  };

/** Page Info on the &quot;ContentLanguagesToArticleConnection&quot; */
export type ContentLanguagesToArticleConnectionPageInfo = ArticleConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "ContentLanguagesToArticleConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ContentLanguagesToArticleConnection connection */
export type ContentLanguagesToArticleConnectionWhereArgs = {
  /** Category ID */
  categoryId?: InputMaybe<Scalars["Int"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars["String"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the ContentLanguages type and the Collection type */
export type ContentLanguagesToCollectionConnection = CollectionConnection &
  Connection & {
    __typename?: "ContentLanguagesToCollectionConnection";
    /** Edges for the ContentLanguagesToCollectionConnection connection */
    edges: Array<ContentLanguagesToCollectionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Collection>;
    /** Information about pagination in a connection. */
    pageInfo: ContentLanguagesToCollectionConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentLanguagesToCollectionConnectionEdge = CollectionConnectionEdge &
  Edge & {
    __typename?: "ContentLanguagesToCollectionConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Collection;
  };

/** Page Info on the &quot;ContentLanguagesToCollectionConnection&quot; */
export type ContentLanguagesToCollectionConnectionPageInfo = CollectionConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "ContentLanguagesToCollectionConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ContentLanguagesToCollectionConnection connection */
export type ContentLanguagesToCollectionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the ContentLanguages type and the ContentLanguages type */
export type ContentLanguagesToContentLanguagesConnection = Connection &
  ContentLanguagesConnection & {
    __typename?: "ContentLanguagesToContentLanguagesConnection";
    /** Edges for the ContentLanguagesToContentLanguagesConnection connection */
    edges: Array<ContentLanguagesToContentLanguagesConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentLanguages>;
    /** Information about pagination in a connection. */
    pageInfo: ContentLanguagesToContentLanguagesConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentLanguagesToContentLanguagesConnectionEdge = ContentLanguagesConnectionEdge &
  Edge & {
    __typename?: "ContentLanguagesToContentLanguagesConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentLanguages;
  };

/** Page Info on the &quot;ContentLanguagesToContentLanguagesConnection&quot; */
export type ContentLanguagesToContentLanguagesConnectionPageInfo =
  ContentLanguagesConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: "ContentLanguagesToContentLanguagesConnectionPageInfo";
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars["String"]>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars["Boolean"];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars["Boolean"];
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars["String"]>;
    };

/** Arguments for filtering the ContentLanguagesToContentLanguagesConnection connection */
export type ContentLanguagesToContentLanguagesConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the ContentLanguages type and the ContentNode type */
export type ContentLanguagesToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: "ContentLanguagesToContentNodeConnection";
    /** Edges for the ContentLanguagesToContentNodeConnection connection */
    edges: Array<ContentLanguagesToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: ContentLanguagesToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentLanguagesToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: "ContentLanguagesToContentNodeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;ContentLanguagesToContentNodeConnection&quot; */
export type ContentLanguagesToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "ContentLanguagesToContentNodeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ContentLanguagesToContentNodeConnection connection */
export type ContentLanguagesToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfContentLanguagesEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the ContentLanguages type and the CountryProfile type */
export type ContentLanguagesToCountryProfileConnection = Connection &
  CountryProfileConnection & {
    __typename?: "ContentLanguagesToCountryProfileConnection";
    /** Edges for the ContentLanguagesToCountryProfileConnection connection */
    edges: Array<ContentLanguagesToCountryProfileConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<CountryProfile>;
    /** Information about pagination in a connection. */
    pageInfo: ContentLanguagesToCountryProfileConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentLanguagesToCountryProfileConnectionEdge = CountryProfileConnectionEdge &
  Edge & {
    __typename?: "ContentLanguagesToCountryProfileConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: CountryProfile;
  };

/** Page Info on the &quot;ContentLanguagesToCountryProfileConnection&quot; */
export type ContentLanguagesToCountryProfileConnectionPageInfo = CountryProfileConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "ContentLanguagesToCountryProfileConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ContentLanguagesToCountryProfileConnection connection */
export type ContentLanguagesToCountryProfileConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the ContentLanguages type and the HomePage type */
export type ContentLanguagesToHomePageConnection = Connection &
  HomePageConnection & {
    __typename?: "ContentLanguagesToHomePageConnection";
    /** Edges for the ContentLanguagesToHomePageConnection connection */
    edges: Array<ContentLanguagesToHomePageConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<HomePage>;
    /** Information about pagination in a connection. */
    pageInfo: ContentLanguagesToHomePageConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentLanguagesToHomePageConnectionEdge = Edge &
  HomePageConnectionEdge & {
    __typename?: "ContentLanguagesToHomePageConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: HomePage;
  };

/** Page Info on the &quot;ContentLanguagesToHomePageConnection&quot; */
export type ContentLanguagesToHomePageConnectionPageInfo = HomePageConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "ContentLanguagesToHomePageConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ContentLanguagesToHomePageConnection connection */
export type ContentLanguagesToHomePageConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the ContentLanguages type and the HowItWorksPage type */
export type ContentLanguagesToHowItWorksPageConnection = Connection &
  HowItWorksPageConnection & {
    __typename?: "ContentLanguagesToHowItWorksPageConnection";
    /** Edges for the ContentLanguagesToHowItWorksPageConnection connection */
    edges: Array<ContentLanguagesToHowItWorksPageConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<HowItWorksPage>;
    /** Information about pagination in a connection. */
    pageInfo: ContentLanguagesToHowItWorksPageConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentLanguagesToHowItWorksPageConnectionEdge = Edge &
  HowItWorksPageConnectionEdge & {
    __typename?: "ContentLanguagesToHowItWorksPageConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: HowItWorksPage;
  };

/** Page Info on the &quot;ContentLanguagesToHowItWorksPageConnection&quot; */
export type ContentLanguagesToHowItWorksPageConnectionPageInfo = HowItWorksPageConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "ContentLanguagesToHowItWorksPageConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ContentLanguagesToHowItWorksPageConnection connection */
export type ContentLanguagesToHowItWorksPageConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the ContentLanguages type and the ContentLanguages type */
export type ContentLanguagesToParentContentLanguagesConnectionEdge =
  ContentLanguagesConnectionEdge &
    Edge &
    OneToOneConnection & {
      __typename?: "ContentLanguagesToParentContentLanguagesConnectionEdge";
      /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
      cursor?: Maybe<Scalars["String"]>;
      /** The node of the connection, without the edges */
      node: ContentLanguages;
    };

/** Connection between the ContentLanguages type and the RegionProfile type */
export type ContentLanguagesToRegionProfileConnection = Connection &
  RegionProfileConnection & {
    __typename?: "ContentLanguagesToRegionProfileConnection";
    /** Edges for the ContentLanguagesToRegionProfileConnection connection */
    edges: Array<ContentLanguagesToRegionProfileConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<RegionProfile>;
    /** Information about pagination in a connection. */
    pageInfo: ContentLanguagesToRegionProfileConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentLanguagesToRegionProfileConnectionEdge = Edge &
  RegionProfileConnectionEdge & {
    __typename?: "ContentLanguagesToRegionProfileConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: RegionProfile;
  };

/** Page Info on the &quot;ContentLanguagesToRegionProfileConnection&quot; */
export type ContentLanguagesToRegionProfileConnectionPageInfo = PageInfo &
  RegionProfileConnectionPageInfo &
  WpPageInfo & {
    __typename?: "ContentLanguagesToRegionProfileConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ContentLanguagesToRegionProfileConnection connection */
export type ContentLanguagesToRegionProfileConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the ContentLanguages type and the Taxonomy type */
export type ContentLanguagesToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: "ContentLanguagesToTaxonomyConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** Nodes used to manage content */
export type ContentNode = {
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars["String"];
  /** The ID of the node in the database. */
  databaseId: Scalars["Int"];
  /** Post publishing date. */
  date?: Maybe<Scalars["String"]>;
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars["String"]>;
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars["String"]>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars["String"]>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars["String"]>;
  /** The unique resource identifier path */
  id: Scalars["ID"];
  /** Whether the node is a Content Node */
  isContentNode: Scalars["Boolean"];
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars["Boolean"]>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars["Boolean"]>;
  /** Whether the node is a Term */
  isTermNode: Scalars["Boolean"];
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link?: Maybe<Scalars["String"]>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars["String"]>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars["String"]>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars["Int"]>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars["ID"]>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars["String"]>;
  /** The current status of the object */
  status?: Maybe<Scalars["String"]>;
  /** The template assigned to a node of content */
  template?: Maybe<ContentTemplate>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars["String"]>;
};

/** Nodes used to manage content */
export type ContentNodeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** Nodes used to manage content */
export type ContentNodeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** Connection to ContentNode Nodes */
export type ContentNodeConnection = {
  /** A list of edges (relational context) between ContentType and connected ContentNode Nodes */
  edges: Array<ContentNodeConnectionEdge>;
  /** A list of connected ContentNode Nodes */
  nodes: Array<ContentNode>;
  /** Information about pagination in a connection. */
  pageInfo: ContentNodeConnectionPageInfo;
};

/** Edge between a Node and a connected ContentNode */
export type ContentNodeConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected ContentNode Node */
  node: ContentNode;
};

/** Page Info on the connected ContentNodeConnectionEdge */
export type ContentNodeConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ContentNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  Id = "ID",
  /** Identify a resource by the URI. */
  Uri = "URI",
}

/** Connection between the ContentNode type and the ContentType type */
export type ContentNodeToContentTypeConnectionEdge = ContentTypeConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: "ContentNodeToContentTypeConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: ContentType;
  };

/** Connection between the ContentNode type and the User type */
export type ContentNodeToEditLastConnectionEdge = Edge &
  OneToOneConnection &
  UserConnectionEdge & {
    __typename?: "ContentNodeToEditLastConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: User;
  };

/** Connection between the ContentNode type and the User type */
export type ContentNodeToEditLockConnectionEdge = Edge &
  OneToOneConnection &
  UserConnectionEdge & {
    __typename?: "ContentNodeToEditLockConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The timestamp for when the node was last edited */
    lockTimestamp?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: User;
  };

/** Connection between the ContentNode type and the EnqueuedScript type */
export type ContentNodeToEnqueuedScriptConnection = Connection &
  EnqueuedScriptConnection & {
    __typename?: "ContentNodeToEnqueuedScriptConnection";
    /** Edges for the ContentNodeToEnqueuedScriptConnection connection */
    edges: Array<ContentNodeToEnqueuedScriptConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedScript>;
    /** Information about pagination in a connection. */
    pageInfo: ContentNodeToEnqueuedScriptConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentNodeToEnqueuedScriptConnectionEdge = Edge &
  EnqueuedScriptConnectionEdge & {
    __typename?: "ContentNodeToEnqueuedScriptConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: EnqueuedScript;
  };

/** Page Info on the &quot;ContentNodeToEnqueuedScriptConnection&quot; */
export type ContentNodeToEnqueuedScriptConnectionPageInfo = EnqueuedScriptConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "ContentNodeToEnqueuedScriptConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Connection between the ContentNode type and the EnqueuedStylesheet type */
export type ContentNodeToEnqueuedStylesheetConnection = Connection &
  EnqueuedStylesheetConnection & {
    __typename?: "ContentNodeToEnqueuedStylesheetConnection";
    /** Edges for the ContentNodeToEnqueuedStylesheetConnection connection */
    edges: Array<ContentNodeToEnqueuedStylesheetConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedStylesheet>;
    /** Information about pagination in a connection. */
    pageInfo: ContentNodeToEnqueuedStylesheetConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentNodeToEnqueuedStylesheetConnectionEdge = Edge &
  EnqueuedStylesheetConnectionEdge & {
    __typename?: "ContentNodeToEnqueuedStylesheetConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: EnqueuedStylesheet;
  };

/** Page Info on the &quot;ContentNodeToEnqueuedStylesheetConnection&quot; */
export type ContentNodeToEnqueuedStylesheetConnectionPageInfo =
  EnqueuedStylesheetConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: "ContentNodeToEnqueuedStylesheetConnectionPageInfo";
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars["String"]>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars["Boolean"];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars["Boolean"];
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars["String"]>;
    };

/** The ContentStatus type */
export type ContentStatus = DatabaseIdentifier &
  HierarchicalNode &
  HierarchicalTermNode &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: "ContentStatus";
    /** The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<ContentStatusToAncestorsContentStatusConnection>;
    /** Connection between the ContentStatus type and the Article type */
    articles?: Maybe<ContentStatusToArticleConnection>;
    /** Connection between the ContentStatus type and its children ContentStatuses. */
    children?: Maybe<ContentStatusToContentStatusConnection>;
    /** Connection between the ContentStatus type and the Collection type */
    collections?: Maybe<ContentStatusToCollectionConnection>;
    /** Connection between the ContentStatus type and the ContentNode type */
    contentNodes?: Maybe<ContentStatusToContentNodeConnection>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    contentStatusId?: Maybe<Scalars["Int"]>;
    /** The number of objects connected to the object */
    count?: Maybe<Scalars["Int"]>;
    /** Connection between the ContentStatus type and the CountryProfile type */
    countryProfiles?: Maybe<ContentStatusToCountryProfileConnection>;
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** The description of the object */
    description?: Maybe<Scalars["String"]>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** Connection between the ContentStatus type and the HomePage type */
    homePages?: Maybe<ContentStatusToHomePageConnection>;
    /** Connection between the ContentStatus type and the HowItWorksPage type */
    howItWorksPages?: Maybe<ContentStatusToHowItWorksPageConnection>;
    /** The unique resource identifier path */
    id: Scalars["ID"];
    /** Whether the node is a Content Node */
    isContentNode: Scalars["Boolean"];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** Whether the node is a Term */
    isTermNode: Scalars["Boolean"];
    /** The link to the term */
    link?: Maybe<Scalars["String"]>;
    /** The human friendly name of the object. */
    name?: Maybe<Scalars["String"]>;
    /** Connection between the ContentStatus type and its parent ContentStatus. */
    parent?: Maybe<ContentStatusToParentContentStatusConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars["Int"]>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars["ID"]>;
    /** Connection between the ContentStatus type and the RegionProfile type */
    regionProfiles?: Maybe<ContentStatusToRegionProfileConnection>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars["String"]>;
    /** Connection between the ContentStatus type and the Taxonomy type */
    taxonomy?: Maybe<ContentStatusToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars["String"]>;
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars["Int"]>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars["Int"]>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars["String"]>;
  };

/** The ContentStatus type */
export type ContentStatusAncestorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The ContentStatus type */
export type ContentStatusArticlesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentStatusToArticleConnectionWhereArgs>;
};

/** The ContentStatus type */
export type ContentStatusChildrenArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentStatusToContentStatusConnectionWhereArgs>;
};

/** The ContentStatus type */
export type ContentStatusCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentStatusToCollectionConnectionWhereArgs>;
};

/** The ContentStatus type */
export type ContentStatusContentNodesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentStatusToContentNodeConnectionWhereArgs>;
};

/** The ContentStatus type */
export type ContentStatusCountryProfilesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentStatusToCountryProfileConnectionWhereArgs>;
};

/** The ContentStatus type */
export type ContentStatusEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The ContentStatus type */
export type ContentStatusEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The ContentStatus type */
export type ContentStatusHomePagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentStatusToHomePageConnectionWhereArgs>;
};

/** The ContentStatus type */
export type ContentStatusHowItWorksPagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentStatusToHowItWorksPageConnectionWhereArgs>;
};

/** The ContentStatus type */
export type ContentStatusRegionProfilesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentStatusToRegionProfileConnectionWhereArgs>;
};

/** Connection to ContentStatus Nodes */
export type ContentStatusConnection = {
  /** A list of edges (relational context) between RootQuery and connected ContentStatus Nodes */
  edges: Array<ContentStatusConnectionEdge>;
  /** A list of connected ContentStatus Nodes */
  nodes: Array<ContentStatus>;
  /** Information about pagination in a connection. */
  pageInfo: ContentStatusConnectionPageInfo;
};

/** Edge between a Node and a connected ContentStatus */
export type ContentStatusConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected ContentStatus Node */
  node: ContentStatus;
};

/** Page Info on the connected ContentStatusConnectionEdge */
export type ContentStatusConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ContentStatusIdType {
  /** The Database ID for the node */
  DatabaseId = "DATABASE_ID",
  /** The hashed Global ID */
  Id = "ID",
  /** The name of the node */
  Name = "NAME",
  /** Url friendly name of the node */
  Slug = "SLUG",
  /** The URI for the node */
  Uri = "URI",
}

/** Connection between the ContentStatus type and the ContentStatus type */
export type ContentStatusToAncestorsContentStatusConnection = Connection &
  ContentStatusConnection & {
    __typename?: "ContentStatusToAncestorsContentStatusConnection";
    /** Edges for the ContentStatusToAncestorsContentStatusConnection connection */
    edges: Array<ContentStatusToAncestorsContentStatusConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentStatus>;
    /** Information about pagination in a connection. */
    pageInfo: ContentStatusToAncestorsContentStatusConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentStatusToAncestorsContentStatusConnectionEdge = ContentStatusConnectionEdge &
  Edge & {
    __typename?: "ContentStatusToAncestorsContentStatusConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentStatus;
  };

/** Page Info on the &quot;ContentStatusToAncestorsContentStatusConnection&quot; */
export type ContentStatusToAncestorsContentStatusConnectionPageInfo =
  ContentStatusConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: "ContentStatusToAncestorsContentStatusConnectionPageInfo";
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars["String"]>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars["Boolean"];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars["Boolean"];
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars["String"]>;
    };

/** Connection between the ContentStatus type and the Article type */
export type ContentStatusToArticleConnection = ArticleConnection &
  Connection & {
    __typename?: "ContentStatusToArticleConnection";
    /** Edges for the ContentStatusToArticleConnection connection */
    edges: Array<ContentStatusToArticleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Article>;
    /** Information about pagination in a connection. */
    pageInfo: ContentStatusToArticleConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentStatusToArticleConnectionEdge = ArticleConnectionEdge &
  Edge & {
    __typename?: "ContentStatusToArticleConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Article;
  };

/** Page Info on the &quot;ContentStatusToArticleConnection&quot; */
export type ContentStatusToArticleConnectionPageInfo = ArticleConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "ContentStatusToArticleConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ContentStatusToArticleConnection connection */
export type ContentStatusToArticleConnectionWhereArgs = {
  /** Category ID */
  categoryId?: InputMaybe<Scalars["Int"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars["String"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the ContentStatus type and the Collection type */
export type ContentStatusToCollectionConnection = CollectionConnection &
  Connection & {
    __typename?: "ContentStatusToCollectionConnection";
    /** Edges for the ContentStatusToCollectionConnection connection */
    edges: Array<ContentStatusToCollectionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Collection>;
    /** Information about pagination in a connection. */
    pageInfo: ContentStatusToCollectionConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentStatusToCollectionConnectionEdge = CollectionConnectionEdge &
  Edge & {
    __typename?: "ContentStatusToCollectionConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Collection;
  };

/** Page Info on the &quot;ContentStatusToCollectionConnection&quot; */
export type ContentStatusToCollectionConnectionPageInfo = CollectionConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "ContentStatusToCollectionConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ContentStatusToCollectionConnection connection */
export type ContentStatusToCollectionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the ContentStatus type and the ContentNode type */
export type ContentStatusToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: "ContentStatusToContentNodeConnection";
    /** Edges for the ContentStatusToContentNodeConnection connection */
    edges: Array<ContentStatusToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: ContentStatusToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentStatusToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: "ContentStatusToContentNodeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;ContentStatusToContentNodeConnection&quot; */
export type ContentStatusToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "ContentStatusToContentNodeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ContentStatusToContentNodeConnection connection */
export type ContentStatusToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfContentStatusEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the ContentStatus type and the ContentStatus type */
export type ContentStatusToContentStatusConnection = Connection &
  ContentStatusConnection & {
    __typename?: "ContentStatusToContentStatusConnection";
    /** Edges for the ContentStatusToContentStatusConnection connection */
    edges: Array<ContentStatusToContentStatusConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentStatus>;
    /** Information about pagination in a connection. */
    pageInfo: ContentStatusToContentStatusConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentStatusToContentStatusConnectionEdge = ContentStatusConnectionEdge &
  Edge & {
    __typename?: "ContentStatusToContentStatusConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentStatus;
  };

/** Page Info on the &quot;ContentStatusToContentStatusConnection&quot; */
export type ContentStatusToContentStatusConnectionPageInfo = ContentStatusConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "ContentStatusToContentStatusConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ContentStatusToContentStatusConnection connection */
export type ContentStatusToContentStatusConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the ContentStatus type and the CountryProfile type */
export type ContentStatusToCountryProfileConnection = Connection &
  CountryProfileConnection & {
    __typename?: "ContentStatusToCountryProfileConnection";
    /** Edges for the ContentStatusToCountryProfileConnection connection */
    edges: Array<ContentStatusToCountryProfileConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<CountryProfile>;
    /** Information about pagination in a connection. */
    pageInfo: ContentStatusToCountryProfileConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentStatusToCountryProfileConnectionEdge = CountryProfileConnectionEdge &
  Edge & {
    __typename?: "ContentStatusToCountryProfileConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: CountryProfile;
  };

/** Page Info on the &quot;ContentStatusToCountryProfileConnection&quot; */
export type ContentStatusToCountryProfileConnectionPageInfo = CountryProfileConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "ContentStatusToCountryProfileConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ContentStatusToCountryProfileConnection connection */
export type ContentStatusToCountryProfileConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the ContentStatus type and the HomePage type */
export type ContentStatusToHomePageConnection = Connection &
  HomePageConnection & {
    __typename?: "ContentStatusToHomePageConnection";
    /** Edges for the ContentStatusToHomePageConnection connection */
    edges: Array<ContentStatusToHomePageConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<HomePage>;
    /** Information about pagination in a connection. */
    pageInfo: ContentStatusToHomePageConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentStatusToHomePageConnectionEdge = Edge &
  HomePageConnectionEdge & {
    __typename?: "ContentStatusToHomePageConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: HomePage;
  };

/** Page Info on the &quot;ContentStatusToHomePageConnection&quot; */
export type ContentStatusToHomePageConnectionPageInfo = HomePageConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "ContentStatusToHomePageConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ContentStatusToHomePageConnection connection */
export type ContentStatusToHomePageConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the ContentStatus type and the HowItWorksPage type */
export type ContentStatusToHowItWorksPageConnection = Connection &
  HowItWorksPageConnection & {
    __typename?: "ContentStatusToHowItWorksPageConnection";
    /** Edges for the ContentStatusToHowItWorksPageConnection connection */
    edges: Array<ContentStatusToHowItWorksPageConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<HowItWorksPage>;
    /** Information about pagination in a connection. */
    pageInfo: ContentStatusToHowItWorksPageConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentStatusToHowItWorksPageConnectionEdge = Edge &
  HowItWorksPageConnectionEdge & {
    __typename?: "ContentStatusToHowItWorksPageConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: HowItWorksPage;
  };

/** Page Info on the &quot;ContentStatusToHowItWorksPageConnection&quot; */
export type ContentStatusToHowItWorksPageConnectionPageInfo = HowItWorksPageConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "ContentStatusToHowItWorksPageConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ContentStatusToHowItWorksPageConnection connection */
export type ContentStatusToHowItWorksPageConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the ContentStatus type and the ContentStatus type */
export type ContentStatusToParentContentStatusConnectionEdge = ContentStatusConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: "ContentStatusToParentContentStatusConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: ContentStatus;
  };

/** Connection between the ContentStatus type and the RegionProfile type */
export type ContentStatusToRegionProfileConnection = Connection &
  RegionProfileConnection & {
    __typename?: "ContentStatusToRegionProfileConnection";
    /** Edges for the ContentStatusToRegionProfileConnection connection */
    edges: Array<ContentStatusToRegionProfileConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<RegionProfile>;
    /** Information about pagination in a connection. */
    pageInfo: ContentStatusToRegionProfileConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentStatusToRegionProfileConnectionEdge = Edge &
  RegionProfileConnectionEdge & {
    __typename?: "ContentStatusToRegionProfileConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: RegionProfile;
  };

/** Page Info on the &quot;ContentStatusToRegionProfileConnection&quot; */
export type ContentStatusToRegionProfileConnectionPageInfo = PageInfo &
  RegionProfileConnectionPageInfo &
  WpPageInfo & {
    __typename?: "ContentStatusToRegionProfileConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ContentStatusToRegionProfileConnection connection */
export type ContentStatusToRegionProfileConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the ContentStatus type and the Taxonomy type */
export type ContentStatusToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: "ContentStatusToTaxonomyConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** The template assigned to a node of content */
export type ContentTemplate = {
  /** The name of the template */
  templateName?: Maybe<Scalars["String"]>;
};

/** An Post Type object */
export type ContentType = Node &
  UniformResourceIdentifiable & {
    __typename?: "ContentType";
    /** Whether this content type should can be exported. */
    canExport?: Maybe<Scalars["Boolean"]>;
    /** Connection between the ContentType type and the Taxonomy type */
    connectedTaxonomies?: Maybe<ContentTypeToTaxonomyConnection>;
    /** Connection between the ContentType type and the ContentNode type */
    contentNodes?: Maybe<ContentTypeToContentNodeConnection>;
    /** Whether content of this type should be deleted when the author of it is deleted from the system. */
    deleteWithUser?: Maybe<Scalars["Boolean"]>;
    /** Description of the content type. */
    description?: Maybe<Scalars["String"]>;
    /** Whether to exclude nodes of this content type from front end search results. */
    excludeFromSearch?: Maybe<Scalars["Boolean"]>;
    /** The plural name of the content type within the GraphQL Schema. */
    graphqlPluralName?: Maybe<Scalars["String"]>;
    /** The singular name of the content type within the GraphQL Schema. */
    graphqlSingleName?: Maybe<Scalars["String"]>;
    /** Whether this content type should have archives. Content archives are generated by type and by date. */
    hasArchive?: Maybe<Scalars["Boolean"]>;
    /** Whether the content type is hierarchical, for example pages. */
    hierarchical?: Maybe<Scalars["Boolean"]>;
    /** The globally unique identifier of the post-type object. */
    id: Scalars["ID"];
    /** Whether the node is a Content Node */
    isContentNode: Scalars["Boolean"];
    /** Whether this page is set to the static front page. */
    isFrontPage: Scalars["Boolean"];
    /** Whether this page is set to the blog posts page. */
    isPostsPage: Scalars["Boolean"];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** Whether the node is a Term */
    isTermNode: Scalars["Boolean"];
    /** Display name of the content type. */
    label?: Maybe<Scalars["String"]>;
    /** Details about the content type labels. */
    labels?: Maybe<PostTypeLabelDetails>;
    /** The name of the icon file to display as a menu icon. */
    menuIcon?: Maybe<Scalars["String"]>;
    /** The position of this post type in the menu. Only applies if show_in_menu is true. */
    menuPosition?: Maybe<Scalars["Int"]>;
    /** The internal name of the post type. This should not be used for display purposes. */
    name?: Maybe<Scalars["String"]>;
    /** Whether a content type is intended for use publicly either via the admin interface or by front-end users. While the default settings of exclude_from_search, publicly_queryable, show_ui, and show_in_nav_menus are inherited from public, each does not rely on this relationship and controls a very specific intention. */
    public?: Maybe<Scalars["Boolean"]>;
    /** Whether queries can be performed on the front end for the content type as part of parse_request(). */
    publiclyQueryable?: Maybe<Scalars["Boolean"]>;
    /** Name of content type to display in REST API &quot;wp/v2&quot; namespace. */
    restBase?: Maybe<Scalars["String"]>;
    /** The REST Controller class assigned to handling this content type. */
    restControllerClass?: Maybe<Scalars["String"]>;
    /** Makes this content type available via the admin bar. */
    showInAdminBar?: Maybe<Scalars["Boolean"]>;
    /** Whether to add the content type to the GraphQL Schema. */
    showInGraphql?: Maybe<Scalars["Boolean"]>;
    /** Where to show the content type in the admin menu. To work, $show_ui must be true. If true, the post type is shown in its own top level menu. If false, no menu is shown. If a string of an existing top level menu (eg. &quot;tools.php&quot; or &quot;edit.php?post_type=page&quot;), the post type will be placed as a sub-menu of that. */
    showInMenu?: Maybe<Scalars["Boolean"]>;
    /** Makes this content type available for selection in navigation menus. */
    showInNavMenus?: Maybe<Scalars["Boolean"]>;
    /** Whether the content type is associated with a route under the the REST API &quot;wp/v2&quot; namespace. */
    showInRest?: Maybe<Scalars["Boolean"]>;
    /** Whether to generate and allow a UI for managing this content type in the admin. */
    showUi?: Maybe<Scalars["Boolean"]>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars["String"]>;
  };

/** An Post Type object */
export type ContentTypeConnectedTaxonomiesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** An Post Type object */
export type ContentTypeContentNodesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ContentTypeToContentNodeConnectionWhereArgs>;
};

/** Connection to ContentType Nodes */
export type ContentTypeConnection = {
  /** A list of edges (relational context) between RootQuery and connected ContentType Nodes */
  edges: Array<ContentTypeConnectionEdge>;
  /** A list of connected ContentType Nodes */
  nodes: Array<ContentType>;
  /** Information about pagination in a connection. */
  pageInfo: ContentTypeConnectionPageInfo;
};

/** Edge between a Node and a connected ContentType */
export type ContentTypeConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected ContentType Node */
  node: ContentType;
};

/** Page Info on the connected ContentTypeConnectionEdge */
export type ContentTypeConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** Allowed Content Types */
export enum ContentTypeEnum {
  /** The Type of Content object */
  Article = "ARTICLE",
  /** The Type of Content object */
  Attachment = "ATTACHMENT",
  /** The Type of Content object */
  Collection = "COLLECTION",
  /** The Type of Content object */
  Country = "COUNTRY",
  /** The Type of Content object */
  Global = "GLOBAL",
  /** The Type of Content object */
  Home = "HOME",
  /** The Type of Content object */
  HowItWorks = "HOW_IT_WORKS",
  /** The Type of Content object */
  Page = "PAGE",
  /** The Type of Content object */
  Post = "POST",
  /** The Type of Content object */
  RegionProfile = "REGION_PROFILE",
}

/** The Type of Identifier used to fetch a single Content Type node. To be used along with the "id" field. Default is "ID". */
export enum ContentTypeIdTypeEnum {
  /** The globally unique ID */
  Id = "ID",
  /** The name of the content type. */
  Name = "NAME",
}

/** Connection between the ContentType type and the ContentNode type */
export type ContentTypeToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: "ContentTypeToContentNodeConnection";
    /** Edges for the ContentTypeToContentNodeConnection connection */
    edges: Array<ContentTypeToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: ContentTypeToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentTypeToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: "ContentTypeToContentNodeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;ContentTypeToContentNodeConnection&quot; */
export type ContentTypeToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "ContentTypeToContentNodeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the ContentTypeToContentNodeConnection connection */
export type ContentTypeToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the ContentType type and the Taxonomy type */
export type ContentTypeToTaxonomyConnection = Connection &
  TaxonomyConnection & {
    __typename?: "ContentTypeToTaxonomyConnection";
    /** Edges for the ContentTypeToTaxonomyConnection connection */
    edges: Array<ContentTypeToTaxonomyConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Taxonomy>;
    /** Information about pagination in a connection. */
    pageInfo: ContentTypeToTaxonomyConnectionPageInfo;
  };

/** An edge in a connection */
export type ContentTypeToTaxonomyConnectionEdge = Edge &
  TaxonomyConnectionEdge & {
    __typename?: "ContentTypeToTaxonomyConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Taxonomy;
  };

/** Page Info on the &quot;ContentTypeToTaxonomyConnection&quot; */
export type ContentTypeToTaxonomyConnectionPageInfo = PageInfo &
  TaxonomyConnectionPageInfo &
  WpPageInfo & {
    __typename?: "ContentTypeToTaxonomyConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Allowed Content Types of the Category taxonomy. */
export enum ContentTypesOfCategoryEnum {
  /** The Type of Content object */
  Article = "ARTICLE",
  /** The Type of Content object */
  Post = "POST",
}

/** Allowed Content Types of the ContentLanguages taxonomy. */
export enum ContentTypesOfContentLanguagesEnum {
  /** The Type of Content object */
  Article = "ARTICLE",
  /** The Type of Content object */
  Collection = "COLLECTION",
  /** The Type of Content object */
  Country = "COUNTRY",
  /** The Type of Content object */
  Home = "HOME",
  /** The Type of Content object */
  HowItWorks = "HOW_IT_WORKS",
  /** The Type of Content object */
  RegionProfile = "REGION_PROFILE",
}

/** Allowed Content Types of the ContentStatus taxonomy. */
export enum ContentTypesOfContentStatusEnum {
  /** The Type of Content object */
  Article = "ARTICLE",
  /** The Type of Content object */
  Collection = "COLLECTION",
  /** The Type of Content object */
  Country = "COUNTRY",
  /** The Type of Content object */
  Home = "HOME",
  /** The Type of Content object */
  HowItWorks = "HOW_IT_WORKS",
  /** The Type of Content object */
  RegionProfile = "REGION_PROFILE",
}

/** Allowed Content Types of the Country taxonomy. */
export enum ContentTypesOfCountryEnum {
  /** The Type of Content object */
  Country = "COUNTRY",
}

/** Allowed Content Types of the Locale taxonomy. */
export enum ContentTypesOfLocaleEnum {
  /** The Type of Content object */
  Article = "ARTICLE",
  /** The Type of Content object */
  Collection = "COLLECTION",
  /** The Type of Content object */
  Country = "COUNTRY",
  /** The Type of Content object */
  Home = "HOME",
  /** The Type of Content object */
  HowItWorks = "HOW_IT_WORKS",
  /** The Type of Content object */
  RegionProfile = "REGION_PROFILE",
}

/** Allowed Content Types of the PostFormat taxonomy. */
export enum ContentTypesOfPostFormatEnum {
  /** The Type of Content object */
  Post = "POST",
}

/** Allowed Content Types of the Region taxonomy. */
export enum ContentTypesOfRegionEnum {
  /** The Type of Content object */
  Country = "COUNTRY",
  /** The Type of Content object */
  RegionProfile = "REGION_PROFILE",
}

/** Allowed Content Types of the Tag taxonomy. */
export enum ContentTypesOfTagEnum {
  /** The Type of Content object */
  Article = "ARTICLE",
  /** The Type of Content object */
  Collection = "COLLECTION",
  /** The Type of Content object */
  Country = "COUNTRY",
  /** The Type of Content object */
  Post = "POST",
}

/** The Country type */
export type Country = DatabaseIdentifier &
  HierarchicalNode &
  HierarchicalTermNode &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: "Country";
    /** The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<CountryToAncestorsCountryConnection>;
    /** Connection between the Country type and its children Countries. */
    children?: Maybe<CountryToCountryConnection>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Template | Country Taxonomy Content&quot; was set to Show in GraphQL. */
    content?: Maybe<Country_Content>;
    /** Connection between the Country type and the ContentNode type */
    contentNodes?: Maybe<CountryToContentNodeConnection>;
    /** The number of objects connected to the object */
    count?: Maybe<Scalars["Int"]>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    countryId?: Maybe<Scalars["Int"]>;
    /** Connection between the Country type and the CountryProfile type */
    countryProfiles?: Maybe<CountryToCountryProfileConnection>;
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** The description of the object */
    description?: Maybe<Scalars["String"]>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The unique resource identifier path */
    id: Scalars["ID"];
    /** Whether the node is a Content Node */
    isContentNode: Scalars["Boolean"];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** Whether the node is a Term */
    isTermNode: Scalars["Boolean"];
    /** The link to the term */
    link?: Maybe<Scalars["String"]>;
    /** The human friendly name of the object. */
    name?: Maybe<Scalars["String"]>;
    /** Connection between the Country type and its parent Country. */
    parent?: Maybe<CountryToParentCountryConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars["Int"]>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars["ID"]>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Template | Request form Country Taxonomy&quot; was set to Show in GraphQL. */
    requestForm?: Maybe<Country_Requestform>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars["String"]>;
    /** Connection between the Country type and the Taxonomy type */
    taxonomy?: Maybe<CountryToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars["String"]>;
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars["Int"]>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars["Int"]>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars["String"]>;
  };

/** The Country type */
export type CountryAncestorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The Country type */
export type CountryChildrenArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CountryToCountryConnectionWhereArgs>;
};

/** The Country type */
export type CountryContentNodesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CountryToContentNodeConnectionWhereArgs>;
};

/** The Country type */
export type CountryCountryProfilesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CountryToCountryProfileConnectionWhereArgs>;
};

/** The Country type */
export type CountryEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The Country type */
export type CountryEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** Connection to Country Nodes */
export type CountryConnection = {
  /** A list of edges (relational context) between RootQuery and connected Country Nodes */
  edges: Array<CountryConnectionEdge>;
  /** A list of connected Country Nodes */
  nodes: Array<Country>;
  /** Information about pagination in a connection. */
  pageInfo: CountryConnectionPageInfo;
};

/** Edge between a Node and a connected Country */
export type CountryConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected Country Node */
  node: Country;
};

/** Page Info on the connected CountryConnectionEdge */
export type CountryConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CountryIdType {
  /** The Database ID for the node */
  DatabaseId = "DATABASE_ID",
  /** The hashed Global ID */
  Id = "ID",
  /** The name of the node */
  Name = "NAME",
  /** Url friendly name of the node */
  Slug = "SLUG",
  /** The URI for the node */
  Uri = "URI",
}

/** The CountryProfile type */
export type CountryProfile = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  HierarchicalNode &
  MenuItemLinkable &
  Node &
  NodeWithPageAttributes &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: "CountryProfile";
    /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
    /** Connection between the HierarchicalContentNode type and the ContentNode type */
    children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
    /** Connection between the CountryProfile type and the ContentLanguages type */
    contentLanguages?: Maybe<CountryProfileToContentLanguagesConnection>;
    /** Connection between the CountryProfile type and the ContentStatus type */
    contentStatuses?: Maybe<CountryProfileToContentStatusConnection>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars["String"];
    /** Connection between the CountryProfile type and the Country type */
    countries?: Maybe<CountryProfileToCountryConnection>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Template | Country&quot; was set to Show in GraphQL. */
    country?: Maybe<CountryProfile_Country>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    countryProfileId: Scalars["Int"];
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** Post publishing date. */
    date?: Maybe<Scalars["String"]>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars["String"]>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars["String"]>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars["String"]>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars["String"]>;
    /** The globally unique identifier of the country object. */
    id: Scalars["ID"];
    /** Whether the node is a Content Node */
    isContentNode: Scalars["Boolean"];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars["Boolean"]>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** Whether the node is a Term */
    isTermNode: Scalars["Boolean"];
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars["String"]>;
    /** Connection between the CountryProfile type and the Locale type */
    locales?: Maybe<CountryProfileToLocaleConnection>;
    /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
    menuOrder?: Maybe<Scalars["Int"]>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Meta&quot; was set to Show in GraphQL. */
    meta?: Maybe<CountryProfile_Meta>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars["String"]>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars["String"]>;
    /** The parent of the node. The parent object can be of various types */
    parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars["Int"]>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars["ID"]>;
    /** Connection between the CountryProfile type and the CountryProfile type */
    preview?: Maybe<CountryProfileToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars["Int"]>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars["ID"]>;
    /** Connection between the CountryProfile type and the Region type */
    regions?: Maybe<CountryProfileToRegionConnection>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Template | Request form&quot; was set to Show in GraphQL. */
    requestForm?: Maybe<CountryProfile_Requestform>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars["String"]>;
    /** The current status of the object */
    status?: Maybe<Scalars["String"]>;
    /** Connection between the CountryProfile type and the tag type */
    tags?: Maybe<CountryProfileToTagConnection>;
    /** The template assigned to a node of content */
    template?: Maybe<ContentTemplate>;
    /** Connection between the CountryProfile type and the TermNode type */
    terms?: Maybe<CountryProfileToTermNodeConnection>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars["String"]>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars["String"]>;
  };

/** The CountryProfile type */
export type CountryProfileAncestorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

/** The CountryProfile type */
export type CountryProfileChildrenArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** The CountryProfile type */
export type CountryProfileContentLanguagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CountryProfileToContentLanguagesConnectionWhereArgs>;
};

/** The CountryProfile type */
export type CountryProfileContentStatusesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CountryProfileToContentStatusConnectionWhereArgs>;
};

/** The CountryProfile type */
export type CountryProfileCountriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CountryProfileToCountryConnectionWhereArgs>;
};

/** The CountryProfile type */
export type CountryProfileEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The CountryProfile type */
export type CountryProfileEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The CountryProfile type */
export type CountryProfileLocalesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CountryProfileToLocaleConnectionWhereArgs>;
};

/** The CountryProfile type */
export type CountryProfileRegionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CountryProfileToRegionConnectionWhereArgs>;
};

/** The CountryProfile type */
export type CountryProfileTagsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CountryProfileToTagConnectionWhereArgs>;
};

/** The CountryProfile type */
export type CountryProfileTermsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CountryProfileToTermNodeConnectionWhereArgs>;
};

/** The CountryProfile type */
export type CountryProfileTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to CountryProfile Nodes */
export type CountryProfileConnection = {
  /** A list of edges (relational context) between RootQuery and connected CountryProfile Nodes */
  edges: Array<CountryProfileConnectionEdge>;
  /** A list of connected CountryProfile Nodes */
  nodes: Array<CountryProfile>;
  /** Information about pagination in a connection. */
  pageInfo: CountryProfileConnectionPageInfo;
};

/** Edge between a Node and a connected CountryProfile */
export type CountryProfileConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected CountryProfile Node */
  node: CountryProfile;
};

/** Page Info on the connected CountryProfileConnectionEdge */
export type CountryProfileConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** Set relationships between the CountryProfile to ContentLanguages */
export type CountryProfileContentLanguagesInput = {
  /** If true, this will append the ContentLanguages to existing related ContentLanguages. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<CountryProfileContentLanguagesNodeInput>>>;
};

/** List of ContentLanguages to connect the CountryProfile to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CountryProfileContentLanguagesNodeInput = {
  /** The description of the ContentLanguages. This field is used to set a description of the ContentLanguages if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the ContentLanguages. If present, this will be used to connect to the CountryProfile. If no existing ContentLanguages exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the ContentLanguages. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the ContentLanguages. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Set relationships between the CountryProfile to ContentStatuses */
export type CountryProfileContentStatusesInput = {
  /** If true, this will append the ContentStatus to existing related ContentStatuses. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<CountryProfileContentStatusesNodeInput>>>;
};

/** List of ContentStatuses to connect the CountryProfile to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CountryProfileContentStatusesNodeInput = {
  /** The description of the ContentStatus. This field is used to set a description of the ContentStatus if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the ContentStatus. If present, this will be used to connect to the CountryProfile. If no existing ContentStatus exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the ContentStatus. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the ContentStatus. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Set relationships between the CountryProfile to Countries */
export type CountryProfileCountriesInput = {
  /** If true, this will append the Country to existing related Countries. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<CountryProfileCountriesNodeInput>>>;
};

/** List of Countries to connect the CountryProfile to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CountryProfileCountriesNodeInput = {
  /** The description of the Country. This field is used to set a description of the Country if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the Country. If present, this will be used to connect to the CountryProfile. If no existing Country exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the Country. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the Country. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CountryProfileIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  Id = "ID",
  /** Identify a resource by the URI. */
  Uri = "URI",
}

/** Set relationships between the CountryProfile to Locales */
export type CountryProfileLocalesInput = {
  /** If true, this will append the Locale to existing related Locales. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<CountryProfileLocalesNodeInput>>>;
};

/** List of Locales to connect the CountryProfile to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CountryProfileLocalesNodeInput = {
  /** The description of the Locale. This field is used to set a description of the Locale if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the Locale. If present, this will be used to connect to the CountryProfile. If no existing Locale exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the Locale. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the Locale. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Set relationships between the CountryProfile to Regions */
export type CountryProfileRegionsInput = {
  /** If true, this will append the Region to existing related Regions. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<CountryProfileRegionsNodeInput>>>;
};

/** List of Regions to connect the CountryProfile to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CountryProfileRegionsNodeInput = {
  /** The description of the Region. This field is used to set a description of the Region if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the Region. If present, this will be used to connect to the CountryProfile. If no existing Region exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the Region. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the Region. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Set relationships between the CountryProfile to tags */
export type CountryProfileTagsInput = {
  /** If true, this will append the tag to existing related tags. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<CountryProfileTagsNodeInput>>>;
};

/** List of tags to connect the CountryProfile to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type CountryProfileTagsNodeInput = {
  /** The description of the tag. This field is used to set a description of the tag if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the tag. If present, this will be used to connect to the CountryProfile. If no existing tag exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the tag. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the tag. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Connection between the CountryProfile type and the ContentLanguages type */
export type CountryProfileToContentLanguagesConnection = Connection &
  ContentLanguagesConnection & {
    __typename?: "CountryProfileToContentLanguagesConnection";
    /** Edges for the CountryProfileToContentLanguagesConnection connection */
    edges: Array<CountryProfileToContentLanguagesConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentLanguages>;
    /** Information about pagination in a connection. */
    pageInfo: CountryProfileToContentLanguagesConnectionPageInfo;
  };

/** An edge in a connection */
export type CountryProfileToContentLanguagesConnectionEdge = ContentLanguagesConnectionEdge &
  Edge & {
    __typename?: "CountryProfileToContentLanguagesConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentLanguages;
  };

/** Page Info on the &quot;CountryProfileToContentLanguagesConnection&quot; */
export type CountryProfileToContentLanguagesConnectionPageInfo =
  ContentLanguagesConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: "CountryProfileToContentLanguagesConnectionPageInfo";
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars["String"]>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars["Boolean"];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars["Boolean"];
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars["String"]>;
    };

/** Arguments for filtering the CountryProfileToContentLanguagesConnection connection */
export type CountryProfileToContentLanguagesConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the CountryProfile type and the ContentStatus type */
export type CountryProfileToContentStatusConnection = Connection &
  ContentStatusConnection & {
    __typename?: "CountryProfileToContentStatusConnection";
    /** Edges for the CountryProfileToContentStatusConnection connection */
    edges: Array<CountryProfileToContentStatusConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentStatus>;
    /** Information about pagination in a connection. */
    pageInfo: CountryProfileToContentStatusConnectionPageInfo;
  };

/** An edge in a connection */
export type CountryProfileToContentStatusConnectionEdge = ContentStatusConnectionEdge &
  Edge & {
    __typename?: "CountryProfileToContentStatusConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentStatus;
  };

/** Page Info on the &quot;CountryProfileToContentStatusConnection&quot; */
export type CountryProfileToContentStatusConnectionPageInfo = ContentStatusConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "CountryProfileToContentStatusConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the CountryProfileToContentStatusConnection connection */
export type CountryProfileToContentStatusConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the CountryProfile type and the Country type */
export type CountryProfileToCountryConnection = Connection &
  CountryConnection & {
    __typename?: "CountryProfileToCountryConnection";
    /** Edges for the CountryProfileToCountryConnection connection */
    edges: Array<CountryProfileToCountryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Country>;
    /** Information about pagination in a connection. */
    pageInfo: CountryProfileToCountryConnectionPageInfo;
  };

/** An edge in a connection */
export type CountryProfileToCountryConnectionEdge = CountryConnectionEdge &
  Edge & {
    __typename?: "CountryProfileToCountryConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Country;
  };

/** Page Info on the &quot;CountryProfileToCountryConnection&quot; */
export type CountryProfileToCountryConnectionPageInfo = CountryConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "CountryProfileToCountryConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the CountryProfileToCountryConnection connection */
export type CountryProfileToCountryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the CountryProfile type and the Locale type */
export type CountryProfileToLocaleConnection = Connection &
  LocaleConnection & {
    __typename?: "CountryProfileToLocaleConnection";
    /** Edges for the CountryProfileToLocaleConnection connection */
    edges: Array<CountryProfileToLocaleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Locale>;
    /** Information about pagination in a connection. */
    pageInfo: CountryProfileToLocaleConnectionPageInfo;
  };

/** An edge in a connection */
export type CountryProfileToLocaleConnectionEdge = Edge &
  LocaleConnectionEdge & {
    __typename?: "CountryProfileToLocaleConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Locale;
  };

/** Page Info on the &quot;CountryProfileToLocaleConnection&quot; */
export type CountryProfileToLocaleConnectionPageInfo = LocaleConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "CountryProfileToLocaleConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the CountryProfileToLocaleConnection connection */
export type CountryProfileToLocaleConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the CountryProfile type and the CountryProfile type */
export type CountryProfileToPreviewConnectionEdge = CountryProfileConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: "CountryProfileToPreviewConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: CountryProfile;
  };

/** Connection between the CountryProfile type and the Region type */
export type CountryProfileToRegionConnection = Connection &
  RegionConnection & {
    __typename?: "CountryProfileToRegionConnection";
    /** Edges for the CountryProfileToRegionConnection connection */
    edges: Array<CountryProfileToRegionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Region>;
    /** Information about pagination in a connection. */
    pageInfo: CountryProfileToRegionConnectionPageInfo;
  };

/** An edge in a connection */
export type CountryProfileToRegionConnectionEdge = Edge &
  RegionConnectionEdge & {
    __typename?: "CountryProfileToRegionConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Region;
  };

/** Page Info on the &quot;CountryProfileToRegionConnection&quot; */
export type CountryProfileToRegionConnectionPageInfo = PageInfo &
  RegionConnectionPageInfo &
  WpPageInfo & {
    __typename?: "CountryProfileToRegionConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the CountryProfileToRegionConnection connection */
export type CountryProfileToRegionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the CountryProfile type and the tag type */
export type CountryProfileToTagConnection = Connection &
  TagConnection & {
    __typename?: "CountryProfileToTagConnection";
    /** Edges for the CountryProfileToTagConnection connection */
    edges: Array<CountryProfileToTagConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Tag>;
    /** Information about pagination in a connection. */
    pageInfo: CountryProfileToTagConnectionPageInfo;
  };

/** An edge in a connection */
export type CountryProfileToTagConnectionEdge = Edge &
  TagConnectionEdge & {
    __typename?: "CountryProfileToTagConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Tag;
  };

/** Page Info on the &quot;CountryProfileToTagConnection&quot; */
export type CountryProfileToTagConnectionPageInfo = PageInfo &
  TagConnectionPageInfo &
  WpPageInfo & {
    __typename?: "CountryProfileToTagConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the CountryProfileToTagConnection connection */
export type CountryProfileToTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the CountryProfile type and the TermNode type */
export type CountryProfileToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: "CountryProfileToTermNodeConnection";
    /** Edges for the CountryProfileToTermNodeConnection connection */
    edges: Array<CountryProfileToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: CountryProfileToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type CountryProfileToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: "CountryProfileToTermNodeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;CountryProfileToTermNodeConnection&quot; */
export type CountryProfileToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: "CountryProfileToTermNodeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the CountryProfileToTermNodeConnection connection */
export type CountryProfileToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Field Group */
export type CountryProfile_Country = AcfFieldGroup & {
  __typename?: "CountryProfile_Country";
  components?: Maybe<CountryProfile_Country_Components>;
  /** Choose a colour that compliments the hero image nicely! This will be used in small places throughout the country page and also in it&#039;s thumbnail when shown on the home page, regions pages or elsewhere around the site. */
  countrycolour?: Maybe<Scalars["String"]>;
  countryheroimage?: Maybe<MediaItem>;
  /** If a video exists, it will be used instead of the hero image. If it doesn&#039;t exist then the hero image will be used. */
  countryherovideo?: Maybe<Scalars["String"]>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type CountryProfile_Country_Components = AcfFieldGroup & {
  __typename?: "CountryProfile_Country_Components";
  articlesArticles?: Maybe<Array<Maybe<CountryProfile_Country_Components_ArticlesArticles>>>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  imagepulloutCta?: Maybe<AcfLink>;
  imagepulloutDescription?: Maybe<Scalars["String"]>;
  imagepulloutImage?: Maybe<MediaItem>;
  imagepulloutTitle?: Maybe<Scalars["String"]>;
  introgroup?: Maybe<CountryProfile_Country_Components_Introgroup>;
  /** Keep this one fairly short too. Think two or three sentances expanding on the heading above (if there is one). Eg, &quot;We’re here in Iceland ready to plan your trip with you, just the way you like it.&quot; */
  partnernoticeContent?: Maybe<Scalars["String"]>;
  /** Keep it short - a few words. Eg, &quot;We&#039;ve put together some ideas for you&quot; */
  partnernoticeHeading?: Maybe<Scalars["String"]>;
  /** Add some smiley LTO photos - preferably 3 (which is also the maximum). */
  partnernoticeImages?: Maybe<Array<Maybe<CountryProfile_Country_Components_PartnernoticeImages>>>;
  /** This displays (or not) the stamp graphic in the bottom corner that says &quot;Global travel, local experts&quot;. It&#039;s purely visual. Probably don&#039;t use it too often. */
  partnernoticeStamp?: Maybe<Scalars["Boolean"]>;
  reviewslistReviews?: Maybe<Array<Maybe<CountryProfile_Country_Components_ReviewslistReviews>>>;
  reviewslistTitle?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type CountryProfile_Country_Components_Introgroup = AcfFieldGroup & {
  __typename?: "CountryProfile_Country_Components_Introgroup";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  intro?: Maybe<Scalars["String"]>;
  subheading?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type CountryProfile_Country_Components_ArticlesArticles = AcfFieldGroup & {
  __typename?: "CountryProfile_Country_Components_articlesArticles";
  article?: Maybe<CountryProfile_Country_Components_ArticlesArticles_Article>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type CountryProfile_Country_Components_ArticlesArticles_Article = Article;

/** Field Group */
export type CountryProfile_Country_Components_PartnernoticeImages = AcfFieldGroup & {
  __typename?: "CountryProfile_Country_Components_partnernoticeImages";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  ltoImage?: Maybe<MediaItem>;
};

/** Field Group */
export type CountryProfile_Country_Components_ReviewslistReviews = AcfFieldGroup & {
  __typename?: "CountryProfile_Country_Components_reviewslistReviews";
  /** The customer name and where they travelled to. Usually written as: Sim, travelled to Bristol */
  author?: Maybe<Scalars["String"]>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** Customer quote. Keep it short. Don&#039;t include the speech marks. There is a character limit of 325. */
  quote?: Maybe<Scalars["String"]>;
  /** How many stars is the review? Can handle 0.5 increments */
  rating?: Maybe<Scalars["Float"]>;
  title?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type CountryProfile_Meta = AcfFieldGroup & {
  __typename?: "CountryProfile_Meta";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** Override for the page title, if empty the actual title will be used. */
  pageTitle?: Maybe<Scalars["String"]>;
  /** Description of content shown to search engines, if empty the search engine will extract its own description from the page. */
  searchDescription?: Maybe<Scalars["String"]>;
  /** Image shown to search engines and linkbots, if empty then a hero image or a generic image will be used. */
  searchImage?: Maybe<MediaItem>;
  /** Override for the title shown to search engines, if empty the page title or the actual title will be used. */
  searchTitle?: Maybe<Scalars["String"]>;
  /**
   * A representation of this page in the URL Use only lowercase letters, numbers, and hyphens.
   *
   * Don&#039;t add slash ( / ) to the beginning of the url fragment.
   */
  urlFragment?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type CountryProfile_Requestform = AcfFieldGroup & {
  __typename?: "CountryProfile_Requestform";
  /**
   * This image is used for the initial intent question and also on the last step of the request form. On different screen sizes it&#039;ll crop into different ratios so keep it non-subject specific; a landscape usually works well.
   *
   * NOTE: This is different to the main country hero image so should be of a different subject. It&#039;s an opportunity to display another side of the country.
   */
  countryRequestFormImage?: Maybe<MediaItem>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
};

/** Connection between the Country type and the Country type */
export type CountryToAncestorsCountryConnection = Connection &
  CountryConnection & {
    __typename?: "CountryToAncestorsCountryConnection";
    /** Edges for the CountryToAncestorsCountryConnection connection */
    edges: Array<CountryToAncestorsCountryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Country>;
    /** Information about pagination in a connection. */
    pageInfo: CountryToAncestorsCountryConnectionPageInfo;
  };

/** An edge in a connection */
export type CountryToAncestorsCountryConnectionEdge = CountryConnectionEdge &
  Edge & {
    __typename?: "CountryToAncestorsCountryConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Country;
  };

/** Page Info on the &quot;CountryToAncestorsCountryConnection&quot; */
export type CountryToAncestorsCountryConnectionPageInfo = CountryConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "CountryToAncestorsCountryConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Connection between the Country type and the ContentNode type */
export type CountryToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: "CountryToContentNodeConnection";
    /** Edges for the CountryToContentNodeConnection connection */
    edges: Array<CountryToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: CountryToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type CountryToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: "CountryToContentNodeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;CountryToContentNodeConnection&quot; */
export type CountryToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "CountryToContentNodeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the CountryToContentNodeConnection connection */
export type CountryToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfCountryEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Country type and the Country type */
export type CountryToCountryConnection = Connection &
  CountryConnection & {
    __typename?: "CountryToCountryConnection";
    /** Edges for the CountryToCountryConnection connection */
    edges: Array<CountryToCountryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Country>;
    /** Information about pagination in a connection. */
    pageInfo: CountryToCountryConnectionPageInfo;
  };

/** An edge in a connection */
export type CountryToCountryConnectionEdge = CountryConnectionEdge &
  Edge & {
    __typename?: "CountryToCountryConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Country;
  };

/** Page Info on the &quot;CountryToCountryConnection&quot; */
export type CountryToCountryConnectionPageInfo = CountryConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "CountryToCountryConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the CountryToCountryConnection connection */
export type CountryToCountryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the Country type and the CountryProfile type */
export type CountryToCountryProfileConnection = Connection &
  CountryProfileConnection & {
    __typename?: "CountryToCountryProfileConnection";
    /** Edges for the CountryToCountryProfileConnection connection */
    edges: Array<CountryToCountryProfileConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<CountryProfile>;
    /** Information about pagination in a connection. */
    pageInfo: CountryToCountryProfileConnectionPageInfo;
  };

/** An edge in a connection */
export type CountryToCountryProfileConnectionEdge = CountryProfileConnectionEdge &
  Edge & {
    __typename?: "CountryToCountryProfileConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: CountryProfile;
  };

/** Page Info on the &quot;CountryToCountryProfileConnection&quot; */
export type CountryToCountryProfileConnectionPageInfo = CountryProfileConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "CountryToCountryProfileConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the CountryToCountryProfileConnection connection */
export type CountryToCountryProfileConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Country type and the Country type */
export type CountryToParentCountryConnectionEdge = CountryConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: "CountryToParentCountryConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: Country;
  };

/** Connection between the Country type and the Taxonomy type */
export type CountryToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: "CountryToTaxonomyConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** Field Group */
export type Country_Content = AcfFieldGroup & {
  __typename?: "Country_Content";
  /** Choose a colour that compliments the hero image nicely! This will be used in small places throughout the country page and also in it&#039;s thumbnail when shown on the home page, regions pages or elsewhere around the site. */
  countryColour?: Maybe<Scalars["String"]>;
  countryHeroImage?: Maybe<MediaItem>;
  /** If a video exists, it will be used instead of the hero image. If it doesn&#039;t exist then the hero image will be used. */
  countryHeroVideo?: Maybe<Scalars["String"]>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type Country_Requestform = AcfFieldGroup & {
  __typename?: "Country_Requestform";
  /**
   * This image is used for the initial intent question and also on the last step of the request form. On different screen sizes it&#039;ll crop into different ratios so keep it non-subject specific; a landscape usually works well.
   *
   * NOTE: This is different to the main country hero image so should be of a different subject. It&#039;s an opportunity to display another side of the country.
   */
  countryRequestFormImage?: Maybe<MediaItem>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
};

/** Input for the createArticle mutation. */
export type CreateArticleInput = {
  /** Set connections between the Article and categories */
  categories?: InputMaybe<ArticleCategoriesInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** Set connections between the Article and ContentLanguages */
  contentLanguages?: InputMaybe<ArticleContentLanguagesInput>;
  /** Set connections between the Article and ContentStatuses */
  contentStatuses?: InputMaybe<ArticleContentStatusesInput>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<LanguageCodeEnum>;
  /** Set connections between the Article and Locales */
  locales?: InputMaybe<ArticleLocalesInput>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the Article and tags */
  tags?: InputMaybe<ArticleTagsInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** The payload for the createArticle mutation. */
export type CreateArticlePayload = {
  __typename?: "CreateArticlePayload";
  /** The Post object mutation type. */
  article?: Maybe<Article>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** Input for the createCategory mutation. */
export type CreateCategoryInput = {
  /** The slug that the category will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the category object */
  description?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<LanguageCodeEnum>;
  /** The name of the category object to mutate */
  name: Scalars["String"];
  /** The ID of the category that should be set as the parent */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The payload for the createCategory mutation. */
export type CreateCategoryPayload = {
  __typename?: "CreateCategoryPayload";
  /** The created category */
  category?: Maybe<Category>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** Input for the createCollection mutation. */
export type CreateCollectionInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Set connections between the Collection and ContentLanguages */
  contentLanguages?: InputMaybe<CollectionContentLanguagesInput>;
  /** Set connections between the Collection and ContentStatuses */
  contentStatuses?: InputMaybe<CollectionContentStatusesInput>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<LanguageCodeEnum>;
  /** Set connections between the Collection and Locales */
  locales?: InputMaybe<CollectionLocalesInput>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the Collection and tags */
  tags?: InputMaybe<CollectionTagsInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** The payload for the createCollection mutation. */
export type CreateCollectionPayload = {
  __typename?: "CreateCollectionPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The Post object mutation type. */
  collection?: Maybe<Collection>;
};

/** Input for the createComment mutation. */
export type CreateCommentInput = {
  /** The approval status of the comment. */
  approved?: InputMaybe<Scalars["String"]>;
  /** The name of the comment's author. */
  author?: InputMaybe<Scalars["String"]>;
  /** The email of the comment's author. */
  authorEmail?: InputMaybe<Scalars["String"]>;
  /** The url of the comment's author. */
  authorUrl?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The database ID of the post object the comment belongs to. */
  commentOn?: InputMaybe<Scalars["Int"]>;
  /** Content of the comment. */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day ( e.g. 01/31/2017 ) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** Parent comment ID of current comment. */
  parent?: InputMaybe<Scalars["ID"]>;
  /** The approval status of the comment */
  status?: InputMaybe<CommentStatusEnum>;
  /** Type of comment. */
  type?: InputMaybe<Scalars["String"]>;
};

/** The payload for the createComment mutation. */
export type CreateCommentPayload = {
  __typename?: "CreateCommentPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The comment that was created */
  comment?: Maybe<Comment>;
  /** Whether the mutation succeeded. If the comment is not approved, the server will not return the comment to a non authenticated user, but a success message can be returned if the create succeeded, and the client can optimistically add the comment to the client cache */
  success?: Maybe<Scalars["Boolean"]>;
};

/** Input for the createContentLanguages mutation. */
export type CreateContentLanguagesInput = {
  /** The slug that the content_language will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the content_language object */
  description?: InputMaybe<Scalars["String"]>;
  /** The name of the content_language object to mutate */
  name: Scalars["String"];
  /** The ID of the content_language that should be set as the parent */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The payload for the createContentLanguages mutation. */
export type CreateContentLanguagesPayload = {
  __typename?: "CreateContentLanguagesPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The created content_language */
  contentLanguages?: Maybe<ContentLanguages>;
};

/** Input for the createContentStatus mutation. */
export type CreateContentStatusInput = {
  /** The slug that the content_status will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the content_status object */
  description?: InputMaybe<Scalars["String"]>;
  /** The name of the content_status object to mutate */
  name: Scalars["String"];
  /** The ID of the content_status that should be set as the parent */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The payload for the createContentStatus mutation. */
export type CreateContentStatusPayload = {
  __typename?: "CreateContentStatusPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The created content_status */
  contentStatus?: Maybe<ContentStatus>;
};

/** Input for the createCountry mutation. */
export type CreateCountryInput = {
  /** The slug that the country will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the country object */
  description?: InputMaybe<Scalars["String"]>;
  /** The name of the country object to mutate */
  name: Scalars["String"];
  /** The ID of the country that should be set as the parent */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The payload for the createCountry mutation. */
export type CreateCountryPayload = {
  __typename?: "CreateCountryPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The created country */
  country?: Maybe<Country>;
};

/** Input for the createCountryProfile mutation. */
export type CreateCountryProfileInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Set connections between the CountryProfile and ContentLanguages */
  contentLanguages?: InputMaybe<CountryProfileContentLanguagesInput>;
  /** Set connections between the CountryProfile and ContentStatuses */
  contentStatuses?: InputMaybe<CountryProfileContentStatusesInput>;
  /** Set connections between the CountryProfile and Countries */
  countries?: InputMaybe<CountryProfileCountriesInput>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** Set connections between the CountryProfile and Locales */
  locales?: InputMaybe<CountryProfileLocalesInput>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** Set connections between the CountryProfile and Regions */
  regions?: InputMaybe<CountryProfileRegionsInput>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the CountryProfile and tags */
  tags?: InputMaybe<CountryProfileTagsInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** The payload for the createCountryProfile mutation. */
export type CreateCountryProfilePayload = {
  __typename?: "CreateCountryProfilePayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The Post object mutation type. */
  countryProfile?: Maybe<CountryProfile>;
};

/** Input for the createGlobalContent mutation. */
export type CreateGlobalContentInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** The payload for the createGlobalContent mutation. */
export type CreateGlobalContentPayload = {
  __typename?: "CreateGlobalContentPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The Post object mutation type. */
  globalContent?: Maybe<GlobalContent>;
};

/** Input for the createHomePage mutation. */
export type CreateHomePageInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Set connections between the HomePage and ContentLanguages */
  contentLanguages?: InputMaybe<HomePageContentLanguagesInput>;
  /** Set connections between the HomePage and ContentStatuses */
  contentStatuses?: InputMaybe<HomePageContentStatusesInput>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<LanguageCodeEnum>;
  /** Set connections between the HomePage and Locales */
  locales?: InputMaybe<HomePageLocalesInput>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** The payload for the createHomePage mutation. */
export type CreateHomePagePayload = {
  __typename?: "CreateHomePagePayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The Post object mutation type. */
  homePage?: Maybe<HomePage>;
};

/** Input for the createHowItWorksPage mutation. */
export type CreateHowItWorksPageInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Set connections between the HowItWorksPage and ContentLanguages */
  contentLanguages?: InputMaybe<HowItWorksPageContentLanguagesInput>;
  /** Set connections between the HowItWorksPage and ContentStatuses */
  contentStatuses?: InputMaybe<HowItWorksPageContentStatusesInput>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<LanguageCodeEnum>;
  /** Set connections between the HowItWorksPage and Locales */
  locales?: InputMaybe<HowItWorksPageLocalesInput>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** The payload for the createHowItWorksPage mutation. */
export type CreateHowItWorksPagePayload = {
  __typename?: "CreateHowItWorksPagePayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The Post object mutation type. */
  howItWorksPage?: Maybe<HowItWorksPage>;
};

/** Input for the createLocale mutation. */
export type CreateLocaleInput = {
  /** The slug that the locale will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the locale object */
  description?: InputMaybe<Scalars["String"]>;
  /** The name of the locale object to mutate */
  name: Scalars["String"];
  /** The ID of the locale that should be set as the parent */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The payload for the createLocale mutation. */
export type CreateLocalePayload = {
  __typename?: "CreateLocalePayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The created locale */
  locale?: Maybe<Locale>;
};

/** Input for the createMediaItem mutation. */
export type CreateMediaItemInput = {
  /** Alternative text to display when mediaItem is not displayed */
  altText?: InputMaybe<Scalars["String"]>;
  /** The userId to assign as the author of the mediaItem */
  authorId?: InputMaybe<Scalars["ID"]>;
  /** The caption for the mediaItem */
  caption?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The comment status for the mediaItem */
  commentStatus?: InputMaybe<Scalars["String"]>;
  /** The date of the mediaItem */
  date?: InputMaybe<Scalars["String"]>;
  /** The date (in GMT zone) of the mediaItem */
  dateGmt?: InputMaybe<Scalars["String"]>;
  /** Description of the mediaItem */
  description?: InputMaybe<Scalars["String"]>;
  /** The file name of the mediaItem */
  filePath?: InputMaybe<Scalars["String"]>;
  /** The file type of the mediaItem */
  fileType?: InputMaybe<MimeTypeEnum>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** The ping status for the mediaItem */
  pingStatus?: InputMaybe<Scalars["String"]>;
  /** The slug of the mediaItem */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the mediaItem */
  status?: InputMaybe<MediaItemStatusEnum>;
  /** The title of the mediaItem */
  title?: InputMaybe<Scalars["String"]>;
};

/** The payload for the createMediaItem mutation. */
export type CreateMediaItemPayload = {
  __typename?: "CreateMediaItemPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The MediaItem object mutation type. */
  mediaItem?: Maybe<MediaItem>;
};

/** Input for the createPage mutation. */
export type CreatePageInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars["ID"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The comment status for the object */
  commentStatus?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<LanguageCodeEnum>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** The payload for the createPage mutation. */
export type CreatePagePayload = {
  __typename?: "CreatePagePayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The Post object mutation type. */
  page?: Maybe<Page>;
};

/** Input for the createPostFormat mutation. */
export type CreatePostFormatInput = {
  /** The slug that the post_format will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the post_format object */
  description?: InputMaybe<Scalars["String"]>;
  /** The name of the post_format object to mutate */
  name: Scalars["String"];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The payload for the createPostFormat mutation. */
export type CreatePostFormatPayload = {
  __typename?: "CreatePostFormatPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The created post_format */
  postFormat?: Maybe<PostFormat>;
};

/** Input for the createPost mutation. */
export type CreatePostInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars["ID"]>;
  /** Set connections between the post and categories */
  categories?: InputMaybe<PostCategoriesInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The comment status for the object */
  commentStatus?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The excerpt of the object */
  excerpt?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<LanguageCodeEnum>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The ping status for the object */
  pingStatus?: InputMaybe<Scalars["String"]>;
  /** URLs that have been pinged. */
  pinged?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Set connections between the post and postFormats */
  postFormats?: InputMaybe<PostPostFormatsInput>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the post and tags */
  tags?: InputMaybe<PostTagsInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
  /** URLs queued to be pinged. */
  toPing?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The payload for the createPost mutation. */
export type CreatePostPayload = {
  __typename?: "CreatePostPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The Post object mutation type. */
  post?: Maybe<Post>;
};

/** Input for the createRegion mutation. */
export type CreateRegionInput = {
  /** The slug that the region will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the region object */
  description?: InputMaybe<Scalars["String"]>;
  /** The name of the region object to mutate */
  name: Scalars["String"];
  /** The ID of the region that should be set as the parent */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The payload for the createRegion mutation. */
export type CreateRegionPayload = {
  __typename?: "CreateRegionPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The created region */
  region?: Maybe<Region>;
};

/** Input for the createRegionProfile mutation. */
export type CreateRegionProfileInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Set connections between the RegionProfile and ContentLanguages */
  contentLanguages?: InputMaybe<RegionProfileContentLanguagesInput>;
  /** Set connections between the RegionProfile and ContentStatuses */
  contentStatuses?: InputMaybe<RegionProfileContentStatusesInput>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** Set connections between the RegionProfile and Locales */
  locales?: InputMaybe<RegionProfileLocalesInput>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** Set connections between the RegionProfile and Regions */
  regions?: InputMaybe<RegionProfileRegionsInput>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** The payload for the createRegionProfile mutation. */
export type CreateRegionProfilePayload = {
  __typename?: "CreateRegionProfilePayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The Post object mutation type. */
  regionProfile?: Maybe<RegionProfile>;
};

/** Input for the createTag mutation. */
export type CreateTagInput = {
  /** The slug that the post_tag will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the post_tag object */
  description?: InputMaybe<Scalars["String"]>;
  language?: InputMaybe<LanguageCodeEnum>;
  /** The name of the post_tag object to mutate */
  name: Scalars["String"];
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The payload for the createTag mutation. */
export type CreateTagPayload = {
  __typename?: "CreateTagPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The created post_tag */
  tag?: Maybe<Tag>;
};

/** Input for the createUser mutation. */
export type CreateUserInput = {
  /** User's AOL IM account. */
  aim?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A string containing content about the user. */
  description?: InputMaybe<Scalars["String"]>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: InputMaybe<Scalars["String"]>;
  /** A string containing the user's email address. */
  email?: InputMaybe<Scalars["String"]>;
  /** 	The user's first name. */
  firstName?: InputMaybe<Scalars["String"]>;
  /** User's Jabber account. */
  jabber?: InputMaybe<Scalars["String"]>;
  /** The user's last name. */
  lastName?: InputMaybe<Scalars["String"]>;
  /** User's locale. */
  locale?: InputMaybe<Scalars["String"]>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: InputMaybe<Scalars["String"]>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: InputMaybe<Scalars["String"]>;
  /** A string that contains the plain text password for the user. */
  password?: InputMaybe<Scalars["String"]>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: InputMaybe<Scalars["String"]>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: InputMaybe<Scalars["String"]>;
  /** An array of roles to be assigned to the user. */
  roles?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** A string that contains the user's username for logging in. */
  username: Scalars["String"];
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: InputMaybe<Scalars["String"]>;
  /** User's Yahoo IM account. */
  yim?: InputMaybe<Scalars["String"]>;
};

/** The payload for the createUser mutation. */
export type CreateUserPayload = {
  __typename?: "CreateUserPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The User object mutation type. */
  user?: Maybe<User>;
};

/** Object that can be identified with a Database ID */
export type DatabaseIdentifier = {
  /** The unique identifier stored in the database */
  databaseId: Scalars["Int"];
};

/** Date values */
export type DateInput = {
  /** Day of the month (from 1 to 31) */
  day?: InputMaybe<Scalars["Int"]>;
  /** Month number (from 1 to 12) */
  month?: InputMaybe<Scalars["Int"]>;
  /** 4 digit year (e.g. 2017) */
  year?: InputMaybe<Scalars["Int"]>;
};

/** Filter the connection based on input */
export type DateQueryInput = {
  /** Nodes should be returned after this date */
  after?: InputMaybe<DateInput>;
  /** Nodes should be returned before this date */
  before?: InputMaybe<DateInput>;
  /** Column to query against */
  column?: InputMaybe<PostObjectsConnectionDateColumnEnum>;
  /** For after/before, whether exact value should be matched or not */
  compare?: InputMaybe<Scalars["String"]>;
  /** Day of the month (from 1 to 31) */
  day?: InputMaybe<Scalars["Int"]>;
  /** Hour (from 0 to 23) */
  hour?: InputMaybe<Scalars["Int"]>;
  /** For after/before, whether exact value should be matched or not */
  inclusive?: InputMaybe<Scalars["Boolean"]>;
  /** Minute (from 0 to 59) */
  minute?: InputMaybe<Scalars["Int"]>;
  /** Month number (from 1 to 12) */
  month?: InputMaybe<Scalars["Int"]>;
  /** OR or AND, how the sub-arrays should be compared */
  relation?: InputMaybe<RelationEnum>;
  /** Second (0 to 59) */
  second?: InputMaybe<Scalars["Int"]>;
  /** Week of the year (from 0 to 53) */
  week?: InputMaybe<Scalars["Int"]>;
  /** 4 digit year (e.g. 2017) */
  year?: InputMaybe<Scalars["Int"]>;
};

/** The template assigned to the node */
export type DefaultTemplate = ContentTemplate & {
  __typename?: "DefaultTemplate";
  /** The name of the template */
  templateName?: Maybe<Scalars["String"]>;
};

/** Input for the deleteArticle mutation. */
export type DeleteArticleInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The ID of the Article to delete */
  id: Scalars["ID"];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars["Boolean"]>;
};

/** The payload for the deleteArticle mutation. */
export type DeleteArticlePayload = {
  __typename?: "DeleteArticlePayload";
  /** The object before it was deleted */
  article?: Maybe<Article>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars["ID"]>;
};

/** Input for the deleteCategory mutation. */
export type DeleteCategoryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The ID of the category to delete */
  id: Scalars["ID"];
};

/** The payload for the deleteCategory mutation. */
export type DeleteCategoryPayload = {
  __typename?: "DeleteCategoryPayload";
  /** The deleted term object */
  category?: Maybe<Category>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars["ID"]>;
};

/** Input for the deleteCollection mutation. */
export type DeleteCollectionInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The ID of the Collection to delete */
  id: Scalars["ID"];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars["Boolean"]>;
};

/** The payload for the deleteCollection mutation. */
export type DeleteCollectionPayload = {
  __typename?: "DeleteCollectionPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The object before it was deleted */
  collection?: Maybe<Collection>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars["ID"]>;
};

/** Input for the deleteComment mutation. */
export type DeleteCommentInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the comment should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The deleted comment ID */
  id: Scalars["ID"];
};

/** The payload for the deleteComment mutation. */
export type DeleteCommentPayload = {
  __typename?: "DeleteCommentPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The deleted comment object */
  comment?: Maybe<Comment>;
  /** The deleted comment ID */
  deletedId?: Maybe<Scalars["ID"]>;
};

/** Input for the deleteContentLanguages mutation. */
export type DeleteContentLanguagesInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The ID of the ContentLanguages to delete */
  id: Scalars["ID"];
};

/** The payload for the deleteContentLanguages mutation. */
export type DeleteContentLanguagesPayload = {
  __typename?: "DeleteContentLanguagesPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The deleted term object */
  contentLanguages?: Maybe<ContentLanguages>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars["ID"]>;
};

/** Input for the deleteContentStatus mutation. */
export type DeleteContentStatusInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The ID of the ContentStatus to delete */
  id: Scalars["ID"];
};

/** The payload for the deleteContentStatus mutation. */
export type DeleteContentStatusPayload = {
  __typename?: "DeleteContentStatusPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The deleted term object */
  contentStatus?: Maybe<ContentStatus>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars["ID"]>;
};

/** Input for the deleteCountry mutation. */
export type DeleteCountryInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The ID of the Country to delete */
  id: Scalars["ID"];
};

/** The payload for the deleteCountry mutation. */
export type DeleteCountryPayload = {
  __typename?: "DeleteCountryPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The deleted term object */
  country?: Maybe<Country>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars["ID"]>;
};

/** Input for the deleteCountryProfile mutation. */
export type DeleteCountryProfileInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The ID of the CountryProfile to delete */
  id: Scalars["ID"];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars["Boolean"]>;
};

/** The payload for the deleteCountryProfile mutation. */
export type DeleteCountryProfilePayload = {
  __typename?: "DeleteCountryProfilePayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The object before it was deleted */
  countryProfile?: Maybe<CountryProfile>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars["ID"]>;
};

/** Input for the deleteGlobalContent mutation. */
export type DeleteGlobalContentInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The ID of the GlobalContent to delete */
  id: Scalars["ID"];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars["Boolean"]>;
};

/** The payload for the deleteGlobalContent mutation. */
export type DeleteGlobalContentPayload = {
  __typename?: "DeleteGlobalContentPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars["ID"]>;
  /** The object before it was deleted */
  globalContent?: Maybe<GlobalContent>;
};

/** Input for the deleteHomePage mutation. */
export type DeleteHomePageInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The ID of the HomePage to delete */
  id: Scalars["ID"];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars["Boolean"]>;
};

/** The payload for the deleteHomePage mutation. */
export type DeleteHomePagePayload = {
  __typename?: "DeleteHomePagePayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars["ID"]>;
  /** The object before it was deleted */
  homePage?: Maybe<HomePage>;
};

/** Input for the deleteHowItWorksPage mutation. */
export type DeleteHowItWorksPageInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The ID of the HowItWorksPage to delete */
  id: Scalars["ID"];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars["Boolean"]>;
};

/** The payload for the deleteHowItWorksPage mutation. */
export type DeleteHowItWorksPagePayload = {
  __typename?: "DeleteHowItWorksPagePayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars["ID"]>;
  /** The object before it was deleted */
  howItWorksPage?: Maybe<HowItWorksPage>;
};

/** Input for the deleteLocale mutation. */
export type DeleteLocaleInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The ID of the Locale to delete */
  id: Scalars["ID"];
};

/** The payload for the deleteLocale mutation. */
export type DeleteLocalePayload = {
  __typename?: "DeleteLocalePayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars["ID"]>;
  /** The deleted term object */
  locale?: Maybe<Locale>;
};

/** Input for the deleteMediaItem mutation. */
export type DeleteMediaItemInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the mediaItem should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The ID of the mediaItem to delete */
  id: Scalars["ID"];
};

/** The payload for the deleteMediaItem mutation. */
export type DeleteMediaItemPayload = {
  __typename?: "DeleteMediaItemPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The ID of the deleted mediaItem */
  deletedId?: Maybe<Scalars["ID"]>;
  /** The mediaItem before it was deleted */
  mediaItem?: Maybe<MediaItem>;
};

/** Input for the deletePage mutation. */
export type DeletePageInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The ID of the page to delete */
  id: Scalars["ID"];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars["Boolean"]>;
};

/** The payload for the deletePage mutation. */
export type DeletePagePayload = {
  __typename?: "DeletePagePayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars["ID"]>;
  /** The object before it was deleted */
  page?: Maybe<Page>;
};

/** Input for the deletePostFormat mutation. */
export type DeletePostFormatInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The ID of the postFormat to delete */
  id: Scalars["ID"];
};

/** The payload for the deletePostFormat mutation. */
export type DeletePostFormatPayload = {
  __typename?: "DeletePostFormatPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars["ID"]>;
  /** The deleted term object */
  postFormat?: Maybe<PostFormat>;
};

/** Input for the deletePost mutation. */
export type DeletePostInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The ID of the post to delete */
  id: Scalars["ID"];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars["Boolean"]>;
};

/** The payload for the deletePost mutation. */
export type DeletePostPayload = {
  __typename?: "DeletePostPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars["ID"]>;
  /** The object before it was deleted */
  post?: Maybe<Post>;
};

/** Input for the deleteRegion mutation. */
export type DeleteRegionInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The ID of the Region to delete */
  id: Scalars["ID"];
};

/** The payload for the deleteRegion mutation. */
export type DeleteRegionPayload = {
  __typename?: "DeleteRegionPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars["ID"]>;
  /** The deleted term object */
  region?: Maybe<Region>;
};

/** Input for the deleteRegionProfile mutation. */
export type DeleteRegionProfileInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: InputMaybe<Scalars["Boolean"]>;
  /** The ID of the RegionProfile to delete */
  id: Scalars["ID"];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars["Boolean"]>;
};

/** The payload for the deleteRegionProfile mutation. */
export type DeleteRegionProfilePayload = {
  __typename?: "DeleteRegionProfilePayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars["ID"]>;
  /** The object before it was deleted */
  regionProfile?: Maybe<RegionProfile>;
};

/** Input for the deleteTag mutation. */
export type DeleteTagInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The ID of the tag to delete */
  id: Scalars["ID"];
};

/** The payload for the deleteTag mutation. */
export type DeleteTagPayload = {
  __typename?: "DeleteTagPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The ID of the deleted object */
  deletedId?: Maybe<Scalars["ID"]>;
  /** The deleted term object */
  tag?: Maybe<Tag>;
};

/** Input for the deleteUser mutation. */
export type DeleteUserInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The ID of the user you want to delete */
  id: Scalars["ID"];
  /** Reassign posts and links to new User ID. */
  reassignId?: InputMaybe<Scalars["ID"]>;
};

/** The payload for the deleteUser mutation. */
export type DeleteUserPayload = {
  __typename?: "DeleteUserPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The ID of the user that you just deleted */
  deletedId?: Maybe<Scalars["ID"]>;
  /** The deleted user object */
  user?: Maybe<User>;
};

/** The discussion setting type */
export type DiscussionSettings = {
  __typename?: "DiscussionSettings";
  /** Allow people to submit comments on new posts. */
  defaultCommentStatus?: Maybe<Scalars["String"]>;
  /** Allow link notifications from other blogs (pingbacks and trackbacks) on new articles. */
  defaultPingStatus?: Maybe<Scalars["String"]>;
};

/** Relational context between connected nodes */
export type Edge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected node */
  node: Node;
};

/** Asset enqueued by the CMS */
export type EnqueuedAsset = {
  /** The inline code to be run after the asset is loaded. */
  after?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /**
   * Deprecated
   * @deprecated Use `EnqueuedAsset.media` instead.
   */
  args?: Maybe<Scalars["Boolean"]>;
  /** The inline code to be run before the asset is loaded. */
  before?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** The HTML conditional comment for the enqueued asset. E.g. IE 6, lte IE 7, etc */
  conditional?: Maybe<Scalars["String"]>;
  /** Dependencies needed to use this asset */
  dependencies?: Maybe<Array<Maybe<EnqueuedAsset>>>;
  /**
   * Extra information needed for the script
   * @deprecated Use `EnqueuedScript.extraData` instead.
   */
  extra?: Maybe<Scalars["String"]>;
  /** The handle of the enqueued asset */
  handle?: Maybe<Scalars["String"]>;
  /** The ID of the enqueued asset */
  id: Scalars["ID"];
  /** The source of the asset */
  src?: Maybe<Scalars["String"]>;
  /** The version of the enqueued asset */
  version?: Maybe<Scalars["String"]>;
};

/** Script enqueued by the CMS */
export type EnqueuedScript = EnqueuedAsset &
  Node & {
    __typename?: "EnqueuedScript";
    /** The inline code to be run after the asset is loaded. */
    after?: Maybe<Array<Maybe<Scalars["String"]>>>;
    /**
     * Deprecated
     * @deprecated Use `EnqueuedAsset.media` instead.
     */
    args?: Maybe<Scalars["Boolean"]>;
    /** The inline code to be run before the asset is loaded. */
    before?: Maybe<Array<Maybe<Scalars["String"]>>>;
    /** The HTML conditional comment for the enqueued asset. E.g. IE 6, lte IE 7, etc */
    conditional?: Maybe<Scalars["String"]>;
    /** Dependencies needed to use this asset */
    dependencies?: Maybe<Array<Maybe<EnqueuedScript>>>;
    /**
     * Extra information needed for the script
     * @deprecated Use `EnqueuedScript.extraData` instead.
     */
    extra?: Maybe<Scalars["String"]>;
    /** Extra data supplied to the enqueued script */
    extraData?: Maybe<Scalars["String"]>;
    /** The handle of the enqueued asset */
    handle?: Maybe<Scalars["String"]>;
    /** The global ID of the enqueued script */
    id: Scalars["ID"];
    /** The source of the asset */
    src?: Maybe<Scalars["String"]>;
    /** The loading strategy to use on the script tag */
    strategy?: Maybe<ScriptLoadingStrategyEnum>;
    /** The version of the enqueued script */
    version?: Maybe<Scalars["String"]>;
  };

/** Connection to EnqueuedScript Nodes */
export type EnqueuedScriptConnection = {
  /** A list of edges (relational context) between ContentNode and connected EnqueuedScript Nodes */
  edges: Array<EnqueuedScriptConnectionEdge>;
  /** A list of connected EnqueuedScript Nodes */
  nodes: Array<EnqueuedScript>;
  /** Information about pagination in a connection. */
  pageInfo: EnqueuedScriptConnectionPageInfo;
};

/** Edge between a Node and a connected EnqueuedScript */
export type EnqueuedScriptConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected EnqueuedScript Node */
  node: EnqueuedScript;
};

/** Page Info on the connected EnqueuedScriptConnectionEdge */
export type EnqueuedScriptConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** Stylesheet enqueued by the CMS */
export type EnqueuedStylesheet = EnqueuedAsset &
  Node & {
    __typename?: "EnqueuedStylesheet";
    /** The inline code to be run after the asset is loaded. */
    after?: Maybe<Array<Maybe<Scalars["String"]>>>;
    /**
     * Deprecated
     * @deprecated Use `EnqueuedAsset.media` instead.
     */
    args?: Maybe<Scalars["Boolean"]>;
    /** The inline code to be run before the asset is loaded. */
    before?: Maybe<Array<Maybe<Scalars["String"]>>>;
    /** The HTML conditional comment for the enqueued asset. E.g. IE 6, lte IE 7, etc */
    conditional?: Maybe<Scalars["String"]>;
    /** Dependencies needed to use this asset */
    dependencies?: Maybe<Array<Maybe<EnqueuedStylesheet>>>;
    /**
     * Extra information needed for the script
     * @deprecated Use `EnqueuedScript.extraData` instead.
     */
    extra?: Maybe<Scalars["String"]>;
    /** The handle of the enqueued asset */
    handle?: Maybe<Scalars["String"]>;
    /** The global ID of the enqueued stylesheet */
    id: Scalars["ID"];
    /** Whether the enqueued style is RTL or not */
    isRtl?: Maybe<Scalars["Boolean"]>;
    /** The media attribute to use for the link */
    media?: Maybe<Scalars["String"]>;
    /** The absolute path to the enqueued style. Set when the stylesheet is meant to load inline. */
    path?: Maybe<Scalars["String"]>;
    /** The `rel` attribute to use for the link */
    rel?: Maybe<Scalars["String"]>;
    /** The source of the asset */
    src?: Maybe<Scalars["String"]>;
    /** Optional suffix, used in combination with RTL */
    suffix?: Maybe<Scalars["String"]>;
    /** The title of the enqueued style. Used for preferred/alternate stylesheets. */
    title?: Maybe<Scalars["String"]>;
    /** The version of the enqueued style */
    version?: Maybe<Scalars["String"]>;
  };

/** Connection to EnqueuedStylesheet Nodes */
export type EnqueuedStylesheetConnection = {
  /** A list of edges (relational context) between ContentNode and connected EnqueuedStylesheet Nodes */
  edges: Array<EnqueuedStylesheetConnectionEdge>;
  /** A list of connected EnqueuedStylesheet Nodes */
  nodes: Array<EnqueuedStylesheet>;
  /** Information about pagination in a connection. */
  pageInfo: EnqueuedStylesheetConnectionPageInfo;
};

/** Edge between a Node and a connected EnqueuedStylesheet */
export type EnqueuedStylesheetConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected EnqueuedStylesheet Node */
  node: EnqueuedStylesheet;
};

/** Page Info on the connected EnqueuedStylesheetConnectionEdge */
export type EnqueuedStylesheetConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** The general setting type */
export type GeneralSettings = {
  __typename?: "GeneralSettings";
  /** A date format for all date strings. */
  dateFormat?: Maybe<Scalars["String"]>;
  /** Site tagline. */
  description?: Maybe<Scalars["String"]>;
  /** This address is used for admin purposes, like new user notification. */
  email?: Maybe<Scalars["String"]>;
  /** WordPress locale code. */
  language?: Maybe<Scalars["String"]>;
  /** A day number of the week that the week should start on. */
  startOfWeek?: Maybe<Scalars["Int"]>;
  /** A time format for all time strings. */
  timeFormat?: Maybe<Scalars["String"]>;
  /** A city in the same timezone as you. */
  timezone?: Maybe<Scalars["String"]>;
  /** Site title. */
  title?: Maybe<Scalars["String"]>;
  /** Site URL. */
  url?: Maybe<Scalars["String"]>;
};

/** The GlobalContent type */
export type GlobalContent = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  HierarchicalNode &
  MenuItemLinkable &
  Node &
  NodeWithPageAttributes &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: "GlobalContent";
    /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
    /** Connection between the HierarchicalContentNode type and the ContentNode type */
    children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars["String"];
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** Post publishing date. */
    date?: Maybe<Scalars["String"]>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars["String"]>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars["String"]>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars["String"]>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Template | Global content&quot; was set to Show in GraphQL. */
    globalContent?: Maybe<GlobalContent_Globalcontent>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    globalContentId: Scalars["Int"];
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars["String"]>;
    /** The globally unique identifier of the global object. */
    id: Scalars["ID"];
    /** Whether the node is a Content Node */
    isContentNode: Scalars["Boolean"];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars["Boolean"]>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** Whether the node is a Term */
    isTermNode: Scalars["Boolean"];
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars["String"]>;
    /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
    menuOrder?: Maybe<Scalars["Int"]>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars["String"]>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars["String"]>;
    /** The parent of the node. The parent object can be of various types */
    parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars["Int"]>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars["ID"]>;
    /** Connection between the GlobalContent type and the GlobalContent type */
    preview?: Maybe<GlobalContentToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars["Int"]>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars["ID"]>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars["String"]>;
    /** The current status of the object */
    status?: Maybe<Scalars["String"]>;
    /** The template assigned to a node of content */
    template?: Maybe<ContentTemplate>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars["String"]>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars["String"]>;
  };

/** The GlobalContent type */
export type GlobalContentAncestorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

/** The GlobalContent type */
export type GlobalContentChildrenArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** The GlobalContent type */
export type GlobalContentEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The GlobalContent type */
export type GlobalContentEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The GlobalContent type */
export type GlobalContentTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to GlobalContent Nodes */
export type GlobalContentConnection = {
  /** A list of edges (relational context) between RootQuery and connected GlobalContent Nodes */
  edges: Array<GlobalContentConnectionEdge>;
  /** A list of connected GlobalContent Nodes */
  nodes: Array<GlobalContent>;
  /** Information about pagination in a connection. */
  pageInfo: GlobalContentConnectionPageInfo;
};

/** Edge between a Node and a connected GlobalContent */
export type GlobalContentConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected GlobalContent Node */
  node: GlobalContent;
};

/** Page Info on the connected GlobalContentConnectionEdge */
export type GlobalContentConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum GlobalContentIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  Id = "ID",
  /** Identify a resource by the URI. */
  Uri = "URI",
}

/** Connection between the GlobalContent type and the GlobalContent type */
export type GlobalContentToPreviewConnectionEdge = Edge &
  GlobalContentConnectionEdge &
  OneToOneConnection & {
    __typename?: "GlobalContentToPreviewConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: GlobalContent;
  };

/** Field Group */
export type GlobalContent_Globalcontent = AcfFieldGroup & {
  __typename?: "GlobalContent_Globalcontent";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** Add 1, 2 or 3 quiet links. These are short pieces of intro text with a link to somewhere on the site. */
  quietlinksLinks?: Maybe<Array<Maybe<GlobalContent_Globalcontent_QuietlinksLinks>>>;
  reviewslistReviews?: Maybe<Array<Maybe<GlobalContent_Globalcontent_ReviewslistReviews>>>;
  reviewslistTitle?: Maybe<Scalars["String"]>;
  socialmedia?: Maybe<Array<Maybe<GlobalContent_Globalcontent_Socialmedia>>>;
  subscribeComponent?: Maybe<GlobalContent_Globalcontent_SubscribeComponent>;
};

/** Field Group */
export type GlobalContent_Globalcontent_SubscribeComponent = AcfFieldGroup & {
  __typename?: "GlobalContent_Globalcontent_SubscribeComponent";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  subscribeBodyText?: Maybe<Scalars["String"]>;
  subscribeText?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type GlobalContent_Globalcontent_QuietlinksLinks = AcfFieldGroup & {
  __typename?: "GlobalContent_Globalcontent_quietlinksLinks";
  cta?: Maybe<AcfLink>;
  description?: Maybe<Scalars["String"]>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type GlobalContent_Globalcontent_ReviewslistReviews = AcfFieldGroup & {
  __typename?: "GlobalContent_Globalcontent_reviewslistReviews";
  /** The customer name and where they travelled to. Usually written as: Sim, travelled to Bristol */
  author?: Maybe<Scalars["String"]>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** Customer quote. Keep it short. Don&#039;t include the speech marks. There is a character limit of 325. */
  quote?: Maybe<Scalars["String"]>;
  /** How many stars is the review? Can handle 0.5 increments */
  rating?: Maybe<Scalars["Float"]>;
  title?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type GlobalContent_Globalcontent_Socialmedia = AcfFieldGroup & {
  __typename?: "GlobalContent_Globalcontent_socialmedia";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** The text displayed if a user hovers over the icon. */
  label?: Maybe<Scalars["String"]>;
  platform?: Maybe<Scalars["String"]>;
  /** Where to take the user if they click on the icon. */
  url?: Maybe<Scalars["String"]>;
};

/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNode = {
  /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
  ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
  /** Connection between the HierarchicalContentNode type and the ContentNode type */
  children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
  /** Connection between the ContentNode type and the ContentType type */
  contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
  /** The name of the Content Type the node belongs to */
  contentTypeName: Scalars["String"];
  /** The unique identifier stored in the database */
  databaseId: Scalars["Int"];
  /** Post publishing date. */
  date?: Maybe<Scalars["String"]>;
  /** The publishing date set in GMT. */
  dateGmt?: Maybe<Scalars["String"]>;
  /** The desired slug of the post */
  desiredSlug?: Maybe<Scalars["String"]>;
  /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
  editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
  /** The RSS enclosure for the object */
  enclosure?: Maybe<Scalars["String"]>;
  /** Connection between the ContentNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
  /** Connection between the ContentNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
  /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
  guid?: Maybe<Scalars["String"]>;
  /** The globally unique ID for the object */
  id: Scalars["ID"];
  /** Whether the node is a Content Node */
  isContentNode: Scalars["Boolean"];
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars["Boolean"]>;
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars["Boolean"]>;
  /** Whether the node is a Term */
  isTermNode: Scalars["Boolean"];
  /** The user that most recently edited the node */
  lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
  /** The permalink of the post */
  link?: Maybe<Scalars["String"]>;
  /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
  modified?: Maybe<Scalars["String"]>;
  /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
  modifiedGmt?: Maybe<Scalars["String"]>;
  /** The parent of the node. The parent object can be of various types */
  parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
  /** Database id of the parent node */
  parentDatabaseId?: Maybe<Scalars["Int"]>;
  /** The globally unique identifier of the parent node. */
  parentId?: Maybe<Scalars["ID"]>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars["Int"]>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars["ID"]>;
  /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
  slug?: Maybe<Scalars["String"]>;
  /** The current status of the object */
  status?: Maybe<Scalars["String"]>;
  /** The template assigned to a node of content */
  template?: Maybe<ContentTemplate>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars["String"]>;
};

/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNodeAncestorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNodeChildrenArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNodeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** Content node with hierarchical (parent/child) relationships */
export type HierarchicalContentNodeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** Connection between the HierarchicalContentNode type and the ContentNode type */
export type HierarchicalContentNodeToContentNodeAncestorsConnection = Connection &
  ContentNodeConnection & {
    __typename?: "HierarchicalContentNodeToContentNodeAncestorsConnection";
    /** Edges for the HierarchicalContentNodeToContentNodeAncestorsConnection connection */
    edges: Array<HierarchicalContentNodeToContentNodeAncestorsConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo;
  };

/** An edge in a connection */
export type HierarchicalContentNodeToContentNodeAncestorsConnectionEdge =
  ContentNodeConnectionEdge &
    Edge & {
      __typename?: "HierarchicalContentNodeToContentNodeAncestorsConnectionEdge";
      /** A cursor for use in pagination */
      cursor?: Maybe<Scalars["String"]>;
      /** The item at the end of the edge */
      node: ContentNode;
    };

/** Page Info on the &quot;HierarchicalContentNodeToContentNodeAncestorsConnection&quot; */
export type HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: "HierarchicalContentNodeToContentNodeAncestorsConnectionPageInfo";
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars["String"]>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars["Boolean"];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars["Boolean"];
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars["String"]>;
    };

/** Arguments for filtering the HierarchicalContentNodeToContentNodeAncestorsConnection connection */
export type HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the HierarchicalContentNode type and the ContentNode type */
export type HierarchicalContentNodeToContentNodeChildrenConnection = Connection &
  ContentNodeConnection & {
    __typename?: "HierarchicalContentNodeToContentNodeChildrenConnection";
    /** Edges for the HierarchicalContentNodeToContentNodeChildrenConnection connection */
    edges: Array<HierarchicalContentNodeToContentNodeChildrenConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo;
  };

/** An edge in a connection */
export type HierarchicalContentNodeToContentNodeChildrenConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: "HierarchicalContentNodeToContentNodeChildrenConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;HierarchicalContentNodeToContentNodeChildrenConnection&quot; */
export type HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo =
  ContentNodeConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: "HierarchicalContentNodeToContentNodeChildrenConnectionPageInfo";
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars["String"]>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars["Boolean"];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars["Boolean"];
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars["String"]>;
    };

/** Arguments for filtering the HierarchicalContentNodeToContentNodeChildrenConnection connection */
export type HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the HierarchicalContentNode type and the ContentNode type */
export type HierarchicalContentNodeToParentContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: "HierarchicalContentNodeToParentContentNodeConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: ContentNode;
  };

/** Node with hierarchical (parent/child) relationships */
export type HierarchicalNode = {
  /** The unique identifier stored in the database */
  databaseId: Scalars["Int"];
  /** The globally unique ID for the object */
  id: Scalars["ID"];
  /** Database id of the parent node */
  parentDatabaseId?: Maybe<Scalars["Int"]>;
  /** The globally unique identifier of the parent node. */
  parentId?: Maybe<Scalars["ID"]>;
};

/** Term node with hierarchical (parent/child) relationships */
export type HierarchicalTermNode = {
  /** The number of objects connected to the object */
  count?: Maybe<Scalars["Int"]>;
  /** The unique identifier stored in the database */
  databaseId: Scalars["Int"];
  /** The description of the object */
  description?: Maybe<Scalars["String"]>;
  /** Connection between the TermNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
  /** Connection between the TermNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /** The globally unique ID for the object */
  id: Scalars["ID"];
  /** Whether the node is a Content Node */
  isContentNode: Scalars["Boolean"];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars["Boolean"]>;
  /** Whether the node is a Term */
  isTermNode: Scalars["Boolean"];
  /** The link to the term */
  link?: Maybe<Scalars["String"]>;
  /** The human friendly name of the object. */
  name?: Maybe<Scalars["String"]>;
  /** Database id of the parent node */
  parentDatabaseId?: Maybe<Scalars["Int"]>;
  /** The globally unique identifier of the parent node. */
  parentId?: Maybe<Scalars["ID"]>;
  /** An alphanumeric identifier for the object unique to its type. */
  slug?: Maybe<Scalars["String"]>;
  /** The name of the taxonomy that the object is associated with */
  taxonomyName?: Maybe<Scalars["String"]>;
  /** The ID of the term group that this term object belongs to */
  termGroupId?: Maybe<Scalars["Int"]>;
  /** The taxonomy ID that the object is associated with */
  termTaxonomyId?: Maybe<Scalars["Int"]>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars["String"]>;
};

/** Term node with hierarchical (parent/child) relationships */
export type HierarchicalTermNodeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** Term node with hierarchical (parent/child) relationships */
export type HierarchicalTermNodeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The HomePage type */
export type HomePage = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  HierarchicalNode &
  MenuItemLinkable &
  Node &
  NodeWithPageAttributes &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: "HomePage";
    /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Component | Billboard&quot; was set to Show in GraphQL. */
    billboard?: Maybe<HomePage_Billboard>;
    /** Connection between the HierarchicalContentNode type and the ContentNode type */
    children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
    /** Connection between the HomePage type and the ContentLanguages type */
    contentLanguages?: Maybe<HomePageToContentLanguagesConnection>;
    /** Connection between the HomePage type and the ContentStatus type */
    contentStatuses?: Maybe<HomePageToContentStatusConnection>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars["String"];
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** Post publishing date. */
    date?: Maybe<Scalars["String"]>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars["String"]>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars["String"]>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars["String"]>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Template | Home&quot; was set to Show in GraphQL. */
    fields?: Maybe<HomePage_Fields>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars["String"]>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    homePageId: Scalars["Int"];
    /** The globally unique identifier of the home object. */
    id: Scalars["ID"];
    /** Whether the node is a Content Node */
    isContentNode: Scalars["Boolean"];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars["Boolean"]>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** Whether the node is a Term */
    isTermNode: Scalars["Boolean"];
    /** Polylang language */
    language?: Maybe<Language>;
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars["String"]>;
    /** Connection between the HomePage type and the Locale type */
    locales?: Maybe<HomePageToLocaleConnection>;
    /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
    menuOrder?: Maybe<Scalars["Int"]>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Meta&quot; was set to Show in GraphQL. */
    meta?: Maybe<HomePage_Meta>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars["String"]>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars["String"]>;
    /** The parent of the node. The parent object can be of various types */
    parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars["Int"]>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars["ID"]>;
    /** Connection between the HomePage type and the HomePage type */
    preview?: Maybe<HomePageToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars["Int"]>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars["ID"]>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars["String"]>;
    /** The current status of the object */
    status?: Maybe<Scalars["String"]>;
    /** The template assigned to a node of content */
    template?: Maybe<ContentTemplate>;
    /** Connection between the HomePage type and the TermNode type */
    terms?: Maybe<HomePageToTermNodeConnection>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars["String"]>;
    /** Get specific translation version of this object */
    translation?: Maybe<HomePage>;
    /** List all translated versions of this post */
    translations?: Maybe<Array<Maybe<HomePage>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars["String"]>;
  };

/** The HomePage type */
export type HomePageAncestorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

/** The HomePage type */
export type HomePageChildrenArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** The HomePage type */
export type HomePageContentLanguagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HomePageToContentLanguagesConnectionWhereArgs>;
};

/** The HomePage type */
export type HomePageContentStatusesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HomePageToContentStatusConnectionWhereArgs>;
};

/** The HomePage type */
export type HomePageEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The HomePage type */
export type HomePageEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The HomePage type */
export type HomePageLocalesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HomePageToLocaleConnectionWhereArgs>;
};

/** The HomePage type */
export type HomePageTermsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HomePageToTermNodeConnectionWhereArgs>;
};

/** The HomePage type */
export type HomePageTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The HomePage type */
export type HomePageTranslationArgs = {
  language: LanguageCodeEnum;
};

/** Connection to HomePage Nodes */
export type HomePageConnection = {
  /** A list of edges (relational context) between RootQuery and connected HomePage Nodes */
  edges: Array<HomePageConnectionEdge>;
  /** A list of connected HomePage Nodes */
  nodes: Array<HomePage>;
  /** Information about pagination in a connection. */
  pageInfo: HomePageConnectionPageInfo;
};

/** Edge between a Node and a connected HomePage */
export type HomePageConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected HomePage Node */
  node: HomePage;
};

/** Page Info on the connected HomePageConnectionEdge */
export type HomePageConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** Set relationships between the HomePage to ContentLanguages */
export type HomePageContentLanguagesInput = {
  /** If true, this will append the ContentLanguages to existing related ContentLanguages. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<HomePageContentLanguagesNodeInput>>>;
};

/** List of ContentLanguages to connect the HomePage to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type HomePageContentLanguagesNodeInput = {
  /** The description of the ContentLanguages. This field is used to set a description of the ContentLanguages if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the ContentLanguages. If present, this will be used to connect to the HomePage. If no existing ContentLanguages exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the ContentLanguages. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the ContentLanguages. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Set relationships between the HomePage to ContentStatuses */
export type HomePageContentStatusesInput = {
  /** If true, this will append the ContentStatus to existing related ContentStatuses. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<HomePageContentStatusesNodeInput>>>;
};

/** List of ContentStatuses to connect the HomePage to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type HomePageContentStatusesNodeInput = {
  /** The description of the ContentStatus. This field is used to set a description of the ContentStatus if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the ContentStatus. If present, this will be used to connect to the HomePage. If no existing ContentStatus exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the ContentStatus. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the ContentStatus. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum HomePageIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  Id = "ID",
  /** Identify a resource by the URI. */
  Uri = "URI",
}

/** Set relationships between the HomePage to Locales */
export type HomePageLocalesInput = {
  /** If true, this will append the Locale to existing related Locales. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<HomePageLocalesNodeInput>>>;
};

/** List of Locales to connect the HomePage to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type HomePageLocalesNodeInput = {
  /** The description of the Locale. This field is used to set a description of the Locale if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the Locale. If present, this will be used to connect to the HomePage. If no existing Locale exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the Locale. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the Locale. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Connection between the HomePage type and the ContentLanguages type */
export type HomePageToContentLanguagesConnection = Connection &
  ContentLanguagesConnection & {
    __typename?: "HomePageToContentLanguagesConnection";
    /** Edges for the HomePageToContentLanguagesConnection connection */
    edges: Array<HomePageToContentLanguagesConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentLanguages>;
    /** Information about pagination in a connection. */
    pageInfo: HomePageToContentLanguagesConnectionPageInfo;
  };

/** An edge in a connection */
export type HomePageToContentLanguagesConnectionEdge = ContentLanguagesConnectionEdge &
  Edge & {
    __typename?: "HomePageToContentLanguagesConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentLanguages;
  };

/** Page Info on the &quot;HomePageToContentLanguagesConnection&quot; */
export type HomePageToContentLanguagesConnectionPageInfo = ContentLanguagesConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "HomePageToContentLanguagesConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the HomePageToContentLanguagesConnection connection */
export type HomePageToContentLanguagesConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the HomePage type and the ContentStatus type */
export type HomePageToContentStatusConnection = Connection &
  ContentStatusConnection & {
    __typename?: "HomePageToContentStatusConnection";
    /** Edges for the HomePageToContentStatusConnection connection */
    edges: Array<HomePageToContentStatusConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentStatus>;
    /** Information about pagination in a connection. */
    pageInfo: HomePageToContentStatusConnectionPageInfo;
  };

/** An edge in a connection */
export type HomePageToContentStatusConnectionEdge = ContentStatusConnectionEdge &
  Edge & {
    __typename?: "HomePageToContentStatusConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentStatus;
  };

/** Page Info on the &quot;HomePageToContentStatusConnection&quot; */
export type HomePageToContentStatusConnectionPageInfo = ContentStatusConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "HomePageToContentStatusConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the HomePageToContentStatusConnection connection */
export type HomePageToContentStatusConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the HomePage type and the Locale type */
export type HomePageToLocaleConnection = Connection &
  LocaleConnection & {
    __typename?: "HomePageToLocaleConnection";
    /** Edges for the HomePageToLocaleConnection connection */
    edges: Array<HomePageToLocaleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Locale>;
    /** Information about pagination in a connection. */
    pageInfo: HomePageToLocaleConnectionPageInfo;
  };

/** An edge in a connection */
export type HomePageToLocaleConnectionEdge = Edge &
  LocaleConnectionEdge & {
    __typename?: "HomePageToLocaleConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Locale;
  };

/** Page Info on the &quot;HomePageToLocaleConnection&quot; */
export type HomePageToLocaleConnectionPageInfo = LocaleConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "HomePageToLocaleConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the HomePageToLocaleConnection connection */
export type HomePageToLocaleConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the HomePage type and the HomePage type */
export type HomePageToPreviewConnectionEdge = Edge &
  HomePageConnectionEdge &
  OneToOneConnection & {
    __typename?: "HomePageToPreviewConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: HomePage;
  };

/** Connection between the HomePage type and the TermNode type */
export type HomePageToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: "HomePageToTermNodeConnection";
    /** Edges for the HomePageToTermNodeConnection connection */
    edges: Array<HomePageToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: HomePageToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type HomePageToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: "HomePageToTermNodeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;HomePageToTermNodeConnection&quot; */
export type HomePageToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: "HomePageToTermNodeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the HomePageToTermNodeConnection connection */
export type HomePageToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Field Group */
export type HomePage_Billboard = AcfFieldGroup & {
  __typename?: "HomePage_Billboard";
  /** Optional text that appears over the image. */
  billboardCaption?: Maybe<Scalars["String"]>;
  billboardContent?: Maybe<Scalars["String"]>;
  billboardCta?: Maybe<AcfLink>;
  billboardImage?: Maybe<MediaItem>;
  billboardImageside?: Maybe<Scalars["String"]>;
  billboardStyle?: Maybe<Scalars["String"]>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type HomePage_Fields = AcfFieldGroup & {
  __typename?: "HomePage_Fields";
  components?: Maybe<HomePage_Fields_Components>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type HomePage_Fields_Components = AcfFieldGroup & {
  __typename?: "HomePage_Fields_Components";
  articlesArticles?: Maybe<Array<Maybe<HomePage_Fields_Components_ArticlesArticles>>>;
  articlesCta?: Maybe<AcfLink>;
  billboard1?: Maybe<Scalars["String"]>;
  /** Optional text that appears over the image. */
  billboard1BillboardCaption?: Maybe<Scalars["String"]>;
  billboard1BillboardContent?: Maybe<Scalars["String"]>;
  billboard1BillboardCta?: Maybe<AcfLink>;
  billboard1BillboardImage?: Maybe<MediaItem>;
  billboard1BillboardImageside?: Maybe<Scalars["String"]>;
  billboard1BillboardStyle?: Maybe<Scalars["String"]>;
  billboard2?: Maybe<Scalars["String"]>;
  /** Optional text that appears over the image. */
  billboard2BillboardCaption?: Maybe<Scalars["String"]>;
  billboard2BillboardContent?: Maybe<Scalars["String"]>;
  billboard2BillboardCta?: Maybe<AcfLink>;
  billboard2BillboardImage?: Maybe<MediaItem>;
  billboard2BillboardImageside?: Maybe<Scalars["String"]>;
  billboard2BillboardStyle?: Maybe<Scalars["String"]>;
  billboard3?: Maybe<Scalars["String"]>;
  /** Optional text that appears over the image. */
  billboard3BillboardCaption?: Maybe<Scalars["String"]>;
  billboard3BillboardContent?: Maybe<Scalars["String"]>;
  billboard3BillboardCta?: Maybe<AcfLink>;
  billboard3BillboardImage?: Maybe<MediaItem>;
  billboard3BillboardImageside?: Maybe<Scalars["String"]>;
  billboard3BillboardStyle?: Maybe<Scalars["String"]>;
  featuredlocation1?: Maybe<Scalars["String"]>;
  featuredlocation1ImagepulloutCta?: Maybe<AcfLink>;
  featuredlocation1ImagepulloutDescription?: Maybe<Scalars["String"]>;
  featuredlocation1ImagepulloutImage?: Maybe<MediaItem>;
  featuredlocation1ImagepulloutTitle?: Maybe<Scalars["String"]>;
  featuredlocation2?: Maybe<Scalars["String"]>;
  featuredlocation2ImagepulloutCta?: Maybe<AcfLink>;
  featuredlocation2ImagepulloutDescription?: Maybe<Scalars["String"]>;
  featuredlocation2ImagepulloutImage?: Maybe<MediaItem>;
  featuredlocation2ImagepulloutTitle?: Maybe<Scalars["String"]>;
  featuredtripideas1?: Maybe<Scalars["String"]>;
  featuredtripideas1Tripideas?: Maybe<
    Array<Maybe<HomePage_Fields_Components_Featuredtripideas1Tripideas>>
  >;
  featuredtripideas2?: Maybe<Scalars["String"]>;
  featuredtripideas2Tripideas?: Maybe<
    Array<Maybe<HomePage_Fields_Components_Featuredtripideas2Tripideas>>
  >;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  homeheroCta?: Maybe<AcfLink>;
  homeheroSubtitle?: Maybe<Scalars["String"]>;
  homeheroTitle?: Maybe<Scalars["String"]>;
  infocusArticles?: Maybe<Array<Maybe<HomePage_Fields_Components_InfocusArticles>>>;
  infocusDescription?: Maybe<Scalars["String"]>;
  infocusSubtitle?: Maybe<Scalars["String"]>;
  infocusTitle?: Maybe<Scalars["String"]>;
  locationcardsCta?: Maybe<AcfLink>;
  /** Add the locations that should show in this grid. Best to pick either 4 or 8 so we have nice even rows but you can add a specific number if neccessary. Minimum of 3. Make sure they&#039;re the right locale. */
  locationcardsLocations?: Maybe<Array<Maybe<HomePage_Fields_Components_LocationcardsLocations>>>;
  locationcardsTitle?: Maybe<Scalars["String"]>;
  /** Should this section show on the page? If yes make sure it has all the relevant content nice and complete. */
  quietlinksActive?: Maybe<Scalars["Boolean"]>;
  /** Add 1, 2 or 3 quiet links. These are short pieces of intro text with a link to somewhere on the site. */
  quietlinksLinks?: Maybe<Array<Maybe<HomePage_Fields_Components_QuietlinksLinks>>>;
  reviewslistReviews?: Maybe<Array<Maybe<HomePage_Fields_Components_ReviewslistReviews>>>;
  reviewslistTitle?: Maybe<Scalars["String"]>;
  /** ARIA label for the dialog. */
  videomodalArialabel?: Maybe<Scalars["String"]>;
  /** Label for the play video button */
  videomodalCtalabel?: Maybe<Scalars["String"]>;
  /** Add the iframe for the video you want to appear in the modal. You can usually get this from YouTube or Vimeo, wherever the video is hosted. */
  videomodalEmbed?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type HomePage_Fields_Components_ArticlesArticles = AcfFieldGroup & {
  __typename?: "HomePage_Fields_Components_articlesArticles";
  article?: Maybe<HomePage_Fields_Components_ArticlesArticles_Article>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type HomePage_Fields_Components_ArticlesArticles_Article = Article;

/** Field Group */
export type HomePage_Fields_Components_Featuredtripideas1Tripideas = AcfFieldGroup & {
  __typename?: "HomePage_Fields_Components_featuredtripideas1Tripideas";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** URL of a Site 2 trip idea */
  url?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type HomePage_Fields_Components_Featuredtripideas2Tripideas = AcfFieldGroup & {
  __typename?: "HomePage_Fields_Components_featuredtripideas2Tripideas";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** URL of a Site 2 trip idea */
  url?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type HomePage_Fields_Components_InfocusArticles = AcfFieldGroup & {
  __typename?: "HomePage_Fields_Components_infocusArticles";
  article?: Maybe<HomePage_Fields_Components_InfocusArticles_Article>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type HomePage_Fields_Components_InfocusArticles_Article = Article;

/** Field Group */
export type HomePage_Fields_Components_LocationcardsLocations = AcfFieldGroup & {
  __typename?: "HomePage_Fields_Components_locationcardsLocations";
  color?: Maybe<Scalars["String"]>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type HomePage_Fields_Components_QuietlinksLinks = AcfFieldGroup & {
  __typename?: "HomePage_Fields_Components_quietlinksLinks";
  cta?: Maybe<AcfLink>;
  description?: Maybe<Scalars["String"]>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type HomePage_Fields_Components_ReviewslistReviews = AcfFieldGroup & {
  __typename?: "HomePage_Fields_Components_reviewslistReviews";
  /** The customer name and where they travelled to. Usually written as: Sim, travelled to Bristol */
  author?: Maybe<Scalars["String"]>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** Customer quote. Keep it short. Don&#039;t include the speech marks. There is a character limit of 325. */
  quote?: Maybe<Scalars["String"]>;
  /** How many stars is the review? Can handle 0.5 increments */
  rating?: Maybe<Scalars["Float"]>;
  title?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type HomePage_Meta = AcfFieldGroup & {
  __typename?: "HomePage_Meta";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** Override for the page title, if empty the actual title will be used. */
  pageTitle?: Maybe<Scalars["String"]>;
  /** Description of content shown to search engines, if empty the search engine will extract its own description from the page. */
  searchDescription?: Maybe<Scalars["String"]>;
  /** Image shown to search engines and linkbots, if empty then a hero image or a generic image will be used. */
  searchImage?: Maybe<MediaItem>;
  /** Override for the title shown to search engines, if empty the page title or the actual title will be used. */
  searchTitle?: Maybe<Scalars["String"]>;
  /**
   * A representation of this page in the URL Use only lowercase letters, numbers, and hyphens.
   *
   * Don&#039;t add slash ( / ) to the beginning of the url fragment.
   */
  urlFragment?: Maybe<Scalars["String"]>;
};

/** The HowItWorksPage type */
export type HowItWorksPage = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  HierarchicalNode &
  MenuItemLinkable &
  Node &
  NodeWithPageAttributes &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: "HowItWorksPage";
    /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
    /** Connection between the HierarchicalContentNode type and the ContentNode type */
    children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
    /** Connection between the HowItWorksPage type and the ContentLanguages type */
    contentLanguages?: Maybe<HowItWorksPageToContentLanguagesConnection>;
    /** Connection between the HowItWorksPage type and the ContentStatus type */
    contentStatuses?: Maybe<HowItWorksPageToContentStatusConnection>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars["String"];
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** Post publishing date. */
    date?: Maybe<Scalars["String"]>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars["String"]>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars["String"]>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars["String"]>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars["String"]>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Template | How It Works&quot; was set to Show in GraphQL. */
    howItWorks?: Maybe<HowItWorksPage_Howitworks>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    howItWorksPageId: Scalars["Int"];
    /** The globally unique identifier of the how-it-works object. */
    id: Scalars["ID"];
    /** Whether the node is a Content Node */
    isContentNode: Scalars["Boolean"];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars["Boolean"]>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** Whether the node is a Term */
    isTermNode: Scalars["Boolean"];
    /** Polylang language */
    language?: Maybe<Language>;
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars["String"]>;
    /** Connection between the HowItWorksPage type and the Locale type */
    locales?: Maybe<HowItWorksPageToLocaleConnection>;
    /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
    menuOrder?: Maybe<Scalars["Int"]>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars["String"]>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars["String"]>;
    /** The parent of the node. The parent object can be of various types */
    parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars["Int"]>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars["ID"]>;
    /** Connection between the HowItWorksPage type and the HowItWorksPage type */
    preview?: Maybe<HowItWorksPageToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars["Int"]>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars["ID"]>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars["String"]>;
    /** The current status of the object */
    status?: Maybe<Scalars["String"]>;
    /** The template assigned to a node of content */
    template?: Maybe<ContentTemplate>;
    /** Connection between the HowItWorksPage type and the TermNode type */
    terms?: Maybe<HowItWorksPageToTermNodeConnection>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars["String"]>;
    /** Get specific translation version of this object */
    translation?: Maybe<HowItWorksPage>;
    /** List all translated versions of this post */
    translations?: Maybe<Array<Maybe<HowItWorksPage>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars["String"]>;
  };

/** The HowItWorksPage type */
export type HowItWorksPageAncestorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

/** The HowItWorksPage type */
export type HowItWorksPageChildrenArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** The HowItWorksPage type */
export type HowItWorksPageContentLanguagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HowItWorksPageToContentLanguagesConnectionWhereArgs>;
};

/** The HowItWorksPage type */
export type HowItWorksPageContentStatusesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HowItWorksPageToContentStatusConnectionWhereArgs>;
};

/** The HowItWorksPage type */
export type HowItWorksPageEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The HowItWorksPage type */
export type HowItWorksPageEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The HowItWorksPage type */
export type HowItWorksPageLocalesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HowItWorksPageToLocaleConnectionWhereArgs>;
};

/** The HowItWorksPage type */
export type HowItWorksPageTermsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HowItWorksPageToTermNodeConnectionWhereArgs>;
};

/** The HowItWorksPage type */
export type HowItWorksPageTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The HowItWorksPage type */
export type HowItWorksPageTranslationArgs = {
  language: LanguageCodeEnum;
};

/** Connection to HowItWorksPage Nodes */
export type HowItWorksPageConnection = {
  /** A list of edges (relational context) between RootQuery and connected HowItWorksPage Nodes */
  edges: Array<HowItWorksPageConnectionEdge>;
  /** A list of connected HowItWorksPage Nodes */
  nodes: Array<HowItWorksPage>;
  /** Information about pagination in a connection. */
  pageInfo: HowItWorksPageConnectionPageInfo;
};

/** Edge between a Node and a connected HowItWorksPage */
export type HowItWorksPageConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected HowItWorksPage Node */
  node: HowItWorksPage;
};

/** Page Info on the connected HowItWorksPageConnectionEdge */
export type HowItWorksPageConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** Set relationships between the HowItWorksPage to ContentLanguages */
export type HowItWorksPageContentLanguagesInput = {
  /** If true, this will append the ContentLanguages to existing related ContentLanguages. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<HowItWorksPageContentLanguagesNodeInput>>>;
};

/** List of ContentLanguages to connect the HowItWorksPage to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type HowItWorksPageContentLanguagesNodeInput = {
  /** The description of the ContentLanguages. This field is used to set a description of the ContentLanguages if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the ContentLanguages. If present, this will be used to connect to the HowItWorksPage. If no existing ContentLanguages exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the ContentLanguages. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the ContentLanguages. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Set relationships between the HowItWorksPage to ContentStatuses */
export type HowItWorksPageContentStatusesInput = {
  /** If true, this will append the ContentStatus to existing related ContentStatuses. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<HowItWorksPageContentStatusesNodeInput>>>;
};

/** List of ContentStatuses to connect the HowItWorksPage to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type HowItWorksPageContentStatusesNodeInput = {
  /** The description of the ContentStatus. This field is used to set a description of the ContentStatus if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the ContentStatus. If present, this will be used to connect to the HowItWorksPage. If no existing ContentStatus exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the ContentStatus. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the ContentStatus. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum HowItWorksPageIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  Id = "ID",
  /** Identify a resource by the URI. */
  Uri = "URI",
}

/** Set relationships between the HowItWorksPage to Locales */
export type HowItWorksPageLocalesInput = {
  /** If true, this will append the Locale to existing related Locales. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<HowItWorksPageLocalesNodeInput>>>;
};

/** List of Locales to connect the HowItWorksPage to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type HowItWorksPageLocalesNodeInput = {
  /** The description of the Locale. This field is used to set a description of the Locale if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the Locale. If present, this will be used to connect to the HowItWorksPage. If no existing Locale exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the Locale. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the Locale. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Connection between the HowItWorksPage type and the ContentLanguages type */
export type HowItWorksPageToContentLanguagesConnection = Connection &
  ContentLanguagesConnection & {
    __typename?: "HowItWorksPageToContentLanguagesConnection";
    /** Edges for the HowItWorksPageToContentLanguagesConnection connection */
    edges: Array<HowItWorksPageToContentLanguagesConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentLanguages>;
    /** Information about pagination in a connection. */
    pageInfo: HowItWorksPageToContentLanguagesConnectionPageInfo;
  };

/** An edge in a connection */
export type HowItWorksPageToContentLanguagesConnectionEdge = ContentLanguagesConnectionEdge &
  Edge & {
    __typename?: "HowItWorksPageToContentLanguagesConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentLanguages;
  };

/** Page Info on the &quot;HowItWorksPageToContentLanguagesConnection&quot; */
export type HowItWorksPageToContentLanguagesConnectionPageInfo =
  ContentLanguagesConnectionPageInfo &
    PageInfo &
    WpPageInfo & {
      __typename?: "HowItWorksPageToContentLanguagesConnectionPageInfo";
      /** When paginating forwards, the cursor to continue. */
      endCursor?: Maybe<Scalars["String"]>;
      /** When paginating forwards, are there more items? */
      hasNextPage: Scalars["Boolean"];
      /** When paginating backwards, are there more items? */
      hasPreviousPage: Scalars["Boolean"];
      /** When paginating backwards, the cursor to continue. */
      startCursor?: Maybe<Scalars["String"]>;
    };

/** Arguments for filtering the HowItWorksPageToContentLanguagesConnection connection */
export type HowItWorksPageToContentLanguagesConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the HowItWorksPage type and the ContentStatus type */
export type HowItWorksPageToContentStatusConnection = Connection &
  ContentStatusConnection & {
    __typename?: "HowItWorksPageToContentStatusConnection";
    /** Edges for the HowItWorksPageToContentStatusConnection connection */
    edges: Array<HowItWorksPageToContentStatusConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentStatus>;
    /** Information about pagination in a connection. */
    pageInfo: HowItWorksPageToContentStatusConnectionPageInfo;
  };

/** An edge in a connection */
export type HowItWorksPageToContentStatusConnectionEdge = ContentStatusConnectionEdge &
  Edge & {
    __typename?: "HowItWorksPageToContentStatusConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentStatus;
  };

/** Page Info on the &quot;HowItWorksPageToContentStatusConnection&quot; */
export type HowItWorksPageToContentStatusConnectionPageInfo = ContentStatusConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "HowItWorksPageToContentStatusConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the HowItWorksPageToContentStatusConnection connection */
export type HowItWorksPageToContentStatusConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the HowItWorksPage type and the Locale type */
export type HowItWorksPageToLocaleConnection = Connection &
  LocaleConnection & {
    __typename?: "HowItWorksPageToLocaleConnection";
    /** Edges for the HowItWorksPageToLocaleConnection connection */
    edges: Array<HowItWorksPageToLocaleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Locale>;
    /** Information about pagination in a connection. */
    pageInfo: HowItWorksPageToLocaleConnectionPageInfo;
  };

/** An edge in a connection */
export type HowItWorksPageToLocaleConnectionEdge = Edge &
  LocaleConnectionEdge & {
    __typename?: "HowItWorksPageToLocaleConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Locale;
  };

/** Page Info on the &quot;HowItWorksPageToLocaleConnection&quot; */
export type HowItWorksPageToLocaleConnectionPageInfo = LocaleConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "HowItWorksPageToLocaleConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the HowItWorksPageToLocaleConnection connection */
export type HowItWorksPageToLocaleConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the HowItWorksPage type and the HowItWorksPage type */
export type HowItWorksPageToPreviewConnectionEdge = Edge &
  HowItWorksPageConnectionEdge &
  OneToOneConnection & {
    __typename?: "HowItWorksPageToPreviewConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: HowItWorksPage;
  };

/** Connection between the HowItWorksPage type and the TermNode type */
export type HowItWorksPageToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: "HowItWorksPageToTermNodeConnection";
    /** Edges for the HowItWorksPageToTermNodeConnection connection */
    edges: Array<HowItWorksPageToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: HowItWorksPageToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type HowItWorksPageToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: "HowItWorksPageToTermNodeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;HowItWorksPageToTermNodeConnection&quot; */
export type HowItWorksPageToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: "HowItWorksPageToTermNodeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the HowItWorksPageToTermNodeConnection connection */
export type HowItWorksPageToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Field Group */
export type HowItWorksPage_Howitworks = AcfFieldGroup & {
  __typename?: "HowItWorksPage_Howitworks";
  components?: Maybe<HowItWorksPage_Howitworks_Components>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type HowItWorksPage_Howitworks_Components = AcfFieldGroup & {
  __typename?: "HowItWorksPage_Howitworks_Components";
  articlesArticles?: Maybe<Array<Maybe<HowItWorksPage_Howitworks_Components_ArticlesArticles>>>;
  articlesCta?: Maybe<AcfLink>;
  /** Optional text that appears over the image. */
  billboardCaption?: Maybe<Scalars["String"]>;
  billboardContent?: Maybe<Scalars["String"]>;
  billboardCta?: Maybe<AcfLink>;
  billboardImage?: Maybe<MediaItem>;
  billboardImageside?: Maybe<Scalars["String"]>;
  billboardStyle?: Maybe<Scalars["String"]>;
  blogarticlesBackgroundcolor?: Maybe<Scalars["String"]>;
  blogarticlesSectionheading?: Maybe<Scalars["String"]>;
  blogarticlesSectionintro?: Maybe<Scalars["String"]>;
  components?: Maybe<Array<Maybe<HowItWorksPage_Howitworks_Components_Components>>>;
  faqslistQuestions?: Maybe<Array<Maybe<HowItWorksPage_Howitworks_Components_FaqslistQuestions>>>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  introductiontext?: Maybe<Scalars["String"]>;
  propositionpulloutgroup?: Maybe<
    Array<Maybe<HowItWorksPage_Howitworks_Components_Propositionpulloutgroup>>
  >;
  reviewslistReviews?: Maybe<Array<Maybe<HowItWorksPage_Howitworks_Components_ReviewslistReviews>>>;
  standardheaderBackgroundimage?: Maybe<MediaItem>;
  standardheaderHeading?: Maybe<Scalars["String"]>;
  standardheaderOverlayopacity?: Maybe<Scalars["Float"]>;
  standardheaderSubheading?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type HowItWorksPage_Howitworks_Components_ArticlesArticles = AcfFieldGroup & {
  __typename?: "HowItWorksPage_Howitworks_Components_articlesArticles";
  article?: Maybe<HowItWorksPage_Howitworks_Components_ArticlesArticles_Article>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type HowItWorksPage_Howitworks_Components_ArticlesArticles_Article = Article;

/** Field Group */
export type HowItWorksPage_Howitworks_Components_Components = AcfFieldGroup & {
  __typename?: "HowItWorksPage_Howitworks_Components_components";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  metrichighlightText?: Maybe<Scalars["String"]>;
  metrichighlightTitle?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type HowItWorksPage_Howitworks_Components_FaqslistQuestions = AcfFieldGroup & {
  __typename?: "HowItWorksPage_Howitworks_Components_faqslistQuestions";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  question?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type HowItWorksPage_Howitworks_Components_Propositionpulloutgroup = AcfFieldGroup & {
  __typename?: "HowItWorksPage_Howitworks_Components_propositionpulloutgroup";
  abtalogo?: Maybe<Scalars["String"]>;
  astalogo?: Maybe<Scalars["String"]>;
  atollogo?: Maybe<Scalars["String"]>;
  buttoncta?: Maybe<Scalars["String"]>;
  buttonurl?: Maybe<Scalars["String"]>;
  drvlogo?: Maybe<Scalars["String"]>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  isLayoutFlipped?: Maybe<Scalars["Boolean"]>;
  propositionpulloutHeading?: Maybe<Scalars["String"]>;
  propositionpulloutItems?: Maybe<
    Array<
      Maybe<HowItWorksPage_Howitworks_Components_Propositionpulloutgroup_PropositionpulloutItems>
    >
  >;
  propositionpulloutLargeimage?: Maybe<MediaItem>;
  propositionpulloutSubtitle?: Maybe<Scalars["String"]>;
  showAbtaLogo?: Maybe<Scalars["Boolean"]>;
  showAstaLogo?: Maybe<Scalars["Boolean"]>;
  showAtolLogo?: Maybe<Scalars["Boolean"]>;
  showDrvLogo?: Maybe<Scalars["Boolean"]>;
};

/** Field Group */
export type HowItWorksPage_Howitworks_Components_Propositionpulloutgroup_PropositionpulloutItems =
  AcfFieldGroup & {
    __typename?: "HowItWorksPage_Howitworks_Components_propositionpulloutgroup_propositionpulloutItems";
    /** The name of the ACF Field Group */
    fieldGroupName?: Maybe<Scalars["String"]>;
    item?: Maybe<Scalars["String"]>;
  };

/** Field Group */
export type HowItWorksPage_Howitworks_Components_ReviewslistReviews = AcfFieldGroup & {
  __typename?: "HowItWorksPage_Howitworks_Components_reviewslistReviews";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** Customer quote. Keep it short. Don&#039;t include the speech marks. */
  quote?: Maybe<Scalars["String"]>;
  /** How many stars is the review? Can handle 0.5 increments */
  rating?: Maybe<Scalars["Float"]>;
};

/** Language (Polylang) */
export type Language = {
  __typename?: "Language";
  /** Language code (Polylang) */
  code?: Maybe<LanguageCodeEnum>;
  /** Language term front page URL */
  homeUrl?: Maybe<Scalars["String"]>;
  /** Language ID (Polylang) */
  id: Scalars["ID"];
  /** Language locale (Polylang) */
  locale?: Maybe<Scalars["String"]>;
  /** Human readable language name (Polylang) */
  name?: Maybe<Scalars["String"]>;
  /** Language term slug. Prefer the &quot;code&quot; field if possible (Polylang) */
  slug?: Maybe<Scalars["String"]>;
};

/** Enum of all available language codes */
export enum LanguageCodeEnum {
  De = "DE",
  En = "EN",
  EnGb = "EN_GB",
}

/** Filter item by specific language, default language or list all languages */
export enum LanguageCodeFilterEnum {
  All = "ALL",
  De = "DE",
  Default = "DEFAULT",
  En = "EN",
  EnGb = "EN_GB",
}

/** The Locale type */
export type Locale = DatabaseIdentifier &
  HierarchicalNode &
  HierarchicalTermNode &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: "Locale";
    /** The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<LocaleToAncestorsLocaleConnection>;
    /** Connection between the Locale type and the Article type */
    articles?: Maybe<LocaleToArticleConnection>;
    /** Connection between the Locale type and its children Locales. */
    children?: Maybe<LocaleToLocaleConnection>;
    /** Connection between the Locale type and the Collection type */
    collections?: Maybe<LocaleToCollectionConnection>;
    /** Connection between the Locale type and the ContentNode type */
    contentNodes?: Maybe<LocaleToContentNodeConnection>;
    /** The number of objects connected to the object */
    count?: Maybe<Scalars["Int"]>;
    /** Connection between the Locale type and the CountryProfile type */
    countryProfiles?: Maybe<LocaleToCountryProfileConnection>;
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** The description of the object */
    description?: Maybe<Scalars["String"]>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** Connection between the Locale type and the HomePage type */
    homePages?: Maybe<LocaleToHomePageConnection>;
    /** Connection between the Locale type and the HowItWorksPage type */
    howItWorksPages?: Maybe<LocaleToHowItWorksPageConnection>;
    /** The unique resource identifier path */
    id: Scalars["ID"];
    /** Whether the node is a Content Node */
    isContentNode: Scalars["Boolean"];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** Whether the node is a Term */
    isTermNode: Scalars["Boolean"];
    /** The link to the term */
    link?: Maybe<Scalars["String"]>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    localeId?: Maybe<Scalars["Int"]>;
    /** The human friendly name of the object. */
    name?: Maybe<Scalars["String"]>;
    /** Connection between the Locale type and its parent Locale. */
    parent?: Maybe<LocaleToParentLocaleConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars["Int"]>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars["ID"]>;
    /** Connection between the Locale type and the RegionProfile type */
    regionProfiles?: Maybe<LocaleToRegionProfileConnection>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars["String"]>;
    /** Connection between the Locale type and the Taxonomy type */
    taxonomy?: Maybe<LocaleToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars["String"]>;
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars["Int"]>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars["Int"]>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars["String"]>;
  };

/** The Locale type */
export type LocaleAncestorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The Locale type */
export type LocaleArticlesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocaleToArticleConnectionWhereArgs>;
};

/** The Locale type */
export type LocaleChildrenArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocaleToLocaleConnectionWhereArgs>;
};

/** The Locale type */
export type LocaleCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocaleToCollectionConnectionWhereArgs>;
};

/** The Locale type */
export type LocaleContentNodesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocaleToContentNodeConnectionWhereArgs>;
};

/** The Locale type */
export type LocaleCountryProfilesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocaleToCountryProfileConnectionWhereArgs>;
};

/** The Locale type */
export type LocaleEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The Locale type */
export type LocaleEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The Locale type */
export type LocaleHomePagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocaleToHomePageConnectionWhereArgs>;
};

/** The Locale type */
export type LocaleHowItWorksPagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocaleToHowItWorksPageConnectionWhereArgs>;
};

/** The Locale type */
export type LocaleRegionProfilesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LocaleToRegionProfileConnectionWhereArgs>;
};

/** Connection to Locale Nodes */
export type LocaleConnection = {
  /** A list of edges (relational context) between RootQuery and connected Locale Nodes */
  edges: Array<LocaleConnectionEdge>;
  /** A list of connected Locale Nodes */
  nodes: Array<Locale>;
  /** Information about pagination in a connection. */
  pageInfo: LocaleConnectionPageInfo;
};

/** Edge between a Node and a connected Locale */
export type LocaleConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected Locale Node */
  node: Locale;
};

/** Page Info on the connected LocaleConnectionEdge */
export type LocaleConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum LocaleIdType {
  /** The Database ID for the node */
  DatabaseId = "DATABASE_ID",
  /** The hashed Global ID */
  Id = "ID",
  /** The name of the node */
  Name = "NAME",
  /** Url friendly name of the node */
  Slug = "SLUG",
  /** The URI for the node */
  Uri = "URI",
}

/** Connection between the Locale type and the Locale type */
export type LocaleToAncestorsLocaleConnection = Connection &
  LocaleConnection & {
    __typename?: "LocaleToAncestorsLocaleConnection";
    /** Edges for the LocaleToAncestorsLocaleConnection connection */
    edges: Array<LocaleToAncestorsLocaleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Locale>;
    /** Information about pagination in a connection. */
    pageInfo: LocaleToAncestorsLocaleConnectionPageInfo;
  };

/** An edge in a connection */
export type LocaleToAncestorsLocaleConnectionEdge = Edge &
  LocaleConnectionEdge & {
    __typename?: "LocaleToAncestorsLocaleConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Locale;
  };

/** Page Info on the &quot;LocaleToAncestorsLocaleConnection&quot; */
export type LocaleToAncestorsLocaleConnectionPageInfo = LocaleConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "LocaleToAncestorsLocaleConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Connection between the Locale type and the Article type */
export type LocaleToArticleConnection = ArticleConnection &
  Connection & {
    __typename?: "LocaleToArticleConnection";
    /** Edges for the LocaleToArticleConnection connection */
    edges: Array<LocaleToArticleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Article>;
    /** Information about pagination in a connection. */
    pageInfo: LocaleToArticleConnectionPageInfo;
  };

/** An edge in a connection */
export type LocaleToArticleConnectionEdge = ArticleConnectionEdge &
  Edge & {
    __typename?: "LocaleToArticleConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Article;
  };

/** Page Info on the &quot;LocaleToArticleConnection&quot; */
export type LocaleToArticleConnectionPageInfo = ArticleConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "LocaleToArticleConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the LocaleToArticleConnection connection */
export type LocaleToArticleConnectionWhereArgs = {
  /** Category ID */
  categoryId?: InputMaybe<Scalars["Int"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars["String"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Locale type and the Collection type */
export type LocaleToCollectionConnection = CollectionConnection &
  Connection & {
    __typename?: "LocaleToCollectionConnection";
    /** Edges for the LocaleToCollectionConnection connection */
    edges: Array<LocaleToCollectionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Collection>;
    /** Information about pagination in a connection. */
    pageInfo: LocaleToCollectionConnectionPageInfo;
  };

/** An edge in a connection */
export type LocaleToCollectionConnectionEdge = CollectionConnectionEdge &
  Edge & {
    __typename?: "LocaleToCollectionConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Collection;
  };

/** Page Info on the &quot;LocaleToCollectionConnection&quot; */
export type LocaleToCollectionConnectionPageInfo = CollectionConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "LocaleToCollectionConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the LocaleToCollectionConnection connection */
export type LocaleToCollectionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Locale type and the ContentNode type */
export type LocaleToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: "LocaleToContentNodeConnection";
    /** Edges for the LocaleToContentNodeConnection connection */
    edges: Array<LocaleToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: LocaleToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type LocaleToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: "LocaleToContentNodeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;LocaleToContentNodeConnection&quot; */
export type LocaleToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "LocaleToContentNodeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the LocaleToContentNodeConnection connection */
export type LocaleToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfLocaleEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Locale type and the CountryProfile type */
export type LocaleToCountryProfileConnection = Connection &
  CountryProfileConnection & {
    __typename?: "LocaleToCountryProfileConnection";
    /** Edges for the LocaleToCountryProfileConnection connection */
    edges: Array<LocaleToCountryProfileConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<CountryProfile>;
    /** Information about pagination in a connection. */
    pageInfo: LocaleToCountryProfileConnectionPageInfo;
  };

/** An edge in a connection */
export type LocaleToCountryProfileConnectionEdge = CountryProfileConnectionEdge &
  Edge & {
    __typename?: "LocaleToCountryProfileConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: CountryProfile;
  };

/** Page Info on the &quot;LocaleToCountryProfileConnection&quot; */
export type LocaleToCountryProfileConnectionPageInfo = CountryProfileConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "LocaleToCountryProfileConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the LocaleToCountryProfileConnection connection */
export type LocaleToCountryProfileConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Locale type and the HomePage type */
export type LocaleToHomePageConnection = Connection &
  HomePageConnection & {
    __typename?: "LocaleToHomePageConnection";
    /** Edges for the LocaleToHomePageConnection connection */
    edges: Array<LocaleToHomePageConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<HomePage>;
    /** Information about pagination in a connection. */
    pageInfo: LocaleToHomePageConnectionPageInfo;
  };

/** An edge in a connection */
export type LocaleToHomePageConnectionEdge = Edge &
  HomePageConnectionEdge & {
    __typename?: "LocaleToHomePageConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: HomePage;
  };

/** Page Info on the &quot;LocaleToHomePageConnection&quot; */
export type LocaleToHomePageConnectionPageInfo = HomePageConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "LocaleToHomePageConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the LocaleToHomePageConnection connection */
export type LocaleToHomePageConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Locale type and the HowItWorksPage type */
export type LocaleToHowItWorksPageConnection = Connection &
  HowItWorksPageConnection & {
    __typename?: "LocaleToHowItWorksPageConnection";
    /** Edges for the LocaleToHowItWorksPageConnection connection */
    edges: Array<LocaleToHowItWorksPageConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<HowItWorksPage>;
    /** Information about pagination in a connection. */
    pageInfo: LocaleToHowItWorksPageConnectionPageInfo;
  };

/** An edge in a connection */
export type LocaleToHowItWorksPageConnectionEdge = Edge &
  HowItWorksPageConnectionEdge & {
    __typename?: "LocaleToHowItWorksPageConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: HowItWorksPage;
  };

/** Page Info on the &quot;LocaleToHowItWorksPageConnection&quot; */
export type LocaleToHowItWorksPageConnectionPageInfo = HowItWorksPageConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "LocaleToHowItWorksPageConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the LocaleToHowItWorksPageConnection connection */
export type LocaleToHowItWorksPageConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Locale type and the Locale type */
export type LocaleToLocaleConnection = Connection &
  LocaleConnection & {
    __typename?: "LocaleToLocaleConnection";
    /** Edges for the LocaleToLocaleConnection connection */
    edges: Array<LocaleToLocaleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Locale>;
    /** Information about pagination in a connection. */
    pageInfo: LocaleToLocaleConnectionPageInfo;
  };

/** An edge in a connection */
export type LocaleToLocaleConnectionEdge = Edge &
  LocaleConnectionEdge & {
    __typename?: "LocaleToLocaleConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Locale;
  };

/** Page Info on the &quot;LocaleToLocaleConnection&quot; */
export type LocaleToLocaleConnectionPageInfo = LocaleConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "LocaleToLocaleConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the LocaleToLocaleConnection connection */
export type LocaleToLocaleConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the Locale type and the Locale type */
export type LocaleToParentLocaleConnectionEdge = Edge &
  LocaleConnectionEdge &
  OneToOneConnection & {
    __typename?: "LocaleToParentLocaleConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: Locale;
  };

/** Connection between the Locale type and the RegionProfile type */
export type LocaleToRegionProfileConnection = Connection &
  RegionProfileConnection & {
    __typename?: "LocaleToRegionProfileConnection";
    /** Edges for the LocaleToRegionProfileConnection connection */
    edges: Array<LocaleToRegionProfileConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<RegionProfile>;
    /** Information about pagination in a connection. */
    pageInfo: LocaleToRegionProfileConnectionPageInfo;
  };

/** An edge in a connection */
export type LocaleToRegionProfileConnectionEdge = Edge &
  RegionProfileConnectionEdge & {
    __typename?: "LocaleToRegionProfileConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: RegionProfile;
  };

/** Page Info on the &quot;LocaleToRegionProfileConnection&quot; */
export type LocaleToRegionProfileConnectionPageInfo = PageInfo &
  RegionProfileConnectionPageInfo &
  WpPageInfo & {
    __typename?: "LocaleToRegionProfileConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the LocaleToRegionProfileConnection connection */
export type LocaleToRegionProfileConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Locale type and the Taxonomy type */
export type LocaleToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: "LocaleToTaxonomyConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** File details for a Media Item */
export type MediaDetails = {
  __typename?: "MediaDetails";
  /** The filename of the mediaItem */
  file?: Maybe<Scalars["String"]>;
  /** The height of the mediaItem */
  height?: Maybe<Scalars["Int"]>;
  /** Meta information associated with the mediaItem */
  meta?: Maybe<MediaItemMeta>;
  /** The available sizes of the mediaItem */
  sizes?: Maybe<Array<Maybe<MediaSize>>>;
  /** The width of the mediaItem */
  width?: Maybe<Scalars["Int"]>;
};

/** File details for a Media Item */
export type MediaDetailsSizesArgs = {
  exclude?: InputMaybe<Array<InputMaybe<MediaItemSizeEnum>>>;
  include?: InputMaybe<Array<InputMaybe<MediaItemSizeEnum>>>;
};

/** The mediaItem type */
export type MediaItem = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  HierarchicalNode &
  Node &
  NodeWithAuthor &
  NodeWithComments &
  NodeWithTemplate &
  NodeWithTitle &
  UniformResourceIdentifiable & {
    __typename?: "MediaItem";
    /** Alternative text to display when resource is not displayed */
    altText?: Maybe<Scalars["String"]>;
    /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars["Int"]>;
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars["ID"]>;
    /** The caption for the resource */
    caption?: Maybe<Scalars["String"]>;
    /** Connection between the HierarchicalContentNode type and the ContentNode type */
    children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
    /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
    commentCount?: Maybe<Scalars["Int"]>;
    /** Whether the comments are open or closed for this particular post. */
    commentStatus?: Maybe<Scalars["String"]>;
    /** Connection between the MediaItem type and the Comment type */
    comments?: Maybe<MediaItemToCommentConnection>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars["String"];
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** Post publishing date. */
    date?: Maybe<Scalars["String"]>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars["String"]>;
    /** Description of the image (stored as post_content) */
    description?: Maybe<Scalars["String"]>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars["String"]>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars["String"]>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** The filesize in bytes of the resource */
    fileSize?: Maybe<Scalars["Int"]>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars["String"]>;
    /** The globally unique identifier of the attachment object. */
    id: Scalars["ID"];
    /** Whether the node is a Content Node */
    isContentNode: Scalars["Boolean"];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars["Boolean"]>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** Whether the node is a Term */
    isTermNode: Scalars["Boolean"];
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars["String"]>;
    /** Details about the mediaItem */
    mediaDetails?: Maybe<MediaDetails>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    mediaItemId: Scalars["Int"];
    /** Url of the mediaItem */
    mediaItemUrl?: Maybe<Scalars["String"]>;
    /** Type of resource */
    mediaType?: Maybe<Scalars["String"]>;
    /** The mime type of the mediaItem */
    mimeType?: Maybe<Scalars["String"]>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars["String"]>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars["String"]>;
    /** The parent of the node. The parent object can be of various types */
    parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars["Int"]>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars["ID"]>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars["Int"]>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars["ID"]>;
    /** The sizes attribute value for an image. */
    sizes?: Maybe<Scalars["String"]>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars["String"]>;
    /** Url of the mediaItem */
    sourceUrl?: Maybe<Scalars["String"]>;
    /** The srcset attribute specifies the URL of the image to use in different situations. It is a comma separated string of urls and their widths. */
    srcSet?: Maybe<Scalars["String"]>;
    /** The current status of the object */
    status?: Maybe<Scalars["String"]>;
    /** The template assigned to a node of content */
    template?: Maybe<ContentTemplate>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars["String"]>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars["String"]>;
  };

/** The mediaItem type */
export type MediaItemAncestorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

/** The mediaItem type */
export type MediaItemCaptionArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The mediaItem type */
export type MediaItemChildrenArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** The mediaItem type */
export type MediaItemCommentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MediaItemToCommentConnectionWhereArgs>;
};

/** The mediaItem type */
export type MediaItemDescriptionArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The mediaItem type */
export type MediaItemEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The mediaItem type */
export type MediaItemEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The mediaItem type */
export type MediaItemFileSizeArgs = {
  size?: InputMaybe<MediaItemSizeEnum>;
};

/** The mediaItem type */
export type MediaItemSizesArgs = {
  size?: InputMaybe<MediaItemSizeEnum>;
};

/** The mediaItem type */
export type MediaItemSourceUrlArgs = {
  size?: InputMaybe<MediaItemSizeEnum>;
};

/** The mediaItem type */
export type MediaItemSrcSetArgs = {
  size?: InputMaybe<MediaItemSizeEnum>;
};

/** The mediaItem type */
export type MediaItemTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to mediaItem Nodes */
export type MediaItemConnection = {
  /** A list of edges (relational context) between RootQuery and connected mediaItem Nodes */
  edges: Array<MediaItemConnectionEdge>;
  /** A list of connected mediaItem Nodes */
  nodes: Array<MediaItem>;
  /** Information about pagination in a connection. */
  pageInfo: MediaItemConnectionPageInfo;
};

/** Edge between a Node and a connected mediaItem */
export type MediaItemConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected mediaItem Node */
  node: MediaItem;
};

/** Page Info on the connected MediaItemConnectionEdge */
export type MediaItemConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum MediaItemIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  Id = "ID",
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = "SLUG",
  /** Identify a media item by its source url */
  SourceUrl = "SOURCE_URL",
  /** Identify a resource by the URI. */
  Uri = "URI",
}

/** Meta connected to a MediaItem */
export type MediaItemMeta = {
  __typename?: "MediaItemMeta";
  /** Aperture measurement of the media item. */
  aperture?: Maybe<Scalars["Float"]>;
  /** Information about the camera used to create the media item. */
  camera?: Maybe<Scalars["String"]>;
  /** The text string description associated with the media item. */
  caption?: Maybe<Scalars["String"]>;
  /** Copyright information associated with the media item. */
  copyright?: Maybe<Scalars["String"]>;
  /** The date/time when the media was created. */
  createdTimestamp?: Maybe<Scalars["Int"]>;
  /** The original creator of the media item. */
  credit?: Maybe<Scalars["String"]>;
  /** The focal length value of the media item. */
  focalLength?: Maybe<Scalars["Float"]>;
  /** The ISO (International Organization for Standardization) value of the media item. */
  iso?: Maybe<Scalars["Int"]>;
  /** List of keywords used to describe or identfy the media item. */
  keywords?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** The vertical or horizontal aspect of the media item. */
  orientation?: Maybe<Scalars["String"]>;
  /** The shutter speed information of the media item. */
  shutterSpeed?: Maybe<Scalars["Float"]>;
  /** A useful title for the media item. */
  title?: Maybe<Scalars["String"]>;
};

/** The size of the media item object. */
export enum MediaItemSizeEnum {
  /** MediaItem with the large size */
  Large = "LARGE",
  /** MediaItem with the medium size */
  Medium = "MEDIUM",
  /** MediaItem with the medium_large size */
  MediumLarge = "MEDIUM_LARGE",
  /** MediaItem with the thumbnail size */
  Thumbnail = "THUMBNAIL",
  /** MediaItem with the 1536x1536 size */
  "1536X1536" = "_1536X1536",
  /** MediaItem with the 2048x2048 size */
  "2048X2048" = "_2048X2048",
}

/** The status of the media item object. */
export enum MediaItemStatusEnum {
  /** Objects with the auto-draft status */
  AutoDraft = "AUTO_DRAFT",
  /** Objects with the inherit status */
  Inherit = "INHERIT",
  /** Objects with the private status */
  Private = "PRIVATE",
  /** Objects with the trash status */
  Trash = "TRASH",
}

/** Connection between the MediaItem type and the Comment type */
export type MediaItemToCommentConnection = CommentConnection &
  Connection & {
    __typename?: "MediaItemToCommentConnection";
    /** Edges for the MediaItemToCommentConnection connection */
    edges: Array<MediaItemToCommentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Comment>;
    /** Information about pagination in a connection. */
    pageInfo: MediaItemToCommentConnectionPageInfo;
  };

/** An edge in a connection */
export type MediaItemToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: "MediaItemToCommentConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Comment;
  };

/** Page Info on the &quot;MediaItemToCommentConnection&quot; */
export type MediaItemToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "MediaItemToCommentConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the MediaItemToCommentConnection connection */
export type MediaItemToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars["String"]>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars["String"]>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars["String"]>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars["String"]>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars["ID"]>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars["String"]>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars["Int"]>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars["Int"]>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars["String"]>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars["String"]>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars["ID"]>;
};

/** Details of an available size for a media item */
export type MediaSize = {
  __typename?: "MediaSize";
  /** The filename of the referenced size */
  file?: Maybe<Scalars["String"]>;
  /** The filesize of the resource */
  fileSize?: Maybe<Scalars["Int"]>;
  /** The height of the referenced size */
  height?: Maybe<Scalars["String"]>;
  /** The mime type of the referenced size */
  mimeType?: Maybe<Scalars["String"]>;
  /** The referenced size name */
  name?: Maybe<Scalars["String"]>;
  /** The url of the referenced size */
  sourceUrl?: Maybe<Scalars["String"]>;
  /** The width of the referenced size */
  width?: Maybe<Scalars["String"]>;
};

/** Menus are the containers for navigation items. Menus can be assigned to menu locations, which are typically registered by the active theme. */
export type Menu = DatabaseIdentifier &
  Node & {
    __typename?: "Menu";
    /** The number of items in the menu */
    count?: Maybe<Scalars["Int"]>;
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** The globally unique identifier of the nav menu object. */
    id: Scalars["ID"];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** The locations a menu is assigned to */
    locations?: Maybe<Array<Maybe<MenuLocationEnum>>>;
    /**
     * WP ID of the nav menu.
     * @deprecated Deprecated in favor of the databaseId field
     */
    menuId?: Maybe<Scalars["Int"]>;
    /** Connection between the Menu type and the MenuItem type */
    menuItems?: Maybe<MenuToMenuItemConnection>;
    /** Display name of the menu. Equivalent to WP_Term-&gt;name. */
    name?: Maybe<Scalars["String"]>;
    /** The url friendly name of the menu. Equivalent to WP_Term-&gt;slug */
    slug?: Maybe<Scalars["String"]>;
  };

/** Menus are the containers for navigation items. Menus can be assigned to menu locations, which are typically registered by the active theme. */
export type MenuMenuItemsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuToMenuItemConnectionWhereArgs>;
};

/** Connection to Menu Nodes */
export type MenuConnection = {
  /** A list of edges (relational context) between RootQuery and connected Menu Nodes */
  edges: Array<MenuConnectionEdge>;
  /** A list of connected Menu Nodes */
  nodes: Array<Menu>;
  /** Information about pagination in a connection. */
  pageInfo: MenuConnectionPageInfo;
};

/** Edge between a Node and a connected Menu */
export type MenuConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected Menu Node */
  node: Menu;
};

/** Page Info on the connected MenuConnectionEdge */
export type MenuConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** Navigation menu items are the individual items assigned to a menu. These are rendered as the links in a navigation menu. */
export type MenuItem = DatabaseIdentifier &
  Node & {
    __typename?: "MenuItem";
    /** Connection between the MenuItem type and the MenuItem type */
    childItems?: Maybe<MenuItemToMenuItemConnection>;
    /** Connection from MenuItem to it&#039;s connected node */
    connectedNode?: Maybe<MenuItemToMenuItemLinkableConnectionEdge>;
    /**
     * The object connected to this menu item.
     * @deprecated Deprecated in favor of the connectedNode field
     */
    connectedObject?: Maybe<MenuItemObjectUnion>;
    /** Class attribute for the menu item link */
    cssClasses?: Maybe<Array<Maybe<Scalars["String"]>>>;
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** Description of the menu item. */
    description?: Maybe<Scalars["String"]>;
    /** The globally unique identifier of the nav menu item object. */
    id: Scalars["ID"];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** Label or title of the menu item. */
    label?: Maybe<Scalars["String"]>;
    /** Link relationship (XFN) of the menu item. */
    linkRelationship?: Maybe<Scalars["String"]>;
    /** The locations the menu item&#039;s Menu is assigned to */
    locations?: Maybe<Array<Maybe<MenuLocationEnum>>>;
    /** The Menu a MenuItem is part of */
    menu?: Maybe<MenuItemToMenuConnectionEdge>;
    /**
     * WP ID of the menu item.
     * @deprecated Deprecated in favor of the databaseId field
     */
    menuItemId?: Maybe<Scalars["Int"]>;
    /** Menu item order */
    order?: Maybe<Scalars["Int"]>;
    /** The database id of the parent menu item or null if it is the root */
    parentDatabaseId?: Maybe<Scalars["Int"]>;
    /** The globally unique identifier of the parent nav menu item object. */
    parentId?: Maybe<Scalars["ID"]>;
    /** Path for the resource. Relative path for internal resources. Absolute path for external resources. */
    path?: Maybe<Scalars["String"]>;
    /** Target attribute for the menu item link. */
    target?: Maybe<Scalars["String"]>;
    /** Title attribute for the menu item link */
    title?: Maybe<Scalars["String"]>;
    /** The uri of the resource the menu item links to */
    uri?: Maybe<Scalars["String"]>;
    /** URL or destination of the menu item. */
    url?: Maybe<Scalars["String"]>;
  };

/** Navigation menu items are the individual items assigned to a menu. These are rendered as the links in a navigation menu. */
export type MenuItemChildItemsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<MenuItemToMenuItemConnectionWhereArgs>;
};

/** Connection to MenuItem Nodes */
export type MenuItemConnection = {
  /** A list of edges (relational context) between RootQuery and connected MenuItem Nodes */
  edges: Array<MenuItemConnectionEdge>;
  /** A list of connected MenuItem Nodes */
  nodes: Array<MenuItem>;
  /** Information about pagination in a connection. */
  pageInfo: MenuItemConnectionPageInfo;
};

/** Edge between a Node and a connected MenuItem */
export type MenuItemConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected MenuItem Node */
  node: MenuItem;
};

/** Page Info on the connected MenuItemConnectionEdge */
export type MenuItemConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** Nodes that can be linked to as Menu Items */
export type MenuItemLinkable = {
  /** The unique identifier stored in the database */
  databaseId: Scalars["Int"];
  /** The unique resource identifier path */
  id: Scalars["ID"];
  /** Whether the node is a Content Node */
  isContentNode: Scalars["Boolean"];
  /** Whether the node is a Term */
  isTermNode: Scalars["Boolean"];
  /** The unique resource identifier path */
  uri?: Maybe<Scalars["String"]>;
};

/** Edge between a Node and a connected MenuItemLinkable */
export type MenuItemLinkableConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected MenuItemLinkable Node */
  node: MenuItemLinkable;
};

/** The Type of Identifier used to fetch a single node. Default is "ID". To be used along with the "id" field. */
export enum MenuItemNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  Id = "ID",
}

/** Deprecated in favor of MenuItemLinkeable Interface */
export type MenuItemObjectUnion =
  | Article
  | Category
  | Collection
  | ContentLanguages
  | ContentStatus
  | Country
  | CountryProfile
  | GlobalContent
  | HomePage
  | HowItWorksPage
  | Locale
  | Page
  | Post
  | PostFormat
  | Region
  | RegionProfile
  | Tag;

/** Connection between the MenuItem type and the Menu type */
export type MenuItemToMenuConnectionEdge = Edge &
  MenuConnectionEdge &
  OneToOneConnection & {
    __typename?: "MenuItemToMenuConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: Menu;
  };

/** Connection between the MenuItem type and the MenuItem type */
export type MenuItemToMenuItemConnection = Connection &
  MenuItemConnection & {
    __typename?: "MenuItemToMenuItemConnection";
    /** Edges for the MenuItemToMenuItemConnection connection */
    edges: Array<MenuItemToMenuItemConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<MenuItem>;
    /** Information about pagination in a connection. */
    pageInfo: MenuItemToMenuItemConnectionPageInfo;
  };

/** An edge in a connection */
export type MenuItemToMenuItemConnectionEdge = Edge &
  MenuItemConnectionEdge & {
    __typename?: "MenuItemToMenuItemConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: MenuItem;
  };

/** Page Info on the &quot;MenuItemToMenuItemConnection&quot; */
export type MenuItemToMenuItemConnectionPageInfo = MenuItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "MenuItemToMenuItemConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the MenuItemToMenuItemConnection connection */
export type MenuItemToMenuItemConnectionWhereArgs = {
  /** The database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: InputMaybe<Scalars["Int"]>;
  /** The ID of the parent menu object */
  parentId?: InputMaybe<Scalars["ID"]>;
};

/** Connection between the MenuItem type and the MenuItemLinkable type */
export type MenuItemToMenuItemLinkableConnectionEdge = Edge &
  MenuItemLinkableConnectionEdge &
  OneToOneConnection & {
    __typename?: "MenuItemToMenuItemLinkableConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: MenuItemLinkable;
  };

/** Registered menu locations */
export enum MenuLocationEnum {
  /** Empty menu location */
  Empty = "EMPTY",
}

/** The Type of Identifier used to fetch a single node. Default is "ID". To be used along with the "id" field. */
export enum MenuNodeIdTypeEnum {
  /** Identify a menu node by the Database ID. */
  DatabaseId = "DATABASE_ID",
  /** Identify a menu node by the (hashed) Global ID. */
  Id = "ID",
  /** Identify a menu node by the slug of menu location to which it is assigned */
  Location = "LOCATION",
  /** Identify a menu node by its name */
  Name = "NAME",
  /** Identify a menu node by its slug */
  Slug = "SLUG",
}

/** Connection between the Menu type and the MenuItem type */
export type MenuToMenuItemConnection = Connection &
  MenuItemConnection & {
    __typename?: "MenuToMenuItemConnection";
    /** Edges for the MenuToMenuItemConnection connection */
    edges: Array<MenuToMenuItemConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<MenuItem>;
    /** Information about pagination in a connection. */
    pageInfo: MenuToMenuItemConnectionPageInfo;
  };

/** An edge in a connection */
export type MenuToMenuItemConnectionEdge = Edge &
  MenuItemConnectionEdge & {
    __typename?: "MenuToMenuItemConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: MenuItem;
  };

/** Page Info on the &quot;MenuToMenuItemConnection&quot; */
export type MenuToMenuItemConnectionPageInfo = MenuItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "MenuToMenuItemConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the MenuToMenuItemConnection connection */
export type MenuToMenuItemConnectionWhereArgs = {
  /** The database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: InputMaybe<Scalars["Int"]>;
  /** The ID of the parent menu object */
  parentId?: InputMaybe<Scalars["ID"]>;
};

/** The MimeType of the object */
export enum MimeTypeEnum {
  /** application/java mime type. */
  ApplicationJava = "APPLICATION_JAVA",
  /** application/msword mime type. */
  ApplicationMsword = "APPLICATION_MSWORD",
  /** application/octet-stream mime type. */
  ApplicationOctetStream = "APPLICATION_OCTET_STREAM",
  /** application/onenote mime type. */
  ApplicationOnenote = "APPLICATION_ONENOTE",
  /** application/oxps mime type. */
  ApplicationOxps = "APPLICATION_OXPS",
  /** application/pdf mime type. */
  ApplicationPdf = "APPLICATION_PDF",
  /** application/rar mime type. */
  ApplicationRar = "APPLICATION_RAR",
  /** application/rtf mime type. */
  ApplicationRtf = "APPLICATION_RTF",
  /** application/ttaf+xml mime type. */
  ApplicationTtafXml = "APPLICATION_TTAF_XML",
  /** application/vnd.apple.keynote mime type. */
  ApplicationVndAppleKeynote = "APPLICATION_VND_APPLE_KEYNOTE",
  /** application/vnd.apple.numbers mime type. */
  ApplicationVndAppleNumbers = "APPLICATION_VND_APPLE_NUMBERS",
  /** application/vnd.apple.pages mime type. */
  ApplicationVndApplePages = "APPLICATION_VND_APPLE_PAGES",
  /** application/vnd.ms-access mime type. */
  ApplicationVndMsAccess = "APPLICATION_VND_MS_ACCESS",
  /** application/vnd.ms-excel mime type. */
  ApplicationVndMsExcel = "APPLICATION_VND_MS_EXCEL",
  /** application/vnd.ms-excel.addin.macroEnabled.12 mime type. */
  ApplicationVndMsExcelAddinMacroenabled_12 = "APPLICATION_VND_MS_EXCEL_ADDIN_MACROENABLED_12",
  /** application/vnd.ms-excel.sheet.binary.macroEnabled.12 mime type. */
  ApplicationVndMsExcelSheetBinaryMacroenabled_12 = "APPLICATION_VND_MS_EXCEL_SHEET_BINARY_MACROENABLED_12",
  /** application/vnd.ms-excel.sheet.macroEnabled.12 mime type. */
  ApplicationVndMsExcelSheetMacroenabled_12 = "APPLICATION_VND_MS_EXCEL_SHEET_MACROENABLED_12",
  /** application/vnd.ms-excel.template.macroEnabled.12 mime type. */
  ApplicationVndMsExcelTemplateMacroenabled_12 = "APPLICATION_VND_MS_EXCEL_TEMPLATE_MACROENABLED_12",
  /** application/vnd.ms-powerpoint mime type. */
  ApplicationVndMsPowerpoint = "APPLICATION_VND_MS_POWERPOINT",
  /** application/vnd.ms-powerpoint.addin.macroEnabled.12 mime type. */
  ApplicationVndMsPowerpointAddinMacroenabled_12 = "APPLICATION_VND_MS_POWERPOINT_ADDIN_MACROENABLED_12",
  /** application/vnd.ms-powerpoint.presentation.macroEnabled.12 mime type. */
  ApplicationVndMsPowerpointPresentationMacroenabled_12 = "APPLICATION_VND_MS_POWERPOINT_PRESENTATION_MACROENABLED_12",
  /** application/vnd.ms-powerpoint.slideshow.macroEnabled.12 mime type. */
  ApplicationVndMsPowerpointSlideshowMacroenabled_12 = "APPLICATION_VND_MS_POWERPOINT_SLIDESHOW_MACROENABLED_12",
  /** application/vnd.ms-powerpoint.slide.macroEnabled.12 mime type. */
  ApplicationVndMsPowerpointSlideMacroenabled_12 = "APPLICATION_VND_MS_POWERPOINT_SLIDE_MACROENABLED_12",
  /** application/vnd.ms-powerpoint.template.macroEnabled.12 mime type. */
  ApplicationVndMsPowerpointTemplateMacroenabled_12 = "APPLICATION_VND_MS_POWERPOINT_TEMPLATE_MACROENABLED_12",
  /** application/vnd.ms-project mime type. */
  ApplicationVndMsProject = "APPLICATION_VND_MS_PROJECT",
  /** application/vnd.ms-word.document.macroEnabled.12 mime type. */
  ApplicationVndMsWordDocumentMacroenabled_12 = "APPLICATION_VND_MS_WORD_DOCUMENT_MACROENABLED_12",
  /** application/vnd.ms-word.template.macroEnabled.12 mime type. */
  ApplicationVndMsWordTemplateMacroenabled_12 = "APPLICATION_VND_MS_WORD_TEMPLATE_MACROENABLED_12",
  /** application/vnd.ms-write mime type. */
  ApplicationVndMsWrite = "APPLICATION_VND_MS_WRITE",
  /** application/vnd.ms-xpsdocument mime type. */
  ApplicationVndMsXpsdocument = "APPLICATION_VND_MS_XPSDOCUMENT",
  /** application/vnd.oasis.opendocument.chart mime type. */
  ApplicationVndOasisOpendocumentChart = "APPLICATION_VND_OASIS_OPENDOCUMENT_CHART",
  /** application/vnd.oasis.opendocument.database mime type. */
  ApplicationVndOasisOpendocumentDatabase = "APPLICATION_VND_OASIS_OPENDOCUMENT_DATABASE",
  /** application/vnd.oasis.opendocument.formula mime type. */
  ApplicationVndOasisOpendocumentFormula = "APPLICATION_VND_OASIS_OPENDOCUMENT_FORMULA",
  /** application/vnd.oasis.opendocument.graphics mime type. */
  ApplicationVndOasisOpendocumentGraphics = "APPLICATION_VND_OASIS_OPENDOCUMENT_GRAPHICS",
  /** application/vnd.oasis.opendocument.presentation mime type. */
  ApplicationVndOasisOpendocumentPresentation = "APPLICATION_VND_OASIS_OPENDOCUMENT_PRESENTATION",
  /** application/vnd.oasis.opendocument.spreadsheet mime type. */
  ApplicationVndOasisOpendocumentSpreadsheet = "APPLICATION_VND_OASIS_OPENDOCUMENT_SPREADSHEET",
  /** application/vnd.oasis.opendocument.text mime type. */
  ApplicationVndOasisOpendocumentText = "APPLICATION_VND_OASIS_OPENDOCUMENT_TEXT",
  /** application/vnd.openxmlformats-officedocument.presentationml.presentation mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlPresentation = "APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_PRESENTATION",
  /** application/vnd.openxmlformats-officedocument.presentationml.slide mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlSlide = "APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDE",
  /** application/vnd.openxmlformats-officedocument.presentationml.slideshow mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlSlideshow = "APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDESHOW",
  /** application/vnd.openxmlformats-officedocument.presentationml.template mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlTemplate = "APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_TEMPLATE",
  /** application/vnd.openxmlformats-officedocument.spreadsheetml.sheet mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet = "APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET",
  /** application/vnd.openxmlformats-officedocument.spreadsheetml.template mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlTemplate = "APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_TEMPLATE",
  /** application/vnd.openxmlformats-officedocument.wordprocessingml.document mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument = "APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_DOCUMENT",
  /** application/vnd.openxmlformats-officedocument.wordprocessingml.template mime type. */
  ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlTemplate = "APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_TEMPLATE",
  /** application/wordperfect mime type. */
  ApplicationWordperfect = "APPLICATION_WORDPERFECT",
  /** application/x-7z-compressed mime type. */
  ApplicationX_7ZCompressed = "APPLICATION_X_7Z_COMPRESSED",
  /** application/x-gzip mime type. */
  ApplicationXGzip = "APPLICATION_X_GZIP",
  /** application/x-msdownload mime type. */
  ApplicationXMsdownload = "APPLICATION_X_MSDOWNLOAD",
  /** application/x-shockwave-flash mime type. */
  ApplicationXShockwaveFlash = "APPLICATION_X_SHOCKWAVE_FLASH",
  /** application/x-tar mime type. */
  ApplicationXTar = "APPLICATION_X_TAR",
  /** application/zip mime type. */
  ApplicationZip = "APPLICATION_ZIP",
  /** audio/aac mime type. */
  AudioAac = "AUDIO_AAC",
  /** audio/flac mime type. */
  AudioFlac = "AUDIO_FLAC",
  /** audio/midi mime type. */
  AudioMidi = "AUDIO_MIDI",
  /** audio/mpeg mime type. */
  AudioMpeg = "AUDIO_MPEG",
  /** audio/ogg mime type. */
  AudioOgg = "AUDIO_OGG",
  /** audio/wav mime type. */
  AudioWav = "AUDIO_WAV",
  /** audio/x-matroska mime type. */
  AudioXMatroska = "AUDIO_X_MATROSKA",
  /** audio/x-ms-wax mime type. */
  AudioXMsWax = "AUDIO_X_MS_WAX",
  /** audio/x-ms-wma mime type. */
  AudioXMsWma = "AUDIO_X_MS_WMA",
  /** audio/x-realaudio mime type. */
  AudioXRealaudio = "AUDIO_X_REALAUDIO",
  /** image/avif mime type. */
  ImageAvif = "IMAGE_AVIF",
  /** image/bmp mime type. */
  ImageBmp = "IMAGE_BMP",
  /** image/gif mime type. */
  ImageGif = "IMAGE_GIF",
  /** image/heic mime type. */
  ImageHeic = "IMAGE_HEIC",
  /** image/jpeg mime type. */
  ImageJpeg = "IMAGE_JPEG",
  /** image/png mime type. */
  ImagePng = "IMAGE_PNG",
  /** image/svg+xml mime type. */
  ImageSvgXml = "IMAGE_SVG_XML",
  /** image/tiff mime type. */
  ImageTiff = "IMAGE_TIFF",
  /** image/webp mime type. */
  ImageWebp = "IMAGE_WEBP",
  /** image/x-icon mime type. */
  ImageXIcon = "IMAGE_X_ICON",
  /** text/calendar mime type. */
  TextCalendar = "TEXT_CALENDAR",
  /** text/css mime type. */
  TextCss = "TEXT_CSS",
  /** text/csv mime type. */
  TextCsv = "TEXT_CSV",
  /** text/plain mime type. */
  TextPlain = "TEXT_PLAIN",
  /** text/richtext mime type. */
  TextRichtext = "TEXT_RICHTEXT",
  /** text/tab-separated-values mime type. */
  TextTabSeparatedValues = "TEXT_TAB_SEPARATED_VALUES",
  /** text/vtt mime type. */
  TextVtt = "TEXT_VTT",
  /** video/3gpp mime type. */
  Video_3Gpp = "VIDEO_3GPP",
  /** video/3gpp2 mime type. */
  Video_3Gpp2 = "VIDEO_3GPP2",
  /** video/avi mime type. */
  VideoAvi = "VIDEO_AVI",
  /** video/divx mime type. */
  VideoDivx = "VIDEO_DIVX",
  /** video/mp4 mime type. */
  VideoMp4 = "VIDEO_MP4",
  /** video/mpeg mime type. */
  VideoMpeg = "VIDEO_MPEG",
  /** video/ogg mime type. */
  VideoOgg = "VIDEO_OGG",
  /** video/quicktime mime type. */
  VideoQuicktime = "VIDEO_QUICKTIME",
  /** video/webm mime type. */
  VideoWebm = "VIDEO_WEBM",
  /** video/x-flv mime type. */
  VideoXFlv = "VIDEO_X_FLV",
  /** video/x-matroska mime type. */
  VideoXMatroska = "VIDEO_X_MATROSKA",
  /** video/x-ms-asf mime type. */
  VideoXMsAsf = "VIDEO_X_MS_ASF",
  /** video/x-ms-wm mime type. */
  VideoXMsWm = "VIDEO_X_MS_WM",
  /** video/x-ms-wmv mime type. */
  VideoXMsWmv = "VIDEO_X_MS_WMV",
  /** video/x-ms-wmx mime type. */
  VideoXMsWmx = "VIDEO_X_MS_WMX",
}

/** An object with an ID */
export type Node = {
  /** The globally unique ID for the object */
  id: Scalars["ID"];
};

/** A node that can have an author assigned to it */
export type NodeWithAuthor = {
  /** Connection between the NodeWithAuthor type and the User type */
  author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
  /** The database identifier of the author of the node */
  authorDatabaseId?: Maybe<Scalars["Int"]>;
  /** The globally unique identifier of the author of the node */
  authorId?: Maybe<Scalars["ID"]>;
  /** The globally unique ID for the object */
  id: Scalars["ID"];
};

/** Connection between the NodeWithAuthor type and the User type */
export type NodeWithAuthorToUserConnectionEdge = Edge &
  OneToOneConnection &
  UserConnectionEdge & {
    __typename?: "NodeWithAuthorToUserConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: User;
  };

/** A node that can have comments associated with it */
export type NodeWithComments = {
  /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
  commentCount?: Maybe<Scalars["Int"]>;
  /** Whether the comments are open or closed for this particular post. */
  commentStatus?: Maybe<Scalars["String"]>;
  /** The globally unique ID for the object */
  id: Scalars["ID"];
};

/** A node that supports the content editor */
export type NodeWithContentEditor = {
  /** The content of the post. */
  content?: Maybe<Scalars["String"]>;
  /** The globally unique ID for the object */
  id: Scalars["ID"];
};

/** A node that supports the content editor */
export type NodeWithContentEditorContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A node that can have an excerpt */
export type NodeWithExcerpt = {
  /** The excerpt of the post. */
  excerpt?: Maybe<Scalars["String"]>;
  /** The globally unique ID for the object */
  id: Scalars["ID"];
};

/** A node that can have an excerpt */
export type NodeWithExcerptExcerptArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A node that can have a featured image set */
export type NodeWithFeaturedImage = {
  /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
  featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
  /** The database identifier for the featured image node assigned to the content node */
  featuredImageDatabaseId?: Maybe<Scalars["Int"]>;
  /** Globally unique ID of the featured image assigned to the node */
  featuredImageId?: Maybe<Scalars["ID"]>;
  /** The globally unique ID for the object */
  id: Scalars["ID"];
};

/** Connection between the NodeWithFeaturedImage type and the MediaItem type */
export type NodeWithFeaturedImageToMediaItemConnectionEdge = Edge &
  MediaItemConnectionEdge &
  OneToOneConnection & {
    __typename?: "NodeWithFeaturedImageToMediaItemConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: MediaItem;
  };

/** A node that can have page attributes */
export type NodeWithPageAttributes = {
  /** The globally unique ID for the object */
  id: Scalars["ID"];
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: Maybe<Scalars["Int"]>;
};

/** A node that can have revisions */
export type NodeWithRevisions = {
  /** The globally unique ID for the object */
  id: Scalars["ID"];
  /** True if the node is a revision of another node */
  isRevision?: Maybe<Scalars["Boolean"]>;
  /** If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node. */
  revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
};

/** Connection between the NodeWithRevisions type and the ContentNode type */
export type NodeWithRevisionsToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge &
  OneToOneConnection & {
    __typename?: "NodeWithRevisionsToContentNodeConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: ContentNode;
  };

/** A node that can have a template associated with it */
export type NodeWithTemplate = {
  /** The globally unique ID for the object */
  id: Scalars["ID"];
  /** The template assigned to the node */
  template?: Maybe<ContentTemplate>;
};

/** A node that NodeWith a title */
export type NodeWithTitle = {
  /** The globally unique ID for the object */
  id: Scalars["ID"];
  /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
  title?: Maybe<Scalars["String"]>;
};

/** A node that NodeWith a title */
export type NodeWithTitleTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** A node that can have trackbacks and pingbacks */
export type NodeWithTrackbacks = {
  /** The globally unique ID for the object */
  id: Scalars["ID"];
  /** Whether the pings are open or closed for this particular post. */
  pingStatus?: Maybe<Scalars["String"]>;
  /** URLs that have been pinged. */
  pinged?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** URLs queued to be pinged. */
  toPing?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

/** A singular connection from one Node to another, with support for relational data on the &quot;edge&quot; of the connection. */
export type OneToOneConnection = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected node */
  node: Node;
};

/** The cardinality of the connection order */
export enum OrderEnum {
  /** Sort the query result set in an ascending order */
  Asc = "ASC",
  /** Sort the query result set in a descending order */
  Desc = "DESC",
}

/** The page type */
export type Page = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  HierarchicalNode &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithComments &
  NodeWithContentEditor &
  NodeWithFeaturedImage &
  NodeWithPageAttributes &
  NodeWithRevisions &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: "Page";
    /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars["Int"]>;
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars["ID"]>;
    /** Connection between the HierarchicalContentNode type and the ContentNode type */
    children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
    /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
    commentCount?: Maybe<Scalars["Int"]>;
    /** Whether the comments are open or closed for this particular post. */
    commentStatus?: Maybe<Scalars["String"]>;
    /** Connection between the Page type and the Comment type */
    comments?: Maybe<PageToCommentConnection>;
    /** The content of the post. */
    content?: Maybe<Scalars["String"]>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars["String"];
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** Post publishing date. */
    date?: Maybe<Scalars["String"]>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars["String"]>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars["String"]>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars["String"]>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars["Int"]>;
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars["ID"]>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars["String"]>;
    /** The globally unique identifier of the page object. */
    id: Scalars["ID"];
    /** Whether the node is a Content Node */
    isContentNode: Scalars["Boolean"];
    /** Whether this page is set to the static front page. */
    isFrontPage: Scalars["Boolean"];
    /** Whether this page is set to the blog posts page. */
    isPostsPage: Scalars["Boolean"];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars["Boolean"]>;
    /** Whether this page is set to the privacy page. */
    isPrivacyPage: Scalars["Boolean"];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** True if the node is a revision of another node */
    isRevision?: Maybe<Scalars["Boolean"]>;
    /** Whether the node is a Term */
    isTermNode: Scalars["Boolean"];
    /** Polylang language */
    language?: Maybe<Language>;
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars["String"]>;
    /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
    menuOrder?: Maybe<Scalars["Int"]>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars["String"]>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars["String"]>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    pageId: Scalars["Int"];
    /** The parent of the node. The parent object can be of various types */
    parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars["Int"]>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars["ID"]>;
    /** Connection between the Page type and the page type */
    preview?: Maybe<PageToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars["Int"]>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars["ID"]>;
    /** If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node. */
    revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
    /** Connection between the Page type and the page type */
    revisions?: Maybe<PageToRevisionConnection>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars["String"]>;
    /** The current status of the object */
    status?: Maybe<Scalars["String"]>;
    /** The template assigned to a node of content */
    template?: Maybe<ContentTemplate>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars["String"]>;
    /** Get specific translation version of this object */
    translation?: Maybe<Page>;
    /** List all translated versions of this post */
    translations?: Maybe<Array<Maybe<Page>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars["String"]>;
  };

/** The page type */
export type PageAncestorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

/** The page type */
export type PageChildrenArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** The page type */
export type PageCommentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PageToCommentConnectionWhereArgs>;
};

/** The page type */
export type PageContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The page type */
export type PageEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The page type */
export type PageEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The page type */
export type PageRevisionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PageToRevisionConnectionWhereArgs>;
};

/** The page type */
export type PageTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The page type */
export type PageTranslationArgs = {
  language: LanguageCodeEnum;
};

/** Connection to page Nodes */
export type PageConnection = {
  /** A list of edges (relational context) between RootQuery and connected page Nodes */
  edges: Array<PageConnectionEdge>;
  /** A list of connected page Nodes */
  nodes: Array<Page>;
  /** Information about pagination in a connection. */
  pageInfo: PageConnectionPageInfo;
};

/** Edge between a Node and a connected page */
export type PageConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected page Node */
  node: Page;
};

/** Page Info on the connected PageConnectionEdge */
export type PageConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PageIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  Id = "ID",
  /** Identify a resource by the URI. */
  Uri = "URI",
}

/** Information about pagination in a connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** Connection between the Page type and the Comment type */
export type PageToCommentConnection = CommentConnection &
  Connection & {
    __typename?: "PageToCommentConnection";
    /** Edges for the PageToCommentConnection connection */
    edges: Array<PageToCommentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Comment>;
    /** Information about pagination in a connection. */
    pageInfo: PageToCommentConnectionPageInfo;
  };

/** An edge in a connection */
export type PageToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: "PageToCommentConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Comment;
  };

/** Page Info on the &quot;PageToCommentConnection&quot; */
export type PageToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "PageToCommentConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the PageToCommentConnection connection */
export type PageToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars["String"]>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars["String"]>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars["String"]>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars["String"]>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars["ID"]>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars["String"]>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars["Int"]>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars["Int"]>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars["String"]>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars["String"]>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars["ID"]>;
};

/** Connection between the Page type and the page type */
export type PageToPreviewConnectionEdge = Edge &
  OneToOneConnection &
  PageConnectionEdge & {
    __typename?: "PageToPreviewConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: Page;
  };

/** Connection between the Page type and the page type */
export type PageToRevisionConnection = Connection &
  PageConnection & {
    __typename?: "PageToRevisionConnection";
    /** Edges for the PageToRevisionConnection connection */
    edges: Array<PageToRevisionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Page>;
    /** Information about pagination in a connection. */
    pageInfo: PageToRevisionConnectionPageInfo;
  };

/** An edge in a connection */
export type PageToRevisionConnectionEdge = Edge &
  PageConnectionEdge & {
    __typename?: "PageToRevisionConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Page;
  };

/** Page Info on the &quot;PageToRevisionConnection&quot; */
export type PageToRevisionConnectionPageInfo = PageConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "PageToRevisionConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the PageToRevisionConnection connection */
export type PageToRevisionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** An plugin object */
export type Plugin = Node & {
  __typename?: "Plugin";
  /** Name of the plugin author(s), may also be a company name. */
  author?: Maybe<Scalars["String"]>;
  /** URI for the related author(s)/company website. */
  authorUri?: Maybe<Scalars["String"]>;
  /** Description of the plugin. */
  description?: Maybe<Scalars["String"]>;
  /** The globally unique identifier of the plugin object. */
  id: Scalars["ID"];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars["Boolean"]>;
  /** Display name of the plugin. */
  name?: Maybe<Scalars["String"]>;
  /** Plugin path. */
  path?: Maybe<Scalars["String"]>;
  /** URI for the plugin website. This is useful for directing users for support requests etc. */
  pluginUri?: Maybe<Scalars["String"]>;
  /** Current version of the plugin. */
  version?: Maybe<Scalars["String"]>;
};

/** Connection to Plugin Nodes */
export type PluginConnection = {
  /** A list of edges (relational context) between RootQuery and connected Plugin Nodes */
  edges: Array<PluginConnectionEdge>;
  /** A list of connected Plugin Nodes */
  nodes: Array<Plugin>;
  /** Information about pagination in a connection. */
  pageInfo: PluginConnectionPageInfo;
};

/** Edge between a Node and a connected Plugin */
export type PluginConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected Plugin Node */
  node: Plugin;
};

/** Page Info on the connected PluginConnectionEdge */
export type PluginConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** The status of the WordPress plugin. */
export enum PluginStatusEnum {
  /** The plugin is currently active. */
  Active = "ACTIVE",
  /** The plugin is a drop-in plugin. */
  DropIn = "DROP_IN",
  /** The plugin is currently inactive. */
  Inactive = "INACTIVE",
  /** The plugin is a must-use plugin. */
  MustUse = "MUST_USE",
  /** The plugin is technically active but was paused while loading. */
  Paused = "PAUSED",
  /** The plugin was active recently. */
  RecentlyActive = "RECENTLY_ACTIVE",
  /** The plugin has an upgrade available. */
  Upgrade = "UPGRADE",
}

/** The post type */
export type Post = ContentNode &
  DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  NodeWithAuthor &
  NodeWithComments &
  NodeWithContentEditor &
  NodeWithExcerpt &
  NodeWithFeaturedImage &
  NodeWithRevisions &
  NodeWithTemplate &
  NodeWithTitle &
  NodeWithTrackbacks &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: "Post";
    /** Connection between the NodeWithAuthor type and the User type */
    author?: Maybe<NodeWithAuthorToUserConnectionEdge>;
    /** The database identifier of the author of the node */
    authorDatabaseId?: Maybe<Scalars["Int"]>;
    /** The globally unique identifier of the author of the node */
    authorId?: Maybe<Scalars["ID"]>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Component | Billboard&quot; was set to Show in GraphQL. */
    billboard?: Maybe<Post_Billboard>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Component | BlogArticles&quot; was set to Show in GraphQL. */
    blogarticles?: Maybe<Post_Blogarticles>;
    /** Connection between the Post type and the category type */
    categories?: Maybe<PostToCategoryConnection>;
    /** The number of comments. Even though WPGraphQL denotes this field as an integer, in WordPress this field should be saved as a numeric string for compatibility. */
    commentCount?: Maybe<Scalars["Int"]>;
    /** Whether the comments are open or closed for this particular post. */
    commentStatus?: Maybe<Scalars["String"]>;
    /** Connection between the Post type and the Comment type */
    comments?: Maybe<PostToCommentConnection>;
    /** The content of the post. */
    content?: Maybe<Scalars["String"]>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars["String"];
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** Post publishing date. */
    date?: Maybe<Scalars["String"]>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars["String"]>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars["String"]>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars["String"]>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** The excerpt of the post. */
    excerpt?: Maybe<Scalars["String"]>;
    /** Connection between the NodeWithFeaturedImage type and the MediaItem type */
    featuredImage?: Maybe<NodeWithFeaturedImageToMediaItemConnectionEdge>;
    /** The database identifier for the featured image node assigned to the content node */
    featuredImageDatabaseId?: Maybe<Scalars["Int"]>;
    /** Globally unique ID of the featured image assigned to the node */
    featuredImageId?: Maybe<Scalars["ID"]>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars["String"]>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Component | HomeHero&quot; was set to Show in GraphQL. */
    homehero?: Maybe<Post_Homehero>;
    /** The globally unique identifier of the post object. */
    id: Scalars["ID"];
    /** Whether the node is a Content Node */
    isContentNode: Scalars["Boolean"];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars["Boolean"]>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** True if the node is a revision of another node */
    isRevision?: Maybe<Scalars["Boolean"]>;
    /** Whether this page is sticky */
    isSticky: Scalars["Boolean"];
    /** Whether the node is a Term */
    isTermNode: Scalars["Boolean"];
    /** Polylang language */
    language?: Maybe<Language>;
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars["String"]>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Meta&quot; was set to Show in GraphQL. */
    meta?: Maybe<Post_Meta>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Component | MetricHighlightGroup&quot; was set to Show in GraphQL. */
    metricHighlightGroup?: Maybe<Post_Metrichighlightgroup>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Component | MetricHighlight&quot; was set to Show in GraphQL. */
    metrichighlight?: Maybe<Post_Metrichighlight>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars["String"]>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars["String"]>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Component | PartnerNotice&quot; was set to Show in GraphQL. */
    partnernotice?: Maybe<Post_Partnernotice>;
    /** Whether the pings are open or closed for this particular post. */
    pingStatus?: Maybe<Scalars["String"]>;
    /** URLs that have been pinged. */
    pinged?: Maybe<Array<Maybe<Scalars["String"]>>>;
    /** Connection between the Post type and the postFormat type */
    postFormats?: Maybe<PostToPostFormatConnection>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    postId: Scalars["Int"];
    /** Connection between the Post type and the post type */
    preview?: Maybe<PostToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars["Int"]>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars["ID"]>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Component | PropositionPullout&quot; was set to Show in GraphQL. */
    propositionpullout?: Maybe<Post_Propositionpullout>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Component | ReviewCarousel&quot; was set to Show in GraphQL. */
    reviewslist?: Maybe<Post_Reviewslist>;
    /** If the current node is a revision, this field exposes the node this is a revision of. Returns null if the node is not a revision of another node. */
    revisionOf?: Maybe<NodeWithRevisionsToContentNodeConnectionEdge>;
    /** Connection between the Post type and the post type */
    revisions?: Maybe<PostToRevisionConnection>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars["String"]>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Component | StandardHeader&quot; was set to Show in GraphQL. */
    standardheader?: Maybe<Post_Standardheader>;
    /** The current status of the object */
    status?: Maybe<Scalars["String"]>;
    /** Connection between the Post type and the tag type */
    tags?: Maybe<PostToTagConnection>;
    /** The template assigned to the node */
    template?: Maybe<ContentTemplate>;
    /** Connection between the Post type and the TermNode type */
    terms?: Maybe<PostToTermNodeConnection>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars["String"]>;
    /** URLs queued to be pinged. */
    toPing?: Maybe<Array<Maybe<Scalars["String"]>>>;
    /** Get specific translation version of this object */
    translation?: Maybe<Post>;
    /** List all translated versions of this post */
    translations?: Maybe<Array<Maybe<Post>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars["String"]>;
  };

/** The post type */
export type PostCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PostToCategoryConnectionWhereArgs>;
};

/** The post type */
export type PostCommentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PostToCommentConnectionWhereArgs>;
};

/** The post type */
export type PostContentArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The post type */
export type PostEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The post type */
export type PostEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The post type */
export type PostExcerptArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The post type */
export type PostPostFormatsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PostToPostFormatConnectionWhereArgs>;
};

/** The post type */
export type PostRevisionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PostToRevisionConnectionWhereArgs>;
};

/** The post type */
export type PostTagsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PostToTagConnectionWhereArgs>;
};

/** The post type */
export type PostTermsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PostToTermNodeConnectionWhereArgs>;
};

/** The post type */
export type PostTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** The post type */
export type PostTranslationArgs = {
  language: LanguageCodeEnum;
};

/** Set relationships between the post to categories */
export type PostCategoriesInput = {
  /** If true, this will append the category to existing related categories. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PostCategoriesNodeInput>>>;
};

/** List of categories to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PostCategoriesNodeInput = {
  /** The description of the category. This field is used to set a description of the category if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the category. If present, this will be used to connect to the post. If no existing category exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the category. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the category. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Connection to post Nodes */
export type PostConnection = {
  /** A list of edges (relational context) between RootQuery and connected post Nodes */
  edges: Array<PostConnectionEdge>;
  /** A list of connected post Nodes */
  nodes: Array<Post>;
  /** Information about pagination in a connection. */
  pageInfo: PostConnectionPageInfo;
};

/** Edge between a Node and a connected post */
export type PostConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected post Node */
  node: Post;
};

/** Page Info on the connected PostConnectionEdge */
export type PostConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** The postFormat type */
export type PostFormat = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: "PostFormat";
    /** Connection between the PostFormat type and the ContentNode type */
    contentNodes?: Maybe<PostFormatToContentNodeConnection>;
    /** The number of objects connected to the object */
    count?: Maybe<Scalars["Int"]>;
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** The description of the object */
    description?: Maybe<Scalars["String"]>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The unique resource identifier path */
    id: Scalars["ID"];
    /** Whether the node is a Content Node */
    isContentNode: Scalars["Boolean"];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** Whether the node is a Term */
    isTermNode: Scalars["Boolean"];
    /** The link to the term */
    link?: Maybe<Scalars["String"]>;
    /** The human friendly name of the object. */
    name?: Maybe<Scalars["String"]>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    postFormatId?: Maybe<Scalars["Int"]>;
    /** Connection between the PostFormat type and the post type */
    posts?: Maybe<PostFormatToPostConnection>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars["String"]>;
    /** Connection between the PostFormat type and the Taxonomy type */
    taxonomy?: Maybe<PostFormatToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars["String"]>;
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars["Int"]>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars["Int"]>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars["String"]>;
  };

/** The postFormat type */
export type PostFormatContentNodesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PostFormatToContentNodeConnectionWhereArgs>;
};

/** The postFormat type */
export type PostFormatEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The postFormat type */
export type PostFormatEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The postFormat type */
export type PostFormatPostsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PostFormatToPostConnectionWhereArgs>;
};

/** Connection to postFormat Nodes */
export type PostFormatConnection = {
  /** A list of edges (relational context) between RootQuery and connected postFormat Nodes */
  edges: Array<PostFormatConnectionEdge>;
  /** A list of connected postFormat Nodes */
  nodes: Array<PostFormat>;
  /** Information about pagination in a connection. */
  pageInfo: PostFormatConnectionPageInfo;
};

/** Edge between a Node and a connected postFormat */
export type PostFormatConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected postFormat Node */
  node: PostFormat;
};

/** Page Info on the connected PostFormatConnectionEdge */
export type PostFormatConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PostFormatIdType {
  /** The Database ID for the node */
  DatabaseId = "DATABASE_ID",
  /** The hashed Global ID */
  Id = "ID",
  /** The name of the node */
  Name = "NAME",
  /** Url friendly name of the node */
  Slug = "SLUG",
  /** The URI for the node */
  Uri = "URI",
}

/** Connection between the PostFormat type and the ContentNode type */
export type PostFormatToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: "PostFormatToContentNodeConnection";
    /** Edges for the PostFormatToContentNodeConnection connection */
    edges: Array<PostFormatToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: PostFormatToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type PostFormatToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: "PostFormatToContentNodeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;PostFormatToContentNodeConnection&quot; */
export type PostFormatToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "PostFormatToContentNodeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the PostFormatToContentNodeConnection connection */
export type PostFormatToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfPostFormatEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the PostFormat type and the post type */
export type PostFormatToPostConnection = Connection &
  PostConnection & {
    __typename?: "PostFormatToPostConnection";
    /** Edges for the PostFormatToPostConnection connection */
    edges: Array<PostFormatToPostConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Post>;
    /** Information about pagination in a connection. */
    pageInfo: PostFormatToPostConnectionPageInfo;
  };

/** An edge in a connection */
export type PostFormatToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: "PostFormatToPostConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Post;
  };

/** Page Info on the &quot;PostFormatToPostConnection&quot; */
export type PostFormatToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: "PostFormatToPostConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the PostFormatToPostConnection connection */
export type PostFormatToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars["Int"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars["String"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the PostFormat type and the Taxonomy type */
export type PostFormatToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: "PostFormatToTaxonomyConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PostIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  Id = "ID",
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = "SLUG",
  /** Identify a resource by the URI. */
  Uri = "URI",
}

/** The format of post field data. */
export enum PostObjectFieldFormatEnum {
  /** Provide the field value directly from database. Null on unauthenticated requests. */
  Raw = "RAW",
  /** Provide the field value as rendered by WordPress. Default. */
  Rendered = "RENDERED",
}

/** The column to use when filtering by date */
export enum PostObjectsConnectionDateColumnEnum {
  /** The date the comment was created in local time. */
  Date = "DATE",
  /** The most recent modification date of the comment. */
  Modified = "MODIFIED",
}

/** Field to order the connection by */
export enum PostObjectsConnectionOrderbyEnum {
  /** Order by author */
  Author = "AUTHOR",
  /** Order by the number of comments it has acquired */
  CommentCount = "COMMENT_COUNT",
  /** Order by publish date */
  Date = "DATE",
  /** Preserve the ID order given in the IN array */
  In = "IN",
  /** Order by the menu order value */
  MenuOrder = "MENU_ORDER",
  /** Order by last modified date */
  Modified = "MODIFIED",
  /** Preserve slug order given in the NAME_IN array */
  NameIn = "NAME_IN",
  /** Order by parent ID */
  Parent = "PARENT",
  /** Order by slug */
  Slug = "SLUG",
  /** Order by title */
  Title = "TITLE",
}

/** Options for ordering the connection */
export type PostObjectsConnectionOrderbyInput = {
  /** The field to order the connection by */
  field: PostObjectsConnectionOrderbyEnum;
  /** Possible directions in which to order a list of items */
  order: OrderEnum;
};

/** Set relationships between the post to postFormats */
export type PostPostFormatsInput = {
  /** If true, this will append the postFormat to existing related postFormats. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PostPostFormatsNodeInput>>>;
};

/** List of postFormats to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PostPostFormatsNodeInput = {
  /** The description of the postFormat. This field is used to set a description of the postFormat if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the postFormat. If present, this will be used to connect to the post. If no existing postFormat exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the postFormat. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the postFormat. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The status of the object. */
export enum PostStatusEnum {
  /** Objects with the acf-disabled status */
  AcfDisabled = "ACF_DISABLED",
  /** Objects with the auto-draft status */
  AutoDraft = "AUTO_DRAFT",
  /** Objects with the draft status */
  Draft = "DRAFT",
  /** Objects with the future status */
  Future = "FUTURE",
  /** Objects with the inherit status */
  Inherit = "INHERIT",
  /** Objects with the pending status */
  Pending = "PENDING",
  /** Objects with the private status */
  Private = "PRIVATE",
  /** Objects with the publish status */
  Publish = "PUBLISH",
  /** Objects with the request-completed status */
  RequestCompleted = "REQUEST_COMPLETED",
  /** Objects with the request-confirmed status */
  RequestConfirmed = "REQUEST_CONFIRMED",
  /** Objects with the request-failed status */
  RequestFailed = "REQUEST_FAILED",
  /** Objects with the request-pending status */
  RequestPending = "REQUEST_PENDING",
  /** Objects with the trash status */
  Trash = "TRASH",
}

/** Set relationships between the post to tags */
export type PostTagsInput = {
  /** If true, this will append the tag to existing related tags. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<PostTagsNodeInput>>>;
};

/** List of tags to connect the post to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type PostTagsNodeInput = {
  /** The description of the tag. This field is used to set a description of the tag if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the tag. If present, this will be used to connect to the post. If no existing tag exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the tag. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the tag. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Post type and the category type */
export type PostToCategoryConnection = CategoryConnection &
  Connection & {
    __typename?: "PostToCategoryConnection";
    /** Edges for the PostToCategoryConnection connection */
    edges: Array<PostToCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Category>;
    /** Information about pagination in a connection. */
    pageInfo: PostToCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type PostToCategoryConnectionEdge = CategoryConnectionEdge &
  Edge & {
    __typename?: "PostToCategoryConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Category;
  };

/** Page Info on the &quot;PostToCategoryConnection&quot; */
export type PostToCategoryConnectionPageInfo = CategoryConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "PostToCategoryConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the PostToCategoryConnection connection */
export type PostToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the Post type and the Comment type */
export type PostToCommentConnection = CommentConnection &
  Connection & {
    __typename?: "PostToCommentConnection";
    /** Edges for the PostToCommentConnection connection */
    edges: Array<PostToCommentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Comment>;
    /** Information about pagination in a connection. */
    pageInfo: PostToCommentConnectionPageInfo;
  };

/** An edge in a connection */
export type PostToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: "PostToCommentConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Comment;
  };

/** Page Info on the &quot;PostToCommentConnection&quot; */
export type PostToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "PostToCommentConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the PostToCommentConnection connection */
export type PostToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars["String"]>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars["String"]>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars["String"]>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars["String"]>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars["ID"]>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars["String"]>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars["Int"]>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars["Int"]>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars["String"]>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars["String"]>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars["ID"]>;
};

/** Connection between the Post type and the postFormat type */
export type PostToPostFormatConnection = Connection &
  PostFormatConnection & {
    __typename?: "PostToPostFormatConnection";
    /** Edges for the PostToPostFormatConnection connection */
    edges: Array<PostToPostFormatConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<PostFormat>;
    /** Information about pagination in a connection. */
    pageInfo: PostToPostFormatConnectionPageInfo;
  };

/** An edge in a connection */
export type PostToPostFormatConnectionEdge = Edge &
  PostFormatConnectionEdge & {
    __typename?: "PostToPostFormatConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: PostFormat;
  };

/** Page Info on the &quot;PostToPostFormatConnection&quot; */
export type PostToPostFormatConnectionPageInfo = PageInfo &
  PostFormatConnectionPageInfo &
  WpPageInfo & {
    __typename?: "PostToPostFormatConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the PostToPostFormatConnection connection */
export type PostToPostFormatConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the Post type and the post type */
export type PostToPreviewConnectionEdge = Edge &
  OneToOneConnection &
  PostConnectionEdge & {
    __typename?: "PostToPreviewConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: Post;
  };

/** Connection between the Post type and the post type */
export type PostToRevisionConnection = Connection &
  PostConnection & {
    __typename?: "PostToRevisionConnection";
    /** Edges for the PostToRevisionConnection connection */
    edges: Array<PostToRevisionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Post>;
    /** Information about pagination in a connection. */
    pageInfo: PostToRevisionConnectionPageInfo;
  };

/** An edge in a connection */
export type PostToRevisionConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: "PostToRevisionConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Post;
  };

/** Page Info on the &quot;PostToRevisionConnection&quot; */
export type PostToRevisionConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: "PostToRevisionConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the PostToRevisionConnection connection */
export type PostToRevisionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars["Int"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars["String"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Post type and the tag type */
export type PostToTagConnection = Connection &
  TagConnection & {
    __typename?: "PostToTagConnection";
    /** Edges for the PostToTagConnection connection */
    edges: Array<PostToTagConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Tag>;
    /** Information about pagination in a connection. */
    pageInfo: PostToTagConnectionPageInfo;
  };

/** An edge in a connection */
export type PostToTagConnectionEdge = Edge &
  TagConnectionEdge & {
    __typename?: "PostToTagConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Tag;
  };

/** Page Info on the &quot;PostToTagConnection&quot; */
export type PostToTagConnectionPageInfo = PageInfo &
  TagConnectionPageInfo &
  WpPageInfo & {
    __typename?: "PostToTagConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the PostToTagConnection connection */
export type PostToTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the Post type and the TermNode type */
export type PostToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: "PostToTermNodeConnection";
    /** Edges for the PostToTermNodeConnection connection */
    edges: Array<PostToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: PostToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type PostToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: "PostToTermNodeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;PostToTermNodeConnection&quot; */
export type PostToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: "PostToTermNodeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the PostToTermNodeConnection connection */
export type PostToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Details for labels of the PostType */
export type PostTypeLabelDetails = {
  __typename?: "PostTypeLabelDetails";
  /** Default is ‘Add New’ for both hierarchical and non-hierarchical types. */
  addNew?: Maybe<Scalars["String"]>;
  /** Label for adding a new singular item. */
  addNewItem?: Maybe<Scalars["String"]>;
  /** Label to signify all items in a submenu link. */
  allItems?: Maybe<Scalars["String"]>;
  /** Label for archives in nav menus */
  archives?: Maybe<Scalars["String"]>;
  /** Label for the attributes meta box. */
  attributes?: Maybe<Scalars["String"]>;
  /** Label for editing a singular item. */
  editItem?: Maybe<Scalars["String"]>;
  /** Label for the Featured Image meta box title. */
  featuredImage?: Maybe<Scalars["String"]>;
  /** Label for the table views hidden heading. */
  filterItemsList?: Maybe<Scalars["String"]>;
  /** Label for the media frame button. */
  insertIntoItem?: Maybe<Scalars["String"]>;
  /** Label for the table hidden heading. */
  itemsList?: Maybe<Scalars["String"]>;
  /** Label for the table pagination hidden heading. */
  itemsListNavigation?: Maybe<Scalars["String"]>;
  /** Label for the menu name. */
  menuName?: Maybe<Scalars["String"]>;
  /** General name for the post type, usually plural. */
  name?: Maybe<Scalars["String"]>;
  /** Label for the new item page title. */
  newItem?: Maybe<Scalars["String"]>;
  /** Label used when no items are found. */
  notFound?: Maybe<Scalars["String"]>;
  /** Label used when no items are in the trash. */
  notFoundInTrash?: Maybe<Scalars["String"]>;
  /** Label used to prefix parents of hierarchical items. */
  parentItemColon?: Maybe<Scalars["String"]>;
  /** Label for removing the featured image. */
  removeFeaturedImage?: Maybe<Scalars["String"]>;
  /** Label for searching plural items. */
  searchItems?: Maybe<Scalars["String"]>;
  /** Label for setting the featured image. */
  setFeaturedImage?: Maybe<Scalars["String"]>;
  /** Name for one object of this post type. */
  singularName?: Maybe<Scalars["String"]>;
  /** Label for the media frame filter. */
  uploadedToThisItem?: Maybe<Scalars["String"]>;
  /** Label in the media frame for using a featured image. */
  useFeaturedImage?: Maybe<Scalars["String"]>;
  /** Label for viewing a singular item. */
  viewItem?: Maybe<Scalars["String"]>;
  /** Label for viewing post type archives. */
  viewItems?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type Post_Billboard = AcfFieldGroup & {
  __typename?: "Post_Billboard";
  /** Optional text that appears over the image. */
  billboardCaption?: Maybe<Scalars["String"]>;
  billboardContent?: Maybe<Scalars["String"]>;
  billboardCta?: Maybe<AcfLink>;
  billboardImage?: Maybe<MediaItem>;
  billboardImageside?: Maybe<Scalars["String"]>;
  billboardStyle?: Maybe<Scalars["String"]>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type Post_Blogarticles = AcfFieldGroup & {
  __typename?: "Post_Blogarticles";
  articlesArticles?: Maybe<Array<Maybe<Post_Blogarticles_ArticlesArticles>>>;
  articlesCta?: Maybe<AcfLink>;
  blogarticlesBackgroundcolor?: Maybe<Scalars["String"]>;
  blogarticlesSectionheading?: Maybe<Scalars["String"]>;
  blogarticlesSectionintro?: Maybe<Scalars["String"]>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type Post_Blogarticles_ArticlesArticles = AcfFieldGroup & {
  __typename?: "Post_Blogarticles_articlesArticles";
  article?: Maybe<Post_Blogarticles_ArticlesArticles_Article>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type Post_Blogarticles_ArticlesArticles_Article = Article;

/** Field Group */
export type Post_Homehero = AcfFieldGroup & {
  __typename?: "Post_Homehero";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  homeheroCta?: Maybe<AcfLink>;
  homeheroSubtitle?: Maybe<Scalars["String"]>;
  homeheroTitle?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type Post_Meta = AcfFieldGroup & {
  __typename?: "Post_Meta";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** Override for the page title, if empty the actual title will be used. */
  pageTitle?: Maybe<Scalars["String"]>;
  /** Description of content shown to search engines, if empty the search engine will extract its own description from the page. */
  searchDescription?: Maybe<Scalars["String"]>;
  /** Image shown to search engines and linkbots, if empty then a hero image or a generic image will be used. */
  searchImage?: Maybe<MediaItem>;
  /** Override for the title shown to search engines, if empty the page title or the actual title will be used. */
  searchTitle?: Maybe<Scalars["String"]>;
  /**
   * A representation of this page in the URL Use only lowercase letters, numbers, and hyphens.
   *
   * Don&#039;t add slash ( / ) to the beginning of the url fragment.
   */
  urlFragment?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type Post_Metrichighlight = AcfFieldGroup & {
  __typename?: "Post_Metrichighlight";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  metrichighlightText?: Maybe<Scalars["String"]>;
  metrichighlightTitle?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type Post_Metrichighlightgroup = AcfFieldGroup & {
  __typename?: "Post_Metrichighlightgroup";
  components?: Maybe<Array<Maybe<Post_Metrichighlightgroup_Components>>>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type Post_Metrichighlightgroup_Components = AcfFieldGroup & {
  __typename?: "Post_Metrichighlightgroup_components";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  metrichighlightText?: Maybe<Scalars["String"]>;
  metrichighlightTitle?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type Post_Partnernotice = AcfFieldGroup & {
  __typename?: "Post_Partnernotice";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** Keep this one fairly short too. Think two or three sentances expanding on the heading above (if there is one). Eg, &quot;We’re here in Iceland ready to plan your trip with you, just the way you like it.&quot; */
  partnernoticeContent?: Maybe<Scalars["String"]>;
  /** Keep it short - a few words. Eg, &quot;We&#039;ve put together some ideas for you&quot; */
  partnernoticeHeading?: Maybe<Scalars["String"]>;
  /** Add some smiley LTO photos - preferably 3 (which is also the maximum). */
  partnernoticeImages?: Maybe<Array<Maybe<Post_Partnernotice_PartnernoticeImages>>>;
  /** This displays (or not) the stamp graphic in the bottom corner that says &quot;Global travel, local experts&quot;. It&#039;s purely visual. Probably don&#039;t use it too often. */
  partnernoticeStamp?: Maybe<Scalars["Boolean"]>;
};

/** Field Group */
export type Post_Partnernotice_PartnernoticeImages = AcfFieldGroup & {
  __typename?: "Post_Partnernotice_partnernoticeImages";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  ltoImage?: Maybe<MediaItem>;
};

/** Field Group */
export type Post_Propositionpullout = AcfFieldGroup & {
  __typename?: "Post_Propositionpullout";
  abtalogo?: Maybe<Scalars["String"]>;
  astalogo?: Maybe<Scalars["String"]>;
  atollogo?: Maybe<Scalars["String"]>;
  buttoncta?: Maybe<Scalars["String"]>;
  buttonurl?: Maybe<Scalars["String"]>;
  drvlogo?: Maybe<Scalars["String"]>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  isLayoutFlipped?: Maybe<Scalars["Boolean"]>;
  propositionpulloutHeading?: Maybe<Scalars["String"]>;
  propositionpulloutItems?: Maybe<Array<Maybe<Post_Propositionpullout_PropositionpulloutItems>>>;
  propositionpulloutLargeimage?: Maybe<MediaItem>;
  propositionpulloutSubtitle?: Maybe<Scalars["String"]>;
  showAbtaLogo?: Maybe<Scalars["Boolean"]>;
  showAstaLogo?: Maybe<Scalars["Boolean"]>;
  showAtolLogo?: Maybe<Scalars["Boolean"]>;
  showDrvLogo?: Maybe<Scalars["Boolean"]>;
};

/** Field Group */
export type Post_Propositionpullout_PropositionpulloutItems = AcfFieldGroup & {
  __typename?: "Post_Propositionpullout_propositionpulloutItems";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  item?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type Post_Reviewslist = AcfFieldGroup & {
  __typename?: "Post_Reviewslist";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  reviewslistReviews?: Maybe<Array<Maybe<Post_Reviewslist_ReviewslistReviews>>>;
};

/** Field Group */
export type Post_Reviewslist_ReviewslistReviews = AcfFieldGroup & {
  __typename?: "Post_Reviewslist_reviewslistReviews";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** Customer quote. Keep it short. Don&#039;t include the speech marks. */
  quote?: Maybe<Scalars["String"]>;
  /** How many stars is the review? Can handle 0.5 increments */
  rating?: Maybe<Scalars["Float"]>;
};

/** Field Group */
export type Post_Standardheader = AcfFieldGroup & {
  __typename?: "Post_Standardheader";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  standardheaderBackgroundimage?: Maybe<MediaItem>;
  standardheaderHeading?: Maybe<Scalars["String"]>;
  standardheaderOverlayopacity?: Maybe<Scalars["Float"]>;
  standardheaderSubheading?: Maybe<Scalars["String"]>;
};

/** Preview settings options. */
export type PreviewSettings = {
  __typename?: "PreviewSettings";
  pageSlug?: Maybe<Scalars["String"]>;
  pageTitle?: Maybe<Scalars["String"]>;
};

/** Nodes that can be seen in a preview (unpublished) state. */
export type Previewable = {
  /** Whether the object is a node in the preview state */
  isPreview?: Maybe<Scalars["Boolean"]>;
  /** The database id of the preview node */
  previewRevisionDatabaseId?: Maybe<Scalars["Int"]>;
  /** Whether the object is a node in the preview state */
  previewRevisionId?: Maybe<Scalars["ID"]>;
};

/** The reading setting type */
export type ReadingSettings = {
  __typename?: "ReadingSettings";
  /** The ID of the page that should display the latest posts */
  pageForPosts?: Maybe<Scalars["Int"]>;
  /** The ID of the page that should be displayed on the front page */
  pageOnFront?: Maybe<Scalars["Int"]>;
  /** Blog pages show at most. */
  postsPerPage?: Maybe<Scalars["Int"]>;
  /** What to show on the front page */
  showOnFront?: Maybe<Scalars["String"]>;
};

/** The Region type */
export type Region = DatabaseIdentifier &
  HierarchicalNode &
  HierarchicalTermNode &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: "Region";
    /** The ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<RegionToAncestorsRegionConnection>;
    /** Connection between the Region type and its children Regions. */
    children?: Maybe<RegionToRegionConnection>;
    /** Connection between the Region type and the ContentNode type */
    contentNodes?: Maybe<RegionToContentNodeConnection>;
    /** The number of objects connected to the object */
    count?: Maybe<Scalars["Int"]>;
    /** Connection between the Region type and the CountryProfile type */
    countryProfiles?: Maybe<RegionToCountryProfileConnection>;
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** The description of the object */
    description?: Maybe<Scalars["String"]>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The unique resource identifier path */
    id: Scalars["ID"];
    /** Whether the node is a Content Node */
    isContentNode: Scalars["Boolean"];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** Whether the node is a Term */
    isTermNode: Scalars["Boolean"];
    /** The link to the term */
    link?: Maybe<Scalars["String"]>;
    /** The human friendly name of the object. */
    name?: Maybe<Scalars["String"]>;
    /** Connection between the Region type and its parent Region. */
    parent?: Maybe<RegionToParentRegionConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars["Int"]>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars["ID"]>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    regionId?: Maybe<Scalars["Int"]>;
    /** Connection between the Region type and the RegionProfile type */
    regionProfiles?: Maybe<RegionToRegionProfileConnection>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Template | Region Taxonomy&quot; was set to Show in GraphQL. */
    regionTaxonomy?: Maybe<Region_Regiontaxonomy>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars["String"]>;
    /** Connection between the Region type and the Taxonomy type */
    taxonomy?: Maybe<RegionToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars["String"]>;
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars["Int"]>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars["Int"]>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars["String"]>;
  };

/** The Region type */
export type RegionAncestorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The Region type */
export type RegionChildrenArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RegionToRegionConnectionWhereArgs>;
};

/** The Region type */
export type RegionContentNodesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RegionToContentNodeConnectionWhereArgs>;
};

/** The Region type */
export type RegionCountryProfilesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RegionToCountryProfileConnectionWhereArgs>;
};

/** The Region type */
export type RegionEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The Region type */
export type RegionEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The Region type */
export type RegionRegionProfilesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RegionToRegionProfileConnectionWhereArgs>;
};

/** Connection to Region Nodes */
export type RegionConnection = {
  /** A list of edges (relational context) between RootQuery and connected Region Nodes */
  edges: Array<RegionConnectionEdge>;
  /** A list of connected Region Nodes */
  nodes: Array<Region>;
  /** Information about pagination in a connection. */
  pageInfo: RegionConnectionPageInfo;
};

/** Edge between a Node and a connected Region */
export type RegionConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected Region Node */
  node: Region;
};

/** Page Info on the connected RegionConnectionEdge */
export type RegionConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum RegionIdType {
  /** The Database ID for the node */
  DatabaseId = "DATABASE_ID",
  /** The hashed Global ID */
  Id = "ID",
  /** The name of the node */
  Name = "NAME",
  /** Url friendly name of the node */
  Slug = "SLUG",
  /** The URI for the node */
  Uri = "URI",
}

/** The RegionProfile type */
export type RegionProfile = ContentNode &
  DatabaseIdentifier &
  HierarchicalContentNode &
  HierarchicalNode &
  MenuItemLinkable &
  Node &
  NodeWithTemplate &
  NodeWithTitle &
  Previewable &
  UniformResourceIdentifiable & {
    __typename?: "RegionProfile";
    /** Returns ancestors of the node. Default ordered as lowest (closest to the child) to highest (closest to the root). */
    ancestors?: Maybe<HierarchicalContentNodeToContentNodeAncestorsConnection>;
    /** Connection between the HierarchicalContentNode type and the ContentNode type */
    children?: Maybe<HierarchicalContentNodeToContentNodeChildrenConnection>;
    /** Connection between the RegionProfile type and the ContentLanguages type */
    contentLanguages?: Maybe<RegionProfileToContentLanguagesConnection>;
    /** Connection between the RegionProfile type and the ContentStatus type */
    contentStatuses?: Maybe<RegionProfileToContentStatusConnection>;
    /** Connection between the ContentNode type and the ContentType type */
    contentType?: Maybe<ContentNodeToContentTypeConnectionEdge>;
    /** The name of the Content Type the node belongs to */
    contentTypeName: Scalars["String"];
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** Post publishing date. */
    date?: Maybe<Scalars["String"]>;
    /** The publishing date set in GMT. */
    dateGmt?: Maybe<Scalars["String"]>;
    /** The desired slug of the post */
    desiredSlug?: Maybe<Scalars["String"]>;
    /** If a user has edited the node within the past 15 seconds, this will return the user that last edited. Null if the edit lock doesn&#039;t exist or is greater than 15 seconds */
    editingLockedBy?: Maybe<ContentNodeToEditLockConnectionEdge>;
    /** The RSS enclosure for the object */
    enclosure?: Maybe<Scalars["String"]>;
    /** Connection between the ContentNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<ContentNodeToEnqueuedScriptConnection>;
    /** Connection between the ContentNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<ContentNodeToEnqueuedStylesheetConnection>;
    /** The global unique identifier for this post. This currently matches the value stored in WP_Post-&gt;guid and the guid column in the &quot;post_objects&quot; database table. */
    guid?: Maybe<Scalars["String"]>;
    /** The globally unique identifier of the region-profile object. */
    id: Scalars["ID"];
    /** Whether the node is a Content Node */
    isContentNode: Scalars["Boolean"];
    /** Whether the object is a node in the preview state */
    isPreview?: Maybe<Scalars["Boolean"]>;
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** Whether the node is a Term */
    isTermNode: Scalars["Boolean"];
    /** The user that most recently edited the node */
    lastEditedBy?: Maybe<ContentNodeToEditLastConnectionEdge>;
    /** The permalink of the post */
    link?: Maybe<Scalars["String"]>;
    /** Connection between the RegionProfile type and the Locale type */
    locales?: Maybe<RegionProfileToLocaleConnection>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Meta&quot; was set to Show in GraphQL. */
    meta?: Maybe<RegionProfile_Meta>;
    /** The local modified time for a post. If a post was recently updated the modified field will change to match the corresponding time. */
    modified?: Maybe<Scalars["String"]>;
    /** The GMT modified time for a post. If a post was recently updated the modified field will change to match the corresponding time in GMT. */
    modifiedGmt?: Maybe<Scalars["String"]>;
    /** The parent of the node. The parent object can be of various types */
    parent?: Maybe<HierarchicalContentNodeToParentContentNodeConnectionEdge>;
    /** Database id of the parent node */
    parentDatabaseId?: Maybe<Scalars["Int"]>;
    /** The globally unique identifier of the parent node. */
    parentId?: Maybe<Scalars["ID"]>;
    /** Connection between the RegionProfile type and the RegionProfile type */
    preview?: Maybe<RegionProfileToPreviewConnectionEdge>;
    /** The database id of the preview node */
    previewRevisionDatabaseId?: Maybe<Scalars["Int"]>;
    /** Whether the object is a node in the preview state */
    previewRevisionId?: Maybe<Scalars["ID"]>;
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Template | Region profile&quot; was set to Show in GraphQL. */
    regionProfile?: Maybe<RegionProfile_Regionprofile>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of the databaseId field
     */
    regionProfileId: Scalars["Int"];
    /** Added to the GraphQL Schema because the ACF Field Group &quot;Template | Region Taxonomy&quot; was set to Show in GraphQL. */
    regionTaxonomy?: Maybe<RegionProfile_Regiontaxonomy>;
    /** Connection between the RegionProfile type and the Region type */
    regions?: Maybe<RegionProfileToRegionConnection>;
    /** The uri slug for the post. This is equivalent to the WP_Post-&gt;post_name field and the post_name column in the database for the &quot;post_objects&quot; table. */
    slug?: Maybe<Scalars["String"]>;
    /** The current status of the object */
    status?: Maybe<Scalars["String"]>;
    /** The template assigned to a node of content */
    template?: Maybe<ContentTemplate>;
    /** Connection between the RegionProfile type and the TermNode type */
    terms?: Maybe<RegionProfileToTermNodeConnection>;
    /** The title of the post. This is currently just the raw title. An amendment to support rendered title needs to be made. */
    title?: Maybe<Scalars["String"]>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars["String"]>;
  };

/** The RegionProfile type */
export type RegionProfileAncestorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs>;
};

/** The RegionProfile type */
export type RegionProfileChildrenArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs>;
};

/** The RegionProfile type */
export type RegionProfileContentLanguagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RegionProfileToContentLanguagesConnectionWhereArgs>;
};

/** The RegionProfile type */
export type RegionProfileContentStatusesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RegionProfileToContentStatusConnectionWhereArgs>;
};

/** The RegionProfile type */
export type RegionProfileEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The RegionProfile type */
export type RegionProfileEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The RegionProfile type */
export type RegionProfileLocalesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RegionProfileToLocaleConnectionWhereArgs>;
};

/** The RegionProfile type */
export type RegionProfileRegionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RegionProfileToRegionConnectionWhereArgs>;
};

/** The RegionProfile type */
export type RegionProfileTermsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RegionProfileToTermNodeConnectionWhereArgs>;
};

/** The RegionProfile type */
export type RegionProfileTitleArgs = {
  format?: InputMaybe<PostObjectFieldFormatEnum>;
};

/** Connection to RegionProfile Nodes */
export type RegionProfileConnection = {
  /** A list of edges (relational context) between RootQuery and connected RegionProfile Nodes */
  edges: Array<RegionProfileConnectionEdge>;
  /** A list of connected RegionProfile Nodes */
  nodes: Array<RegionProfile>;
  /** Information about pagination in a connection. */
  pageInfo: RegionProfileConnectionPageInfo;
};

/** Edge between a Node and a connected RegionProfile */
export type RegionProfileConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected RegionProfile Node */
  node: RegionProfile;
};

/** Page Info on the connected RegionProfileConnectionEdge */
export type RegionProfileConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** Set relationships between the RegionProfile to ContentLanguages */
export type RegionProfileContentLanguagesInput = {
  /** If true, this will append the ContentLanguages to existing related ContentLanguages. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<RegionProfileContentLanguagesNodeInput>>>;
};

/** List of ContentLanguages to connect the RegionProfile to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type RegionProfileContentLanguagesNodeInput = {
  /** The description of the ContentLanguages. This field is used to set a description of the ContentLanguages if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the ContentLanguages. If present, this will be used to connect to the RegionProfile. If no existing ContentLanguages exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the ContentLanguages. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the ContentLanguages. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Set relationships between the RegionProfile to ContentStatuses */
export type RegionProfileContentStatusesInput = {
  /** If true, this will append the ContentStatus to existing related ContentStatuses. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<RegionProfileContentStatusesNodeInput>>>;
};

/** List of ContentStatuses to connect the RegionProfile to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type RegionProfileContentStatusesNodeInput = {
  /** The description of the ContentStatus. This field is used to set a description of the ContentStatus if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the ContentStatus. If present, this will be used to connect to the RegionProfile. If no existing ContentStatus exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the ContentStatus. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the ContentStatus. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum RegionProfileIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = "DATABASE_ID",
  /** Identify a resource by the (hashed) Global ID. */
  Id = "ID",
  /** Identify a resource by the URI. */
  Uri = "URI",
}

/** Set relationships between the RegionProfile to Locales */
export type RegionProfileLocalesInput = {
  /** If true, this will append the Locale to existing related Locales. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<RegionProfileLocalesNodeInput>>>;
};

/** List of Locales to connect the RegionProfile to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type RegionProfileLocalesNodeInput = {
  /** The description of the Locale. This field is used to set a description of the Locale if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the Locale. If present, this will be used to connect to the RegionProfile. If no existing Locale exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the Locale. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the Locale. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Set relationships between the RegionProfile to Regions */
export type RegionProfileRegionsInput = {
  /** If true, this will append the Region to existing related Regions. If false, this will replace existing relationships. Default true. */
  append?: InputMaybe<Scalars["Boolean"]>;
  /** The input list of items to set. */
  nodes?: InputMaybe<Array<InputMaybe<RegionProfileRegionsNodeInput>>>;
};

/** List of Regions to connect the RegionProfile to. If an ID is set, it will be used to create the connection. If not, it will look for a slug. If neither are valid existing terms, and the site is configured to allow terms to be created during post mutations, a term will be created using the Name if it exists in the input, then fallback to the slug if it exists. */
export type RegionProfileRegionsNodeInput = {
  /** The description of the Region. This field is used to set a description of the Region if a new one is created during the mutation. */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the Region. If present, this will be used to connect to the RegionProfile. If no existing Region exists with this ID, no connection will be made. */
  id?: InputMaybe<Scalars["ID"]>;
  /** The name of the Region. This field is used to create a new term, if term creation is enabled in nested mutations, and if one does not already exist with the provided slug or ID or if a slug or ID is not provided. If no name is included and a term is created, the creation will fallback to the slug field. */
  name?: InputMaybe<Scalars["String"]>;
  /** The slug of the Region. If no ID is present, this field will be used to make a connection. If no existing term exists with this slug, this field will be used as a fallback to the Name field when creating a new term to connect to, if term creation is enabled as a nested mutation. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Connection between the RegionProfile type and the ContentLanguages type */
export type RegionProfileToContentLanguagesConnection = Connection &
  ContentLanguagesConnection & {
    __typename?: "RegionProfileToContentLanguagesConnection";
    /** Edges for the RegionProfileToContentLanguagesConnection connection */
    edges: Array<RegionProfileToContentLanguagesConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentLanguages>;
    /** Information about pagination in a connection. */
    pageInfo: RegionProfileToContentLanguagesConnectionPageInfo;
  };

/** An edge in a connection */
export type RegionProfileToContentLanguagesConnectionEdge = ContentLanguagesConnectionEdge &
  Edge & {
    __typename?: "RegionProfileToContentLanguagesConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentLanguages;
  };

/** Page Info on the &quot;RegionProfileToContentLanguagesConnection&quot; */
export type RegionProfileToContentLanguagesConnectionPageInfo = ContentLanguagesConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RegionProfileToContentLanguagesConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RegionProfileToContentLanguagesConnection connection */
export type RegionProfileToContentLanguagesConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the RegionProfile type and the ContentStatus type */
export type RegionProfileToContentStatusConnection = Connection &
  ContentStatusConnection & {
    __typename?: "RegionProfileToContentStatusConnection";
    /** Edges for the RegionProfileToContentStatusConnection connection */
    edges: Array<RegionProfileToContentStatusConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentStatus>;
    /** Information about pagination in a connection. */
    pageInfo: RegionProfileToContentStatusConnectionPageInfo;
  };

/** An edge in a connection */
export type RegionProfileToContentStatusConnectionEdge = ContentStatusConnectionEdge &
  Edge & {
    __typename?: "RegionProfileToContentStatusConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentStatus;
  };

/** Page Info on the &quot;RegionProfileToContentStatusConnection&quot; */
export type RegionProfileToContentStatusConnectionPageInfo = ContentStatusConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RegionProfileToContentStatusConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RegionProfileToContentStatusConnection connection */
export type RegionProfileToContentStatusConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the RegionProfile type and the Locale type */
export type RegionProfileToLocaleConnection = Connection &
  LocaleConnection & {
    __typename?: "RegionProfileToLocaleConnection";
    /** Edges for the RegionProfileToLocaleConnection connection */
    edges: Array<RegionProfileToLocaleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Locale>;
    /** Information about pagination in a connection. */
    pageInfo: RegionProfileToLocaleConnectionPageInfo;
  };

/** An edge in a connection */
export type RegionProfileToLocaleConnectionEdge = Edge &
  LocaleConnectionEdge & {
    __typename?: "RegionProfileToLocaleConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Locale;
  };

/** Page Info on the &quot;RegionProfileToLocaleConnection&quot; */
export type RegionProfileToLocaleConnectionPageInfo = LocaleConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RegionProfileToLocaleConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RegionProfileToLocaleConnection connection */
export type RegionProfileToLocaleConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the RegionProfile type and the RegionProfile type */
export type RegionProfileToPreviewConnectionEdge = Edge &
  OneToOneConnection &
  RegionProfileConnectionEdge & {
    __typename?: "RegionProfileToPreviewConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: RegionProfile;
  };

/** Connection between the RegionProfile type and the Region type */
export type RegionProfileToRegionConnection = Connection &
  RegionConnection & {
    __typename?: "RegionProfileToRegionConnection";
    /** Edges for the RegionProfileToRegionConnection connection */
    edges: Array<RegionProfileToRegionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Region>;
    /** Information about pagination in a connection. */
    pageInfo: RegionProfileToRegionConnectionPageInfo;
  };

/** An edge in a connection */
export type RegionProfileToRegionConnectionEdge = Edge &
  RegionConnectionEdge & {
    __typename?: "RegionProfileToRegionConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Region;
  };

/** Page Info on the &quot;RegionProfileToRegionConnection&quot; */
export type RegionProfileToRegionConnectionPageInfo = PageInfo &
  RegionConnectionPageInfo &
  WpPageInfo & {
    __typename?: "RegionProfileToRegionConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RegionProfileToRegionConnection connection */
export type RegionProfileToRegionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the RegionProfile type and the TermNode type */
export type RegionProfileToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: "RegionProfileToTermNodeConnection";
    /** Edges for the RegionProfileToTermNodeConnection connection */
    edges: Array<RegionProfileToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: RegionProfileToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type RegionProfileToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: "RegionProfileToTermNodeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;RegionProfileToTermNodeConnection&quot; */
export type RegionProfileToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: "RegionProfileToTermNodeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RegionProfileToTermNodeConnection connection */
export type RegionProfileToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Field Group */
export type RegionProfile_Meta = AcfFieldGroup & {
  __typename?: "RegionProfile_Meta";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** Override for the page title, if empty the actual title will be used. */
  pageTitle?: Maybe<Scalars["String"]>;
  /** Description of content shown to search engines, if empty the search engine will extract its own description from the page. */
  searchDescription?: Maybe<Scalars["String"]>;
  /** Image shown to search engines and linkbots, if empty then a hero image or a generic image will be used. */
  searchImage?: Maybe<MediaItem>;
  /** Override for the title shown to search engines, if empty the page title or the actual title will be used. */
  searchTitle?: Maybe<Scalars["String"]>;
  /**
   * A representation of this page in the URL Use only lowercase letters, numbers, and hyphens.
   *
   * Don&#039;t add slash ( / ) to the beginning of the url fragment.
   */
  urlFragment?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type RegionProfile_Regionprofile = AcfFieldGroup & {
  __typename?: "RegionProfile_Regionprofile";
  components?: Maybe<RegionProfile_Regionprofile_Components>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  regionIntro?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type RegionProfile_Regionprofile_Components = AcfFieldGroup & {
  __typename?: "RegionProfile_Regionprofile_Components";
  articlesArticles?: Maybe<Array<Maybe<RegionProfile_Regionprofile_Components_ArticlesArticles>>>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  imagepulloutImage?: Maybe<MediaItem>;
  reviewslistReviews?: Maybe<
    Array<Maybe<RegionProfile_Regionprofile_Components_ReviewslistReviews>>
  >;
  reviewslistTitle?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type RegionProfile_Regionprofile_Components_ArticlesArticles = AcfFieldGroup & {
  __typename?: "RegionProfile_Regionprofile_Components_articlesArticles";
  article?: Maybe<RegionProfile_Regionprofile_Components_ArticlesArticles_Article>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  url?: Maybe<Scalars["String"]>;
};

export type RegionProfile_Regionprofile_Components_ArticlesArticles_Article = Article;

/** Field Group */
export type RegionProfile_Regionprofile_Components_ReviewslistReviews = AcfFieldGroup & {
  __typename?: "RegionProfile_Regionprofile_Components_reviewslistReviews";
  /** The customer name and where they travelled to. Usually written as: Sim, travelled to Bristol */
  author?: Maybe<Scalars["String"]>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** Customer quote. Keep it short. Don&#039;t include the speech marks. There is a character limit of 325. */
  quote?: Maybe<Scalars["String"]>;
  /** How many stars is the review? Can handle 0.5 increments */
  rating?: Maybe<Scalars["Float"]>;
  title?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type RegionProfile_Regiontaxonomy = AcfFieldGroup & {
  __typename?: "RegionProfile_Regiontaxonomy";
  components?: Maybe<RegionProfile_Regiontaxonomy_Components>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type RegionProfile_Regiontaxonomy_Components = AcfFieldGroup & {
  __typename?: "RegionProfile_Regiontaxonomy_Components";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** Add some smiley LTO photos - preferably 3 (which is also the maximum). */
  partnernoticeImages?: Maybe<
    Array<Maybe<RegionProfile_Regiontaxonomy_Components_PartnernoticeImages>>
  >;
};

/** Field Group */
export type RegionProfile_Regiontaxonomy_Components_PartnernoticeImages = AcfFieldGroup & {
  __typename?: "RegionProfile_Regiontaxonomy_Components_partnernoticeImages";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  ltoImage?: Maybe<MediaItem>;
};

/** Connection between the Region type and the Region type */
export type RegionToAncestorsRegionConnection = Connection &
  RegionConnection & {
    __typename?: "RegionToAncestorsRegionConnection";
    /** Edges for the RegionToAncestorsRegionConnection connection */
    edges: Array<RegionToAncestorsRegionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Region>;
    /** Information about pagination in a connection. */
    pageInfo: RegionToAncestorsRegionConnectionPageInfo;
  };

/** An edge in a connection */
export type RegionToAncestorsRegionConnectionEdge = Edge &
  RegionConnectionEdge & {
    __typename?: "RegionToAncestorsRegionConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Region;
  };

/** Page Info on the &quot;RegionToAncestorsRegionConnection&quot; */
export type RegionToAncestorsRegionConnectionPageInfo = PageInfo &
  RegionConnectionPageInfo &
  WpPageInfo & {
    __typename?: "RegionToAncestorsRegionConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Connection between the Region type and the ContentNode type */
export type RegionToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: "RegionToContentNodeConnection";
    /** Edges for the RegionToContentNodeConnection connection */
    edges: Array<RegionToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: RegionToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type RegionToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: "RegionToContentNodeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;RegionToContentNodeConnection&quot; */
export type RegionToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RegionToContentNodeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RegionToContentNodeConnection connection */
export type RegionToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfRegionEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Region type and the CountryProfile type */
export type RegionToCountryProfileConnection = Connection &
  CountryProfileConnection & {
    __typename?: "RegionToCountryProfileConnection";
    /** Edges for the RegionToCountryProfileConnection connection */
    edges: Array<RegionToCountryProfileConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<CountryProfile>;
    /** Information about pagination in a connection. */
    pageInfo: RegionToCountryProfileConnectionPageInfo;
  };

/** An edge in a connection */
export type RegionToCountryProfileConnectionEdge = CountryProfileConnectionEdge &
  Edge & {
    __typename?: "RegionToCountryProfileConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: CountryProfile;
  };

/** Page Info on the &quot;RegionToCountryProfileConnection&quot; */
export type RegionToCountryProfileConnectionPageInfo = CountryProfileConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RegionToCountryProfileConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RegionToCountryProfileConnection connection */
export type RegionToCountryProfileConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Region type and the Region type */
export type RegionToParentRegionConnectionEdge = Edge &
  OneToOneConnection &
  RegionConnectionEdge & {
    __typename?: "RegionToParentRegionConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: Region;
  };

/** Connection between the Region type and the Region type */
export type RegionToRegionConnection = Connection &
  RegionConnection & {
    __typename?: "RegionToRegionConnection";
    /** Edges for the RegionToRegionConnection connection */
    edges: Array<RegionToRegionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Region>;
    /** Information about pagination in a connection. */
    pageInfo: RegionToRegionConnectionPageInfo;
  };

/** An edge in a connection */
export type RegionToRegionConnectionEdge = Edge &
  RegionConnectionEdge & {
    __typename?: "RegionToRegionConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Region;
  };

/** Page Info on the &quot;RegionToRegionConnection&quot; */
export type RegionToRegionConnectionPageInfo = PageInfo &
  RegionConnectionPageInfo &
  WpPageInfo & {
    __typename?: "RegionToRegionConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RegionToRegionConnection connection */
export type RegionToRegionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the Region type and the RegionProfile type */
export type RegionToRegionProfileConnection = Connection &
  RegionProfileConnection & {
    __typename?: "RegionToRegionProfileConnection";
    /** Edges for the RegionToRegionProfileConnection connection */
    edges: Array<RegionToRegionProfileConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<RegionProfile>;
    /** Information about pagination in a connection. */
    pageInfo: RegionToRegionProfileConnectionPageInfo;
  };

/** An edge in a connection */
export type RegionToRegionProfileConnectionEdge = Edge &
  RegionProfileConnectionEdge & {
    __typename?: "RegionToRegionProfileConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: RegionProfile;
  };

/** Page Info on the &quot;RegionToRegionProfileConnection&quot; */
export type RegionToRegionProfileConnectionPageInfo = PageInfo &
  RegionProfileConnectionPageInfo &
  WpPageInfo & {
    __typename?: "RegionToRegionProfileConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RegionToRegionProfileConnection connection */
export type RegionToRegionProfileConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Region type and the Taxonomy type */
export type RegionToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: "RegionToTaxonomyConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

/** Field Group */
export type Region_Regiontaxonomy = AcfFieldGroup & {
  __typename?: "Region_Regiontaxonomy";
  components?: Maybe<Region_Regiontaxonomy_Components>;
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
};

/** Field Group */
export type Region_Regiontaxonomy_Components = AcfFieldGroup & {
  __typename?: "Region_Regiontaxonomy_Components";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  /** Add some smiley LTO photos - preferably 3 (which is also the maximum). */
  partnernoticeImages?: Maybe<Array<Maybe<Region_Regiontaxonomy_Components_PartnernoticeImages>>>;
};

/** Field Group */
export type Region_Regiontaxonomy_Components_PartnernoticeImages = AcfFieldGroup & {
  __typename?: "Region_Regiontaxonomy_Components_partnernoticeImages";
  /** The name of the ACF Field Group */
  fieldGroupName?: Maybe<Scalars["String"]>;
  ltoImage?: Maybe<MediaItem>;
};

/** Input for the registerUser mutation. */
export type RegisterUserInput = {
  /** User's AOL IM account. */
  aim?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A string containing content about the user. */
  description?: InputMaybe<Scalars["String"]>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: InputMaybe<Scalars["String"]>;
  /** A string containing the user's email address. */
  email?: InputMaybe<Scalars["String"]>;
  /** 	The user's first name. */
  firstName?: InputMaybe<Scalars["String"]>;
  /** User's Jabber account. */
  jabber?: InputMaybe<Scalars["String"]>;
  /** The user's last name. */
  lastName?: InputMaybe<Scalars["String"]>;
  /** User's locale. */
  locale?: InputMaybe<Scalars["String"]>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: InputMaybe<Scalars["String"]>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: InputMaybe<Scalars["String"]>;
  /** A string that contains the plain text password for the user. */
  password?: InputMaybe<Scalars["String"]>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: InputMaybe<Scalars["String"]>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: InputMaybe<Scalars["String"]>;
  /** A string that contains the user's username. */
  username: Scalars["String"];
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: InputMaybe<Scalars["String"]>;
  /** User's Yahoo IM account. */
  yim?: InputMaybe<Scalars["String"]>;
};

/** The payload for the registerUser mutation. */
export type RegisterUserPayload = {
  __typename?: "RegisterUserPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The User object mutation type. */
  user?: Maybe<User>;
};

/** The logical relation between each item in the array when there are more than one. */
export enum RelationEnum {
  /** The logical AND condition returns true if both operands are true, otherwise, it returns false. */
  And = "AND",
  /** The logical OR condition returns false if both operands are false, otherwise, it returns true. */
  Or = "OR",
}

/** Input for the resetUserPassword mutation. */
export type ResetUserPasswordInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Password reset key */
  key?: InputMaybe<Scalars["String"]>;
  /** The user's login (username). */
  login?: InputMaybe<Scalars["String"]>;
  /** The new password. */
  password?: InputMaybe<Scalars["String"]>;
};

/** The payload for the resetUserPassword mutation. */
export type ResetUserPasswordPayload = {
  __typename?: "ResetUserPasswordPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The User object mutation type. */
  user?: Maybe<User>;
};

/** Input for the restoreComment mutation. */
export type RestoreCommentInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The ID of the comment to be restored */
  id: Scalars["ID"];
};

/** The payload for the restoreComment mutation. */
export type RestoreCommentPayload = {
  __typename?: "RestoreCommentPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The restored comment object */
  comment?: Maybe<Comment>;
  /** The ID of the restored comment */
  restoredId?: Maybe<Scalars["ID"]>;
};

/** The root mutation */
export type RootMutation = {
  __typename?: "RootMutation";
  /** The createArticle mutation */
  createArticle?: Maybe<CreateArticlePayload>;
  /** The createCategory mutation */
  createCategory?: Maybe<CreateCategoryPayload>;
  /** The createCollection mutation */
  createCollection?: Maybe<CreateCollectionPayload>;
  /** The createComment mutation */
  createComment?: Maybe<CreateCommentPayload>;
  /** The createContentLanguages mutation */
  createContentLanguages?: Maybe<CreateContentLanguagesPayload>;
  /** The createContentStatus mutation */
  createContentStatus?: Maybe<CreateContentStatusPayload>;
  /** The createCountry mutation */
  createCountry?: Maybe<CreateCountryPayload>;
  /** The createCountryProfile mutation */
  createCountryProfile?: Maybe<CreateCountryProfilePayload>;
  /** The createGlobalContent mutation */
  createGlobalContent?: Maybe<CreateGlobalContentPayload>;
  /** The createHomePage mutation */
  createHomePage?: Maybe<CreateHomePagePayload>;
  /** The createHowItWorksPage mutation */
  createHowItWorksPage?: Maybe<CreateHowItWorksPagePayload>;
  /** The createLocale mutation */
  createLocale?: Maybe<CreateLocalePayload>;
  /** The createMediaItem mutation */
  createMediaItem?: Maybe<CreateMediaItemPayload>;
  /** The createPage mutation */
  createPage?: Maybe<CreatePagePayload>;
  /** The createPost mutation */
  createPost?: Maybe<CreatePostPayload>;
  /** The createPostFormat mutation */
  createPostFormat?: Maybe<CreatePostFormatPayload>;
  /** The createRegion mutation */
  createRegion?: Maybe<CreateRegionPayload>;
  /** The createRegionProfile mutation */
  createRegionProfile?: Maybe<CreateRegionProfilePayload>;
  /** The createTag mutation */
  createTag?: Maybe<CreateTagPayload>;
  /** The createUser mutation */
  createUser?: Maybe<CreateUserPayload>;
  /** The deleteArticle mutation */
  deleteArticle?: Maybe<DeleteArticlePayload>;
  /** The deleteCategory mutation */
  deleteCategory?: Maybe<DeleteCategoryPayload>;
  /** The deleteCollection mutation */
  deleteCollection?: Maybe<DeleteCollectionPayload>;
  /** The deleteComment mutation */
  deleteComment?: Maybe<DeleteCommentPayload>;
  /** The deleteContentLanguages mutation */
  deleteContentLanguages?: Maybe<DeleteContentLanguagesPayload>;
  /** The deleteContentStatus mutation */
  deleteContentStatus?: Maybe<DeleteContentStatusPayload>;
  /** The deleteCountry mutation */
  deleteCountry?: Maybe<DeleteCountryPayload>;
  /** The deleteCountryProfile mutation */
  deleteCountryProfile?: Maybe<DeleteCountryProfilePayload>;
  /** The deleteGlobalContent mutation */
  deleteGlobalContent?: Maybe<DeleteGlobalContentPayload>;
  /** The deleteHomePage mutation */
  deleteHomePage?: Maybe<DeleteHomePagePayload>;
  /** The deleteHowItWorksPage mutation */
  deleteHowItWorksPage?: Maybe<DeleteHowItWorksPagePayload>;
  /** The deleteLocale mutation */
  deleteLocale?: Maybe<DeleteLocalePayload>;
  /** The deleteMediaItem mutation */
  deleteMediaItem?: Maybe<DeleteMediaItemPayload>;
  /** The deletePage mutation */
  deletePage?: Maybe<DeletePagePayload>;
  /** The deletePost mutation */
  deletePost?: Maybe<DeletePostPayload>;
  /** The deletePostFormat mutation */
  deletePostFormat?: Maybe<DeletePostFormatPayload>;
  /** The deleteRegion mutation */
  deleteRegion?: Maybe<DeleteRegionPayload>;
  /** The deleteRegionProfile mutation */
  deleteRegionProfile?: Maybe<DeleteRegionProfilePayload>;
  /** The deleteTag mutation */
  deleteTag?: Maybe<DeleteTagPayload>;
  /** The deleteUser mutation */
  deleteUser?: Maybe<DeleteUserPayload>;
  /** Increase the count. */
  increaseCount?: Maybe<Scalars["Int"]>;
  /** The registerUser mutation */
  registerUser?: Maybe<RegisterUserPayload>;
  /** The resetUserPassword mutation */
  resetUserPassword?: Maybe<ResetUserPasswordPayload>;
  /** The restoreComment mutation */
  restoreComment?: Maybe<RestoreCommentPayload>;
  /** Send password reset email to user */
  sendPasswordResetEmail?: Maybe<SendPasswordResetEmailPayload>;
  /** The updateArticle mutation */
  updateArticle?: Maybe<UpdateArticlePayload>;
  /** The updateCategory mutation */
  updateCategory?: Maybe<UpdateCategoryPayload>;
  /** The updateCollection mutation */
  updateCollection?: Maybe<UpdateCollectionPayload>;
  /** The updateComment mutation */
  updateComment?: Maybe<UpdateCommentPayload>;
  /** The updateContentLanguages mutation */
  updateContentLanguages?: Maybe<UpdateContentLanguagesPayload>;
  /** The updateContentStatus mutation */
  updateContentStatus?: Maybe<UpdateContentStatusPayload>;
  /** The updateCountry mutation */
  updateCountry?: Maybe<UpdateCountryPayload>;
  /** The updateCountryProfile mutation */
  updateCountryProfile?: Maybe<UpdateCountryProfilePayload>;
  /** The updateGlobalContent mutation */
  updateGlobalContent?: Maybe<UpdateGlobalContentPayload>;
  /** The updateHomePage mutation */
  updateHomePage?: Maybe<UpdateHomePagePayload>;
  /** The updateHowItWorksPage mutation */
  updateHowItWorksPage?: Maybe<UpdateHowItWorksPagePayload>;
  /** The updateLocale mutation */
  updateLocale?: Maybe<UpdateLocalePayload>;
  /** The updateMediaItem mutation */
  updateMediaItem?: Maybe<UpdateMediaItemPayload>;
  /** The updatePage mutation */
  updatePage?: Maybe<UpdatePagePayload>;
  /** The updatePost mutation */
  updatePost?: Maybe<UpdatePostPayload>;
  /** The updatePostFormat mutation */
  updatePostFormat?: Maybe<UpdatePostFormatPayload>;
  /** The updateRegion mutation */
  updateRegion?: Maybe<UpdateRegionPayload>;
  /** The updateRegionProfile mutation */
  updateRegionProfile?: Maybe<UpdateRegionProfilePayload>;
  /** The updateSettings mutation */
  updateSettings?: Maybe<UpdateSettingsPayload>;
  /** The updateTag mutation */
  updateTag?: Maybe<UpdateTagPayload>;
  /** The updateUser mutation */
  updateUser?: Maybe<UpdateUserPayload>;
};

/** The root mutation */
export type RootMutationCreateArticleArgs = {
  input: CreateArticleInput;
};

/** The root mutation */
export type RootMutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};

/** The root mutation */
export type RootMutationCreateCollectionArgs = {
  input: CreateCollectionInput;
};

/** The root mutation */
export type RootMutationCreateCommentArgs = {
  input: CreateCommentInput;
};

/** The root mutation */
export type RootMutationCreateContentLanguagesArgs = {
  input: CreateContentLanguagesInput;
};

/** The root mutation */
export type RootMutationCreateContentStatusArgs = {
  input: CreateContentStatusInput;
};

/** The root mutation */
export type RootMutationCreateCountryArgs = {
  input: CreateCountryInput;
};

/** The root mutation */
export type RootMutationCreateCountryProfileArgs = {
  input: CreateCountryProfileInput;
};

/** The root mutation */
export type RootMutationCreateGlobalContentArgs = {
  input: CreateGlobalContentInput;
};

/** The root mutation */
export type RootMutationCreateHomePageArgs = {
  input: CreateHomePageInput;
};

/** The root mutation */
export type RootMutationCreateHowItWorksPageArgs = {
  input: CreateHowItWorksPageInput;
};

/** The root mutation */
export type RootMutationCreateLocaleArgs = {
  input: CreateLocaleInput;
};

/** The root mutation */
export type RootMutationCreateMediaItemArgs = {
  input: CreateMediaItemInput;
};

/** The root mutation */
export type RootMutationCreatePageArgs = {
  input: CreatePageInput;
};

/** The root mutation */
export type RootMutationCreatePostArgs = {
  input: CreatePostInput;
};

/** The root mutation */
export type RootMutationCreatePostFormatArgs = {
  input: CreatePostFormatInput;
};

/** The root mutation */
export type RootMutationCreateRegionArgs = {
  input: CreateRegionInput;
};

/** The root mutation */
export type RootMutationCreateRegionProfileArgs = {
  input: CreateRegionProfileInput;
};

/** The root mutation */
export type RootMutationCreateTagArgs = {
  input: CreateTagInput;
};

/** The root mutation */
export type RootMutationCreateUserArgs = {
  input: CreateUserInput;
};

/** The root mutation */
export type RootMutationDeleteArticleArgs = {
  input: DeleteArticleInput;
};

/** The root mutation */
export type RootMutationDeleteCategoryArgs = {
  input: DeleteCategoryInput;
};

/** The root mutation */
export type RootMutationDeleteCollectionArgs = {
  input: DeleteCollectionInput;
};

/** The root mutation */
export type RootMutationDeleteCommentArgs = {
  input: DeleteCommentInput;
};

/** The root mutation */
export type RootMutationDeleteContentLanguagesArgs = {
  input: DeleteContentLanguagesInput;
};

/** The root mutation */
export type RootMutationDeleteContentStatusArgs = {
  input: DeleteContentStatusInput;
};

/** The root mutation */
export type RootMutationDeleteCountryArgs = {
  input: DeleteCountryInput;
};

/** The root mutation */
export type RootMutationDeleteCountryProfileArgs = {
  input: DeleteCountryProfileInput;
};

/** The root mutation */
export type RootMutationDeleteGlobalContentArgs = {
  input: DeleteGlobalContentInput;
};

/** The root mutation */
export type RootMutationDeleteHomePageArgs = {
  input: DeleteHomePageInput;
};

/** The root mutation */
export type RootMutationDeleteHowItWorksPageArgs = {
  input: DeleteHowItWorksPageInput;
};

/** The root mutation */
export type RootMutationDeleteLocaleArgs = {
  input: DeleteLocaleInput;
};

/** The root mutation */
export type RootMutationDeleteMediaItemArgs = {
  input: DeleteMediaItemInput;
};

/** The root mutation */
export type RootMutationDeletePageArgs = {
  input: DeletePageInput;
};

/** The root mutation */
export type RootMutationDeletePostArgs = {
  input: DeletePostInput;
};

/** The root mutation */
export type RootMutationDeletePostFormatArgs = {
  input: DeletePostFormatInput;
};

/** The root mutation */
export type RootMutationDeleteRegionArgs = {
  input: DeleteRegionInput;
};

/** The root mutation */
export type RootMutationDeleteRegionProfileArgs = {
  input: DeleteRegionProfileInput;
};

/** The root mutation */
export type RootMutationDeleteTagArgs = {
  input: DeleteTagInput;
};

/** The root mutation */
export type RootMutationDeleteUserArgs = {
  input: DeleteUserInput;
};

/** The root mutation */
export type RootMutationIncreaseCountArgs = {
  count?: InputMaybe<Scalars["Int"]>;
};

/** The root mutation */
export type RootMutationRegisterUserArgs = {
  input: RegisterUserInput;
};

/** The root mutation */
export type RootMutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};

/** The root mutation */
export type RootMutationRestoreCommentArgs = {
  input: RestoreCommentInput;
};

/** The root mutation */
export type RootMutationSendPasswordResetEmailArgs = {
  input: SendPasswordResetEmailInput;
};

/** The root mutation */
export type RootMutationUpdateArticleArgs = {
  input: UpdateArticleInput;
};

/** The root mutation */
export type RootMutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};

/** The root mutation */
export type RootMutationUpdateCollectionArgs = {
  input: UpdateCollectionInput;
};

/** The root mutation */
export type RootMutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};

/** The root mutation */
export type RootMutationUpdateContentLanguagesArgs = {
  input: UpdateContentLanguagesInput;
};

/** The root mutation */
export type RootMutationUpdateContentStatusArgs = {
  input: UpdateContentStatusInput;
};

/** The root mutation */
export type RootMutationUpdateCountryArgs = {
  input: UpdateCountryInput;
};

/** The root mutation */
export type RootMutationUpdateCountryProfileArgs = {
  input: UpdateCountryProfileInput;
};

/** The root mutation */
export type RootMutationUpdateGlobalContentArgs = {
  input: UpdateGlobalContentInput;
};

/** The root mutation */
export type RootMutationUpdateHomePageArgs = {
  input: UpdateHomePageInput;
};

/** The root mutation */
export type RootMutationUpdateHowItWorksPageArgs = {
  input: UpdateHowItWorksPageInput;
};

/** The root mutation */
export type RootMutationUpdateLocaleArgs = {
  input: UpdateLocaleInput;
};

/** The root mutation */
export type RootMutationUpdateMediaItemArgs = {
  input: UpdateMediaItemInput;
};

/** The root mutation */
export type RootMutationUpdatePageArgs = {
  input: UpdatePageInput;
};

/** The root mutation */
export type RootMutationUpdatePostArgs = {
  input: UpdatePostInput;
};

/** The root mutation */
export type RootMutationUpdatePostFormatArgs = {
  input: UpdatePostFormatInput;
};

/** The root mutation */
export type RootMutationUpdateRegionArgs = {
  input: UpdateRegionInput;
};

/** The root mutation */
export type RootMutationUpdateRegionProfileArgs = {
  input: UpdateRegionProfileInput;
};

/** The root mutation */
export type RootMutationUpdateSettingsArgs = {
  input: UpdateSettingsInput;
};

/** The root mutation */
export type RootMutationUpdateTagArgs = {
  input: UpdateTagInput;
};

/** The root mutation */
export type RootMutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/** The root entry point into the Graph */
export type RootQuery = {
  __typename?: "RootQuery";
  /** Connection between the RootQuery type and the ContentLanguages type */
  allContentLanguages?: Maybe<RootQueryToContentLanguagesConnection>;
  /** Entry point to get all settings for the site */
  allSettings?: Maybe<Settings>;
  /** An object of the Article Type.  */
  article?: Maybe<Article>;
  /**
   * A Article object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  articleBy?: Maybe<Article>;
  /** Connection between the RootQuery type and the Article type */
  articles?: Maybe<RootQueryToArticleConnection>;
  /** Connection between the RootQuery type and the category type */
  categories?: Maybe<RootQueryToCategoryConnection>;
  /** A 0bject */
  category?: Maybe<Category>;
  /** An object of the Collection Type.  */
  collection?: Maybe<Collection>;
  /**
   * A Collection object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  collectionBy?: Maybe<Collection>;
  /** Connection between the RootQuery type and the Collection type */
  collections?: Maybe<RootQueryToCollectionConnection>;
  /** Returns a Comment */
  comment?: Maybe<Comment>;
  /** Connection between the RootQuery type and the Comment type */
  comments?: Maybe<RootQueryToCommentConnection>;
  /** A 0bject */
  contentLanguages?: Maybe<ContentLanguages>;
  /** A node used to manage content */
  contentNode?: Maybe<ContentNode>;
  /** Connection between the RootQuery type and the ContentNode type */
  contentNodes?: Maybe<RootQueryToContentNodeConnection>;
  /** A 0bject */
  contentStatus?: Maybe<ContentStatus>;
  /** Connection between the RootQuery type and the ContentStatus type */
  contentStatuses?: Maybe<RootQueryToContentStatusConnection>;
  /** Fetch a Content Type node by unique Identifier */
  contentType?: Maybe<ContentType>;
  /** Connection between the RootQuery type and the ContentType type */
  contentTypes?: Maybe<RootQueryToContentTypeConnection>;
  /** Connection between the RootQuery type and the Country type */
  countries?: Maybe<RootQueryToCountryConnection>;
  /** A 0bject */
  country?: Maybe<Country>;
  /** An object of the CountryProfile Type.  */
  countryProfile?: Maybe<CountryProfile>;
  /**
   * A CountryProfile object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  countryProfileBy?: Maybe<CountryProfile>;
  /** Connection between the RootQuery type and the CountryProfile type */
  countryProfiles?: Maybe<RootQueryToCountryProfileConnection>;
  /** Get language list */
  defaultLanguage?: Maybe<Language>;
  /** Fields of the &#039;DiscussionSettings&#039; settings group */
  discussionSettings?: Maybe<DiscussionSettings>;
  /** Fields of the &#039;GeneralSettings&#039; settings group */
  generalSettings?: Maybe<GeneralSettings>;
  /** An object of the GlobalContent Type.  */
  globalContent?: Maybe<GlobalContent>;
  /**
   * A GlobalContent object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  globalContentBy?: Maybe<GlobalContent>;
  /** Connection between the RootQuery type and the GlobalContent type */
  globalContents?: Maybe<RootQueryToGlobalContentConnection>;
  /** An object of the HomePage Type.  */
  homePage?: Maybe<HomePage>;
  /**
   * A HomePage object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  homePageBy?: Maybe<HomePage>;
  /** Connection between the RootQuery type and the HomePage type */
  homePages?: Maybe<RootQueryToHomePageConnection>;
  /** An object of the HowItWorksPage Type.  */
  howItWorksPage?: Maybe<HowItWorksPage>;
  /**
   * A HowItWorksPage object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  howItWorksPageBy?: Maybe<HowItWorksPage>;
  /** Connection between the RootQuery type and the HowItWorksPage type */
  howItWorksPages?: Maybe<RootQueryToHowItWorksPageConnection>;
  /** List available languages */
  languages?: Maybe<Array<Maybe<Language>>>;
  /** A 0bject */
  locale?: Maybe<Locale>;
  /** Connection between the RootQuery type and the Locale type */
  locales?: Maybe<RootQueryToLocaleConnection>;
  /** An object of the mediaItem Type.  */
  mediaItem?: Maybe<MediaItem>;
  /**
   * A mediaItem object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  mediaItemBy?: Maybe<MediaItem>;
  /** Connection between the RootQuery type and the mediaItem type */
  mediaItems?: Maybe<RootQueryToMediaItemConnection>;
  /** A WordPress navigation menu */
  menu?: Maybe<Menu>;
  /** A WordPress navigation menu item */
  menuItem?: Maybe<MenuItem>;
  /** Connection between the RootQuery type and the MenuItem type */
  menuItems?: Maybe<RootQueryToMenuItemConnection>;
  /** Connection between the RootQuery type and the Menu type */
  menus?: Maybe<RootQueryToMenuConnection>;
  /** Fetches an object given its ID */
  node?: Maybe<Node>;
  /** Fetches an object given its Unique Resource Identifier */
  nodeByUri?: Maybe<UniformResourceIdentifiable>;
  /** An object of the page Type.  */
  page?: Maybe<Page>;
  /**
   * A page object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  pageBy?: Maybe<Page>;
  /** Connection between the RootQuery type and the page type */
  pages?: Maybe<RootQueryToPageConnection>;
  /** A WordPress plugin */
  plugin?: Maybe<Plugin>;
  /** Connection between the RootQuery type and the Plugin type */
  plugins?: Maybe<RootQueryToPluginConnection>;
  /** An object of the post Type.  */
  post?: Maybe<Post>;
  /**
   * A post object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  postBy?: Maybe<Post>;
  /** A 0bject */
  postFormat?: Maybe<PostFormat>;
  /** Connection between the RootQuery type and the postFormat type */
  postFormats?: Maybe<RootQueryToPostFormatConnection>;
  /** Connection between the RootQuery type and the post type */
  posts?: Maybe<RootQueryToPostConnection>;
  /** Preview settings options. */
  previewSettings?: Maybe<PreviewSettings>;
  /** Fields of the &#039;ReadingSettings&#039; settings group */
  readingSettings?: Maybe<ReadingSettings>;
  /** A 0bject */
  region?: Maybe<Region>;
  /** An object of the RegionProfile Type.  */
  regionProfile?: Maybe<RegionProfile>;
  /**
   * A RegionProfile object
   * @deprecated Deprecated in favor of using the single entry point for this type with ID and IDType fields. For example, instead of postBy( id: &quot;&quot; ), use post(id: &quot;&quot; idType: &quot;&quot;)
   */
  regionProfileBy?: Maybe<RegionProfile>;
  /** Connection between the RootQuery type and the RegionProfile type */
  regionProfiles?: Maybe<RootQueryToRegionProfileConnection>;
  /** Connection between the RootQuery type and the Region type */
  regions?: Maybe<RootQueryToRegionConnection>;
  /** Connection between the RootQuery type and the EnqueuedScript type */
  registeredScripts?: Maybe<RootQueryToEnqueuedScriptConnection>;
  /** Connection between the RootQuery type and the EnqueuedStylesheet type */
  registeredStylesheets?: Maybe<RootQueryToEnqueuedStylesheetConnection>;
  /** Connection between the RootQuery type and the ContentNode type */
  revisions?: Maybe<RootQueryToRevisionsConnection>;
  /** A 0bject */
  tag?: Maybe<Tag>;
  /** Connection between the RootQuery type and the tag type */
  tags?: Maybe<RootQueryToTagConnection>;
  /** Connection between the RootQuery type and the Taxonomy type */
  taxonomies?: Maybe<RootQueryToTaxonomyConnection>;
  /** Fetch a Taxonomy node by unique Identifier */
  taxonomy?: Maybe<Taxonomy>;
  /** A node in a taxonomy used to group and relate content nodes */
  termNode?: Maybe<TermNode>;
  /** Connection between the RootQuery type and the TermNode type */
  terms?: Maybe<RootQueryToTermNodeConnection>;
  /** A Theme object */
  theme?: Maybe<Theme>;
  /** Connection between the RootQuery type and the Theme type */
  themes?: Maybe<RootQueryToThemeConnection>;
  /** Translate string using pll_translate_string() (Polylang) */
  translateString?: Maybe<Scalars["String"]>;
  /** Returns a user */
  user?: Maybe<User>;
  /** Returns a user role */
  userRole?: Maybe<UserRole>;
  /** Connection between the RootQuery type and the UserRole type */
  userRoles?: Maybe<RootQueryToUserRoleConnection>;
  /** Connection between the RootQuery type and the User type */
  users?: Maybe<RootQueryToUserConnection>;
  /** Returns the current user */
  viewer?: Maybe<User>;
  /** Fields of the &#039;WritingSettings&#039; settings group */
  writingSettings?: Maybe<WritingSettings>;
};

/** The root entry point into the Graph */
export type RootQueryAllContentLanguagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToContentLanguagesConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryArticleArgs = {
  asPreview?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  idType?: InputMaybe<ArticleIdType>;
};

/** The root entry point into the Graph */
export type RootQueryArticleByArgs = {
  articleId?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  slug?: InputMaybe<Scalars["String"]>;
  uri?: InputMaybe<Scalars["String"]>;
};

/** The root entry point into the Graph */
export type RootQueryArticlesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToArticleConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToCategoryConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryCategoryArgs = {
  id: Scalars["ID"];
  idType?: InputMaybe<CategoryIdType>;
};

/** The root entry point into the Graph */
export type RootQueryCollectionArgs = {
  asPreview?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  idType?: InputMaybe<CollectionIdType>;
};

/** The root entry point into the Graph */
export type RootQueryCollectionByArgs = {
  collectionId?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  slug?: InputMaybe<Scalars["String"]>;
  uri?: InputMaybe<Scalars["String"]>;
};

/** The root entry point into the Graph */
export type RootQueryCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToCollectionConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryCommentArgs = {
  id: Scalars["ID"];
  idType?: InputMaybe<CommentNodeIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryCommentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToCommentConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryContentLanguagesArgs = {
  id: Scalars["ID"];
  idType?: InputMaybe<ContentLanguagesIdType>;
};

/** The root entry point into the Graph */
export type RootQueryContentNodeArgs = {
  asPreview?: InputMaybe<Scalars["Boolean"]>;
  contentType?: InputMaybe<ContentTypeEnum>;
  id: Scalars["ID"];
  idType?: InputMaybe<ContentNodeIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryContentNodesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToContentNodeConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryContentStatusArgs = {
  id: Scalars["ID"];
  idType?: InputMaybe<ContentStatusIdType>;
};

/** The root entry point into the Graph */
export type RootQueryContentStatusesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToContentStatusConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryContentTypeArgs = {
  id: Scalars["ID"];
  idType?: InputMaybe<ContentTypeIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryContentTypesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The root entry point into the Graph */
export type RootQueryCountriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToCountryConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryCountryArgs = {
  id: Scalars["ID"];
  idType?: InputMaybe<CountryIdType>;
};

/** The root entry point into the Graph */
export type RootQueryCountryProfileArgs = {
  asPreview?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  idType?: InputMaybe<CountryProfileIdType>;
};

/** The root entry point into the Graph */
export type RootQueryCountryProfileByArgs = {
  countryProfileId?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  uri?: InputMaybe<Scalars["String"]>;
};

/** The root entry point into the Graph */
export type RootQueryCountryProfilesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToCountryProfileConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryGlobalContentArgs = {
  asPreview?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  idType?: InputMaybe<GlobalContentIdType>;
};

/** The root entry point into the Graph */
export type RootQueryGlobalContentByArgs = {
  globalContentId?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  uri?: InputMaybe<Scalars["String"]>;
};

/** The root entry point into the Graph */
export type RootQueryGlobalContentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToGlobalContentConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryHomePageArgs = {
  asPreview?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  idType?: InputMaybe<HomePageIdType>;
};

/** The root entry point into the Graph */
export type RootQueryHomePageByArgs = {
  homePageId?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  uri?: InputMaybe<Scalars["String"]>;
};

/** The root entry point into the Graph */
export type RootQueryHomePagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToHomePageConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryHowItWorksPageArgs = {
  asPreview?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  idType?: InputMaybe<HowItWorksPageIdType>;
};

/** The root entry point into the Graph */
export type RootQueryHowItWorksPageByArgs = {
  howItWorksPageId?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["ID"]>;
  uri?: InputMaybe<Scalars["String"]>;
};

/** The root entry point into the Graph */
export type RootQueryHowItWorksPagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToHowItWorksPageConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryLocaleArgs = {
  id: Scalars["ID"];
  idType?: InputMaybe<LocaleIdType>;
};

/** The root entry point into the Graph */
export type RootQueryLocalesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToLocaleConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryMediaItemArgs = {
  asPreview?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  idType?: InputMaybe<MediaItemIdType>;
};

/** The root entry point into the Graph */
export type RootQueryMediaItemByArgs = {
  id?: InputMaybe<Scalars["ID"]>;
  mediaItemId?: InputMaybe<Scalars["Int"]>;
  slug?: InputMaybe<Scalars["String"]>;
  uri?: InputMaybe<Scalars["String"]>;
};

/** The root entry point into the Graph */
export type RootQueryMediaItemsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToMediaItemConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryMenuArgs = {
  id: Scalars["ID"];
  idType?: InputMaybe<MenuNodeIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryMenuItemArgs = {
  id: Scalars["ID"];
  idType?: InputMaybe<MenuItemNodeIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryMenuItemsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToMenuItemConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryMenusArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToMenuConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryNodeArgs = {
  id?: InputMaybe<Scalars["ID"]>;
};

/** The root entry point into the Graph */
export type RootQueryNodeByUriArgs = {
  uri: Scalars["String"];
};

/** The root entry point into the Graph */
export type RootQueryPageArgs = {
  asPreview?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  idType?: InputMaybe<PageIdType>;
};

/** The root entry point into the Graph */
export type RootQueryPageByArgs = {
  id?: InputMaybe<Scalars["ID"]>;
  pageId?: InputMaybe<Scalars["Int"]>;
  uri?: InputMaybe<Scalars["String"]>;
};

/** The root entry point into the Graph */
export type RootQueryPagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToPageConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryPluginArgs = {
  id: Scalars["ID"];
};

/** The root entry point into the Graph */
export type RootQueryPluginsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToPluginConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryPostArgs = {
  asPreview?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  idType?: InputMaybe<PostIdType>;
};

/** The root entry point into the Graph */
export type RootQueryPostByArgs = {
  id?: InputMaybe<Scalars["ID"]>;
  postId?: InputMaybe<Scalars["Int"]>;
  slug?: InputMaybe<Scalars["String"]>;
  uri?: InputMaybe<Scalars["String"]>;
};

/** The root entry point into the Graph */
export type RootQueryPostFormatArgs = {
  id: Scalars["ID"];
  idType?: InputMaybe<PostFormatIdType>;
};

/** The root entry point into the Graph */
export type RootQueryPostFormatsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToPostFormatConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryPostsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToPostConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryRegionArgs = {
  id: Scalars["ID"];
  idType?: InputMaybe<RegionIdType>;
};

/** The root entry point into the Graph */
export type RootQueryRegionProfileArgs = {
  asPreview?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  idType?: InputMaybe<RegionProfileIdType>;
};

/** The root entry point into the Graph */
export type RootQueryRegionProfileByArgs = {
  id?: InputMaybe<Scalars["ID"]>;
  regionProfileId?: InputMaybe<Scalars["Int"]>;
  uri?: InputMaybe<Scalars["String"]>;
};

/** The root entry point into the Graph */
export type RootQueryRegionProfilesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToRegionProfileConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryRegionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToRegionConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryRegisteredScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The root entry point into the Graph */
export type RootQueryRegisteredStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The root entry point into the Graph */
export type RootQueryRevisionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToRevisionsConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryTagArgs = {
  id: Scalars["ID"];
  idType?: InputMaybe<TagIdType>;
};

/** The root entry point into the Graph */
export type RootQueryTagsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToTagConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryTaxonomiesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The root entry point into the Graph */
export type RootQueryTaxonomyArgs = {
  id: Scalars["ID"];
  idType?: InputMaybe<TaxonomyIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryTermNodeArgs = {
  id: Scalars["ID"];
  idType?: InputMaybe<TermNodeIdTypeEnum>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
};

/** The root entry point into the Graph */
export type RootQueryTermsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToTermNodeConnectionWhereArgs>;
};

/** The root entry point into the Graph */
export type RootQueryThemeArgs = {
  id: Scalars["ID"];
};

/** The root entry point into the Graph */
export type RootQueryThemesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The root entry point into the Graph */
export type RootQueryTranslateStringArgs = {
  language: LanguageCodeEnum;
  string: Scalars["String"];
};

/** The root entry point into the Graph */
export type RootQueryUserArgs = {
  id: Scalars["ID"];
  idType?: InputMaybe<UserNodeIdTypeEnum>;
};

/** The root entry point into the Graph */
export type RootQueryUserRoleArgs = {
  id: Scalars["ID"];
};

/** The root entry point into the Graph */
export type RootQueryUserRolesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The root entry point into the Graph */
export type RootQueryUsersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<RootQueryToUserConnectionWhereArgs>;
};

/** Connection between the RootQuery type and the Article type */
export type RootQueryToArticleConnection = ArticleConnection &
  Connection & {
    __typename?: "RootQueryToArticleConnection";
    /** Edges for the RootQueryToArticleConnection connection */
    edges: Array<RootQueryToArticleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Article>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToArticleConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToArticleConnectionEdge = ArticleConnectionEdge &
  Edge & {
    __typename?: "RootQueryToArticleConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Article;
  };

/** Page Info on the &quot;RootQueryToArticleConnection&quot; */
export type RootQueryToArticleConnectionPageInfo = ArticleConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToArticleConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToArticleConnection connection */
export type RootQueryToArticleConnectionWhereArgs = {
  /** Category ID */
  categoryId?: InputMaybe<Scalars["Int"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars["String"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter by Articles by language code (Polylang) */
  language?: InputMaybe<LanguageCodeFilterEnum>;
  /** Filter Articles by one or more languages (Polylang) */
  languages?: InputMaybe<Array<LanguageCodeEnum>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the RootQuery type and the category type */
export type RootQueryToCategoryConnection = CategoryConnection &
  Connection & {
    __typename?: "RootQueryToCategoryConnection";
    /** Edges for the RootQueryToCategoryConnection connection */
    edges: Array<RootQueryToCategoryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Category>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToCategoryConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToCategoryConnectionEdge = CategoryConnectionEdge &
  Edge & {
    __typename?: "RootQueryToCategoryConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Category;
  };

/** Page Info on the &quot;RootQueryToCategoryConnection&quot; */
export type RootQueryToCategoryConnectionPageInfo = CategoryConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToCategoryConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToCategoryConnection connection */
export type RootQueryToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter by Categorys by language code (Polylang) */
  language?: InputMaybe<LanguageCodeFilterEnum>;
  /** Filter Categorys by one or more languages (Polylang) */
  languages?: InputMaybe<Array<LanguageCodeEnum>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the RootQuery type and the Collection type */
export type RootQueryToCollectionConnection = CollectionConnection &
  Connection & {
    __typename?: "RootQueryToCollectionConnection";
    /** Edges for the RootQueryToCollectionConnection connection */
    edges: Array<RootQueryToCollectionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Collection>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToCollectionConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToCollectionConnectionEdge = CollectionConnectionEdge &
  Edge & {
    __typename?: "RootQueryToCollectionConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Collection;
  };

/** Page Info on the &quot;RootQueryToCollectionConnection&quot; */
export type RootQueryToCollectionConnectionPageInfo = CollectionConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToCollectionConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToCollectionConnection connection */
export type RootQueryToCollectionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter by Collections by language code (Polylang) */
  language?: InputMaybe<LanguageCodeFilterEnum>;
  /** Filter Collections by one or more languages (Polylang) */
  languages?: InputMaybe<Array<LanguageCodeEnum>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the RootQuery type and the Comment type */
export type RootQueryToCommentConnection = CommentConnection &
  Connection & {
    __typename?: "RootQueryToCommentConnection";
    /** Edges for the RootQueryToCommentConnection connection */
    edges: Array<RootQueryToCommentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Comment>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToCommentConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: "RootQueryToCommentConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Comment;
  };

/** Page Info on the &quot;RootQueryToCommentConnection&quot; */
export type RootQueryToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToCommentConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToCommentConnection connection */
export type RootQueryToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars["String"]>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars["String"]>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars["String"]>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars["String"]>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars["ID"]>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars["String"]>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars["Int"]>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars["Int"]>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars["String"]>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars["String"]>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars["ID"]>;
};

/** Connection between the RootQuery type and the ContentLanguages type */
export type RootQueryToContentLanguagesConnection = Connection &
  ContentLanguagesConnection & {
    __typename?: "RootQueryToContentLanguagesConnection";
    /** Edges for the RootQueryToContentLanguagesConnection connection */
    edges: Array<RootQueryToContentLanguagesConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentLanguages>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToContentLanguagesConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToContentLanguagesConnectionEdge = ContentLanguagesConnectionEdge &
  Edge & {
    __typename?: "RootQueryToContentLanguagesConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentLanguages;
  };

/** Page Info on the &quot;RootQueryToContentLanguagesConnection&quot; */
export type RootQueryToContentLanguagesConnectionPageInfo = ContentLanguagesConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToContentLanguagesConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToContentLanguagesConnection connection */
export type RootQueryToContentLanguagesConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the RootQuery type and the ContentNode type */
export type RootQueryToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: "RootQueryToContentNodeConnection";
    /** Edges for the RootQueryToContentNodeConnection connection */
    edges: Array<RootQueryToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: "RootQueryToContentNodeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;RootQueryToContentNodeConnection&quot; */
export type RootQueryToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToContentNodeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToContentNodeConnection connection */
export type RootQueryToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter content nodes by language code (Polylang) */
  language?: InputMaybe<LanguageCodeFilterEnum>;
  /** Filter content nodes by one or more languages (Polylang) */
  languages?: InputMaybe<Array<LanguageCodeEnum>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the RootQuery type and the ContentStatus type */
export type RootQueryToContentStatusConnection = Connection &
  ContentStatusConnection & {
    __typename?: "RootQueryToContentStatusConnection";
    /** Edges for the RootQueryToContentStatusConnection connection */
    edges: Array<RootQueryToContentStatusConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentStatus>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToContentStatusConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToContentStatusConnectionEdge = ContentStatusConnectionEdge &
  Edge & {
    __typename?: "RootQueryToContentStatusConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentStatus;
  };

/** Page Info on the &quot;RootQueryToContentStatusConnection&quot; */
export type RootQueryToContentStatusConnectionPageInfo = ContentStatusConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToContentStatusConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToContentStatusConnection connection */
export type RootQueryToContentStatusConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the RootQuery type and the ContentType type */
export type RootQueryToContentTypeConnection = Connection &
  ContentTypeConnection & {
    __typename?: "RootQueryToContentTypeConnection";
    /** Edges for the RootQueryToContentTypeConnection connection */
    edges: Array<RootQueryToContentTypeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentType>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToContentTypeConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToContentTypeConnectionEdge = ContentTypeConnectionEdge &
  Edge & {
    __typename?: "RootQueryToContentTypeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentType;
  };

/** Page Info on the &quot;RootQueryToContentTypeConnection&quot; */
export type RootQueryToContentTypeConnectionPageInfo = ContentTypeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToContentTypeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Connection between the RootQuery type and the Country type */
export type RootQueryToCountryConnection = Connection &
  CountryConnection & {
    __typename?: "RootQueryToCountryConnection";
    /** Edges for the RootQueryToCountryConnection connection */
    edges: Array<RootQueryToCountryConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Country>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToCountryConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToCountryConnectionEdge = CountryConnectionEdge &
  Edge & {
    __typename?: "RootQueryToCountryConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Country;
  };

/** Page Info on the &quot;RootQueryToCountryConnection&quot; */
export type RootQueryToCountryConnectionPageInfo = CountryConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToCountryConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToCountryConnection connection */
export type RootQueryToCountryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the RootQuery type and the CountryProfile type */
export type RootQueryToCountryProfileConnection = Connection &
  CountryProfileConnection & {
    __typename?: "RootQueryToCountryProfileConnection";
    /** Edges for the RootQueryToCountryProfileConnection connection */
    edges: Array<RootQueryToCountryProfileConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<CountryProfile>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToCountryProfileConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToCountryProfileConnectionEdge = CountryProfileConnectionEdge &
  Edge & {
    __typename?: "RootQueryToCountryProfileConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: CountryProfile;
  };

/** Page Info on the &quot;RootQueryToCountryProfileConnection&quot; */
export type RootQueryToCountryProfileConnectionPageInfo = CountryProfileConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToCountryProfileConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToCountryProfileConnection connection */
export type RootQueryToCountryProfileConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
  /** Filter by the "url_fragment" custom field */
  urlFragment?: InputMaybe<Scalars["String"]>;
};

/** Connection between the RootQuery type and the EnqueuedScript type */
export type RootQueryToEnqueuedScriptConnection = Connection &
  EnqueuedScriptConnection & {
    __typename?: "RootQueryToEnqueuedScriptConnection";
    /** Edges for the RootQueryToEnqueuedScriptConnection connection */
    edges: Array<RootQueryToEnqueuedScriptConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedScript>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToEnqueuedScriptConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToEnqueuedScriptConnectionEdge = Edge &
  EnqueuedScriptConnectionEdge & {
    __typename?: "RootQueryToEnqueuedScriptConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: EnqueuedScript;
  };

/** Page Info on the &quot;RootQueryToEnqueuedScriptConnection&quot; */
export type RootQueryToEnqueuedScriptConnectionPageInfo = EnqueuedScriptConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToEnqueuedScriptConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Connection between the RootQuery type and the EnqueuedStylesheet type */
export type RootQueryToEnqueuedStylesheetConnection = Connection &
  EnqueuedStylesheetConnection & {
    __typename?: "RootQueryToEnqueuedStylesheetConnection";
    /** Edges for the RootQueryToEnqueuedStylesheetConnection connection */
    edges: Array<RootQueryToEnqueuedStylesheetConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedStylesheet>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToEnqueuedStylesheetConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToEnqueuedStylesheetConnectionEdge = Edge &
  EnqueuedStylesheetConnectionEdge & {
    __typename?: "RootQueryToEnqueuedStylesheetConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: EnqueuedStylesheet;
  };

/** Page Info on the &quot;RootQueryToEnqueuedStylesheetConnection&quot; */
export type RootQueryToEnqueuedStylesheetConnectionPageInfo = EnqueuedStylesheetConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToEnqueuedStylesheetConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Connection between the RootQuery type and the GlobalContent type */
export type RootQueryToGlobalContentConnection = Connection &
  GlobalContentConnection & {
    __typename?: "RootQueryToGlobalContentConnection";
    /** Edges for the RootQueryToGlobalContentConnection connection */
    edges: Array<RootQueryToGlobalContentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<GlobalContent>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToGlobalContentConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToGlobalContentConnectionEdge = Edge &
  GlobalContentConnectionEdge & {
    __typename?: "RootQueryToGlobalContentConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: GlobalContent;
  };

/** Page Info on the &quot;RootQueryToGlobalContentConnection&quot; */
export type RootQueryToGlobalContentConnectionPageInfo = GlobalContentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToGlobalContentConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToGlobalContentConnection connection */
export type RootQueryToGlobalContentConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the RootQuery type and the HomePage type */
export type RootQueryToHomePageConnection = Connection &
  HomePageConnection & {
    __typename?: "RootQueryToHomePageConnection";
    /** Edges for the RootQueryToHomePageConnection connection */
    edges: Array<RootQueryToHomePageConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<HomePage>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToHomePageConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToHomePageConnectionEdge = Edge &
  HomePageConnectionEdge & {
    __typename?: "RootQueryToHomePageConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: HomePage;
  };

/** Page Info on the &quot;RootQueryToHomePageConnection&quot; */
export type RootQueryToHomePageConnectionPageInfo = HomePageConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToHomePageConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToHomePageConnection connection */
export type RootQueryToHomePageConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter by HomePages by language code (Polylang) */
  language?: InputMaybe<LanguageCodeFilterEnum>;
  /** Filter HomePages by one or more languages (Polylang) */
  languages?: InputMaybe<Array<LanguageCodeEnum>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the RootQuery type and the HowItWorksPage type */
export type RootQueryToHowItWorksPageConnection = Connection &
  HowItWorksPageConnection & {
    __typename?: "RootQueryToHowItWorksPageConnection";
    /** Edges for the RootQueryToHowItWorksPageConnection connection */
    edges: Array<RootQueryToHowItWorksPageConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<HowItWorksPage>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToHowItWorksPageConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToHowItWorksPageConnectionEdge = Edge &
  HowItWorksPageConnectionEdge & {
    __typename?: "RootQueryToHowItWorksPageConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: HowItWorksPage;
  };

/** Page Info on the &quot;RootQueryToHowItWorksPageConnection&quot; */
export type RootQueryToHowItWorksPageConnectionPageInfo = HowItWorksPageConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToHowItWorksPageConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToHowItWorksPageConnection connection */
export type RootQueryToHowItWorksPageConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter by HowItWorksPages by language code (Polylang) */
  language?: InputMaybe<LanguageCodeFilterEnum>;
  /** Filter HowItWorksPages by one or more languages (Polylang) */
  languages?: InputMaybe<Array<LanguageCodeEnum>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the RootQuery type and the Locale type */
export type RootQueryToLocaleConnection = Connection &
  LocaleConnection & {
    __typename?: "RootQueryToLocaleConnection";
    /** Edges for the RootQueryToLocaleConnection connection */
    edges: Array<RootQueryToLocaleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Locale>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToLocaleConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToLocaleConnectionEdge = Edge &
  LocaleConnectionEdge & {
    __typename?: "RootQueryToLocaleConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Locale;
  };

/** Page Info on the &quot;RootQueryToLocaleConnection&quot; */
export type RootQueryToLocaleConnectionPageInfo = LocaleConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToLocaleConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToLocaleConnection connection */
export type RootQueryToLocaleConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the RootQuery type and the mediaItem type */
export type RootQueryToMediaItemConnection = Connection &
  MediaItemConnection & {
    __typename?: "RootQueryToMediaItemConnection";
    /** Edges for the RootQueryToMediaItemConnection connection */
    edges: Array<RootQueryToMediaItemConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<MediaItem>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToMediaItemConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToMediaItemConnectionEdge = Edge &
  MediaItemConnectionEdge & {
    __typename?: "RootQueryToMediaItemConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: MediaItem;
  };

/** Page Info on the &quot;RootQueryToMediaItemConnection&quot; */
export type RootQueryToMediaItemConnectionPageInfo = MediaItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToMediaItemConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToMediaItemConnection connection */
export type RootQueryToMediaItemConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the RootQuery type and the Menu type */
export type RootQueryToMenuConnection = Connection &
  MenuConnection & {
    __typename?: "RootQueryToMenuConnection";
    /** Edges for the RootQueryToMenuConnection connection */
    edges: Array<RootQueryToMenuConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Menu>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToMenuConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToMenuConnectionEdge = Edge &
  MenuConnectionEdge & {
    __typename?: "RootQueryToMenuConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Menu;
  };

/** Page Info on the &quot;RootQueryToMenuConnection&quot; */
export type RootQueryToMenuConnectionPageInfo = MenuConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToMenuConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToMenuConnection connection */
export type RootQueryToMenuConnectionWhereArgs = {
  /** The database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>;
  /** The slug of the menu to query items for */
  slug?: InputMaybe<Scalars["String"]>;
};

/** Connection between the RootQuery type and the MenuItem type */
export type RootQueryToMenuItemConnection = Connection &
  MenuItemConnection & {
    __typename?: "RootQueryToMenuItemConnection";
    /** Edges for the RootQueryToMenuItemConnection connection */
    edges: Array<RootQueryToMenuItemConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<MenuItem>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToMenuItemConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToMenuItemConnectionEdge = Edge &
  MenuItemConnectionEdge & {
    __typename?: "RootQueryToMenuItemConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: MenuItem;
  };

/** Page Info on the &quot;RootQueryToMenuItemConnection&quot; */
export type RootQueryToMenuItemConnectionPageInfo = MenuItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToMenuItemConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToMenuItemConnection connection */
export type RootQueryToMenuItemConnectionWhereArgs = {
  /** The database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  language?: InputMaybe<LanguageCodeFilterEnum>;
  /** The menu location for the menu being queried */
  location?: InputMaybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: InputMaybe<Scalars["Int"]>;
  /** The ID of the parent menu object */
  parentId?: InputMaybe<Scalars["ID"]>;
};

/** Connection between the RootQuery type and the page type */
export type RootQueryToPageConnection = Connection &
  PageConnection & {
    __typename?: "RootQueryToPageConnection";
    /** Edges for the RootQueryToPageConnection connection */
    edges: Array<RootQueryToPageConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Page>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToPageConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToPageConnectionEdge = Edge &
  PageConnectionEdge & {
    __typename?: "RootQueryToPageConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Page;
  };

/** Page Info on the &quot;RootQueryToPageConnection&quot; */
export type RootQueryToPageConnectionPageInfo = PageConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToPageConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToPageConnection connection */
export type RootQueryToPageConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter by Pages by language code (Polylang) */
  language?: InputMaybe<LanguageCodeFilterEnum>;
  /** Filter Pages by one or more languages (Polylang) */
  languages?: InputMaybe<Array<LanguageCodeEnum>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the RootQuery type and the Plugin type */
export type RootQueryToPluginConnection = Connection &
  PluginConnection & {
    __typename?: "RootQueryToPluginConnection";
    /** Edges for the RootQueryToPluginConnection connection */
    edges: Array<RootQueryToPluginConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Plugin>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToPluginConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToPluginConnectionEdge = Edge &
  PluginConnectionEdge & {
    __typename?: "RootQueryToPluginConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Plugin;
  };

/** Page Info on the &quot;RootQueryToPluginConnection&quot; */
export type RootQueryToPluginConnectionPageInfo = PageInfo &
  PluginConnectionPageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToPluginConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToPluginConnection connection */
export type RootQueryToPluginConnectionWhereArgs = {
  /** Show plugin based on a keyword search. */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve plugins where plugin status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PluginStatusEnum>>>;
  /** Show plugins with a specific status. */
  status?: InputMaybe<PluginStatusEnum>;
};

/** Connection between the RootQuery type and the post type */
export type RootQueryToPostConnection = Connection &
  PostConnection & {
    __typename?: "RootQueryToPostConnection";
    /** Edges for the RootQueryToPostConnection connection */
    edges: Array<RootQueryToPostConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Post>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToPostConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: "RootQueryToPostConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Post;
  };

/** Page Info on the &quot;RootQueryToPostConnection&quot; */
export type RootQueryToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToPostConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToPostConnection connection */
export type RootQueryToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars["Int"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars["String"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter by Posts by language code (Polylang) */
  language?: InputMaybe<LanguageCodeFilterEnum>;
  /** Filter Posts by one or more languages (Polylang) */
  languages?: InputMaybe<Array<LanguageCodeEnum>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the RootQuery type and the postFormat type */
export type RootQueryToPostFormatConnection = Connection &
  PostFormatConnection & {
    __typename?: "RootQueryToPostFormatConnection";
    /** Edges for the RootQueryToPostFormatConnection connection */
    edges: Array<RootQueryToPostFormatConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<PostFormat>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToPostFormatConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToPostFormatConnectionEdge = Edge &
  PostFormatConnectionEdge & {
    __typename?: "RootQueryToPostFormatConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: PostFormat;
  };

/** Page Info on the &quot;RootQueryToPostFormatConnection&quot; */
export type RootQueryToPostFormatConnectionPageInfo = PageInfo &
  PostFormatConnectionPageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToPostFormatConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToPostFormatConnection connection */
export type RootQueryToPostFormatConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the RootQuery type and the Region type */
export type RootQueryToRegionConnection = Connection &
  RegionConnection & {
    __typename?: "RootQueryToRegionConnection";
    /** Edges for the RootQueryToRegionConnection connection */
    edges: Array<RootQueryToRegionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Region>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToRegionConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToRegionConnectionEdge = Edge &
  RegionConnectionEdge & {
    __typename?: "RootQueryToRegionConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Region;
  };

/** Page Info on the &quot;RootQueryToRegionConnection&quot; */
export type RootQueryToRegionConnectionPageInfo = PageInfo &
  RegionConnectionPageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToRegionConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToRegionConnection connection */
export type RootQueryToRegionConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the RootQuery type and the RegionProfile type */
export type RootQueryToRegionProfileConnection = Connection &
  RegionProfileConnection & {
    __typename?: "RootQueryToRegionProfileConnection";
    /** Edges for the RootQueryToRegionProfileConnection connection */
    edges: Array<RootQueryToRegionProfileConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<RegionProfile>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToRegionProfileConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToRegionProfileConnectionEdge = Edge &
  RegionProfileConnectionEdge & {
    __typename?: "RootQueryToRegionProfileConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: RegionProfile;
  };

/** Page Info on the &quot;RootQueryToRegionProfileConnection&quot; */
export type RootQueryToRegionProfileConnectionPageInfo = PageInfo &
  RegionProfileConnectionPageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToRegionProfileConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToRegionProfileConnection connection */
export type RootQueryToRegionProfileConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
  /** Filter by the "url_fragment" custom field */
  urlFragment?: InputMaybe<Scalars["String"]>;
};

/** Connection between the RootQuery type and the ContentNode type */
export type RootQueryToRevisionsConnection = Connection &
  ContentNodeConnection & {
    __typename?: "RootQueryToRevisionsConnection";
    /** Edges for the RootQueryToRevisionsConnection connection */
    edges: Array<RootQueryToRevisionsConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToRevisionsConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToRevisionsConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: "RootQueryToRevisionsConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;RootQueryToRevisionsConnection&quot; */
export type RootQueryToRevisionsConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToRevisionsConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToRevisionsConnection connection */
export type RootQueryToRevisionsConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the RootQuery type and the tag type */
export type RootQueryToTagConnection = Connection &
  TagConnection & {
    __typename?: "RootQueryToTagConnection";
    /** Edges for the RootQueryToTagConnection connection */
    edges: Array<RootQueryToTagConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Tag>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToTagConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToTagConnectionEdge = Edge &
  TagConnectionEdge & {
    __typename?: "RootQueryToTagConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Tag;
  };

/** Page Info on the &quot;RootQueryToTagConnection&quot; */
export type RootQueryToTagConnectionPageInfo = PageInfo &
  TagConnectionPageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToTagConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToTagConnection connection */
export type RootQueryToTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter by Tags by language code (Polylang) */
  language?: InputMaybe<LanguageCodeFilterEnum>;
  /** Filter Tags by one or more languages (Polylang) */
  languages?: InputMaybe<Array<LanguageCodeEnum>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the RootQuery type and the Taxonomy type */
export type RootQueryToTaxonomyConnection = Connection &
  TaxonomyConnection & {
    __typename?: "RootQueryToTaxonomyConnection";
    /** Edges for the RootQueryToTaxonomyConnection connection */
    edges: Array<RootQueryToTaxonomyConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Taxonomy>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToTaxonomyConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToTaxonomyConnectionEdge = Edge &
  TaxonomyConnectionEdge & {
    __typename?: "RootQueryToTaxonomyConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Taxonomy;
  };

/** Page Info on the &quot;RootQueryToTaxonomyConnection&quot; */
export type RootQueryToTaxonomyConnectionPageInfo = PageInfo &
  TaxonomyConnectionPageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToTaxonomyConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Connection between the RootQuery type and the TermNode type */
export type RootQueryToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: "RootQueryToTermNodeConnection";
    /** Edges for the RootQueryToTermNodeConnection connection */
    edges: Array<RootQueryToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: "RootQueryToTermNodeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;RootQueryToTermNodeConnection&quot; */
export type RootQueryToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToTermNodeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToTermNodeConnection connection */
export type RootQueryToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: InputMaybe<Scalars["String"]>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: InputMaybe<Scalars["Int"]>;
  /** True to limit results to terms that have no children. This parameter has no effect on non-hierarchical taxonomies. Default false. */
  childless?: InputMaybe<Scalars["Boolean"]>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: InputMaybe<Scalars["String"]>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term ids to exclude along with all of their descendant terms. If $include is non-empty, $exclude_tree is ignored. Default empty array. */
  excludeTree?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: InputMaybe<Scalars["Boolean"]>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: InputMaybe<Scalars["Boolean"]>;
  /** Array of term ids to include. Default empty array. */
  include?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: InputMaybe<Scalars["String"]>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Direction the connection should be ordered in */
  order?: InputMaybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: InputMaybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: InputMaybe<Scalars["Boolean"]>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: InputMaybe<Array<InputMaybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomyId?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: InputMaybe<Scalars["Boolean"]>;
};

/** Connection between the RootQuery type and the Theme type */
export type RootQueryToThemeConnection = Connection &
  ThemeConnection & {
    __typename?: "RootQueryToThemeConnection";
    /** Edges for the RootQueryToThemeConnection connection */
    edges: Array<RootQueryToThemeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Theme>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToThemeConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToThemeConnectionEdge = Edge &
  ThemeConnectionEdge & {
    __typename?: "RootQueryToThemeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Theme;
  };

/** Page Info on the &quot;RootQueryToThemeConnection&quot; */
export type RootQueryToThemeConnectionPageInfo = PageInfo &
  ThemeConnectionPageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToThemeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Connection between the RootQuery type and the User type */
export type RootQueryToUserConnection = Connection &
  UserConnection & {
    __typename?: "RootQueryToUserConnection";
    /** Edges for the RootQueryToUserConnection connection */
    edges: Array<RootQueryToUserConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<User>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToUserConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToUserConnectionEdge = Edge &
  UserConnectionEdge & {
    __typename?: "RootQueryToUserConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: User;
  };

/** Page Info on the &quot;RootQueryToUserConnection&quot; */
export type RootQueryToUserConnectionPageInfo = PageInfo &
  UserConnectionPageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToUserConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the RootQueryToUserConnection connection */
export type RootQueryToUserConnectionWhereArgs = {
  /** Array of userIds to exclude. */
  exclude?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  /** Pass an array of post types to filter results to users who have published posts in those post types. */
  hasPublishedPosts?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of userIds to include. */
  include?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  /** The user login. */
  login?: InputMaybe<Scalars["String"]>;
  /** An array of logins to include. Users matching one of these logins will be included in results. */
  loginIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** An array of logins to exclude. Users matching one of these logins will not be included in results. */
  loginNotIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** The user nicename. */
  nicename?: InputMaybe<Scalars["String"]>;
  /** An array of nicenames to include. Users matching one of these nicenames will be included in results. */
  nicenameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** An array of nicenames to exclude. Users matching one of these nicenames will not be included in results. */
  nicenameNotIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<UsersConnectionOrderbyInput>>>;
  /** An array of role names that users must match to be included in results. Note that this is an inclusive list: users must match *each* role. */
  role?: InputMaybe<UserRoleEnum>;
  /** An array of role names. Matched users must have at least one of these roles. */
  roleIn?: InputMaybe<Array<InputMaybe<UserRoleEnum>>>;
  /** An array of role names to exclude. Users matching one or more of these roles will not be included in results. */
  roleNotIn?: InputMaybe<Array<InputMaybe<UserRoleEnum>>>;
  /** Search keyword. Searches for possible string matches on columns. When "searchColumns" is left empty, it tries to determine which column to search in based on search string. */
  search?: InputMaybe<Scalars["String"]>;
  /** Array of column names to be searched. Accepts 'ID', 'login', 'nicename', 'email', 'url'. */
  searchColumns?: InputMaybe<Array<InputMaybe<UsersConnectionSearchColumnEnum>>>;
};

/** Connection between the RootQuery type and the UserRole type */
export type RootQueryToUserRoleConnection = Connection &
  UserRoleConnection & {
    __typename?: "RootQueryToUserRoleConnection";
    /** Edges for the RootQueryToUserRoleConnection connection */
    edges: Array<RootQueryToUserRoleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<UserRole>;
    /** Information about pagination in a connection. */
    pageInfo: RootQueryToUserRoleConnectionPageInfo;
  };

/** An edge in a connection */
export type RootQueryToUserRoleConnectionEdge = Edge &
  UserRoleConnectionEdge & {
    __typename?: "RootQueryToUserRoleConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: UserRole;
  };

/** Page Info on the &quot;RootQueryToUserRoleConnection&quot; */
export type RootQueryToUserRoleConnectionPageInfo = PageInfo &
  UserRoleConnectionPageInfo &
  WpPageInfo & {
    __typename?: "RootQueryToUserRoleConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** The strategy to use when loading the script */
export enum ScriptLoadingStrategyEnum {
  /** Use the script `async` attribute */
  Async = "ASYNC",
  /** Use the script `defer` attribute */
  Defer = "DEFER",
}

/** Input for the sendPasswordResetEmail mutation. */
export type SendPasswordResetEmailInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A string that contains the user's username or email address. */
  username: Scalars["String"];
};

/** The payload for the sendPasswordResetEmail mutation. */
export type SendPasswordResetEmailPayload = {
  __typename?: "SendPasswordResetEmailPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Whether the mutation completed successfully. This does NOT necessarily mean that an email was sent. */
  success?: Maybe<Scalars["Boolean"]>;
  /**
   * The user that the password reset email was sent to
   * @deprecated This field will be removed in a future version of WPGraphQL
   */
  user?: Maybe<User>;
};

/** All of the registered settings */
export type Settings = {
  __typename?: "Settings";
  /** Settings of the the string Settings Group */
  discussionSettingsDefaultCommentStatus?: Maybe<Scalars["String"]>;
  /** Settings of the the string Settings Group */
  discussionSettingsDefaultPingStatus?: Maybe<Scalars["String"]>;
  /** Settings of the the string Settings Group */
  generalSettingsDateFormat?: Maybe<Scalars["String"]>;
  /** Settings of the the string Settings Group */
  generalSettingsDescription?: Maybe<Scalars["String"]>;
  /** Settings of the the string Settings Group */
  generalSettingsEmail?: Maybe<Scalars["String"]>;
  /** Settings of the the string Settings Group */
  generalSettingsLanguage?: Maybe<Scalars["String"]>;
  /** Settings of the the integer Settings Group */
  generalSettingsStartOfWeek?: Maybe<Scalars["Int"]>;
  /** Settings of the the string Settings Group */
  generalSettingsTimeFormat?: Maybe<Scalars["String"]>;
  /** Settings of the the string Settings Group */
  generalSettingsTimezone?: Maybe<Scalars["String"]>;
  /** Settings of the the string Settings Group */
  generalSettingsTitle?: Maybe<Scalars["String"]>;
  /** Settings of the the string Settings Group */
  generalSettingsUrl?: Maybe<Scalars["String"]>;
  /** Settings of the the integer Settings Group */
  readingSettingsPageForPosts?: Maybe<Scalars["Int"]>;
  /** Settings of the the integer Settings Group */
  readingSettingsPageOnFront?: Maybe<Scalars["Int"]>;
  /** Settings of the the integer Settings Group */
  readingSettingsPostsPerPage?: Maybe<Scalars["Int"]>;
  /** Settings of the the string Settings Group */
  readingSettingsShowOnFront?: Maybe<Scalars["String"]>;
  /** Settings of the the integer Settings Group */
  writingSettingsDefaultCategory?: Maybe<Scalars["Int"]>;
  /** Settings of the the string Settings Group */
  writingSettingsDefaultPostFormat?: Maybe<Scalars["String"]>;
  /** Settings of the the boolean Settings Group */
  writingSettingsUseSmilies?: Maybe<Scalars["Boolean"]>;
};

/** The tag type */
export type Tag = DatabaseIdentifier &
  MenuItemLinkable &
  Node &
  TermNode &
  UniformResourceIdentifiable & {
    __typename?: "Tag";
    /** Connection between the Tag type and the Article type */
    articles?: Maybe<TagToArticleConnection>;
    /** Connection between the Tag type and the Collection type */
    collections?: Maybe<TagToCollectionConnection>;
    /** Connection between the Tag type and the ContentNode type */
    contentNodes?: Maybe<TagToContentNodeConnection>;
    /** The number of objects connected to the object */
    count?: Maybe<Scalars["Int"]>;
    /** Connection between the Tag type and the CountryProfile type */
    countryProfiles?: Maybe<TagToCountryProfileConnection>;
    /** The unique identifier stored in the database */
    databaseId: Scalars["Int"];
    /** The description of the object */
    description?: Maybe<Scalars["String"]>;
    /** Connection between the TermNode type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
    /** Connection between the TermNode type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
    /** The unique resource identifier path */
    id: Scalars["ID"];
    /** Whether the node is a Content Node */
    isContentNode: Scalars["Boolean"];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** Whether the node is a Term */
    isTermNode: Scalars["Boolean"];
    /** List available translations for this post */
    language?: Maybe<Language>;
    /** The link to the term */
    link?: Maybe<Scalars["String"]>;
    /** The human friendly name of the object. */
    name?: Maybe<Scalars["String"]>;
    /** Connection between the Tag type and the post type */
    posts?: Maybe<TagToPostConnection>;
    /** An alphanumeric identifier for the object unique to its type. */
    slug?: Maybe<Scalars["String"]>;
    /**
     * The id field matches the WP_Post-&gt;ID field.
     * @deprecated Deprecated in favor of databaseId
     */
    tagId?: Maybe<Scalars["Int"]>;
    /** Connection between the Tag type and the Taxonomy type */
    taxonomy?: Maybe<TagToTaxonomyConnectionEdge>;
    /** The name of the taxonomy that the object is associated with */
    taxonomyName?: Maybe<Scalars["String"]>;
    /** The ID of the term group that this term object belongs to */
    termGroupId?: Maybe<Scalars["Int"]>;
    /** The taxonomy ID that the object is associated with */
    termTaxonomyId?: Maybe<Scalars["Int"]>;
    /** Get specific translation version of this object */
    translation?: Maybe<Tag>;
    /** List all translated versions of this term */
    translations?: Maybe<Array<Maybe<Tag>>>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars["String"]>;
  };

/** The tag type */
export type TagArticlesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TagToArticleConnectionWhereArgs>;
};

/** The tag type */
export type TagCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TagToCollectionConnectionWhereArgs>;
};

/** The tag type */
export type TagContentNodesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TagToContentNodeConnectionWhereArgs>;
};

/** The tag type */
export type TagCountryProfilesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TagToCountryProfileConnectionWhereArgs>;
};

/** The tag type */
export type TagEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The tag type */
export type TagEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** The tag type */
export type TagPostsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<TagToPostConnectionWhereArgs>;
};

/** The tag type */
export type TagTranslationArgs = {
  language: LanguageCodeEnum;
};

/** Connection to tag Nodes */
export type TagConnection = {
  /** A list of edges (relational context) between RootQuery and connected tag Nodes */
  edges: Array<TagConnectionEdge>;
  /** A list of connected tag Nodes */
  nodes: Array<Tag>;
  /** Information about pagination in a connection. */
  pageInfo: TagConnectionPageInfo;
};

/** Edge between a Node and a connected tag */
export type TagConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected tag Node */
  node: Tag;
};

/** Page Info on the connected TagConnectionEdge */
export type TagConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum TagIdType {
  /** The Database ID for the node */
  DatabaseId = "DATABASE_ID",
  /** The hashed Global ID */
  Id = "ID",
  /** The name of the node */
  Name = "NAME",
  /** Url friendly name of the node */
  Slug = "SLUG",
  /** The URI for the node */
  Uri = "URI",
}

/** Connection between the Tag type and the Article type */
export type TagToArticleConnection = ArticleConnection &
  Connection & {
    __typename?: "TagToArticleConnection";
    /** Edges for the TagToArticleConnection connection */
    edges: Array<TagToArticleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Article>;
    /** Information about pagination in a connection. */
    pageInfo: TagToArticleConnectionPageInfo;
  };

/** An edge in a connection */
export type TagToArticleConnectionEdge = ArticleConnectionEdge &
  Edge & {
    __typename?: "TagToArticleConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Article;
  };

/** Page Info on the &quot;TagToArticleConnection&quot; */
export type TagToArticleConnectionPageInfo = ArticleConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "TagToArticleConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the TagToArticleConnection connection */
export type TagToArticleConnectionWhereArgs = {
  /** Category ID */
  categoryId?: InputMaybe<Scalars["Int"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars["String"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Tag type and the Collection type */
export type TagToCollectionConnection = CollectionConnection &
  Connection & {
    __typename?: "TagToCollectionConnection";
    /** Edges for the TagToCollectionConnection connection */
    edges: Array<TagToCollectionConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Collection>;
    /** Information about pagination in a connection. */
    pageInfo: TagToCollectionConnectionPageInfo;
  };

/** An edge in a connection */
export type TagToCollectionConnectionEdge = CollectionConnectionEdge &
  Edge & {
    __typename?: "TagToCollectionConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Collection;
  };

/** Page Info on the &quot;TagToCollectionConnection&quot; */
export type TagToCollectionConnectionPageInfo = CollectionConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "TagToCollectionConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the TagToCollectionConnection connection */
export type TagToCollectionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Tag type and the ContentNode type */
export type TagToContentNodeConnection = Connection &
  ContentNodeConnection & {
    __typename?: "TagToContentNodeConnection";
    /** Edges for the TagToContentNodeConnection connection */
    edges: Array<TagToContentNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: TagToContentNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type TagToContentNodeConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: "TagToContentNodeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;TagToContentNodeConnection&quot; */
export type TagToContentNodeConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "TagToContentNodeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the TagToContentNodeConnection connection */
export type TagToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypesOfTagEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Tag type and the CountryProfile type */
export type TagToCountryProfileConnection = Connection &
  CountryProfileConnection & {
    __typename?: "TagToCountryProfileConnection";
    /** Edges for the TagToCountryProfileConnection connection */
    edges: Array<TagToCountryProfileConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<CountryProfile>;
    /** Information about pagination in a connection. */
    pageInfo: TagToCountryProfileConnectionPageInfo;
  };

/** An edge in a connection */
export type TagToCountryProfileConnectionEdge = CountryProfileConnectionEdge &
  Edge & {
    __typename?: "TagToCountryProfileConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: CountryProfile;
  };

/** Page Info on the &quot;TagToCountryProfileConnection&quot; */
export type TagToCountryProfileConnectionPageInfo = CountryProfileConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "TagToCountryProfileConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the TagToCountryProfileConnection connection */
export type TagToCountryProfileConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Tag type and the post type */
export type TagToPostConnection = Connection &
  PostConnection & {
    __typename?: "TagToPostConnection";
    /** Edges for the TagToPostConnection connection */
    edges: Array<TagToPostConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Post>;
    /** Information about pagination in a connection. */
    pageInfo: TagToPostConnectionPageInfo;
  };

/** An edge in a connection */
export type TagToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: "TagToPostConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Post;
  };

/** Page Info on the &quot;TagToPostConnection&quot; */
export type TagToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: "TagToPostConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the TagToPostConnection connection */
export type TagToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars["Int"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars["String"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the Tag type and the Taxonomy type */
export type TagToTaxonomyConnectionEdge = Edge &
  OneToOneConnection &
  TaxonomyConnectionEdge & {
    __typename?: "TagToTaxonomyConnectionEdge";
    /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
    cursor?: Maybe<Scalars["String"]>;
    /** The node of the connection, without the edges */
    node: Taxonomy;
  };

export type TaxArray = {
  field?: InputMaybe<TaxQueryField>;
  /** Whether or not to include children for hierarchical taxonomies. Defaults to false to improve performance (note that this is opposite of the default for WP_Query). */
  includeChildren?: InputMaybe<Scalars["Boolean"]>;
  operator?: InputMaybe<TaxQueryOperator>;
  taxonomy?: InputMaybe<TaxonomyEnum>;
  /** A list of term slugs */
  terms?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** Query objects based on taxonomy parameters */
export type TaxQuery = {
  relation?: InputMaybe<RelationEnum>;
  taxArray?: InputMaybe<Array<InputMaybe<TaxArray>>>;
};

/** Which field to select taxonomy term by. Default value is "term_id" */
export enum TaxQueryField {
  Id = "ID",
  Name = "NAME",
  Slug = "SLUG",
  TaxonomyId = "TAXONOMY_ID",
}

export enum TaxQueryOperator {
  And = "AND",
  Exists = "EXISTS",
  In = "IN",
  NotExists = "NOT_EXISTS",
  NotIn = "NOT_IN",
}

/** A taxonomy object */
export type Taxonomy = Node & {
  __typename?: "Taxonomy";
  /** List of Content Types associated with the Taxonomy */
  connectedContentTypes?: Maybe<TaxonomyToContentTypeConnection>;
  /** List of Term Nodes associated with the Taxonomy */
  connectedTerms?: Maybe<TaxonomyToTermNodeConnection>;
  /** Description of the taxonomy. This field is equivalent to WP_Taxonomy-&gt;description */
  description?: Maybe<Scalars["String"]>;
  /** The plural name of the post type within the GraphQL Schema. */
  graphqlPluralName?: Maybe<Scalars["String"]>;
  /** The singular name of the post type within the GraphQL Schema. */
  graphqlSingleName?: Maybe<Scalars["String"]>;
  /** Whether the taxonomy is hierarchical */
  hierarchical?: Maybe<Scalars["Boolean"]>;
  /** The globally unique identifier of the taxonomy object. */
  id: Scalars["ID"];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars["Boolean"]>;
  /** Name of the taxonomy shown in the menu. Usually plural. */
  label?: Maybe<Scalars["String"]>;
  /** The display name of the taxonomy. This field is equivalent to WP_Taxonomy-&gt;label */
  name?: Maybe<Scalars["String"]>;
  /** Whether the taxonomy is publicly queryable */
  public?: Maybe<Scalars["Boolean"]>;
  /** Name of content type to display in REST API &quot;wp/v2&quot; namespace. */
  restBase?: Maybe<Scalars["String"]>;
  /** The REST Controller class assigned to handling this content type. */
  restControllerClass?: Maybe<Scalars["String"]>;
  /** Whether to show the taxonomy as part of a tag cloud widget. This field is equivalent to WP_Taxonomy-&gt;show_tagcloud */
  showCloud?: Maybe<Scalars["Boolean"]>;
  /** Whether to display a column for the taxonomy on its post type listing screens. */
  showInAdminColumn?: Maybe<Scalars["Boolean"]>;
  /** Whether to add the post type to the GraphQL Schema. */
  showInGraphql?: Maybe<Scalars["Boolean"]>;
  /** Whether to show the taxonomy in the admin menu */
  showInMenu?: Maybe<Scalars["Boolean"]>;
  /** Whether the taxonomy is available for selection in navigation menus. */
  showInNavMenus?: Maybe<Scalars["Boolean"]>;
  /** Whether to show the taxonomy in the quick/bulk edit panel. */
  showInQuickEdit?: Maybe<Scalars["Boolean"]>;
  /** Whether to add the post type route in the REST API &quot;wp/v2&quot; namespace. */
  showInRest?: Maybe<Scalars["Boolean"]>;
  /** Whether to generate and allow a UI for managing terms in this taxonomy in the admin */
  showUi?: Maybe<Scalars["Boolean"]>;
};

/** A taxonomy object */
export type TaxonomyConnectedContentTypesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** A taxonomy object */
export type TaxonomyConnectedTermsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** Connection to Taxonomy Nodes */
export type TaxonomyConnection = {
  /** A list of edges (relational context) between RootQuery and connected Taxonomy Nodes */
  edges: Array<TaxonomyConnectionEdge>;
  /** A list of connected Taxonomy Nodes */
  nodes: Array<Taxonomy>;
  /** Information about pagination in a connection. */
  pageInfo: TaxonomyConnectionPageInfo;
};

/** Edge between a Node and a connected Taxonomy */
export type TaxonomyConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected Taxonomy Node */
  node: Taxonomy;
};

/** Page Info on the connected TaxonomyConnectionEdge */
export type TaxonomyConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** Allowed taxonomies */
export enum TaxonomyEnum {
  /** Taxonomy enum category */
  Category = "CATEGORY",
  /** Taxonomy enum content_language */
  Contentlanguages = "CONTENTLANGUAGES",
  /** Taxonomy enum content_status */
  Contentstatus = "CONTENTSTATUS",
  /** Taxonomy enum country */
  Country = "COUNTRY",
  /** Taxonomy enum locale */
  Locale = "LOCALE",
  /** Taxonomy enum post_format */
  Postformat = "POSTFORMAT",
  /** Taxonomy enum region */
  Region = "REGION",
  /** Taxonomy enum post_tag */
  Tag = "TAG",
}

/** The Type of Identifier used to fetch a single Taxonomy node. To be used along with the "id" field. Default is "ID". */
export enum TaxonomyIdTypeEnum {
  /** The globally unique ID */
  Id = "ID",
  /** The name of the taxonomy */
  Name = "NAME",
}

/** Connection between the Taxonomy type and the ContentType type */
export type TaxonomyToContentTypeConnection = Connection &
  ContentTypeConnection & {
    __typename?: "TaxonomyToContentTypeConnection";
    /** Edges for the TaxonomyToContentTypeConnection connection */
    edges: Array<TaxonomyToContentTypeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentType>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyToContentTypeConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyToContentTypeConnectionEdge = ContentTypeConnectionEdge &
  Edge & {
    __typename?: "TaxonomyToContentTypeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentType;
  };

/** Page Info on the &quot;TaxonomyToContentTypeConnection&quot; */
export type TaxonomyToContentTypeConnectionPageInfo = ContentTypeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "TaxonomyToContentTypeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Connection between the Taxonomy type and the TermNode type */
export type TaxonomyToTermNodeConnection = Connection &
  TermNodeConnection & {
    __typename?: "TaxonomyToTermNodeConnection";
    /** Edges for the TaxonomyToTermNodeConnection connection */
    edges: Array<TaxonomyToTermNodeConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<TermNode>;
    /** Information about pagination in a connection. */
    pageInfo: TaxonomyToTermNodeConnectionPageInfo;
  };

/** An edge in a connection */
export type TaxonomyToTermNodeConnectionEdge = Edge &
  TermNodeConnectionEdge & {
    __typename?: "TaxonomyToTermNodeConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: TermNode;
  };

/** Page Info on the &quot;TaxonomyToTermNodeConnection&quot; */
export type TaxonomyToTermNodeConnectionPageInfo = PageInfo &
  TermNodeConnectionPageInfo &
  WpPageInfo & {
    __typename?: "TaxonomyToTermNodeConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Terms are nodes within a Taxonomy, used to group and relate other nodes. */
export type TermNode = {
  /** The number of objects connected to the object */
  count?: Maybe<Scalars["Int"]>;
  /** Identifies the primary key from the database. */
  databaseId: Scalars["Int"];
  /** The description of the object */
  description?: Maybe<Scalars["String"]>;
  /** Connection between the TermNode type and the EnqueuedScript type */
  enqueuedScripts?: Maybe<TermNodeToEnqueuedScriptConnection>;
  /** Connection between the TermNode type and the EnqueuedStylesheet type */
  enqueuedStylesheets?: Maybe<TermNodeToEnqueuedStylesheetConnection>;
  /** The unique resource identifier path */
  id: Scalars["ID"];
  /** Whether the node is a Content Node */
  isContentNode: Scalars["Boolean"];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars["Boolean"]>;
  /** Whether the node is a Term */
  isTermNode: Scalars["Boolean"];
  /** The link to the term */
  link?: Maybe<Scalars["String"]>;
  /** The human friendly name of the object. */
  name?: Maybe<Scalars["String"]>;
  /** An alphanumeric identifier for the object unique to its type. */
  slug?: Maybe<Scalars["String"]>;
  /** The name of the taxonomy that the object is associated with */
  taxonomyName?: Maybe<Scalars["String"]>;
  /** The ID of the term group that this term object belongs to */
  termGroupId?: Maybe<Scalars["Int"]>;
  /** The taxonomy ID that the object is associated with */
  termTaxonomyId?: Maybe<Scalars["Int"]>;
  /** The unique resource identifier path */
  uri?: Maybe<Scalars["String"]>;
};

/** Terms are nodes within a Taxonomy, used to group and relate other nodes. */
export type TermNodeEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** Terms are nodes within a Taxonomy, used to group and relate other nodes. */
export type TermNodeEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** Connection to TermNode Nodes */
export type TermNodeConnection = {
  /** A list of edges (relational context) between RootQuery and connected TermNode Nodes */
  edges: Array<TermNodeConnectionEdge>;
  /** A list of connected TermNode Nodes */
  nodes: Array<TermNode>;
  /** Information about pagination in a connection. */
  pageInfo: TermNodeConnectionPageInfo;
};

/** Edge between a Node and a connected TermNode */
export type TermNodeConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected TermNode Node */
  node: TermNode;
};

/** Page Info on the connected TermNodeConnectionEdge */
export type TermNodeConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single resource. Default is "ID". To be used along with the "id" field. */
export enum TermNodeIdTypeEnum {
  /** The Database ID for the node */
  DatabaseId = "DATABASE_ID",
  /** The hashed Global ID */
  Id = "ID",
  /** The name of the node */
  Name = "NAME",
  /** Url friendly name of the node */
  Slug = "SLUG",
  /** The URI for the node */
  Uri = "URI",
}

/** Connection between the TermNode type and the EnqueuedScript type */
export type TermNodeToEnqueuedScriptConnection = Connection &
  EnqueuedScriptConnection & {
    __typename?: "TermNodeToEnqueuedScriptConnection";
    /** Edges for the TermNodeToEnqueuedScriptConnection connection */
    edges: Array<TermNodeToEnqueuedScriptConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedScript>;
    /** Information about pagination in a connection. */
    pageInfo: TermNodeToEnqueuedScriptConnectionPageInfo;
  };

/** An edge in a connection */
export type TermNodeToEnqueuedScriptConnectionEdge = Edge &
  EnqueuedScriptConnectionEdge & {
    __typename?: "TermNodeToEnqueuedScriptConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: EnqueuedScript;
  };

/** Page Info on the &quot;TermNodeToEnqueuedScriptConnection&quot; */
export type TermNodeToEnqueuedScriptConnectionPageInfo = EnqueuedScriptConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "TermNodeToEnqueuedScriptConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Connection between the TermNode type and the EnqueuedStylesheet type */
export type TermNodeToEnqueuedStylesheetConnection = Connection &
  EnqueuedStylesheetConnection & {
    __typename?: "TermNodeToEnqueuedStylesheetConnection";
    /** Edges for the TermNodeToEnqueuedStylesheetConnection connection */
    edges: Array<TermNodeToEnqueuedStylesheetConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedStylesheet>;
    /** Information about pagination in a connection. */
    pageInfo: TermNodeToEnqueuedStylesheetConnectionPageInfo;
  };

/** An edge in a connection */
export type TermNodeToEnqueuedStylesheetConnectionEdge = Edge &
  EnqueuedStylesheetConnectionEdge & {
    __typename?: "TermNodeToEnqueuedStylesheetConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: EnqueuedStylesheet;
  };

/** Page Info on the &quot;TermNodeToEnqueuedStylesheetConnection&quot; */
export type TermNodeToEnqueuedStylesheetConnectionPageInfo = EnqueuedStylesheetConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "TermNodeToEnqueuedStylesheetConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Options for ordering the connection by */
export enum TermObjectsConnectionOrderbyEnum {
  /** Order the connection by item count. */
  Count = "COUNT",
  /** Order the connection by description. */
  Description = "DESCRIPTION",
  /** Order the connection by name. */
  Name = "NAME",
  /** Order the connection by slug. */
  Slug = "SLUG",
  /** Order the connection by term group. */
  TermGroup = "TERM_GROUP",
  /** Order the connection by term id. */
  TermId = "TERM_ID",
  /** Order the connection by term order. */
  TermOrder = "TERM_ORDER",
}

/** A theme object */
export type Theme = Node & {
  __typename?: "Theme";
  /** Name of the theme author(s), could also be a company name. This field is equivalent to WP_Theme-&gt;get( &quot;Author&quot; ). */
  author?: Maybe<Scalars["String"]>;
  /** URI for the author/company website. This field is equivalent to WP_Theme-&gt;get( &quot;AuthorURI&quot; ). */
  authorUri?: Maybe<Scalars["String"]>;
  /** The description of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Description&quot; ). */
  description?: Maybe<Scalars["String"]>;
  /** The globally unique identifier of the theme object. */
  id: Scalars["ID"];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars["Boolean"]>;
  /** Display name of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Name&quot; ). */
  name?: Maybe<Scalars["String"]>;
  /** The URL of the screenshot for the theme. The screenshot is intended to give an overview of what the theme looks like. This field is equivalent to WP_Theme-&gt;get_screenshot(). */
  screenshot?: Maybe<Scalars["String"]>;
  /** The theme slug is used to internally match themes. Theme slugs can have subdirectories like: my-theme/sub-theme. This field is equivalent to WP_Theme-&gt;get_stylesheet(). */
  slug?: Maybe<Scalars["String"]>;
  /** URI for the author/company website. This field is equivalent to WP_Theme-&gt;get( &quot;Tags&quot; ). */
  tags?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** A URI if the theme has a website associated with it. The Theme URI is handy for directing users to a theme site for support etc. This field is equivalent to WP_Theme-&gt;get( &quot;ThemeURI&quot; ). */
  themeUri?: Maybe<Scalars["String"]>;
  /** The current version of the theme. This field is equivalent to WP_Theme-&gt;get( &quot;Version&quot; ). */
  version?: Maybe<Scalars["String"]>;
};

/** Connection to Theme Nodes */
export type ThemeConnection = {
  /** A list of edges (relational context) between RootQuery and connected Theme Nodes */
  edges: Array<ThemeConnectionEdge>;
  /** A list of connected Theme Nodes */
  nodes: Array<Theme>;
  /** Information about pagination in a connection. */
  pageInfo: ThemeConnectionPageInfo;
};

/** Edge between a Node and a connected Theme */
export type ThemeConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected Theme Node */
  node: Theme;
};

/** Page Info on the connected ThemeConnectionEdge */
export type ThemeConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** Any node that has a URI */
export type UniformResourceIdentifiable = {
  /** The unique resource identifier path */
  id: Scalars["ID"];
  /** Whether the node is a Content Node */
  isContentNode: Scalars["Boolean"];
  /** Whether the node is a Term */
  isTermNode: Scalars["Boolean"];
  /** The unique resource identifier path */
  uri?: Maybe<Scalars["String"]>;
};

/** Input for the updateArticle mutation. */
export type UpdateArticleInput = {
  /** Set connections between the Article and categories */
  categories?: InputMaybe<ArticleCategoriesInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** Set connections between the Article and ContentLanguages */
  contentLanguages?: InputMaybe<ArticleContentLanguagesInput>;
  /** Set connections between the Article and ContentStatuses */
  contentStatuses?: InputMaybe<ArticleContentStatusesInput>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The ID of the Article object */
  id: Scalars["ID"];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars["Boolean"]>;
  language?: InputMaybe<LanguageCodeEnum>;
  /** Set connections between the Article and Locales */
  locales?: InputMaybe<ArticleLocalesInput>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the Article and tags */
  tags?: InputMaybe<ArticleTagsInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** The payload for the updateArticle mutation. */
export type UpdateArticlePayload = {
  __typename?: "UpdateArticlePayload";
  /** The Post object mutation type. */
  article?: Maybe<Article>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** Input for the updateCategory mutation. */
export type UpdateCategoryInput = {
  /** The slug that the category will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the category object */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the category object to update */
  id: Scalars["ID"];
  language?: InputMaybe<LanguageCodeEnum>;
  /** The name of the category object to mutate */
  name?: InputMaybe<Scalars["String"]>;
  /** The ID of the category that should be set as the parent */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The payload for the updateCategory mutation. */
export type UpdateCategoryPayload = {
  __typename?: "UpdateCategoryPayload";
  /** The created category */
  category?: Maybe<Category>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** Input for the updateCollection mutation. */
export type UpdateCollectionInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Set connections between the Collection and ContentLanguages */
  contentLanguages?: InputMaybe<CollectionContentLanguagesInput>;
  /** Set connections between the Collection and ContentStatuses */
  contentStatuses?: InputMaybe<CollectionContentStatusesInput>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The ID of the Collection object */
  id: Scalars["ID"];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars["Boolean"]>;
  language?: InputMaybe<LanguageCodeEnum>;
  /** Set connections between the Collection and Locales */
  locales?: InputMaybe<CollectionLocalesInput>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the Collection and tags */
  tags?: InputMaybe<CollectionTagsInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** The payload for the updateCollection mutation. */
export type UpdateCollectionPayload = {
  __typename?: "UpdateCollectionPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The Post object mutation type. */
  collection?: Maybe<Collection>;
};

/** Input for the updateComment mutation. */
export type UpdateCommentInput = {
  /** The approval status of the comment. */
  approved?: InputMaybe<Scalars["String"]>;
  /** The name of the comment's author. */
  author?: InputMaybe<Scalars["String"]>;
  /** The email of the comment's author. */
  authorEmail?: InputMaybe<Scalars["String"]>;
  /** The url of the comment's author. */
  authorUrl?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The database ID of the post object the comment belongs to. */
  commentOn?: InputMaybe<Scalars["Int"]>;
  /** Content of the comment. */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day ( e.g. 01/31/2017 ) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The ID of the comment being updated. */
  id: Scalars["ID"];
  /** Parent comment ID of current comment. */
  parent?: InputMaybe<Scalars["ID"]>;
  /** The approval status of the comment */
  status?: InputMaybe<CommentStatusEnum>;
  /** Type of comment. */
  type?: InputMaybe<Scalars["String"]>;
};

/** The payload for the updateComment mutation. */
export type UpdateCommentPayload = {
  __typename?: "UpdateCommentPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The comment that was created */
  comment?: Maybe<Comment>;
  /** Whether the mutation succeeded. If the comment is not approved, the server will not return the comment to a non authenticated user, but a success message can be returned if the create succeeded, and the client can optimistically add the comment to the client cache */
  success?: Maybe<Scalars["Boolean"]>;
};

/** Input for the updateContentLanguages mutation. */
export type UpdateContentLanguagesInput = {
  /** The slug that the content_language will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the content_language object */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the ContentLanguages object to update */
  id: Scalars["ID"];
  /** The name of the content_language object to mutate */
  name?: InputMaybe<Scalars["String"]>;
  /** The ID of the content_language that should be set as the parent */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The payload for the updateContentLanguages mutation. */
export type UpdateContentLanguagesPayload = {
  __typename?: "UpdateContentLanguagesPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The created content_language */
  contentLanguages?: Maybe<ContentLanguages>;
};

/** Input for the updateContentStatus mutation. */
export type UpdateContentStatusInput = {
  /** The slug that the content_status will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the content_status object */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the ContentStatus object to update */
  id: Scalars["ID"];
  /** The name of the content_status object to mutate */
  name?: InputMaybe<Scalars["String"]>;
  /** The ID of the content_status that should be set as the parent */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The payload for the updateContentStatus mutation. */
export type UpdateContentStatusPayload = {
  __typename?: "UpdateContentStatusPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The created content_status */
  contentStatus?: Maybe<ContentStatus>;
};

/** Input for the updateCountry mutation. */
export type UpdateCountryInput = {
  /** The slug that the country will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the country object */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the Country object to update */
  id: Scalars["ID"];
  /** The name of the country object to mutate */
  name?: InputMaybe<Scalars["String"]>;
  /** The ID of the country that should be set as the parent */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The payload for the updateCountry mutation. */
export type UpdateCountryPayload = {
  __typename?: "UpdateCountryPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The created country */
  country?: Maybe<Country>;
};

/** Input for the updateCountryProfile mutation. */
export type UpdateCountryProfileInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Set connections between the CountryProfile and ContentLanguages */
  contentLanguages?: InputMaybe<CountryProfileContentLanguagesInput>;
  /** Set connections between the CountryProfile and ContentStatuses */
  contentStatuses?: InputMaybe<CountryProfileContentStatusesInput>;
  /** Set connections between the CountryProfile and Countries */
  countries?: InputMaybe<CountryProfileCountriesInput>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The ID of the CountryProfile object */
  id: Scalars["ID"];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars["Boolean"]>;
  /** Set connections between the CountryProfile and Locales */
  locales?: InputMaybe<CountryProfileLocalesInput>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** Set connections between the CountryProfile and Regions */
  regions?: InputMaybe<CountryProfileRegionsInput>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the CountryProfile and tags */
  tags?: InputMaybe<CountryProfileTagsInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** The payload for the updateCountryProfile mutation. */
export type UpdateCountryProfilePayload = {
  __typename?: "UpdateCountryProfilePayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The Post object mutation type. */
  countryProfile?: Maybe<CountryProfile>;
};

/** Input for the updateGlobalContent mutation. */
export type UpdateGlobalContentInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The ID of the GlobalContent object */
  id: Scalars["ID"];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars["Boolean"]>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** The payload for the updateGlobalContent mutation. */
export type UpdateGlobalContentPayload = {
  __typename?: "UpdateGlobalContentPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The Post object mutation type. */
  globalContent?: Maybe<GlobalContent>;
};

/** Input for the updateHomePage mutation. */
export type UpdateHomePageInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Set connections between the HomePage and ContentLanguages */
  contentLanguages?: InputMaybe<HomePageContentLanguagesInput>;
  /** Set connections between the HomePage and ContentStatuses */
  contentStatuses?: InputMaybe<HomePageContentStatusesInput>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The ID of the HomePage object */
  id: Scalars["ID"];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars["Boolean"]>;
  language?: InputMaybe<LanguageCodeEnum>;
  /** Set connections between the HomePage and Locales */
  locales?: InputMaybe<HomePageLocalesInput>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** The payload for the updateHomePage mutation. */
export type UpdateHomePagePayload = {
  __typename?: "UpdateHomePagePayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The Post object mutation type. */
  homePage?: Maybe<HomePage>;
};

/** Input for the updateHowItWorksPage mutation. */
export type UpdateHowItWorksPageInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Set connections between the HowItWorksPage and ContentLanguages */
  contentLanguages?: InputMaybe<HowItWorksPageContentLanguagesInput>;
  /** Set connections between the HowItWorksPage and ContentStatuses */
  contentStatuses?: InputMaybe<HowItWorksPageContentStatusesInput>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The ID of the HowItWorksPage object */
  id: Scalars["ID"];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars["Boolean"]>;
  language?: InputMaybe<LanguageCodeEnum>;
  /** Set connections between the HowItWorksPage and Locales */
  locales?: InputMaybe<HowItWorksPageLocalesInput>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** The payload for the updateHowItWorksPage mutation. */
export type UpdateHowItWorksPagePayload = {
  __typename?: "UpdateHowItWorksPagePayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The Post object mutation type. */
  howItWorksPage?: Maybe<HowItWorksPage>;
};

/** Input for the updateLocale mutation. */
export type UpdateLocaleInput = {
  /** The slug that the locale will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the locale object */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the Locale object to update */
  id: Scalars["ID"];
  /** The name of the locale object to mutate */
  name?: InputMaybe<Scalars["String"]>;
  /** The ID of the locale that should be set as the parent */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The payload for the updateLocale mutation. */
export type UpdateLocalePayload = {
  __typename?: "UpdateLocalePayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The created locale */
  locale?: Maybe<Locale>;
};

/** Input for the updateMediaItem mutation. */
export type UpdateMediaItemInput = {
  /** Alternative text to display when mediaItem is not displayed */
  altText?: InputMaybe<Scalars["String"]>;
  /** The userId to assign as the author of the mediaItem */
  authorId?: InputMaybe<Scalars["ID"]>;
  /** The caption for the mediaItem */
  caption?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The comment status for the mediaItem */
  commentStatus?: InputMaybe<Scalars["String"]>;
  /** The date of the mediaItem */
  date?: InputMaybe<Scalars["String"]>;
  /** The date (in GMT zone) of the mediaItem */
  dateGmt?: InputMaybe<Scalars["String"]>;
  /** Description of the mediaItem */
  description?: InputMaybe<Scalars["String"]>;
  /** The file name of the mediaItem */
  filePath?: InputMaybe<Scalars["String"]>;
  /** The file type of the mediaItem */
  fileType?: InputMaybe<MimeTypeEnum>;
  /** The ID of the mediaItem object */
  id: Scalars["ID"];
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** The ping status for the mediaItem */
  pingStatus?: InputMaybe<Scalars["String"]>;
  /** The slug of the mediaItem */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the mediaItem */
  status?: InputMaybe<MediaItemStatusEnum>;
  /** The title of the mediaItem */
  title?: InputMaybe<Scalars["String"]>;
};

/** The payload for the updateMediaItem mutation. */
export type UpdateMediaItemPayload = {
  __typename?: "UpdateMediaItemPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The MediaItem object mutation type. */
  mediaItem?: Maybe<MediaItem>;
};

/** Input for the updatePage mutation. */
export type UpdatePageInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars["ID"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The comment status for the object */
  commentStatus?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The ID of the page object */
  id: Scalars["ID"];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars["Boolean"]>;
  language?: InputMaybe<LanguageCodeEnum>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** The payload for the updatePage mutation. */
export type UpdatePagePayload = {
  __typename?: "UpdatePagePayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The Post object mutation type. */
  page?: Maybe<Page>;
};

/** Input for the updatePostFormat mutation. */
export type UpdatePostFormatInput = {
  /** The slug that the post_format will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the post_format object */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the postFormat object to update */
  id: Scalars["ID"];
  /** The name of the post_format object to mutate */
  name?: InputMaybe<Scalars["String"]>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The payload for the updatePostFormat mutation. */
export type UpdatePostFormatPayload = {
  __typename?: "UpdatePostFormatPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The created post_format */
  postFormat?: Maybe<PostFormat>;
};

/** Input for the updatePost mutation. */
export type UpdatePostInput = {
  /** The userId to assign as the author of the object */
  authorId?: InputMaybe<Scalars["ID"]>;
  /** Set connections between the post and categories */
  categories?: InputMaybe<PostCategoriesInput>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The comment status for the object */
  commentStatus?: InputMaybe<Scalars["String"]>;
  /** The content of the object */
  content?: InputMaybe<Scalars["String"]>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The excerpt of the object */
  excerpt?: InputMaybe<Scalars["String"]>;
  /** The ID of the post object */
  id: Scalars["ID"];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars["Boolean"]>;
  language?: InputMaybe<LanguageCodeEnum>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** The ping status for the object */
  pingStatus?: InputMaybe<Scalars["String"]>;
  /** URLs that have been pinged. */
  pinged?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Set connections between the post and postFormats */
  postFormats?: InputMaybe<PostPostFormatsInput>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** Set connections between the post and tags */
  tags?: InputMaybe<PostTagsInput>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
  /** URLs queued to be pinged. */
  toPing?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The payload for the updatePost mutation. */
export type UpdatePostPayload = {
  __typename?: "UpdatePostPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The Post object mutation type. */
  post?: Maybe<Post>;
};

/** Input for the updateRegion mutation. */
export type UpdateRegionInput = {
  /** The slug that the region will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the region object */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the Region object to update */
  id: Scalars["ID"];
  /** The name of the region object to mutate */
  name?: InputMaybe<Scalars["String"]>;
  /** The ID of the region that should be set as the parent */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The payload for the updateRegion mutation. */
export type UpdateRegionPayload = {
  __typename?: "UpdateRegionPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The created region */
  region?: Maybe<Region>;
};

/** Input for the updateRegionProfile mutation. */
export type UpdateRegionProfileInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Set connections between the RegionProfile and ContentLanguages */
  contentLanguages?: InputMaybe<RegionProfileContentLanguagesInput>;
  /** Set connections between the RegionProfile and ContentStatuses */
  contentStatuses?: InputMaybe<RegionProfileContentStatusesInput>;
  /** The date of the object. Preferable to enter as year/month/day (e.g. 01/31/2017) as it will rearrange date as fit if it is not specified. Incomplete dates may have unintended results for example, "2017" as the input will use current date with timestamp 20:17  */
  date?: InputMaybe<Scalars["String"]>;
  /** The ID of the RegionProfile object */
  id: Scalars["ID"];
  /** Override the edit lock when another user is editing the post */
  ignoreEditLock?: InputMaybe<Scalars["Boolean"]>;
  /** Set connections between the RegionProfile and Locales */
  locales?: InputMaybe<RegionProfileLocalesInput>;
  /** A field used for ordering posts. This is typically used with nav menu items or for special ordering of hierarchical content types. */
  menuOrder?: InputMaybe<Scalars["Int"]>;
  /** The ID of the parent object */
  parentId?: InputMaybe<Scalars["ID"]>;
  /** The password used to protect the content of the object */
  password?: InputMaybe<Scalars["String"]>;
  /** Set connections between the RegionProfile and Regions */
  regions?: InputMaybe<RegionProfileRegionsInput>;
  /** The slug of the object */
  slug?: InputMaybe<Scalars["String"]>;
  /** The status of the object */
  status?: InputMaybe<PostStatusEnum>;
  /** The title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** The payload for the updateRegionProfile mutation. */
export type UpdateRegionProfilePayload = {
  __typename?: "UpdateRegionProfilePayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The Post object mutation type. */
  regionProfile?: Maybe<RegionProfile>;
};

/** Input for the updateSettings mutation. */
export type UpdateSettingsInput = {
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** Allow people to submit comments on new posts. */
  discussionSettingsDefaultCommentStatus?: InputMaybe<Scalars["String"]>;
  /** Allow link notifications from other blogs (pingbacks and trackbacks) on new articles. */
  discussionSettingsDefaultPingStatus?: InputMaybe<Scalars["String"]>;
  /** A date format for all date strings. */
  generalSettingsDateFormat?: InputMaybe<Scalars["String"]>;
  /** Site tagline. */
  generalSettingsDescription?: InputMaybe<Scalars["String"]>;
  /** This address is used for admin purposes, like new user notification. */
  generalSettingsEmail?: InputMaybe<Scalars["String"]>;
  /** WordPress locale code. */
  generalSettingsLanguage?: InputMaybe<Scalars["String"]>;
  /** A day number of the week that the week should start on. */
  generalSettingsStartOfWeek?: InputMaybe<Scalars["Int"]>;
  /** A time format for all time strings. */
  generalSettingsTimeFormat?: InputMaybe<Scalars["String"]>;
  /** A city in the same timezone as you. */
  generalSettingsTimezone?: InputMaybe<Scalars["String"]>;
  /** Site title. */
  generalSettingsTitle?: InputMaybe<Scalars["String"]>;
  /** Site URL. */
  generalSettingsUrl?: InputMaybe<Scalars["String"]>;
  /** The ID of the page that should display the latest posts */
  readingSettingsPageForPosts?: InputMaybe<Scalars["Int"]>;
  /** The ID of the page that should be displayed on the front page */
  readingSettingsPageOnFront?: InputMaybe<Scalars["Int"]>;
  /** Blog pages show at most. */
  readingSettingsPostsPerPage?: InputMaybe<Scalars["Int"]>;
  /** What to show on the front page */
  readingSettingsShowOnFront?: InputMaybe<Scalars["String"]>;
  /** Default post category. */
  writingSettingsDefaultCategory?: InputMaybe<Scalars["Int"]>;
  /** Default post format. */
  writingSettingsDefaultPostFormat?: InputMaybe<Scalars["String"]>;
  /** Convert emoticons like :-) and :-P to graphics on display. */
  writingSettingsUseSmilies?: InputMaybe<Scalars["Boolean"]>;
};

/** The payload for the updateSettings mutation. */
export type UpdateSettingsPayload = {
  __typename?: "UpdateSettingsPayload";
  /** Update all settings. */
  allSettings?: Maybe<Settings>;
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Update the DiscussionSettings setting. */
  discussionSettings?: Maybe<DiscussionSettings>;
  /** Update the GeneralSettings setting. */
  generalSettings?: Maybe<GeneralSettings>;
  /** Update the ReadingSettings setting. */
  readingSettings?: Maybe<ReadingSettings>;
  /** Update the WritingSettings setting. */
  writingSettings?: Maybe<WritingSettings>;
};

/** Input for the updateTag mutation. */
export type UpdateTagInput = {
  /** The slug that the post_tag will be an alias of */
  aliasOf?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The description of the post_tag object */
  description?: InputMaybe<Scalars["String"]>;
  /** The ID of the tag object to update */
  id: Scalars["ID"];
  language?: InputMaybe<LanguageCodeEnum>;
  /** The name of the post_tag object to mutate */
  name?: InputMaybe<Scalars["String"]>;
  /** If this argument exists then the slug will be checked to see if it is not an existing valid term. If that check succeeds (it is not a valid term), then it is added and the term id is given. If it fails, then a check is made to whether the taxonomy is hierarchical and the parent argument is not empty. If the second check succeeds, the term will be inserted and the term id will be given. If the slug argument is empty, then it will be calculated from the term name. */
  slug?: InputMaybe<Scalars["String"]>;
};

/** The payload for the updateTag mutation. */
export type UpdateTagPayload = {
  __typename?: "UpdateTagPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The created post_tag */
  tag?: Maybe<Tag>;
};

/** Input for the updateUser mutation. */
export type UpdateUserInput = {
  /** User's AOL IM account. */
  aim?: InputMaybe<Scalars["String"]>;
  /** This is an ID that can be passed to a mutation by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A string containing content about the user. */
  description?: InputMaybe<Scalars["String"]>;
  /** A string that will be shown on the site. Defaults to user's username. It is likely that you will want to change this, for both appearance and security through obscurity (that is if you dont use and delete the default admin user). */
  displayName?: InputMaybe<Scalars["String"]>;
  /** A string containing the user's email address. */
  email?: InputMaybe<Scalars["String"]>;
  /** 	The user's first name. */
  firstName?: InputMaybe<Scalars["String"]>;
  /** The ID of the user */
  id: Scalars["ID"];
  /** User's Jabber account. */
  jabber?: InputMaybe<Scalars["String"]>;
  /** The user's last name. */
  lastName?: InputMaybe<Scalars["String"]>;
  /** User's locale. */
  locale?: InputMaybe<Scalars["String"]>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: InputMaybe<Scalars["String"]>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: InputMaybe<Scalars["String"]>;
  /** A string that contains the plain text password for the user. */
  password?: InputMaybe<Scalars["String"]>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: InputMaybe<Scalars["String"]>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: InputMaybe<Scalars["String"]>;
  /** An array of roles to be assigned to the user. */
  roles?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: InputMaybe<Scalars["String"]>;
  /** User's Yahoo IM account. */
  yim?: InputMaybe<Scalars["String"]>;
};

/** The payload for the updateUser mutation. */
export type UpdateUserPayload = {
  __typename?: "UpdateUserPayload";
  /** If a &#039;clientMutationId&#039; input is provided to the mutation, it will be returned as output on the mutation. This ID can be used by the client to track the progress of mutations and catch possible duplicate mutation submissions. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The User object mutation type. */
  user?: Maybe<User>;
};

/** A User object */
export type User = Commenter &
  DatabaseIdentifier &
  Node &
  UniformResourceIdentifiable & {
    __typename?: "User";
    /** Avatar object for user. The avatar object can be retrieved in different sizes by specifying the size argument. */
    avatar?: Maybe<Avatar>;
    /** User metadata option name. Usually it will be &quot;wp_capabilities&quot;. */
    capKey?: Maybe<Scalars["String"]>;
    /** A list of capabilities (permissions) granted to the user */
    capabilities?: Maybe<Array<Maybe<Scalars["String"]>>>;
    /** Connection between the User type and the Comment type */
    comments?: Maybe<UserToCommentConnection>;
    /** Identifies the primary key from the database. */
    databaseId: Scalars["Int"];
    /** Description of the user. */
    description?: Maybe<Scalars["String"]>;
    /** Email address of the user. This is equivalent to the WP_User-&gt;user_email property. */
    email?: Maybe<Scalars["String"]>;
    /** Connection between the User type and the EnqueuedScript type */
    enqueuedScripts?: Maybe<UserToEnqueuedScriptConnection>;
    /** Connection between the User type and the EnqueuedStylesheet type */
    enqueuedStylesheets?: Maybe<UserToEnqueuedStylesheetConnection>;
    /** A complete list of capabilities including capabilities inherited from a role. This is equivalent to the array keys of WP_User-&gt;allcaps. */
    extraCapabilities?: Maybe<Array<Maybe<Scalars["String"]>>>;
    /** First name of the user. This is equivalent to the WP_User-&gt;user_first_name property. */
    firstName?: Maybe<Scalars["String"]>;
    /** The globally unique identifier for the user object. */
    id: Scalars["ID"];
    /** Whether the node is a Content Node */
    isContentNode: Scalars["Boolean"];
    /** Whether the object is restricted from the current viewer */
    isRestricted?: Maybe<Scalars["Boolean"]>;
    /** Whether the node is a Term */
    isTermNode: Scalars["Boolean"];
    /** Last name of the user. This is equivalent to the WP_User-&gt;user_last_name property. */
    lastName?: Maybe<Scalars["String"]>;
    /** The preferred language locale set for the user. Value derived from get_user_locale(). */
    locale?: Maybe<Scalars["String"]>;
    /** Connection between the User type and the mediaItem type */
    mediaItems?: Maybe<UserToMediaItemConnection>;
    /** Display name of the user. This is equivalent to the WP_User-&gt;display_name property. */
    name?: Maybe<Scalars["String"]>;
    /** The nicename for the user. This field is equivalent to WP_User-&gt;user_nicename */
    nicename?: Maybe<Scalars["String"]>;
    /** Nickname of the user. */
    nickname?: Maybe<Scalars["String"]>;
    /** Connection between the User type and the page type */
    pages?: Maybe<UserToPageConnection>;
    /** Connection between the User type and the post type */
    posts?: Maybe<UserToPostConnection>;
    /** The date the user registered or was created. The field follows a full ISO8601 date string format. */
    registeredDate?: Maybe<Scalars["String"]>;
    /** Connection between the User and Revisions authored by the user */
    revisions?: Maybe<UserToRevisionsConnection>;
    /** Connection between the User type and the UserRole type */
    roles?: Maybe<UserToUserRoleConnection>;
    /** Whether the Toolbar should be displayed when the user is viewing the site. */
    shouldShowAdminToolbar?: Maybe<Scalars["Boolean"]>;
    /** The slug for the user. This field is equivalent to WP_User-&gt;user_nicename */
    slug?: Maybe<Scalars["String"]>;
    /** The unique resource identifier path */
    uri?: Maybe<Scalars["String"]>;
    /** A website url that is associated with the user. */
    url?: Maybe<Scalars["String"]>;
    /**
     * The Id of the user. Equivalent to WP_User-&gt;ID
     * @deprecated Deprecated in favor of the databaseId field
     */
    userId?: Maybe<Scalars["Int"]>;
    /** Username for the user. This field is equivalent to WP_User-&gt;user_login. */
    username?: Maybe<Scalars["String"]>;
  };

/** A User object */
export type UserAvatarArgs = {
  forceDefault?: InputMaybe<Scalars["Boolean"]>;
  rating?: InputMaybe<AvatarRatingEnum>;
  size?: InputMaybe<Scalars["Int"]>;
};

/** A User object */
export type UserCommentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserToCommentConnectionWhereArgs>;
};

/** A User object */
export type UserEnqueuedScriptsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** A User object */
export type UserEnqueuedStylesheetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** A User object */
export type UserMediaItemsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserToMediaItemConnectionWhereArgs>;
};

/** A User object */
export type UserPagesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserToPageConnectionWhereArgs>;
};

/** A User object */
export type UserPostsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserToPostConnectionWhereArgs>;
};

/** A User object */
export type UserRevisionsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserToRevisionsConnectionWhereArgs>;
};

/** A User object */
export type UserRolesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

/** Connection to User Nodes */
export type UserConnection = {
  /** A list of edges (relational context) between RootQuery and connected User Nodes */
  edges: Array<UserConnectionEdge>;
  /** A list of connected User Nodes */
  nodes: Array<User>;
  /** Information about pagination in a connection. */
  pageInfo: UserConnectionPageInfo;
};

/** Edge between a Node and a connected User */
export type UserConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected User Node */
  node: User;
};

/** Page Info on the connected UserConnectionEdge */
export type UserConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** The Type of Identifier used to fetch a single User node. To be used along with the "id" field. Default is "ID". */
export enum UserNodeIdTypeEnum {
  /** The Database ID for the node */
  DatabaseId = "DATABASE_ID",
  /** The Email of the User */
  Email = "EMAIL",
  /** The hashed Global ID */
  Id = "ID",
  /** The slug of the User */
  Slug = "SLUG",
  /** The URI for the node */
  Uri = "URI",
  /** The username the User uses to login with */
  Username = "USERNAME",
}

/** A user role object */
export type UserRole = Node & {
  __typename?: "UserRole";
  /** The capabilities that belong to this role */
  capabilities?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** The display name of the role */
  displayName?: Maybe<Scalars["String"]>;
  /** The globally unique identifier for the user role object. */
  id: Scalars["ID"];
  /** Whether the object is restricted from the current viewer */
  isRestricted?: Maybe<Scalars["Boolean"]>;
  /** The registered name of the role */
  name?: Maybe<Scalars["String"]>;
};

/** Connection to UserRole Nodes */
export type UserRoleConnection = {
  /** A list of edges (relational context) between RootQuery and connected UserRole Nodes */
  edges: Array<UserRoleConnectionEdge>;
  /** A list of connected UserRole Nodes */
  nodes: Array<UserRole>;
  /** Information about pagination in a connection. */
  pageInfo: UserRoleConnectionPageInfo;
};

/** Edge between a Node and a connected UserRole */
export type UserRoleConnectionEdge = {
  /** Opaque reference to the nodes position in the connection. Value can be used with pagination args. */
  cursor?: Maybe<Scalars["String"]>;
  /** The connected UserRole Node */
  node: UserRole;
};

/** Page Info on the connected UserRoleConnectionEdge */
export type UserRoleConnectionPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** Names of available user roles */
export enum UserRoleEnum {
  /** User role with specific capabilities */
  Administrator = "ADMINISTRATOR",
  /** User role with specific capabilities */
  Author = "AUTHOR",
  /** User role with specific capabilities */
  Contributor = "CONTRIBUTOR",
  /** User role with specific capabilities */
  Editor = "EDITOR",
  /** User role with specific capabilities */
  SeoEditor = "SEO_EDITOR",
  /** User role with specific capabilities */
  SeoManager = "SEO_MANAGER",
  /** User role with specific capabilities */
  Subscriber = "SUBSCRIBER",
}

/** Connection between the User type and the Comment type */
export type UserToCommentConnection = CommentConnection &
  Connection & {
    __typename?: "UserToCommentConnection";
    /** Edges for the UserToCommentConnection connection */
    edges: Array<UserToCommentConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Comment>;
    /** Information about pagination in a connection. */
    pageInfo: UserToCommentConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToCommentConnectionEdge = CommentConnectionEdge &
  Edge & {
    __typename?: "UserToCommentConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Comment;
  };

/** Page Info on the &quot;UserToCommentConnection&quot; */
export type UserToCommentConnectionPageInfo = CommentConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "UserToCommentConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the UserToCommentConnection connection */
export type UserToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: InputMaybe<Scalars["String"]>;
  /** Array of author IDs to include comments for. */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Comment author URL. */
  authorUrl?: InputMaybe<Scalars["String"]>;
  /** Array of comment IDs to include. */
  commentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Include comments of a given type. */
  commentType?: InputMaybe<Scalars["String"]>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: InputMaybe<Scalars["String"]>;
  /** Content object author ID to limit results by. */
  contentAuthor?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: InputMaybe<Scalars["ID"]>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Content object name (i.e. slug ) to retrieve affiliated comments for. */
  contentName?: InputMaybe<Scalars["String"]>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: InputMaybe<Scalars["Int"]>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Array of IDs or email addresses of users whose unapproved comments will be returned by the query regardless of $status. Default empty */
  includeUnapproved?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: InputMaybe<Scalars["Int"]>;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: InputMaybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: InputMaybe<Scalars["Int"]>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: InputMaybe<Scalars["String"]>;
  /** Comment status to limit results by. */
  status?: InputMaybe<Scalars["String"]>;
  /** Include comments for a specific user ID. */
  userId?: InputMaybe<Scalars["ID"]>;
};

/** Connection between the User type and the EnqueuedScript type */
export type UserToEnqueuedScriptConnection = Connection &
  EnqueuedScriptConnection & {
    __typename?: "UserToEnqueuedScriptConnection";
    /** Edges for the UserToEnqueuedScriptConnection connection */
    edges: Array<UserToEnqueuedScriptConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedScript>;
    /** Information about pagination in a connection. */
    pageInfo: UserToEnqueuedScriptConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToEnqueuedScriptConnectionEdge = Edge &
  EnqueuedScriptConnectionEdge & {
    __typename?: "UserToEnqueuedScriptConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: EnqueuedScript;
  };

/** Page Info on the &quot;UserToEnqueuedScriptConnection&quot; */
export type UserToEnqueuedScriptConnectionPageInfo = EnqueuedScriptConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "UserToEnqueuedScriptConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Connection between the User type and the EnqueuedStylesheet type */
export type UserToEnqueuedStylesheetConnection = Connection &
  EnqueuedStylesheetConnection & {
    __typename?: "UserToEnqueuedStylesheetConnection";
    /** Edges for the UserToEnqueuedStylesheetConnection connection */
    edges: Array<UserToEnqueuedStylesheetConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<EnqueuedStylesheet>;
    /** Information about pagination in a connection. */
    pageInfo: UserToEnqueuedStylesheetConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToEnqueuedStylesheetConnectionEdge = Edge &
  EnqueuedStylesheetConnectionEdge & {
    __typename?: "UserToEnqueuedStylesheetConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: EnqueuedStylesheet;
  };

/** Page Info on the &quot;UserToEnqueuedStylesheetConnection&quot; */
export type UserToEnqueuedStylesheetConnectionPageInfo = EnqueuedStylesheetConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "UserToEnqueuedStylesheetConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Connection between the User type and the mediaItem type */
export type UserToMediaItemConnection = Connection &
  MediaItemConnection & {
    __typename?: "UserToMediaItemConnection";
    /** Edges for the UserToMediaItemConnection connection */
    edges: Array<UserToMediaItemConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<MediaItem>;
    /** Information about pagination in a connection. */
    pageInfo: UserToMediaItemConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToMediaItemConnectionEdge = Edge &
  MediaItemConnectionEdge & {
    __typename?: "UserToMediaItemConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: MediaItem;
  };

/** Page Info on the &quot;UserToMediaItemConnection&quot; */
export type UserToMediaItemConnectionPageInfo = MediaItemConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "UserToMediaItemConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the UserToMediaItemConnection connection */
export type UserToMediaItemConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the User type and the page type */
export type UserToPageConnection = Connection &
  PageConnection & {
    __typename?: "UserToPageConnection";
    /** Edges for the UserToPageConnection connection */
    edges: Array<UserToPageConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Page>;
    /** Information about pagination in a connection. */
    pageInfo: UserToPageConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToPageConnectionEdge = Edge &
  PageConnectionEdge & {
    __typename?: "UserToPageConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Page;
  };

/** Page Info on the &quot;UserToPageConnection&quot; */
export type UserToPageConnectionPageInfo = PageConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "UserToPageConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the UserToPageConnection connection */
export type UserToPageConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the User type and the post type */
export type UserToPostConnection = Connection &
  PostConnection & {
    __typename?: "UserToPostConnection";
    /** Edges for the UserToPostConnection connection */
    edges: Array<UserToPostConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<Post>;
    /** Information about pagination in a connection. */
    pageInfo: UserToPostConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToPostConnectionEdge = Edge &
  PostConnectionEdge & {
    __typename?: "UserToPostConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: Post;
  };

/** Page Info on the &quot;UserToPostConnection&quot; */
export type UserToPostConnectionPageInfo = PageInfo &
  PostConnectionPageInfo &
  WpPageInfo & {
    __typename?: "UserToPostConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the UserToPostConnection connection */
export type UserToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: InputMaybe<Scalars["Int"]>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: InputMaybe<Scalars["String"]>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Category ID */
  categoryId?: InputMaybe<Scalars["Int"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Use Category Slug */
  categoryName?: InputMaybe<Scalars["String"]>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: InputMaybe<Scalars["String"]>;
  /** Use Tag ID */
  tagId?: InputMaybe<Scalars["String"]>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Array of tag slugs, used to display objects from one tag AND another */
  tagSlugAnd?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Array of tag slugs, used to include objects in ANY specified tags */
  tagSlugIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the User type and the ContentNode type */
export type UserToRevisionsConnection = Connection &
  ContentNodeConnection & {
    __typename?: "UserToRevisionsConnection";
    /** Edges for the UserToRevisionsConnection connection */
    edges: Array<UserToRevisionsConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<ContentNode>;
    /** Information about pagination in a connection. */
    pageInfo: UserToRevisionsConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToRevisionsConnectionEdge = ContentNodeConnectionEdge &
  Edge & {
    __typename?: "UserToRevisionsConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: ContentNode;
  };

/** Page Info on the &quot;UserToRevisionsConnection&quot; */
export type UserToRevisionsConnectionPageInfo = ContentNodeConnectionPageInfo &
  PageInfo &
  WpPageInfo & {
    __typename?: "UserToRevisionsConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Arguments for filtering the UserToRevisionsConnection connection */
export type UserToRevisionsConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: InputMaybe<Array<InputMaybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: InputMaybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: InputMaybe<Scalars["Boolean"]>;
  /** Specific database ID of the object */
  id?: InputMaybe<Scalars["Int"]>;
  /** Array of IDs for the objects to retrieve */
  in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: InputMaybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: InputMaybe<Scalars["String"]>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** What parameter to use to order the objects by. */
  orderby?: InputMaybe<Array<InputMaybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: InputMaybe<Scalars["ID"]>;
  /** Specify objects whose parent is in an array */
  parentIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** Show posts with a specific password. */
  password?: InputMaybe<Scalars["String"]>;
  /** Show Posts based on a keyword search */
  search?: InputMaybe<Scalars["String"]>;
  /** Retrieve posts where post status is in an array. */
  stati?: InputMaybe<Array<InputMaybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: InputMaybe<PostStatusEnum>;
  taxQuery?: InputMaybe<TaxQuery>;
  /** Title of the object */
  title?: InputMaybe<Scalars["String"]>;
};

/** Connection between the User type and the UserRole type */
export type UserToUserRoleConnection = Connection &
  UserRoleConnection & {
    __typename?: "UserToUserRoleConnection";
    /** Edges for the UserToUserRoleConnection connection */
    edges: Array<UserToUserRoleConnectionEdge>;
    /** The nodes of the connection, without the edges */
    nodes: Array<UserRole>;
    /** Information about pagination in a connection. */
    pageInfo: UserToUserRoleConnectionPageInfo;
  };

/** An edge in a connection */
export type UserToUserRoleConnectionEdge = Edge &
  UserRoleConnectionEdge & {
    __typename?: "UserToUserRoleConnectionEdge";
    /** A cursor for use in pagination */
    cursor?: Maybe<Scalars["String"]>;
    /** The item at the end of the edge */
    node: UserRole;
  };

/** Page Info on the &quot;UserToUserRoleConnection&quot; */
export type UserToUserRoleConnectionPageInfo = PageInfo &
  UserRoleConnectionPageInfo &
  WpPageInfo & {
    __typename?: "UserToUserRoleConnectionPageInfo";
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>;
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"];
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"];
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>;
  };

/** Field to order the connection by */
export enum UsersConnectionOrderbyEnum {
  /** Order by display name */
  DisplayName = "DISPLAY_NAME",
  /** Order by email address */
  Email = "EMAIL",
  /** Order by login */
  Login = "LOGIN",
  /** Preserve the login order given in the LOGIN_IN array */
  LoginIn = "LOGIN_IN",
  /** Order by nice name */
  NiceName = "NICE_NAME",
  /** Preserve the nice name order given in the NICE_NAME_IN array */
  NiceNameIn = "NICE_NAME_IN",
  /** Order by registration date */
  Registered = "REGISTERED",
  /** Order by URL */
  Url = "URL",
}

/** Options for ordering the connection */
export type UsersConnectionOrderbyInput = {
  /** The field name used to sort the results. */
  field: UsersConnectionOrderbyEnum;
  /** The cardinality of the order of the connection */
  order?: InputMaybe<OrderEnum>;
};

/** Column used for searching for users. */
export enum UsersConnectionSearchColumnEnum {
  /** The user's email address. */
  Email = "EMAIL",
  /** The globally unique ID. */
  Id = "ID",
  /** The username the User uses to login with. */
  Login = "LOGIN",
  /** A URL-friendly name for the user. The default is the user's username. */
  Nicename = "NICENAME",
  /** The URL of the user's website. */
  Url = "URL",
}

/** Information about pagination in a connection. */
export type WpPageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** The writing setting type */
export type WritingSettings = {
  __typename?: "WritingSettings";
  /** Default post category. */
  defaultCategory?: Maybe<Scalars["Int"]>;
  /** Default post format. */
  defaultPostFormat?: Maybe<Scalars["String"]>;
  /** Convert emoticons like :-) and :-P to graphics on display. */
  useSmilies?: Maybe<Scalars["Boolean"]>;
};

export type AllRegionProfilesQueryVariables = Exact<{
  locales?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
}>;

export type AllRegionProfilesQuery = {
  __typename?: "RootQuery";
  regionProfiles?: {
    __typename?: "RootQueryToRegionProfileConnection";
    nodes: Array<{
      __typename?: "RegionProfile";
      id: string;
      title?: string | null;
      meta?: {
        __typename?: "RegionProfile_Meta";
        urlFragment?: string | null;
        pageTitle?: string | null;
      } | null;
      regionProfile?: {
        __typename?: "RegionProfile_Regionprofile";
        components?: {
          __typename?: "RegionProfile_Regionprofile_Components";
          imagepulloutImage?: {
            __typename?: "MediaItem";
            altText?: string | null;
            sourceUrl?: string | null;
          } | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type AllRegionsQueryVariables = Exact<{ [key: string]: never }>;

export type AllRegionsQuery = {
  __typename?: "RootQuery";
  regions?: {
    __typename?: "RootQueryToRegionConnection";
    nodes: Array<{
      __typename?: "Region";
      id: string;
      regionProfiles?: {
        __typename?: "RegionToRegionProfileConnection";
        nodes: Array<{
          __typename?: "RegionProfile";
          meta?: { __typename?: "RegionProfile_Meta"; urlFragment?: string | null } | null;
          locales?: {
            __typename?: "RegionProfileToLocaleConnection";
            nodes: Array<{ __typename?: "Locale"; slug?: string | null }>;
          } | null;
        }>;
      } | null;
    }>;
  } | null;
};

export type ArticleFieldsFragment = {
  __typename?: "Article";
  id: string;
  title?: string | null;
  status?: string | null;
  date?: string | null;
  databaseId: number;
  uri?: string | null;
  slug?: string | null;
  articles?: { __typename?: "Article_Articles"; author?: string | null } | null;
  language?: {
    __typename?: "Language";
    name?: string | null;
    code?: LanguageCodeEnum | null;
  } | null;
  tags?: {
    __typename?: "ArticleToTagConnection";
    nodes: Array<{ __typename?: "Tag"; slug?: string | null; name?: string | null }>;
  } | null;
  categories?: {
    __typename?: "ArticleToCategoryConnection";
    nodes: Array<{ __typename?: "Category"; name?: string | null; slug?: string | null }>;
  } | null;
  featuredImage?: {
    __typename?: "NodeWithFeaturedImageToMediaItemConnectionEdge";
    node: {
      __typename?: "MediaItem";
      mediaItemUrl?: string | null;
      mediaDetails?: {
        __typename?: "MediaDetails";
        height?: number | null;
        width?: number | null;
      } | null;
    };
  } | null;
  meta?: {
    __typename?: "Article_Meta";
    searchTitle?: string | null;
    pageTitle?: string | null;
    searchDescription?: string | null;
    urlFragment?: string | null;
    searchImage?: {
      __typename?: "MediaItem";
      mediaItemUrl?: string | null;
      mediaDetails?: {
        __typename?: "MediaDetails";
        height?: number | null;
        width?: number | null;
      } | null;
    } | null;
  } | null;
};

export type GetArticlesQueryVariables = Exact<{
  locales?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
  languages?: InputMaybe<Array<LanguageCodeEnum> | LanguageCodeEnum>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
  after?: InputMaybe<Scalars["String"]>;
  first: Scalars["Int"];
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>> | InputMaybe<Scalars["ID"]>>;
}>;

export type GetArticlesQuery = {
  __typename?: "RootQuery";
  articles?: {
    __typename?: "RootQueryToArticleConnection";
    edges: Array<{
      __typename?: "RootQueryToArticleConnectionEdge";
      node: {
        __typename?: "Article";
        id: string;
        title?: string | null;
        status?: string | null;
        date?: string | null;
        databaseId: number;
        uri?: string | null;
        slug?: string | null;
        locales?: {
          __typename?: "ArticleToLocaleConnection";
          nodes: Array<{ __typename?: "Locale"; slug?: string | null }>;
        } | null;
        articles?: { __typename?: "Article_Articles"; author?: string | null } | null;
        language?: {
          __typename?: "Language";
          name?: string | null;
          code?: LanguageCodeEnum | null;
        } | null;
        tags?: {
          __typename?: "ArticleToTagConnection";
          nodes: Array<{ __typename?: "Tag"; slug?: string | null; name?: string | null }>;
        } | null;
        categories?: {
          __typename?: "ArticleToCategoryConnection";
          nodes: Array<{ __typename?: "Category"; name?: string | null; slug?: string | null }>;
        } | null;
        featuredImage?: {
          __typename?: "NodeWithFeaturedImageToMediaItemConnectionEdge";
          node: {
            __typename?: "MediaItem";
            mediaItemUrl?: string | null;
            mediaDetails?: {
              __typename?: "MediaDetails";
              height?: number | null;
              width?: number | null;
            } | null;
          };
        } | null;
        meta?: {
          __typename?: "Article_Meta";
          searchTitle?: string | null;
          pageTitle?: string | null;
          searchDescription?: string | null;
          urlFragment?: string | null;
          searchImage?: {
            __typename?: "MediaItem";
            mediaItemUrl?: string | null;
            mediaDetails?: {
              __typename?: "MediaDetails";
              height?: number | null;
              width?: number | null;
            } | null;
          } | null;
        } | null;
      };
    }>;
    pageInfo: {
      __typename?: "RootQueryToArticleConnectionPageInfo";
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetArticleBySlugQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;

export type GetArticleBySlugQuery = {
  __typename?: "RootQuery";
  article?: {
    __typename?: "Article";
    content?: string | null;
    status?: string | null;
    id: string;
    title?: string | null;
    date?: string | null;
    databaseId: number;
    uri?: string | null;
    slug?: string | null;
    locales?: {
      __typename?: "ArticleToLocaleConnection";
      nodes: Array<{ __typename?: "Locale"; slug?: string | null }>;
    } | null;
    articles?: { __typename?: "Article_Articles"; author?: string | null } | null;
    language?: {
      __typename?: "Language";
      name?: string | null;
      code?: LanguageCodeEnum | null;
    } | null;
    tags?: {
      __typename?: "ArticleToTagConnection";
      nodes: Array<{ __typename?: "Tag"; slug?: string | null; name?: string | null }>;
    } | null;
    categories?: {
      __typename?: "ArticleToCategoryConnection";
      nodes: Array<{ __typename?: "Category"; name?: string | null; slug?: string | null }>;
    } | null;
    featuredImage?: {
      __typename?: "NodeWithFeaturedImageToMediaItemConnectionEdge";
      node: {
        __typename?: "MediaItem";
        mediaItemUrl?: string | null;
        mediaDetails?: {
          __typename?: "MediaDetails";
          height?: number | null;
          width?: number | null;
        } | null;
      };
    } | null;
    meta?: {
      __typename?: "Article_Meta";
      searchTitle?: string | null;
      pageTitle?: string | null;
      searchDescription?: string | null;
      urlFragment?: string | null;
      searchImage?: {
        __typename?: "MediaItem";
        mediaItemUrl?: string | null;
        mediaDetails?: {
          __typename?: "MediaDetails";
          height?: number | null;
          width?: number | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type CollectionFieldsFragment = {
  __typename?: "Collection";
  title?: string | null;
  status?: string | null;
  date?: string | null;
  id: string;
  uri?: string | null;
  slug?: string | null;
  language?: {
    __typename?: "Language";
    name?: string | null;
    code?: LanguageCodeEnum | null;
  } | null;
  tags?: {
    __typename?: "CollectionToTagConnection";
    nodes: Array<{ __typename?: "Tag"; slug?: string | null; name?: string | null }>;
  } | null;
  featuredImage?: {
    __typename?: "NodeWithFeaturedImageToMediaItemConnectionEdge";
    node: {
      __typename?: "MediaItem";
      altText?: string | null;
      mediaItemUrl?: string | null;
      mediaDetails?: {
        __typename?: "MediaDetails";
        height?: number | null;
        width?: number | null;
      } | null;
    };
  } | null;
  meta?: {
    __typename?: "Collection_Meta";
    searchTitle?: string | null;
    pageTitle?: string | null;
    searchDescription?: string | null;
    urlFragment?: string | null;
    searchImage?: {
      __typename?: "MediaItem";
      mediaItemUrl?: string | null;
      mediaDetails?: {
        __typename?: "MediaDetails";
        height?: number | null;
        width?: number | null;
      } | null;
    } | null;
  } | null;
  collections?: {
    __typename?: "Collection_Collections";
    components?: {
      __typename?: "Collection_Collections_Components";
      collectionsVideo?: string | null;
      header?: {
        __typename?: "Collection_Collections_Components_Header";
        subtitle?: string | null;
        title?: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetCollectionsQueryVariables = Exact<{
  locales?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
  languages?: InputMaybe<Array<LanguageCodeEnum> | LanguageCodeEnum>;
  tags?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
  tagsOperator?: TaxQueryOperator;
  notIn?: InputMaybe<Array<InputMaybe<Scalars["ID"]>> | InputMaybe<Scalars["ID"]>>;
  after?: InputMaybe<Scalars["String"]>;
  first: Scalars["Int"];
}>;

export type GetCollectionsQuery = {
  __typename?: "RootQuery";
  collections?: {
    __typename?: "RootQueryToCollectionConnection";
    edges: Array<{
      __typename?: "RootQueryToCollectionConnectionEdge";
      node: {
        __typename?: "Collection";
        title?: string | null;
        status?: string | null;
        date?: string | null;
        id: string;
        uri?: string | null;
        slug?: string | null;
        locales?: {
          __typename?: "CollectionToLocaleConnection";
          nodes: Array<{ __typename?: "Locale"; slug?: string | null }>;
        } | null;
        language?: {
          __typename?: "Language";
          name?: string | null;
          code?: LanguageCodeEnum | null;
        } | null;
        tags?: {
          __typename?: "CollectionToTagConnection";
          nodes: Array<{ __typename?: "Tag"; slug?: string | null; name?: string | null }>;
        } | null;
        featuredImage?: {
          __typename?: "NodeWithFeaturedImageToMediaItemConnectionEdge";
          node: {
            __typename?: "MediaItem";
            altText?: string | null;
            mediaItemUrl?: string | null;
            mediaDetails?: {
              __typename?: "MediaDetails";
              height?: number | null;
              width?: number | null;
            } | null;
          };
        } | null;
        meta?: {
          __typename?: "Collection_Meta";
          searchTitle?: string | null;
          pageTitle?: string | null;
          searchDescription?: string | null;
          urlFragment?: string | null;
          searchImage?: {
            __typename?: "MediaItem";
            mediaItemUrl?: string | null;
            mediaDetails?: {
              __typename?: "MediaDetails";
              height?: number | null;
              width?: number | null;
            } | null;
          } | null;
        } | null;
        collections?: {
          __typename?: "Collection_Collections";
          components?: {
            __typename?: "Collection_Collections_Components";
            collectionsVideo?: string | null;
            header?: {
              __typename?: "Collection_Collections_Components_Header";
              subtitle?: string | null;
              title?: string | null;
            } | null;
          } | null;
        } | null;
      };
    }>;
    pageInfo: {
      __typename?: "RootQueryToCollectionConnectionPageInfo";
      endCursor?: string | null;
      hasNextPage: boolean;
    };
  } | null;
};

export type GetCollectionBySlugQueryVariables = Exact<{
  slug: Scalars["ID"];
}>;

export type GetCollectionBySlugQuery = {
  __typename?: "RootQuery";
  collection?: {
    __typename?: "Collection";
    status?: string | null;
    title?: string | null;
    date?: string | null;
    id: string;
    uri?: string | null;
    slug?: string | null;
    locales?: {
      __typename?: "CollectionToLocaleConnection";
      nodes: Array<{ __typename?: "Locale"; slug?: string | null }>;
    } | null;
    collections?: {
      __typename?: "Collection_Collections";
      components?: {
        __typename?: "Collection_Collections_Components";
        tripideasSectionTitle?: string | null;
        tripideasSectionSubtitle?: string | null;
        tripideasSectionDescription?: string | null;
        experttipTitle?: string | null;
        experttipQuote?: string | null;
        articlesSectionSubtitle?: string | null;
        articlesSectionTitle?: string | null;
        articlesSectionDescription?: string | null;
        chaptersNavlabel?: string | null;
        reviewslistTitle?: string | null;
        collectionsVideo?: string | null;
        header?: {
          __typename?: "Collection_Collections_Components_Header";
          subtitle?: string | null;
          title?: string | null;
          intro?: string | null;
        } | null;
        tripideas?: Array<{
          __typename?: "Collection_Collections_Components_tripideas";
          url?: string | null;
        } | null> | null;
        experttipImages?: Array<{
          __typename?: "MediaItem";
          altText?: string | null;
          mediaItemUrl?: string | null;
          mediaDetails?: {
            __typename?: "MediaDetails";
            height?: number | null;
            width?: number | null;
          } | null;
        } | null> | null;
        experttipCta?: {
          __typename?: "AcfLink";
          target?: string | null;
          title?: string | null;
          url?: string | null;
        } | null;
        articlesArticles?: Array<{
          __typename?: "Collection_Collections_Components_articlesArticles";
          type?: string | null;
          url?: string | null;
          article?: {
            __typename: "Article";
            slug?: string | null;
            title?: string | null;
            dateGmt?: string | null;
            status?: string | null;
            locales?: {
              __typename?: "ArticleToLocaleConnection";
              nodes: Array<{ __typename?: "Locale"; slug?: string | null }>;
            } | null;
            featuredImage?: {
              __typename?: "NodeWithFeaturedImageToMediaItemConnectionEdge";
              node: {
                __typename?: "MediaItem";
                mediaItemUrl?: string | null;
                mediaDetails?: {
                  __typename?: "MediaDetails";
                  height?: number | null;
                  width?: number | null;
                } | null;
              };
            } | null;
          } | null;
        } | null> | null;
        articlesCta?: {
          __typename?: "AcfLink";
          target?: string | null;
          title?: string | null;
          url?: string | null;
        } | null;
        chaptersHeaderimage?: {
          __typename?: "MediaItem";
          altText?: string | null;
          mediaItemUrl?: string | null;
          mediaDetails?: {
            __typename?: "MediaDetails";
            height?: number | null;
            width?: number | null;
          } | null;
        } | null;
        chapters?: Array<{
          __typename?: "Collection_Collections_Components_chapters";
          title?: string | null;
          menutitle?: string | null;
          html?: string | null;
          slug?: string | null;
        } | null> | null;
        reviewslistReviews?: Array<{
          __typename?: "Collection_Collections_Components_reviewslistReviews";
          author?: string | null;
          quote?: string | null;
          rating?: number | null;
          title?: string | null;
        } | null> | null;
      } | null;
    } | null;
    language?: {
      __typename?: "Language";
      name?: string | null;
      code?: LanguageCodeEnum | null;
    } | null;
    tags?: {
      __typename?: "CollectionToTagConnection";
      nodes: Array<{ __typename?: "Tag"; slug?: string | null; name?: string | null }>;
    } | null;
    featuredImage?: {
      __typename?: "NodeWithFeaturedImageToMediaItemConnectionEdge";
      node: {
        __typename?: "MediaItem";
        altText?: string | null;
        mediaItemUrl?: string | null;
        mediaDetails?: {
          __typename?: "MediaDetails";
          height?: number | null;
          width?: number | null;
        } | null;
      };
    } | null;
    meta?: {
      __typename?: "Collection_Meta";
      searchTitle?: string | null;
      pageTitle?: string | null;
      searchDescription?: string | null;
      urlFragment?: string | null;
      searchImage?: {
        __typename?: "MediaItem";
        mediaItemUrl?: string | null;
        mediaDetails?: {
          __typename?: "MediaDetails";
          height?: number | null;
          width?: number | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type GetCountryQueryVariables = Exact<{
  country?: InputMaybe<Scalars["String"]>;
  locales?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
}>;

export type GetCountryQuery = {
  __typename?: "RootQuery";
  countryProfiles?: {
    __typename?: "RootQueryToCountryProfileConnection";
    nodes: Array<{
      __typename?: "CountryProfile";
      meta?: {
        __typename?: "CountryProfile_Meta";
        searchTitle?: string | null;
        searchDescription?: string | null;
        searchImage?: { __typename?: "MediaItem"; sourceUrl?: string | null } | null;
      } | null;
      country?: {
        __typename?: "CountryProfile_Country";
        countryherovideo?: string | null;
        countrycolour?: string | null;
        components?: {
          __typename?: "CountryProfile_Country_Components";
          partnernoticeContent?: string | null;
          partnernoticeHeading?: string | null;
          partnernoticeStamp?: boolean | null;
          introgroup?: {
            __typename?: "CountryProfile_Country_Components_Introgroup";
            intro?: string | null;
            subheading?: string | null;
          } | null;
          articlesArticles?: Array<{
            __typename?: "CountryProfile_Country_Components_articlesArticles";
            article?: {
              __typename?: "Article";
              link?: string | null;
              title?: string | null;
              date?: string | null;
              featuredImage?: {
                __typename?: "NodeWithFeaturedImageToMediaItemConnectionEdge";
                node: {
                  __typename?: "MediaItem";
                  altText?: string | null;
                  sourceUrl?: string | null;
                  title?: string | null;
                };
              } | null;
              meta?: { __typename?: "Article_Meta"; urlFragment?: string | null } | null;
            } | null;
          } | null> | null;
          reviewslistReviews?: Array<{
            __typename?: "CountryProfile_Country_Components_reviewslistReviews";
            author?: string | null;
            quote?: string | null;
            rating?: number | null;
            title?: string | null;
          } | null> | null;
          imagepulloutImage?: { __typename?: "MediaItem"; sourceUrl?: string | null } | null;
        } | null;
        countryheroimage?: { __typename?: "MediaItem"; sourceUrl?: string | null } | null;
      } | null;
      countries?: {
        __typename?: "CountryProfileToCountryConnection";
        nodes: Array<{
          __typename?: "Country";
          slug?: string | null;
          countryProfiles?: {
            __typename?: "CountryToCountryProfileConnection";
            nodes: Array<{
              __typename?: "CountryProfile";
              locales?: {
                __typename?: "CountryProfileToLocaleConnection";
                nodes: Array<{ __typename?: "Locale"; name?: string | null }>;
              } | null;
              meta?: { __typename?: "CountryProfile_Meta"; urlFragment?: string | null } | null;
            }>;
          } | null;
        }>;
      } | null;
    }>;
  } | null;
  globalContents?: {
    __typename?: "RootQueryToGlobalContentConnection";
    nodes: Array<{
      __typename?: "GlobalContent";
      globalContent?: {
        __typename?: "GlobalContent_Globalcontent";
        quietlinksLinks?: Array<{
          __typename?: "GlobalContent_Globalcontent_quietlinksLinks";
          title?: string | null;
          description?: string | null;
          cta?: { __typename?: "AcfLink"; title?: string | null; url?: string | null } | null;
        } | null> | null;
      } | null;
    }>;
  } | null;
};

export type GetCountryHeroImageQueryVariables = Exact<{
  country?: InputMaybe<Scalars["String"]>;
  locales?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
}>;

export type GetCountryHeroImageQuery = {
  __typename?: "RootQuery";
  countryProfiles?: {
    __typename?: "RootQueryToCountryProfileConnection";
    nodes: Array<{
      __typename?: "CountryProfile";
      country?: {
        __typename?: "CountryProfile_Country";
        countryheroimage?: {
          __typename?: "MediaItem";
          sourceUrl?: string | null;
          altText?: string | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type GetGlobalContentQueryVariables = Exact<{
  localeTerms?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
}>;

export type GetGlobalContentQuery = {
  __typename?: "RootQuery";
  globalContents?: {
    __typename?: "RootQueryToGlobalContentConnection";
    nodes: Array<{
      __typename?: "GlobalContent";
      globalContent?: {
        __typename?: "GlobalContent_Globalcontent";
        reviewslistTitle?: string | null;
        socialmedia?: Array<{
          __typename?: "GlobalContent_Globalcontent_socialmedia";
          label?: string | null;
          platform?: string | null;
          url?: string | null;
        } | null> | null;
        reviewslistReviews?: Array<{
          __typename?: "GlobalContent_Globalcontent_reviewslistReviews";
          title?: string | null;
          rating?: number | null;
          quote?: string | null;
          author?: string | null;
        } | null> | null;
      } | null;
    }>;
  } | null;
};

export type GetHomePagesQueryVariables = Exact<{ [key: string]: never }>;

export type GetHomePagesQuery = {
  __typename?: "RootQuery";
  homePages?: {
    __typename?: "RootQueryToHomePageConnection";
    nodes: Array<{
      __typename?: "HomePage";
      meta?: {
        __typename?: "HomePage_Meta";
        pageTitle?: string | null;
        searchTitle?: string | null;
        searchDescription?: string | null;
        urlFragment?: string | null;
        searchImage?: { __typename?: "MediaItem"; sourceUrl?: string | null } | null;
      } | null;
      locales?: {
        __typename?: "HomePageToLocaleConnection";
        nodes: Array<{ __typename?: "Locale"; slug?: string | null }>;
      } | null;
      language?: {
        __typename?: "Language";
        name?: string | null;
        code?: LanguageCodeEnum | null;
      } | null;
      fields?: {
        __typename?: "HomePage_Fields";
        components?: {
          __typename?: "HomePage_Fields_Components";
          homeheroTitle?: string | null;
          homeheroSubtitle?: string | null;
          videomodalEmbed?: string | null;
          videomodalCtalabel?: string | null;
          videomodalArialabel?: string | null;
          billboard1BillboardCaption?: string | null;
          billboard1BillboardStyle?: string | null;
          billboard1BillboardContent?: string | null;
          infocusTitle?: string | null;
          infocusSubtitle?: string | null;
          infocusDescription?: string | null;
          locationcardsTitle?: string | null;
          billboard2BillboardCaption?: string | null;
          billboard2BillboardStyle?: string | null;
          billboard2BillboardContent?: string | null;
          reviewslistTitle?: string | null;
          billboard3BillboardCaption?: string | null;
          billboard3BillboardStyle?: string | null;
          billboard3BillboardContent?: string | null;
          featuredlocation1ImagepulloutTitle?: string | null;
          featuredlocation1ImagepulloutDescription?: string | null;
          featuredlocation2ImagepulloutTitle?: string | null;
          featuredlocation2ImagepulloutDescription?: string | null;
          homeheroCta?: {
            __typename?: "AcfLink";
            target?: string | null;
            title?: string | null;
            url?: string | null;
          } | null;
          quietlinksLinks?: Array<{
            __typename?: "HomePage_Fields_Components_quietlinksLinks";
            title?: string | null;
            description?: string | null;
            cta?: {
              __typename?: "AcfLink";
              target?: string | null;
              title?: string | null;
              url?: string | null;
            } | null;
          } | null> | null;
          billboard1BillboardImage?: {
            __typename?: "MediaItem";
            sourceUrl?: string | null;
            altText?: string | null;
            mediaDetails?: {
              __typename?: "MediaDetails";
              height?: number | null;
              width?: number | null;
            } | null;
          } | null;
          billboard1BillboardCta?: {
            __typename?: "AcfLink";
            target?: string | null;
            title?: string | null;
            url?: string | null;
          } | null;
          infocusArticles?: Array<{
            __typename?: "HomePage_Fields_Components_infocusArticles";
            url?: string | null;
            type?: string | null;
          } | null> | null;
          locationcardsLocations?: Array<{
            __typename?: "HomePage_Fields_Components_locationcardsLocations";
            url?: string | null;
            color?: string | null;
          } | null> | null;
          locationcardsCta?: {
            __typename?: "AcfLink";
            target?: string | null;
            title?: string | null;
            url?: string | null;
          } | null;
          billboard2BillboardImage?: {
            __typename?: "MediaItem";
            sourceUrl?: string | null;
            altText?: string | null;
            mediaDetails?: {
              __typename?: "MediaDetails";
              height?: number | null;
              width?: number | null;
            } | null;
          } | null;
          billboard2BillboardCta?: {
            __typename?: "AcfLink";
            target?: string | null;
            title?: string | null;
            url?: string | null;
          } | null;
          reviewslistReviews?: Array<{
            __typename?: "HomePage_Fields_Components_reviewslistReviews";
            rating?: number | null;
            title?: string | null;
            quote?: string | null;
            author?: string | null;
          } | null> | null;
          articlesArticles?: Array<{
            __typename?: "HomePage_Fields_Components_articlesArticles";
            type?: string | null;
            url?: string | null;
            article?: { __typename: "Article"; slug?: string | null; databaseId: number } | null;
          } | null> | null;
          articlesCta?: {
            __typename?: "AcfLink";
            target?: string | null;
            title?: string | null;
            url?: string | null;
          } | null;
          billboard3BillboardImage?: {
            __typename?: "MediaItem";
            sourceUrl?: string | null;
            altText?: string | null;
            mediaDetails?: {
              __typename?: "MediaDetails";
              height?: number | null;
              width?: number | null;
            } | null;
          } | null;
          billboard3BillboardCta?: {
            __typename?: "AcfLink";
            target?: string | null;
            title?: string | null;
            url?: string | null;
          } | null;
          featuredlocation1ImagepulloutImage?: {
            __typename?: "MediaItem";
            sourceUrl?: string | null;
            altText?: string | null;
            mediaDetails?: {
              __typename?: "MediaDetails";
              height?: number | null;
              width?: number | null;
            } | null;
          } | null;
          featuredlocation1ImagepulloutCta?: {
            __typename?: "AcfLink";
            target?: string | null;
            title?: string | null;
            url?: string | null;
          } | null;
          featuredtripideas1Tripideas?: Array<{
            __typename?: "HomePage_Fields_Components_featuredtripideas1Tripideas";
            url?: string | null;
          } | null> | null;
          featuredlocation2ImagepulloutImage?: {
            __typename?: "MediaItem";
            sourceUrl?: string | null;
            altText?: string | null;
            mediaDetails?: {
              __typename?: "MediaDetails";
              height?: number | null;
              width?: number | null;
            } | null;
          } | null;
          featuredlocation2ImagepulloutCta?: {
            __typename?: "AcfLink";
            target?: string | null;
            title?: string | null;
            url?: string | null;
          } | null;
          featuredtripideas2Tripideas?: Array<{
            __typename?: "HomePage_Fields_Components_featuredtripideas2Tripideas";
            url?: string | null;
          } | null> | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type GetHowItWorksQueryVariables = Exact<{ [key: string]: never }>;

export type GetHowItWorksQuery = {
  __typename?: "RootQuery";
  howItWorksPages?: {
    __typename?: "RootQueryToHowItWorksPageConnection";
    nodes: Array<{
      __typename?: "HowItWorksPage";
      howItWorks?: {
        __typename?: "HowItWorksPage_Howitworks";
        components?: {
          __typename?: "HowItWorksPage_Howitworks_Components";
          introductiontext?: string | null;
          standardheaderHeading?: string | null;
          standardheaderOverlayopacity?: number | null;
          standardheaderSubheading?: string | null;
          billboardContent?: string | null;
          billboardCaption?: string | null;
          components?: Array<{
            __typename?: "HowItWorksPage_Howitworks_Components_components";
            metrichighlightText?: string | null;
            metrichighlightTitle?: string | null;
          } | null> | null;
          standardheaderBackgroundimage?: {
            __typename?: "MediaItem";
            sourceUrl?: string | null;
            altText?: string | null;
            mediaDetails?: {
              __typename?: "MediaDetails";
              height?: number | null;
              width?: number | null;
            } | null;
          } | null;
          propositionpulloutgroup?: Array<{
            __typename?: "HowItWorksPage_Howitworks_Components_propositionpulloutgroup";
            propositionpulloutHeading?: string | null;
            propositionpulloutSubtitle?: string | null;
            abtalogo?: string | null;
            astalogo?: string | null;
            atollogo?: string | null;
            isLayoutFlipped?: boolean | null;
            buttonurl?: string | null;
            buttoncta?: string | null;
            drvlogo?: string | null;
            showAbtaLogo?: boolean | null;
            showAstaLogo?: boolean | null;
            showAtolLogo?: boolean | null;
            showDrvLogo?: boolean | null;
            propositionpulloutItems?: Array<{
              __typename?: "HowItWorksPage_Howitworks_Components_propositionpulloutgroup_propositionpulloutItems";
              item?: string | null;
            } | null> | null;
            propositionpulloutLargeimage?: {
              __typename?: "MediaItem";
              sourceUrl?: string | null;
              altText?: string | null;
              mediaDetails?: {
                __typename?: "MediaDetails";
                height?: number | null;
                width?: number | null;
              } | null;
            } | null;
          } | null> | null;
          faqslistQuestions?: Array<{
            __typename?: "HowItWorksPage_Howitworks_Components_faqslistQuestions";
            question?: string | null;
            url?: string | null;
          } | null> | null;
          billboardCta?: {
            __typename?: "AcfLink";
            title?: string | null;
            target?: string | null;
            url?: string | null;
          } | null;
          billboardImage?: {
            __typename?: "MediaItem";
            altText?: string | null;
            sourceUrl?: string | null;
            mediaDetails?: {
              __typename?: "MediaDetails";
              height?: number | null;
              width?: number | null;
            } | null;
          } | null;
          articlesArticles?: Array<{
            __typename?: "HowItWorksPage_Howitworks_Components_articlesArticles";
            article?: {
              __typename?: "Article";
              title?: string | null;
              date?: string | null;
              featuredImage?: {
                __typename?: "NodeWithFeaturedImageToMediaItemConnectionEdge";
                node: { __typename?: "MediaItem"; sourceUrl?: string | null };
              } | null;
              meta?: { __typename?: "Article_Meta"; urlFragment?: string | null } | null;
            } | null;
          } | null> | null;
          reviewslistReviews?: Array<{
            __typename?: "HowItWorksPage_Howitworks_Components_reviewslistReviews";
            quote?: string | null;
            rating?: number | null;
          } | null> | null;
        } | null;
      } | null;
      language?: { __typename?: "Language"; slug?: string | null } | null;
    }>;
  } | null;
};

export type GetRegionProfileQueryVariables = Exact<{
  region?: InputMaybe<Scalars["String"]>;
  locales?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
  regionId: Scalars["ID"];
}>;

export type GetRegionProfileQuery = {
  __typename?: "RootQuery";
  regionProfiles?: {
    __typename?: "RootQueryToRegionProfileConnection";
    nodes: Array<{
      __typename?: "RegionProfile";
      title?: string | null;
      locales?: {
        __typename?: "RegionProfileToLocaleConnection";
        nodes: Array<{ __typename?: "Locale"; slug?: string | null }>;
      } | null;
      meta?: {
        __typename?: "RegionProfile_Meta";
        urlFragment?: string | null;
        pageTitle?: string | null;
        searchDescription?: string | null;
      } | null;
      regionProfile?: {
        __typename?: "RegionProfile_Regionprofile";
        regionIntro?: string | null;
        components?: {
          __typename?: "RegionProfile_Regionprofile_Components";
          reviewslistTitle?: string | null;
          articlesArticles?: Array<{
            __typename?: "RegionProfile_Regionprofile_Components_articlesArticles";
            article?: {
              __typename?: "Article";
              title?: string | null;
              date?: string | null;
              link?: string | null;
              featuredImage?: {
                __typename?: "NodeWithFeaturedImageToMediaItemConnectionEdge";
                node: {
                  __typename?: "MediaItem";
                  altText?: string | null;
                  sourceUrl?: string | null;
                  title?: string | null;
                };
              } | null;
              meta?: { __typename?: "Article_Meta"; urlFragment?: string | null } | null;
            } | null;
          } | null> | null;
          reviewslistReviews?: Array<{
            __typename?: "RegionProfile_Regionprofile_Components_reviewslistReviews";
            author?: string | null;
            quote?: string | null;
            rating?: number | null;
            title?: string | null;
          } | null> | null;
          imagepulloutImage?: {
            __typename?: "MediaItem";
            altText?: string | null;
            sourceUrl?: string | null;
          } | null;
        } | null;
      } | null;
      terms?: {
        __typename?: "RegionProfileToTermNodeConnection";
        nodes: Array<
          | { __typename?: "Category" }
          | { __typename?: "ContentLanguages" }
          | { __typename?: "ContentStatus" }
          | { __typename?: "Country" }
          | { __typename?: "Locale" }
          | { __typename?: "PostFormat" }
          | { __typename?: "Region"; name?: string | null; termTaxonomyId?: number | null }
          | { __typename?: "Tag" }
        >;
      } | null;
      regions?: {
        __typename?: "RegionProfileToRegionConnection";
        nodes: Array<{
          __typename?: "Region";
          slug?: string | null;
          regionProfiles?: {
            __typename?: "RegionToRegionProfileConnection";
            nodes: Array<{
              __typename?: "RegionProfile";
              locales?: {
                __typename?: "RegionProfileToLocaleConnection";
                nodes: Array<{ __typename?: "Locale"; name?: string | null }>;
              } | null;
              meta?: { __typename?: "RegionProfile_Meta"; urlFragment?: string | null } | null;
            }>;
          } | null;
        }>;
      } | null;
    }>;
  } | null;
  region?: {
    __typename?: "Region";
    regionTaxonomy?: {
      __typename?: "Region_Regiontaxonomy";
      components?: {
        __typename?: "Region_Regiontaxonomy_Components";
        partnernoticeImages?: Array<{
          __typename?: "Region_Regiontaxonomy_Components_partnernoticeImages";
          ltoImage?: {
            __typename?: "MediaItem";
            altText?: string | null;
            sourceUrl?: string | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};

export type GetRegionIdBySlugQueryVariables = Exact<{
  regionProfileSlug?: InputMaybe<Scalars["String"]>;
}>;

export type GetRegionIdBySlugQuery = {
  __typename?: "RootQuery";
  regionProfiles?: {
    __typename?: "RootQueryToRegionProfileConnection";
    nodes: Array<{
      __typename?: "RegionProfile";
      regions?: {
        __typename?: "RegionProfileToRegionConnection";
        nodes: Array<{ __typename?: "Region"; id: string }>;
      } | null;
    }>;
  } | null;
};

export type GetCountriesByRegionQueryVariables = Exact<{
  regionTermIds?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
  localeTerms?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
}>;

export type GetCountriesByRegionQuery = {
  __typename?: "RootQuery";
  countryProfiles?: {
    __typename?: "RootQueryToCountryProfileConnection";
    nodes: Array<{
      __typename?: "CountryProfile";
      id: string;
      title?: string | null;
      country?: {
        __typename?: "CountryProfile_Country";
        countrycolour?: string | null;
        countryheroimage?: {
          __typename?: "MediaItem";
          altText?: string | null;
          sourceUrl?: string | null;
        } | null;
      } | null;
      meta?: {
        __typename?: "CountryProfile_Meta";
        urlFragment?: string | null;
        pageTitle?: string | null;
      } | null;
    }>;
  } | null;
};

export type GetRequestFormQueryVariables = Exact<{
  country?: InputMaybe<Scalars["String"]>;
  locale?: InputMaybe<Scalars["String"]>;
}>;

export type GetRequestFormQuery = {
  __typename?: "RootQuery";
  countryProfiles?: {
    __typename?: "RootQueryToCountryProfileConnection";
    nodes: Array<{
      __typename?: "CountryProfile";
      requestForm?: {
        __typename?: "CountryProfile_Requestform";
        countryRequestFormImage?: { __typename?: "MediaItem"; sourceUrl?: string | null } | null;
      } | null;
    }>;
  } | null;
};

export type GetSubscribeQueryVariables = Exact<{
  locales?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
}>;

export type GetSubscribeQuery = {
  __typename?: "RootQuery";
  globalContents?: {
    __typename?: "RootQueryToGlobalContentConnection";
    nodes: Array<{
      __typename?: "GlobalContent";
      globalContent?: {
        __typename?: "GlobalContent_Globalcontent";
        subscribeComponent?: {
          __typename?: "GlobalContent_Globalcontent_SubscribeComponent";
          subscribeText?: string | null;
          subscribeBodyText?: string | null;
        } | null;
      } | null;
    }>;
  } | null;
};

export type GetTagsBySlugQueryVariables = Exact<{
  slugs?: InputMaybe<Array<InputMaybe<Scalars["String"]>> | InputMaybe<Scalars["String"]>>;
}>;

export type GetTagsBySlugQuery = {
  __typename?: "RootQuery";
  tags?: {
    __typename?: "RootQueryToTagConnection";
    nodes: Array<{
      __typename?: "Tag";
      name?: string | null;
      slug?: string | null;
      count?: number | null;
    }>;
  } | null;
};

export const ArticleFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ArticleFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Article" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "title" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "format" },
                value: { kind: "EnumValue", value: "RENDERED" },
              },
            ],
          },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "articles" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "author" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "date" } },
          { kind: "Field", name: { kind: "Name", value: "databaseId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "language" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "tags" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "categories" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "featuredImage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "node" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "mediaItemUrl" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mediaDetails" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "height" } },
                            { kind: "Field", name: { kind: "Name", value: "width" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "searchTitle" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "searchImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "mediaItemUrl" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mediaDetails" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "height" } },
                            { kind: "Field", name: { kind: "Name", value: "width" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "pageTitle" } },
                { kind: "Field", name: { kind: "Name", value: "searchDescription" } },
                { kind: "Field", name: { kind: "Name", value: "urlFragment" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "uri" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ArticleFieldsFragment, unknown>;
export const CollectionFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CollectionFields" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Collection" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "title" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "format" },
                value: { kind: "EnumValue", value: "RENDERED" },
              },
            ],
          },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "date" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "language" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "tags" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "featuredImage" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "node" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "altText" } },
                      { kind: "Field", name: { kind: "Name", value: "mediaItemUrl" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mediaDetails" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "height" } },
                            { kind: "Field", name: { kind: "Name", value: "width" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "meta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "searchTitle" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "searchImage" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "mediaItemUrl" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mediaDetails" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "height" } },
                            { kind: "Field", name: { kind: "Name", value: "width" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "pageTitle" } },
                { kind: "Field", name: { kind: "Name", value: "searchDescription" } },
                { kind: "Field", name: { kind: "Name", value: "urlFragment" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "uri" } },
          { kind: "Field", name: { kind: "Name", value: "slug" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "collections" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "components" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "header" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "subtitle" } },
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "collectionsVideo" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CollectionFieldsFragment, unknown>;
export const AllRegionProfilesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllRegionProfiles" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locales" } },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "regionProfiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "500" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "NullValue" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "taxQuery" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "taxArray" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "taxonomy" },
                                  value: { kind: "EnumValue", value: "LOCALE" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "terms" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "locales" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "field" },
                                  value: { kind: "EnumValue", value: "SLUG" },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "meta" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "urlFragment" } },
                            { kind: "Field", name: { kind: "Name", value: "pageTitle" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionProfile" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "components" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imagepulloutImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "altText" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "sourceUrl" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllRegionProfilesQuery, AllRegionProfilesQueryVariables>;
export const AllRegionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "AllRegions" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "regions" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "500" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "NullValue" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionProfiles" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "meta" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "urlFragment" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "locales" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "nodes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "slug" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AllRegionsQuery, AllRegionsQueryVariables>;
export const GetArticlesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetArticles" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locales" } },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "languages" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "LanguageCodeEnum" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "tags" } },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
          defaultValue: { kind: "ListValue", values: [] },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "first" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "notIn" } },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "articles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "languages" },
                      value: { kind: "Variable", name: { kind: "Name", value: "languages" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "tagSlugIn" },
                      value: { kind: "Variable", name: { kind: "Name", value: "tags" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "taxQuery" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "taxArray" },
                            value: {
                              kind: "ListValue",
                              values: [
                                {
                                  kind: "ObjectValue",
                                  fields: [
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "taxonomy" },
                                      value: { kind: "EnumValue", value: "LOCALE" },
                                    },
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "operator" },
                                      value: { kind: "EnumValue", value: "IN" },
                                    },
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "field" },
                                      value: { kind: "EnumValue", value: "SLUG" },
                                    },
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "terms" },
                                      value: {
                                        kind: "Variable",
                                        name: { kind: "Name", value: "locales" },
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "notIn" },
                      value: { kind: "Variable", name: { kind: "Name", value: "notIn" } },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "Variable", name: { kind: "Name", value: "after" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "first" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "ArticleFields" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locales" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "slug" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "endCursor" } },
                      { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ArticleFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetArticlesQuery, GetArticlesQueryVariables>;
export const GetArticleBySlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetArticleBySlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "article" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "slug" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "idType" },
                value: { kind: "EnumValue", value: "SLUG" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "ArticleFields" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "locales" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "slug" } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "content" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "format" },
                      value: { kind: "EnumValue", value: "RENDERED" },
                    },
                  ],
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
    ...ArticleFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetArticleBySlugQuery, GetArticleBySlugQueryVariables>;
export const GetCollectionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCollections" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locales" } },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "languages" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "LanguageCodeEnum" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "tags" } },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
          defaultValue: { kind: "ListValue", values: [] },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "tagsOperator" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "TaxQueryOperator" } },
          },
          defaultValue: { kind: "EnumValue", value: "NOT_IN" },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "notIn" } },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "first" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "collections" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "languages" },
                      value: { kind: "Variable", name: { kind: "Name", value: "languages" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "taxQuery" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "relation" },
                            value: { kind: "EnumValue", value: "AND" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "taxArray" },
                            value: {
                              kind: "ListValue",
                              values: [
                                {
                                  kind: "ObjectValue",
                                  fields: [
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "taxonomy" },
                                      value: { kind: "EnumValue", value: "LOCALE" },
                                    },
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "operator" },
                                      value: { kind: "EnumValue", value: "IN" },
                                    },
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "field" },
                                      value: { kind: "EnumValue", value: "SLUG" },
                                    },
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "terms" },
                                      value: {
                                        kind: "Variable",
                                        name: { kind: "Name", value: "locales" },
                                      },
                                    },
                                  ],
                                },
                                {
                                  kind: "ObjectValue",
                                  fields: [
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "taxonomy" },
                                      value: { kind: "EnumValue", value: "TAG" },
                                    },
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "operator" },
                                      value: {
                                        kind: "Variable",
                                        name: { kind: "Name", value: "tagsOperator" },
                                      },
                                    },
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "field" },
                                      value: { kind: "EnumValue", value: "SLUG" },
                                    },
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "terms" },
                                      value: {
                                        kind: "Variable",
                                        name: { kind: "Name", value: "tags" },
                                      },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "notIn" },
                      value: { kind: "Variable", name: { kind: "Name", value: "notIn" } },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "Variable", name: { kind: "Name", value: "after" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "first" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: { kind: "Name", value: "CollectionFields" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locales" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "slug" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "endCursor" } },
                      { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...CollectionFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetCollectionsQuery, GetCollectionsQueryVariables>;
export const GetCollectionBySlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCollectionBySlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "collection" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "slug" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "idType" },
                value: { kind: "EnumValue", value: "SLUG" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "CollectionFields" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "locales" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "slug" } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "collections" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "components" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "header" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "subtitle" } },
                                  { kind: "Field", name: { kind: "Name", value: "title" } },
                                  { kind: "Field", name: { kind: "Name", value: "intro" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tripideasSectionTitle" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tripideasSectionSubtitle" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tripideasSectionDescription" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "tripideas" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "experttipImages" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "altText" } },
                                  { kind: "Field", name: { kind: "Name", value: "mediaItemUrl" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "mediaDetails" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "height" } },
                                        { kind: "Field", name: { kind: "Name", value: "width" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "experttipTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "experttipQuote" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "experttipCta" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "target" } },
                                  { kind: "Field", name: { kind: "Name", value: "title" } },
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "articlesSectionSubtitle" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "articlesSectionTitle" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "articlesSectionDescription" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "articlesArticles" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "type" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "article" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "__typename" },
                                        },
                                        {
                                          kind: "InlineFragment",
                                          typeCondition: {
                                            kind: "NamedType",
                                            name: { kind: "Name", value: "Article" },
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "slug" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "title" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "dateGmt" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "status" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "locales" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "nodes" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "slug" },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "featuredImage" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "node" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "mediaItemUrl",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "mediaDetails",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "height",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "width",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "articlesCta" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "target" } },
                                  { kind: "Field", name: { kind: "Name", value: "title" } },
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "chaptersNavlabel" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "chaptersHeaderimage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "altText" } },
                                  { kind: "Field", name: { kind: "Name", value: "mediaItemUrl" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "mediaDetails" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "height" } },
                                        { kind: "Field", name: { kind: "Name", value: "width" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "chapters" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "title" } },
                                  { kind: "Field", name: { kind: "Name", value: "menutitle" } },
                                  { kind: "Field", name: { kind: "Name", value: "html" } },
                                  { kind: "Field", name: { kind: "Name", value: "slug" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "reviewslistTitle" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "reviewslistReviews" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "author" } },
                                  { kind: "Field", name: { kind: "Name", value: "quote" } },
                                  { kind: "Field", name: { kind: "Name", value: "rating" } },
                                  { kind: "Field", name: { kind: "Name", value: "title" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
    ...CollectionFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<GetCollectionBySlugQuery, GetCollectionBySlugQueryVariables>;
export const GetCountryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCountry" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "country" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locales" } },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "countryProfiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "urlFragment" },
                      value: { kind: "Variable", name: { kind: "Name", value: "country" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "taxQuery" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "taxArray" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "taxonomy" },
                                  value: { kind: "EnumValue", value: "LOCALE" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "terms" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "locales" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "field" },
                                  value: { kind: "EnumValue", value: "SLUG" },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "meta" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "searchTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "searchDescription" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "searchImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "sourceUrl" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "components" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "introgroup" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "intro" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "subheading" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "articlesArticles" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "article" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: { kind: "Name", value: "Article" },
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "link" },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "title" },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "featuredImage",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "node" },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "altText",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "sourceUrl",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "title",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "date" },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "meta" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "urlFragment",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "reviewslistReviews" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "author" } },
                                        { kind: "Field", name: { kind: "Name", value: "quote" } },
                                        { kind: "Field", name: { kind: "Name", value: "rating" } },
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "partnernoticeContent" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "partnernoticeHeading" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "partnernoticeStamp" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imagepulloutImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "sourceUrl" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "countryherovideo" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryheroimage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "sourceUrl" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "countrycolour" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "countries" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "countryProfiles" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "nodes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "locales" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "nodes" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "name" },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "meta" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "urlFragment" },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "slug" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "globalContents" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "taxQuery" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "taxArray" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "taxonomy" },
                                  value: { kind: "EnumValue", value: "LOCALE" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "terms" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "locales" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "field" },
                                  value: { kind: "EnumValue", value: "SLUG" },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "globalContent" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "quietlinksLinks" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "title" } },
                                  { kind: "Field", name: { kind: "Name", value: "description" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "cta" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCountryQuery, GetCountryQueryVariables>;
export const GetCountryHeroImageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCountryHeroImage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "country" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locales" } },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "countryProfiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "urlFragment" },
                      value: { kind: "Variable", name: { kind: "Name", value: "country" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "taxQuery" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "taxArray" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "taxonomy" },
                                  value: { kind: "EnumValue", value: "LOCALE" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "terms" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "locales" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "field" },
                                  value: { kind: "EnumValue", value: "SLUG" },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryheroimage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "sourceUrl" } },
                                  { kind: "Field", name: { kind: "Name", value: "altText" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCountryHeroImageQuery, GetCountryHeroImageQueryVariables>;
export const GetGlobalContentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetGlobalContent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "localeTerms" } },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "globalContents" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "taxQuery" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "taxArray" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "taxonomy" },
                                  value: { kind: "EnumValue", value: "LOCALE" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "terms" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "localeTerms" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "field" },
                                  value: { kind: "EnumValue", value: "SLUG" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "operator" },
                                  value: { kind: "EnumValue", value: "IN" },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "globalContent" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "socialmedia" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "label" } },
                                  { kind: "Field", name: { kind: "Name", value: "platform" } },
                                  { kind: "Field", name: { kind: "Name", value: "url" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "reviewslistReviews" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "title" } },
                                  { kind: "Field", name: { kind: "Name", value: "rating" } },
                                  { kind: "Field", name: { kind: "Name", value: "quote" } },
                                  { kind: "Field", name: { kind: "Name", value: "author" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "reviewslistTitle" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetGlobalContentQuery, GetGlobalContentQueryVariables>;
export const GetHomePagesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetHomePages" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "homePages" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "meta" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "pageTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "searchTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "searchDescription" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "searchImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "sourceUrl" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "urlFragment" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locales" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "slug" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "language" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "code" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fields" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "components" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "homeheroTitle" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "homeheroSubtitle" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "homeheroCta" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "target" } },
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "videomodalEmbed" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "videomodalCtalabel" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "videomodalArialabel" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "quietlinksLinks" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "description" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "cta" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "target" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "title" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "url" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billboard1BillboardImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "sourceUrl" },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "altText" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "mediaDetails" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "height" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "width" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billboard1BillboardCaption" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billboard1BillboardStyle" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billboard1BillboardContent" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billboard1BillboardCta" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "target" } },
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "infocusTitle" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "infocusSubtitle" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "infocusDescription" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "infocusArticles" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                        { kind: "Field", name: { kind: "Name", value: "type" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "locationcardsTitle" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "locationcardsLocations" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                        { kind: "Field", name: { kind: "Name", value: "color" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "locationcardsCta" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "target" } },
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billboard2BillboardImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "sourceUrl" },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "altText" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "mediaDetails" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "height" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "width" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billboard2BillboardCaption" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billboard2BillboardStyle" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billboard2BillboardContent" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billboard2BillboardCta" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "target" } },
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "reviewslistTitle" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "reviewslistReviews" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "rating" } },
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                        { kind: "Field", name: { kind: "Name", value: "quote" } },
                                        { kind: "Field", name: { kind: "Name", value: "author" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "articlesArticles" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "type" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "article" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "__typename" },
                                              },
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: { kind: "Name", value: "Article" },
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "slug" },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "databaseId" },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "articlesCta" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "target" } },
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billboard3BillboardImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "sourceUrl" },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "altText" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "mediaDetails" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "height" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "width" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billboard3BillboardCaption" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billboard3BillboardStyle" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billboard3BillboardContent" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billboard3BillboardCta" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "target" } },
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "featuredlocation1ImagepulloutTitle",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "featuredlocation1ImagepulloutDescription",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "featuredlocation1ImagepulloutImage",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "sourceUrl" },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "altText" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "mediaDetails" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "height" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "width" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "featuredlocation1ImagepulloutCta",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "target" } },
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "featuredtripideas1Tripideas" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "featuredlocation2ImagepulloutTitle",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "featuredlocation2ImagepulloutDescription",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "featuredlocation2ImagepulloutImage",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "sourceUrl" },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "altText" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "mediaDetails" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "height" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "width" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "featuredlocation2ImagepulloutCta",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "target" } },
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "featuredtripideas2Tripideas" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetHomePagesQuery, GetHomePagesQueryVariables>;
export const GetHowItWorksDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetHowItWorks" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "howItWorksPages" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "howItWorks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "components" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "components" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "metrichighlightText" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "metrichighlightTitle" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "introductiontext" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "standardheaderHeading" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "standardheaderOverlayopacity" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "standardheaderSubheading" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "standardheaderBackgroundimage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "sourceUrl" },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "altText" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "mediaDetails" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "height" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "width" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "propositionpulloutgroup" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "propositionpulloutHeading",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "propositionpulloutItems" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "item" },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "propositionpulloutSubtitle",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "propositionpulloutLargeimage",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "sourceUrl" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "altText" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "mediaDetails" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "height" },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "width" },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "abtalogo" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "astalogo" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "atollogo" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "isLayoutFlipped" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "buttonurl" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "buttoncta" },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "drvlogo" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "showAbtaLogo" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "showAstaLogo" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "showAtolLogo" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "showDrvLogo" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "faqslistQuestions" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "question" },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billboardCta" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                        { kind: "Field", name: { kind: "Name", value: "target" } },
                                        { kind: "Field", name: { kind: "Name", value: "url" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billboardContent" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billboardImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "altText" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "sourceUrl" },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "mediaDetails" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "height" },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "width" },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "billboardCaption" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "articlesArticles" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "article" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: { kind: "Name", value: "Article" },
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "title" },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "date" },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "featuredImage",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "node" },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "sourceUrl",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "meta" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "urlFragment",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "reviewslistReviews" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "quote" } },
                                        { kind: "Field", name: { kind: "Name", value: "rating" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "language" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "slug" } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetHowItWorksQuery, GetHowItWorksQueryVariables>;
export const GetRegionProfileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetRegionProfile" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "region" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locales" } },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "regionId" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "regionProfiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "urlFragment" },
                      value: { kind: "Variable", name: { kind: "Name", value: "region" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "taxQuery" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "taxArray" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "taxonomy" },
                                  value: { kind: "EnumValue", value: "LOCALE" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "terms" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "locales" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "field" },
                                  value: { kind: "EnumValue", value: "SLUG" },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "locales" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "slug" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "meta" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "urlFragment" } },
                            { kind: "Field", name: { kind: "Name", value: "pageTitle" } },
                            { kind: "Field", name: { kind: "Name", value: "searchDescription" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regionProfile" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "regionIntro" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "components" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "articlesArticles" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "article" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                  kind: "NamedType",
                                                  name: { kind: "Name", value: "Article" },
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "title" },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "date" },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "featuredImage",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "node" },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "altText",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "sourceUrl",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "title",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "link" },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "meta" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "urlFragment",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "reviewslistTitle" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "reviewslistReviews" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "author" } },
                                        { kind: "Field", name: { kind: "Name", value: "quote" } },
                                        { kind: "Field", name: { kind: "Name", value: "rating" } },
                                        { kind: "Field", name: { kind: "Name", value: "title" } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "imagepulloutImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "altText" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "sourceUrl" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "terms" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "where" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "taxonomies" },
                                  value: { kind: "EnumValue", value: "REGION" },
                                },
                              ],
                            },
                          },
                        ],
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                      kind: "NamedType",
                                      name: { kind: "Name", value: "Region" },
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "termTaxonomyId" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "regionProfiles" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "nodes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "locales" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "nodes" },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "name" },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "meta" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: { kind: "Name", value: "urlFragment" },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: "Field", name: { kind: "Name", value: "slug" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "region" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "regionId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "regionTaxonomy" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "components" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "partnernoticeImages" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ltoImage" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "altText" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "sourceUrl" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRegionProfileQuery, GetRegionProfileQueryVariables>;
export const GetRegionIdBySlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetRegionIdBySlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "regionProfileSlug" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "regionProfiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "urlFragment" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "regionProfileSlug" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "regions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRegionIdBySlugQuery, GetRegionIdBySlugQueryVariables>;
export const GetCountriesByRegionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCountriesByRegion" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "regionTermIds" } },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "localeTerms" } },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "countryProfiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "IntValue", value: "500" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "NullValue" },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "taxQuery" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "relation" },
                            value: { kind: "EnumValue", value: "AND" },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "taxArray" },
                            value: {
                              kind: "ListValue",
                              values: [
                                {
                                  kind: "ObjectValue",
                                  fields: [
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "taxonomy" },
                                      value: { kind: "EnumValue", value: "REGION" },
                                    },
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "terms" },
                                      value: {
                                        kind: "Variable",
                                        name: { kind: "Name", value: "regionTermIds" },
                                      },
                                    },
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "field" },
                                      value: { kind: "EnumValue", value: "ID" },
                                    },
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "operator" },
                                      value: { kind: "EnumValue", value: "IN" },
                                    },
                                  ],
                                },
                                {
                                  kind: "ObjectValue",
                                  fields: [
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "taxonomy" },
                                      value: { kind: "EnumValue", value: "LOCALE" },
                                    },
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "terms" },
                                      value: {
                                        kind: "Variable",
                                        name: { kind: "Name", value: "localeTerms" },
                                      },
                                    },
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "field" },
                                      value: { kind: "EnumValue", value: "SLUG" },
                                    },
                                    {
                                      kind: "ObjectField",
                                      name: { kind: "Name", value: "operator" },
                                      value: { kind: "EnumValue", value: "IN" },
                                    },
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "countrycolour" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryheroimage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "altText" } },
                                  { kind: "Field", name: { kind: "Name", value: "sourceUrl" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "meta" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "urlFragment" } },
                            { kind: "Field", name: { kind: "Name", value: "pageTitle" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCountriesByRegionQuery, GetCountriesByRegionQueryVariables>;
export const GetRequestFormDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetRequestForm" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "country" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locale" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "countryProfiles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "urlFragment" },
                      value: { kind: "Variable", name: { kind: "Name", value: "country" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "taxQuery" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "taxArray" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "terms" },
                                  value: {
                                    kind: "ListValue",
                                    values: [
                                      { kind: "Variable", name: { kind: "Name", value: "locale" } },
                                    ],
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "taxonomy" },
                                  value: { kind: "EnumValue", value: "LOCALE" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "field" },
                                  value: { kind: "EnumValue", value: "SLUG" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "operator" },
                                  value: { kind: "EnumValue", value: "IN" },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestForm" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "countryRequestFormImage" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "sourceUrl" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRequestFormQuery, GetRequestFormQueryVariables>;
export const GetSubscribeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSubscribe" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "locales" } },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "globalContents" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "taxQuery" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "taxArray" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "taxonomy" },
                                  value: { kind: "EnumValue", value: "LOCALE" },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "terms" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "locales" },
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "field" },
                                  value: { kind: "EnumValue", value: "SLUG" },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "globalContent" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subscribeComponent" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "subscribeText" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "subscribeBodyText" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSubscribeQuery, GetSubscribeQueryVariables>;
export const GetTagsBySlugDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetTagsBySlug" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "slugs" } },
          type: {
            kind: "ListType",
            type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "tags" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "slug" },
                      value: { kind: "Variable", name: { kind: "Name", value: "slugs" } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "slug" } },
                      { kind: "Field", name: { kind: "Name", value: "count" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetTagsBySlugQuery, GetTagsBySlugQueryVariables>;
